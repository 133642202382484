import { Component, Input, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'child-modal',
  templateUrl: 'child-modal.component.html'
})
export class ChildModalComponent {
  @Input()
    model:any;
  @ViewChild('modal')
    modal:ModalDirective;

  isVisible:boolean=false;
  resolver:any;

  public show():Promise<any> {
    // this.modal.show();

    var result=new Promise(
      (resolve, reject) => {

        this.modal.show();
        this.isVisible=true;

        this.resolver=resolve;

      }
    );

    result.then(value => {
      this.hide();
    });

    return result;

  }

  public hide():void {
    this.modal.hide();
    this.isVisible=false;
  }

  public confirm():void {
    this.resolver(true);

  }

  public reject():void {
    this.resolver(false);
  }

}
