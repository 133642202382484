
import { Component, OnInit, Input } from '@angular/core';
import { PAGES, Configuration } from "../../../core/services/configuration/configuration";
import { JourneyPage } from "../../../core/services/types";
import { TokenService } from '../../../core/services/token/token.service';

@Component({
  selector: 'section-headings',
  templateUrl: './section-headings.component.html',
  styleUrls: ['./section-headings.component.scss']
})
export class SectionHeadingsComponent implements OnInit {

  @Input()
  page: string;

  showActiveItemOnly: boolean = Configuration.loadCurrentJourneyType().topMenu.showActiveItemOnly;

  pages: JourneyPage[];

  constructor(private tokenService: TokenService ) { }

  ngOnInit() {
    this.pages = Configuration.loadCurrentJourneyPages(this.tokenService.roles); 
  }

}
