
import { Icon } from './../icon-component/icon-component';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Configuration } from './../../../core/services/configuration/configuration';
import { ActionsService } from "../../../core/services/actions/actions.service";

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'section-container',
  templateUrl: './section-container.component.html',
  styleUrls: ['./section-container.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        state(
          'open',
          style({ height: '*' })
          // style({ height: '200px' })
        ),
        state(
          'close',
          style({ height: 0, paddingTop: 0, paddingBottom: 0, overflow: 'hidden' })
        ),
        transition('open => close', animate('300ms ease-in')),
        transition('close => open', animate('300ms ease-out'))
      ]
    )
  ]
})


export class SectionContainerComponent implements OnInit, OnChanges {

  @Input()
  valid: boolean;

  @Input()
  enabled: boolean = true;

  @Input()
  editing: boolean;

  @Input()
  title: string;

  @Input()
  invalidIcon: string;

  @Input()
  validIconSmall: string;

  @Input()
  id: string;

  @Input()
  creationTimeInSeconds: number;

  icons: Array<Icon> = []

  @Output()
  toggleHelp: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  stayOpen: boolean;

  closed: boolean = true;
  observer: Subscription;

  isLoading = false;
  loadingText: string = '...loading...';

  constructor(private actionsService: ActionsService) { }

  ngOnInit() {

    if (this.stayOpen == null) {
      this.setOpenDefaultSetting(this.valid);
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes["valid"] || changes["editing"]) {
      this.icons = this.getIcons();
    }

    if (typeof changes.enabled != 'undefined') {
      this.checkEnabled();
    }

    if (typeof changes.stayOpen != 'undefined' && this.stayOpen != null) {
      if (this.stayOpen) {
        this.open();
      }
      else {
        this.close();
      }
    }
  }

  ngAfterViewInit() {

    this.registerOpenObserver();
    this.registerLoadingObserver();
  }

  ngOnDestroy() {

    this.actionsService.cancelSubscription(this.observer);

  }

  toggleCollapse() {

    if (this.enabled) {
      this.closed = !this.closed;

      if (!this.closed) {
        this.actionsService.broadcast(
          {
            action: 'section',
            behaviour: 'scroll',
            value: this.id
          }
        )
      }
    }

  }

  open() {

    this.closed = false;

  }

  close() {

    this.closed = true;

  }

  clickHelp(event) {

    if (!this.closed) {
      event.cancelBubble = true;
    }
    this.toggleHelp.emit(true);

  }

  /*
    This observer subscription is set up to allow the open default setting to be applied for components other than through
    view initialisation
  */
  registerOpenObserver() {

    this.observer = this.actionsService.registerObserver(
      `${this.id}-section-container.set-open`,
      action => {
        this.setOpenDefaultSetting(action.value);
      },
      this
    );

  }


  registerLoadingObserver() {

    this.observer = this.actionsService.registerObserver(
      `${this.id}-section-container.set-loading`,
      action => {
        if (action.value && action.value.isLoading) {
          this.setOpenDefaultSetting(!action.value.isLoading);
        }
        this.isLoading = action.value.isLoading;
        this.loadingText = action.value.loadingText;
      },
      this
    );

  }

  checkEnabled(): boolean {

    if (!this.enabled) {
      this.closed = true;
      return false;
    }
    return true;

  }

  setOpenDefaultSetting(valid: boolean) {

    if (this.checkEnabled()) {

      switch (Configuration.loadCurrentJourneyType().sectionNavigation.sectionCollapse) {
        case 'ALL_COLLAPSED':
          this.closed = true;
          break;

        case 'ALL_OPEN':
          this.closed = false;
          break;

        case 'INVALID_OPEN':
          if (valid != null) {
            this.closed = valid;
          }
          break;

        default:

      }
    }

    return this.closed;

  }

  getIcons(): Array<Icon> {
    return [
      <Icon>{
        classes: 'icon-tick',
        value: this.editing && this.valid,
        title: this.title,
      },
      <Icon>{
        classes: this.invalidIcon + ' icon-invalid',
        value: this.editing && !this.valid,
        title: this.title,
      },
      <Icon>{
        classes: 'icon-lock',
        value: !this.editing,
        title: this.title,
      }
    ]
  }

}
