
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {finalize, catchError, timeout, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpResponseBase
  // Response 
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoanOffer } from '../types';
import { TimedActionService } from '../timed-action/timed-action.service';
import { LoginService } from '../login/login.service';
import { ApplicationStatusService } from '../application-status/application-status.service';
import { LoadingModalService } from '../modal/loading-modal.service';

@Injectable()
export class ApplyService {

  applyUrl: string = environment.base + '/quotes/submitproposal';
  updateProposalUrl: string = environment.base + '/lookUp/proposalstatusupdate';
  applyOfferTimeout;

  private body: any = {
    quoteId: null
  };

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private timedActionService: TimedActionService,
    private applicationStatusService: ApplicationStatusService,
    private loadingModalService: LoadingModalService) {

    this.applyOfferTimeout = this.applicationStatusService.configuration.acceptanceConfiguration.applyTimeout;
  }

  applyForLoan(quoteId): Observable<Array<LoanOffer>> {
    this.loadingModalService.show();
    this.loadingModalService.displayMessage(1);
    return this.http.post(this.applyUrl, Number(quoteId), {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        this.logApplicationForLoanResult(true);
        return response;
      }),
      timeout(this.applyOfferTimeout),
      catchError((error) => {

        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        this.logApplicationForLoanResult(false);
        return new Array();
      }),
      finalize(() => this.loadingModalService.hide()),);
  }

  updateProposalStatus(contactid): Observable<Array<any>>{
    return this.http.post(this.updateProposalUrl, Number(contactid), {headers: this.loginService.getHeaderOptions(true)}).pipe(
    map((response: HttpResponse<any>) => {
      return response.body;
    }),
    catchError((error) => {
      return observableThrowError(error);
    }),)
  }

  logApplicationForLoanResult(success: boolean) {
    if (success) {
      window['dataLayer'].push(
        {
          event: 'service-response',
          label: 'successful-loan-application',
          timestamp: new Date().toISOString()
        }
      )
    } else {
      window['dataLayer'].push(
        {
          event: 'service-response',
          label: 'unsuccessful-loan-application',
          timestamp: new Date().toISOString()
        }
      )
    }
  }
}
