import { FinanceOptions } from "./configuration.types";

const FINANCE_OPTIONS: FinanceOptions = {
    HP: {
        label: 'Hire Purchase',
        requiresAnnualMileage: false,
        maximumDeposit: 1.0,
        conditions: [],
        content: '<ul class="list"><li>Fixed Monthly Payments</li><li>Fixed Interest</li><li>No annual mileage limit</li><li>Own vehicle at end of agreement</li></ul>',
        productLink: 'https://www.creditplus.co.uk/car-finance/options/hire-purchase/'
    },
    PCP: {
        label: 'Personal Contract Purchase',
        requiresAnnualMileage: true,
        maximumDeposit: 0.3,
        conditions: [
            {
                key: 'vehicleage',
                comparison: '<=',
                value: 4
            },
            {
                key: 'mileage',
                comparison: '<=',
                value: 60000
            }
        ],
        content: '<ul class="list"><li>Lower monthly payments</li><li>Annual mileage limit</li><li>Balloon payment at end of contract (pay and keep car, leave car or use it as a deposit for a new car)</li><li>Available for good credit ratings and above</li></ul>',
        productLink: 'https://www.creditplus.co.uk/car-finance/options/personal-contract-purchase/'
    },
    LP: {
        label: 'Lease Purchase',
        requiresAnnualMileage: true,
        maximumDeposit: 0.3,
        conditions: [
            {
                key: 'vehicleage',
                comparison: '<=',
                value: 4
            }
        ],
        content: '<ul class="list"><li>Fixed Monthly Payments</li><li>Defer up to 31% of payment to end of agreement</li><li>Own vehicle at end of agreement</li><li>Balloon payment (must be paid)</li></ul>',
        productLink: 'https://www.creditplus.co.uk/car-finance-glossary/p/personal-contract-hire/'
    }
};


export {
    FINANCE_OPTIONS
}