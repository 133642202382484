
const defaultModalSettings = {
    title: 'Discard Changes',
    content: 'Are you sure that you want to discard the changes?',
    yes: 'Yes',
    no: 'No',
    config: {
    ignoreBackdropClick: true,
    keyboard: false
    }
};

const defaultErrorMessages= {
    required: 'The {{field}} is required.',
    registrationRequired: 'The {{field}} is required, or search one of our cars',
    minlength: 'The {{field}} must be at least {{requiredLength}} characters long.',
    maxlength: 'The {{field}} cannot be more than {{requiredLength}} characters long.',
    min: 'The {{field}} should have a minimum value of {{minimumValue}}.',
    max: 'The {{field}} should have a maximum value of {{maximumValue}}.',
    minDate:'The {{field}} cannot be before {{minimumValue}}.',
    maxDate:'The {{field}} cannot be after {{maximumValue}}.',
    anyof: 'The {{field}} should be included in {{requiredValue}}.',
    pattern:'The {{field}} is invalid.',
    nonBlank:'The {{field}} is required'
};

export {
    defaultModalSettings,
    defaultErrorMessages
};
