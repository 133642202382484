<div [ngClass]="cssClass" #headerbar>
  <div class="channel">
    <!-- <div class="icon-container">
      <div class="icon"><img class="icon-img" src="../../../../assets/navbar/home.png"></div>
      <div class="icon"><img class="icon-img" src="../../../../assets/navbar/documents.png"></div>
      <div class="icon"><img class="icon-img" src="../../../../assets/navbar/vehicles.png"></div>
      <div class="icon"><img class="icon-img" src="../../../../assets/navbar/admin.png"></div>
    </div> -->

    <!-- <div> 
      <a [routerLink]="homeRoute" routerLinkActive="active" class="navbar-brand" href="#">
        <span #mobilelogo class="mobile-logo">creditplus</span>
      </a>
    </div> -->
    <!--
    <div class="contact-container">
      <div *ngIf="dealerPortal">
        <div class="list">
          <div class="navigate">
            <a (click)="redirectToPortal()">Home</a>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="trustpilot-container" *ngIf="!dp">
          <img src="../../../../assets/trustpilot.png" class="trustpilot">
        </div>
        <div class="contact-phone desktop-only" >
          <a class="call" [href]="contact">
            <p >Need some help?
              <strong>{{contact}}</strong>
            </p>
          </a>
        </div>
        <div class="contact-phone2">
          <a href=tel:{{contact}} class="call-link">
            <input type="button" class="cpCtaButton next" value="Call us" *ngIf="cp">
            <input type="button" class="next dp" value="Call us" *ngIf="dp">
            <input type="button" class="next ccf" value="Call us" *ngIf="ccf">
          </a>
        </div>
      </div>
    </div> -->
  </div>


   <div *ngIf="!loggedIn" class="channel">
    <div>
      <a routerLink="/login" class="navbar-brand">
         <img [src]="logo" />
        
      </a>
    </div>
  </div> 
</div>