import { CapDetails } from "./CapDetails";

export class Vehicle {    
  Make: string;
  Model: string;
  IsMock: boolean;
  Mileage:number;
  Colour:string;
  Registration:string;
  ImageUrl_Main: string;
  ImageUrl_More1: string;
  ImageUrl_More2: string;
  ImageUrl_More3: string;
  ImageUrl_More4: string;
  ImageUrl_More5: string;
  ImageUrl_More6: string;
  ImageUrl_More7: string;
  ImageUrl_More8: string;
  ImageUrl_More9: string;
  Variant: string;
  RegistrationYear: number;
  Doors: number;
  Transmission: string;
  TransmissionType: number; // to match G3 leads
  FuelType: string;
  EngineSizeCC: number;
  EngineSize: number;
  Price: number;
  Description: string;
  BodyType: string;
  //Id for finding vehicle in database
  Id:number;
  RetailPrice: number;
  MonthlyPrice: number;
  CapDetails: CapDetails;
}

export class ApplicantVehicle {    
  make: string;
  model: string;
  isMock: boolean;
  mileage:number;
  colour:string;
  registration:string;
  imageUrl_Main: string;
  imageUrl_More1: string;
  imageUrl_More2: string;
  imageUrl_More3: string;
  imageUrl_More4: string;
  imageUrl_More5: string;
  imageUrl_More6: string;
  imageUrl_More7: string;
  imageUrl_More8: string;
  imageUrl_More9: string;
  variant: string;
  registrationYear: number;
  doors: number;
  transmission: string;
  transmissionType: number; // to match G3 leads
  fuelType: string;
  engineSizeCC: number;
  price: number;
  description: string;
  bodyType: string;
  //Id for finding vehicle in database
  id:number;
  retailPrice: number;
  monthlyPrice: number;
  capDetails: CapDetails;
}
