import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { ButtonGroup, NumberPlusMinus } from './../models.class';

import { SaveCancelComponent }  from './../toolbar/save-cancel/save-cancel.component';
import { RecordNavigatorComponent }  from './../toolbar/record-navigator/record-navigator.component';

import { ActionsService } from './../../../core/services/actions/actions.service';

@Component({
    selector: 'form-container',
    templateUrl: 'form-container.component.html',
    styleUrls: ['form-container.component.scss'],
    providers: [ ActionsService ]
})
export class FormContainerComponent implements OnInit {

    // @Input()
    public cont :string="TOOLBAR1";

    @ViewChild(SaveCancelComponent)
    private saveCancelComponent: SaveCancelComponent;

    @ViewChild(RecordNavigatorComponent)
    private recordNavigatorComponent: RecordNavigatorComponent;

    public buttonmodel :ButtonGroup;
    public model :ButtonGroup;
    public dropdownmodel :ButtonGroup;
    public listgroupmodel  :ButtonGroup;
    public breadcrumbmodel :any;
    public numberplusminusmodel :NumberPlusMinus;

    private actionsSubscription: Subscription;

    constructor(private actionsService: ActionsService) {

        this.actionsSubscription=this.actionsService.registerGlobalObserver(
            action => { this.action(action); },
            this
        );

        this.breadcrumbmodel={
            items:[
                {link:'', text:'Breadcrumb 1'},
                {link:'', text:'Breadcrumb 2'},
                {link:'', text:'Breadcrumb 3'}
            ]
        };

        this.model=new ButtonGroup(
            {
                group:'SORT',
                type:'buttongroup',
                btnclass:'btn-primary',
                navbar:true,
                selection:'single',
                visible:true,
                items:[
                    {text:'Sort 1', action:'SORT1', disabled:false, active:false, visible:true},
                    {text:'Sort 2', action:'SORT2', disabled:false, active:false, visible:true},
                    {text:'Sort 3', action:'SORT3', disabled:false, active:false, visible:true}
                ]
            }
        );

        this.dropdownmodel=new ButtonGroup(
            {
                group:'SORT',
                type:'dropdown',
                btnclass:'btn-success',
                navbar:true,
                selection:'single',
                visible:true,
                items:[
                    {text:'Item 1', action:'ITEM1', disabled:false, active:false, visible:true},
                    {text:'Item 2', action:'ITEM2', disabled:false, active:false, visible:true},
                    {text:'Item 3', action:'ITEM3', disabled:false, active:false, visible:true}
                ]
            }
        );

        this.buttonmodel=new ButtonGroup(
            {
                group:'BUTTON',
                type:'buttons',
                btnclass:'btn-success',
                navbar:true,
                selection:'multiple',
                visible:true,
                items:[
                    {text:'Button 1', action:'BUTTON1', disabled:false, active:false, visible:true, candelete:true},
                    {text:'Button 2', action:'BUTTON2', disabled:false, active:false, visible:true, candelete:true},
                    {text:'Button 3', action:'BUTTON3', disabled:false, active:false, visible:true, candelete:true}
                ]
            }
        );

        this.listgroupmodel=new ButtonGroup(
            {
                group:'LISTGROUP',
                type:'listgroup',
                btnclass:'',
                navbar:false,
                selection:'multiple',
                visible:true,
                items:[
                    {text:'List item 1', action:'LIST1', disabled:false, active:false, visible:true, candelete:true},
                    {text:'List item 2', action:'LIST2', disabled:false, active:false, visible:true, candelete:true},
                    {text:'List item 3', action:'LIST3', disabled:false, active:false, visible:true, candelete:true}
                ]
            }
        );

         this.numberplusminusmodel={
            value:5,
            min:0,
            max:10,
            visible:true,
            enabled:true
         };

    }
    ngOnDestroy(){

      this.actionsSubscription.unsubscribe();

    }

    gotoNextRecord() {

        this.recordNavigatorComponent.next();
        this.saveCancelComponent.disable('cancel');
        this.saveCancelComponent.toggle('save');
    }

    action(action) {

        switch (action.group) {
            case 'search' :
                var tag={text:action.value, action:'BUTTON'+(this.buttonmodel.items.length+2), disabled:false, active:false, visible:true, candelete:true};
                this.buttonmodel.items.push(tag);
                break;
            default:
                break;
        }

    }

    save() {

    }

    cancel() {

    }

    search(value) {

    }

    filter() {

    }

    position(position) {

    }

    goto(page) {

    }

    add() {

    }

    edit() {

    }

    delete() {

    }

    sort(key) {

    }

    ngOnInit() {

        this.actionsService.context=this.cont;

    }

}
