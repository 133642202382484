
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URLS } from '../../../core/services/configuration/configuration.urls';
import { AuthenticationResult } from './../../../core/services/login/loginmodels';
import { LoginService } from '../../../core/services/login/login.service';


@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  username: string;
  contactId: string;
  permanentUser: boolean = false;
  nextPageUrl: string;
  nextPageUrlDp: string;
  source: string;

  /*
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private loginService: LoginService) {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.permanentUser = params['perm'] ? true : false;
        this.username = params['username'];
        this.contactId = params['customerId'];
        this.permanentUser = params['perm'] ? true : false;
        this.source = params['source'];
        if (!this.username || !this.contactId) {
          this.router.navigateByUrl(URLS.NotFound.route);
        }
      }
    );
  }
  
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.permanentUser = params['perm'] ? true : false;
        this.username = params['username'];
        this.contactId = params['customerId'];
        this.permanentUser = params['perm'] ? true : false;
        if (!this.username || !this.contactId) {
          this.router.navigateByUrl(URLS.NotFound.route);
        }
      }
    );
    this.nextPageUrl = 'register-panel?username=' + this.username + '&customerId=' + this.contactId;
    this.nextPageUrlDp = 'login';
    if (!this.permanentUser) {
        this.submitTempRegistration();
    }
  }
  

  async submitTempRegistration() {
    let registrationResult: AuthenticationResult = await this.loginService.temporaryRegister(this.username, this.contactId).toPromise();
    if (this.isValidAnswer(registrationResult)) {
      this.loginService.setUserInformation(registrationResult);
      this.router.navigateByUrl(this.nextPageUrl);
    }
  }
  
  */
  private isValidAnswer(result: AuthenticationResult) {
    return result
      && result.username
      && result.bearerToken
      && result.id
  }
  
}
