import { ApplicationStatusService } from './../application-status/application-status.service';
import { Injectable } from '@angular/core';

import { Application } from "../types";

import { ApplicationError } from '../global-error-handler/application-error';

@Injectable()
export class CreditRatingService {

  ratingMethod;

  constructor(private applicationStatusService : ApplicationStatusService) { 
    this.ratingMethod = this.applicationStatusService.configuration.creditRating.ratingMethod;
  }

  getSoftSearchRatingScore(application: Application) {

    if (application.creditRating.score == null) {
      throw new ApplicationError('No Credit Rating supplied', this.applicationStatusService.configuration.errors.creditRating_generic);
    }

    return application.creditRating.score;

  }

  getRating(application: Application): any {

    return this.getSoftSearchRatingScore(application);
  }
}
