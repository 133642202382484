import { Component, Input, OnInit } from '@angular/core';

import { ActionsService } from './../../../../core/services/actions/actions.service';

import { RecordNavigator, Navigate, Visibility, Broadcast } from './../../models.class';

@Component({
    moduleId:module.id,
    selector: 'record-navigator',
    templateUrl: 'record-navigator.component.html',
    styleUrls: ['record-navigator.component.scss']
})
export class RecordNavigatorComponent implements OnInit, Navigate, Visibility, Broadcast {

    @Input()
    public model :RecordNavigator={
        items:[1,2,3,4,5],
        position:0,
        visible:true
    };

    constructor(private actionsService :ActionsService) {

    }

    ngOnInit() {

    }

    first() {

        if (this.model.position>0) {
            this.model.position=0;
            this.broadcast();
        }

    }

    last() {

        if (this.model.position<(this.model.items.length-1)) {
            this.model.position=this.model.items.length-1;
            this.broadcast();
        }

    }

    next() {

        if (this.model.position<(this.model.items.length-1)) {
            this.model.position++;
            this.broadcast();
        };

    }

    previous() {

        if (this.model.position>0) {
            this.model.position--;
            this.broadcast();
        };

    }

    goto(position :number) {

        if (position>-1 && position<this.model.items.length-1) {
            this.model.position=position;
            this.broadcast();
        };

    }

    show() {

        this.model.visible=true;

    }

    hide() {

        this.model.visible=false;

    }

    toggle() {

        this.model.visible=(! this.model.visible);

    }

    broadcast() {

        var action={
            group:'navigator',
            position:this.model.position
        };

        this.actionsService.broadcast(action);

    }
}
