import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'panoramic-viewer',
  templateUrl: './panoramic-viewer.component.html',
  styleUrls: ['./panoramic-viewer.component.css']
})
export class PanoramicViewerComponent implements OnInit {

  @Input() imageUrl:string;

  imageWasResized:boolean = false;

  constructor() { }

  ngOnInit(): void {
}

ngAfterViewInit(){
  
  const create360Viewer = require('360-image-viewer');
  const canvasFit = require('canvas-fit');
 
  // load your image
  var image = new Image();
  image.crossOrigin = "anonymous"; // ask for CORS permission
  image.src = this.imageUrl;
  
  
  image.onload = () => {
    if (this.imageWasResized==false){
  var canvas = document.createElement('canvas');
  canvas.width = image.width/2;
  canvas.height = image.height/2;
  canvas.getContext('2d').drawImage(image, 0, 0, image.width/2, image.height/2);
  image.src = canvas.toDataURL('image/jpeg');
  this.imageWasResized=true;
    }
    else{
  var viewer = create360Viewer({
    image:image, fov: 90, rotateSpeed: -0.2
  });
  // attach canvas to body
  document.getElementById("canvas-holder").appendChild(viewer.canvas);
 
  // setup fullscreen canvas sizing
  const fit = canvasFit(viewer.canvas, window, window.devicePixelRatio);
  window.addEventListener('resize', fit, false);
  fit();
 
  // start the render loop
  viewer.start();

  }
}
}

}
