<div class="showroom-container" *ngIf="!vehicle_details_page_on">
  <div [ngClass]="(!displayVehiclesMobile)?'desktop-only-showroom filter-container':'filter-container-mobile'">
    <div class="left-side">
      <side-bar [isShowroom]="true" [application]="application"></side-bar>
    </div>
  </div>
  <div class="vehicles-container">
    <!-- <div class="mobile-only-showroom top-section" [hidden]=(displayVehiclesMobile)>
      <a (click)="returnQuotes()">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.05469 11.918C7.16406 11.8086 7.21875 11.6445 7.21875 11.4531C7.21875 11.2891 7.13672 11.125 7.02734 10.9883L3.74609 7.84375H11.5938C11.7578 7.84375 11.9219 7.78906 12.0586 7.65234C12.168 7.54297 12.25 7.37891 12.25 7.1875V6.3125C12.25 6.14844 12.168 5.98438 12.0586 5.84766C11.9219 5.73828 11.7578 5.65625 11.5938 5.65625H3.74609L7.02734 2.51172C7.13672 2.40234 7.21875 2.23828 7.21875 2.04688C7.21875 1.88281 7.16406 1.71875 7.05469 1.58203L6.42578 0.980469C6.28906 0.871094 6.125 0.789062 5.96094 0.789062C5.76953 0.789062 5.60547 0.871094 5.49609 0.980469L0.191406 6.28516C0.0546875 6.42188 0 6.58594 0 6.75C0 6.94141 0.0546875 7.10547 0.191406 7.21484L5.49609 12.5195C5.60547 12.6562 5.76953 12.7109 5.96094 12.7109C6.125 12.7109 6.28906 12.6562 6.42578 12.5195L7.05469 11.918Z"
            fill="#203757" />
        </svg>
        Back to Quotes
      </a>
    </div> -->
    <div class="right">
      <div class="page-size-selector" [hidden]=(displayVehiclesMobile)>
        <!-- <div class="title">
          <h2>Search Vehicles</h2>
        </div> -->
        <button class="scroll-top" (click)="scrollToTop();">Top</button>
        <div class="top-bar">
          <div class="mobile-only-showroom mobile-filters">
            <!-- <div class="mobile-filter" (click)="displayFilters()">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3438 0.75C13.5078 0.75 13.6719 0.832031 13.8086 0.941406C13.918 1.07812 14 1.24219 14 1.40625C14 1.59766 13.918 1.76172 13.8086 1.87109L8.75 6.92969V14.0938C8.75 14.3672 8.61328 14.5859 8.39453 14.6953C8.14844 14.8047 7.92969 14.8047 7.71094 14.6406L5.52344 13.1094C5.33203 12.9727 5.25 12.7812 5.25 12.5625V6.92969L0.191406 1.87109C0.0546875 1.76172 0 1.59766 0 1.40625C0 1.24219 0.0546875 1.07812 0.191406 0.941406C0.300781 0.832031 0.464844 0.75 0.65625 0.75H13.3438Z"
                  fill="#203757" />
              </svg>
              Filters
            </div> -->
            <div class="sml-btn">Browse</div>
            <div class="sml-btn" (click)="displayFilters()">Filters</div>
            <div class="sml-btn">Add</div>
            <div class="reset-button">
              <a (click)="clearFilterValues()">
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.7188 0.5C11.7891 0.5 11.8594 0.546875 11.9062 0.59375C11.9531 0.640625 12 0.710938 12 0.78125V5.46875C12 5.5625 11.9531 5.63281 11.9062 5.67969C11.8594 5.72656 11.7891 5.75 11.7188 5.75H7.03125C6.9375 5.75 6.86719 5.72656 6.82031 5.67969C6.77344 5.63281 6.75 5.5625 6.75 5.46875V4.36719C6.75 4.29688 6.77344 4.22656 6.82031 4.17969C6.86719 4.13281 6.96094 4.08594 7.05469 4.08594L9.42188 4.20312C9.04688 3.64062 8.55469 3.19531 7.94531 2.86719C7.33594 2.53906 6.70312 2.375 6 2.375C5.25 2.375 4.54688 2.5625 3.91406 2.9375C3.28125 3.3125 2.78906 3.80469 2.41406 4.4375C2.03906 5.07031 1.875 5.75 1.875 6.5C1.875 7.25 2.03906 7.95312 2.41406 8.58594C2.78906 9.21875 3.28125 9.71094 3.91406 10.0859C4.54688 10.4609 5.25 10.625 6 10.625C6.51562 10.625 7.00781 10.5547 7.47656 10.3672C7.94531 10.1797 8.34375 9.92188 8.71875 9.59375C8.76562 9.54688 8.83594 9.52344 8.90625 9.52344C8.97656 9.52344 9.04688 9.57031 9.11719 9.61719L9.91406 10.4141C9.96094 10.4609 9.98438 10.5312 9.98438 10.625C9.98438 10.7188 9.9375 10.7656 9.89062 10.8125C9.375 11.2812 8.76562 11.6562 8.10938 11.9141C7.42969 12.1953 6.72656 12.3125 6 12.3125C4.94531 12.3125 3.98438 12.0547 3.09375 11.5391C2.20312 11.0234 1.47656 10.3203 0.960938 9.42969C0.445312 8.53906 0.1875 7.57812 0.1875 6.52344C0.1875 5.46875 0.445312 4.48438 0.960938 3.59375C1.47656 2.70312 2.20312 2 3.09375 1.48438C3.98438 0.96875 4.94531 0.6875 6 0.6875C6.86719 0.6875 7.6875 0.875 8.46094 1.22656C9.21094 1.60156 9.86719 2.09375 10.4297 2.72656L10.3359 0.804688C10.3359 0.710938 10.3594 0.640625 10.4062 0.59375C10.4531 0.546875 10.5234 0.5 10.6172 0.5H11.7188Z"
                    fill="#203757" />
                </svg>
                Reset
              </a>
            </div>
          </div>
          <div class="inputs">
            <div class="vehicle-count">
              <p *ngIf="!isDemo" class="count-cars">{{pagerModel.totalRecords}} vehicles found</p>
              <p *ngIf="isDemo" class="count-cars">131569 vehicles found</p>
            </div>
          </div>
          <div class="sort">
            <order-by-selector [filterOptions]="filterOption"></order-by-selector>
          </div>
        </div>
        <div *ngIf="isVehicleShowroomEmpty()" class="empty-showroom">
          <h3>There are 0 results found</h3>
        </div>
        <div *ngIf="!isVehicleShowroomEmpty()" class="showroom-item-wrapper">
          <div *ngFor="let vehicle of showroomVehicleResults" class="showroom-item">
            <quote-showroom-item [vehicle]="vehicle" (vehicleChange)="vehicleFound($event)" [application]="application" [id]="cardId" (vehicleSelected)='vehicleSelected($event)' (financeBreakdownClicked)='financeBreakdownClicked($event)'></quote-showroom-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
