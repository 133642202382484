<ng-template #popoverTemplate>
  <p>Please complete the following sections:</p>
  <ul>
    <ng-container *ngFor="let item of invalidSubSet">
      <li *ngIf="!item.valid">
        <a (click)="scrollToSection(item.key)">{{item.title}}</a>
      </li>
    </ng-container>
  </ul>
</ng-template>
<button [disabled]="disabled" [hidden]="hidden" (click)="proceed.emit()" class="btn btn-main button-cp-cta"
[popover]="popoverTemplate" #popoverDirective="bs-popover" placement="right" triggers="">{{label}}</button>
