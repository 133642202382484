
/*
  Conditions
*/
class Condition {
    public key: 'retailprice' | 'enginesize' | 'netmonthlyincome' | 'mileage' | 'vehicleage';
    public comparison: '=' | '<=' | '>=' | '<>' | '>' | '<';
    public value: number | string | boolean;
}

class AnswerCondition {
    public key: string;
    public comparison: '=' | '<=' | '>=' | '<>' | '>' | '<';
    public value: number | string | boolean;
}

class AnswersCondition {
    public operator: 'OR' | 'AND';
    public conditions: AnswerCondition[];
}

export {
    Condition, AnswerCondition, AnswersCondition
}