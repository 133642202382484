import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";


@Component({
  selector: 'form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.scss']
})
export class FormGeneratorComponent implements OnInit {

  @Input()
  items: any[] = [
    {
      name: 'forename',
      label: 'Forename',
      description: 'This is the help text for the forename',
      validators: []
    },
    {
      name: 'middleNames',
      label: 'Middle names',
      description: 'This is the help text for the middle names',
      validators: []
    },
    {
      name: 'surname',
      label: 'Surname',
      description: 'This is the help text for the surname',
      validators: []
    }
  ];

  outerHTML: string;
  innerHTML: string='';
  innerHTMLLine: string;
  safeHTML: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {

    this.items.map(
      (item) => {

        this.innerHTMLLine =
          `<label for="${item.name}">${item.label}</label>
          <input type="text" class="form-control" formControlName="${ item.name}" aria-describedby="${item.name}HelpText">
          <p id="${ item.name}HelpText" class="form-text text-muted">
            ${ item.description}
          </p>
          <control-error-messages [model]="formErrors.${ item.name }"></control-error-messages>
          `;

        this.innerHTML += this.innerHTMLLine;
      }
    );

          this.outerHTML=`
            <div class="panel panel-default">
              <div class="panel-body" [innerHTML]="safeHTML">
                <form [formGroup]="form" (ngSubmit)="submit()">
                  <fieldset [disabled]="!editing">
                  ${ this.innerHTML }
                    <form-error-messages [model]="{formErrors:formErrors, valid:form.valid}"></form-error-messages>
                  </fieldset>
                </form>
              </div>
            </div>
          `;

        this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(this.outerHTML);
  }

  ngOnInit() {
  }

}
