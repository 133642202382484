import { Component, Input } from '@angular/core';

import { ButtonGroupComponent }  from './../button-group/button-group.component';
import { ButtonGroup } from './../../models.class';

@Component({
    selector: 'amendment',
    templateUrl: 'amendment.component.html',
    styleUrls: ['amendment.component.scss']
})

export class AmendmentComponent extends ButtonGroupComponent {

    @Input()
    model :ButtonGroup = new ButtonGroup(
         {
            group:'AMENDMENT',
            type:'icons',
            btnclass:'btn-primary',
            navbar:true,
            selection:'none',
            visible:true,
            items:[
                {icon:'fa-plus', action:'ADD', disabled:false, active:false, visible:true},
                {icon:'fa-pencil', action:'EDIT', disabled:false, active:false, visible:true},
                {icon:'fa-trash', action:'DELETE', disabled:false, active:false, visible:true}
            ]
        }
    );

}
