import { IconComponent } from './components/icon-component/icon-component';
import { AddressComponent } from './components/address/address.component';
import { IsMobileService } from './components/is-mobile/is-mobile.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule} from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { ChildModalComponent } from './components/child-modal/child-modal.component';
import { ControlErrorMessagesComponent } from './components/control-error-messages/control-error-messages.component';
import { FormErrorMessagesComponent } from './components/form-error-messages/form-error-messages.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { ApplicationHeaderComponent } from './components/application-header/application-header.component';
import { ApplicationFooterComponent } from './components/application-footer/application-footer.component';

import { SortComponent } from './components/toolbar/sort/sort.component';
import { FilterComponent } from './components/toolbar/filter/filter.component';
import { SearchComponent } from './components/toolbar/search/search.component';
import { RecordsetPagerComponent } from './components/toolbar/recordset-pager/recordset-pager.component';
import { RecordNavigatorComponent } from './components/toolbar/record-navigator/record-navigator.component';
import { ToolbarComponent } from './components/toolbar/toolbar/toolbar.component';
import { ButtonGroupComponent } from './components/toolbar/button-group/button-group.component';
import { AmendmentComponent } from './components/toolbar/amendment/amendment.component';
import { SaveCancelComponent } from './components/toolbar/save-cancel/save-cancel.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { SortDirectionComponent } from './components/toolbar/sort-direction/sort-direction.component';
import { NumberPlusMinusComponent } from './components/number-plus-minus/number-plus-minus.component';

import { LoginStatusComponent } from './components/login-status/login-status.component';
import { BaseFormComponent } from './components/base-form/base-form.component';

import { FullnamePipe } from './../shared/pipes/fullname/fullname.pipe';
import { AddressPipe } from './pipes/address/address.pipe';
import { SafePipe } from './pipes/safe/safe.pipe';
import { MinDirective } from './directives/validation/min/min.directive';
import { MaxDirective } from './directives/validation/max/max.directive';
import { AnyofDirective } from './directives/validation/anyof/anyof.directive';
import { MinDateDirective } from './directives/validation/minDate/minDate.directive';
import { MaxDateDirective } from './directives/validation/maxDate/maxDate.directive';
import { NonBlankDirective } from './directives/validation/nonBlank/nonBlank.directive';

import { PasswordPolicyValidatorDirective } from './directives/validation/password-policy-validator/password-policy-validator.directive';

import { SectionContainerComponent } from './components/section-container/section-container.component';
import { FocusIfDirective } from './directives/focus/focus-if.directive';
import { SelectAllOnFocusDirective } from './directives/focus/select-all-on-focus.directive';
import { LoadingObserverComponent } from './components/loading/loading-observer.component';
import { FormGeneratorComponent } from './components/form-generator/form-generator.component';
import { HelpPanelComponent } from './components/help-panel/help-panel.component';
import { ValidInvalidIconComponent } from './components/valid-invalid-icon/valid-invalid-icon.component';
import { CustomRadioButtonComponent } from './components/custom-radio-button/custom-radio-button.component';
import { YesOrNoComponent } from './components/custom-radio-button/yes-or-no.component';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DocumentListerComponent } from './components/document-lister/document-lister.component';
import { ProductLinkDirective } from './components/product-link/product-link.component';
import { IsMobileDirective } from './components/is-mobile/is-mobile.directive';
import { TelephoneComponent } from './telephone/telephone.component';
import { SectionChildContainerComponent } from './components/section-child-container/section-child-container.component';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { ElementHeightDirective } from './directives/element-height/element-height.directive';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SavingAlertComponent } from './components/saving-alert/saving-alert.component';
import { PropertiesPanelComponent } from './components/properties-panel/properties-panel.component';
import { SectionHeadingsComponent } from './components/section-headings/section-headings.component';
import { VehicleDisplayComponent } from './components/vehicle-display/vehicle-display.component';
import { ShowVehicleComponent } from './components/show-vehicle/show-vehicle.component';
import { SuggestFinanceComponent } from './components/suggest-finance/suggest-finance.component';
import { DesktopWarningComponent } from './components/desktop-warning/desktop-warning.component';

import { BackwardButtonComponent } from './components/backward-button/backward-button.component';
import { ForwardButtonComponent } from './components/forward-button/forward-button.component';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ButtonWithInvalidSectionsPopupComponent } from './components/button-with-invalid-sections-popup/button-with-invalid-sections-popup.component';
import { CollapsableContainerComponent } from './components/collapsable-container/collapsable-container.component';
import { PageSizeSelectionComponent } from './components/page-size-selection/page-size-selection.component';
import { OrderBySelectorComponent } from './components/order-by-selector/order-by-selector.component';
import { RibbonComponent } from './components/ribbon/ribbon.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CreditplusPrivacyPolicyComponent } from './components/creditplus-privacy-policy/creditplus-privacy-policy.component';
import { DealerplusPrivacyPolicyComponent } from './components/dealerplus-privacy-policy/dealerplus-privacy-policy.component';
import { ComparecarfinancePrivacyPolicyComponent } from './components/comparecarfinance-privacy-policy/comparecarfinance-privacy-policy.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { VehicleSummaryComponent } from './components/vehicle-summary/vehicle-summary.component';
import { PanoramicViewerComponent } from './components/panoramic-viewer/panoramic-viewer.component';
@NgModule({
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, RouterModule,
    ModalModule.forRoot(), TabsModule.forRoot(), AccordionModule.forRoot(), CollapseModule.forRoot(), PopoverModule.forRoot(),
    FileUploadModule, NgxPageScrollCoreModule, NgxPageScrollModule],
  declarations: [
    ChildModalComponent, FormErrorMessagesComponent, ControlErrorMessagesComponent,
    LoadingModalComponent, LoadingComponent, LoadingObserverComponent,
    SortComponent, FilterComponent, SearchComponent, RecordsetPagerComponent, RecordNavigatorComponent, ToolbarComponent,
    ButtonGroupComponent, AmendmentComponent, SaveCancelComponent, BreadcrumbComponent, NavbarComponent, FormContainerComponent,
    SortDirectionComponent, NumberPlusMinusComponent, LoginStatusComponent, BaseFormComponent, FullnamePipe, AddressPipe,
    ApplicationHeaderComponent, ApplicationFooterComponent, MinDirective, MaxDirective, AnyofDirective, PasswordPolicyValidatorDirective,
    MinDateDirective, MaxDateDirective, NonBlankDirective, SectionContainerComponent, FocusIfDirective, VehicleDisplayComponent,
    ShowVehicleComponent, SuggestFinanceComponent,
    SelectAllOnFocusDirective, FormGeneratorComponent, HelpPanelComponent, ValidInvalidIconComponent, CustomRadioButtonComponent,
    YesOrNoComponent, DropzoneComponent, DocumentListerComponent, ProductLinkDirective, IsMobileDirective, TelephoneComponent,
    AddressLookupComponent, AddressComponent, SafePipe, SectionChildContainerComponent, ElementHeightDirective,
    IconComponent, ChangePasswordComponent, NotFoundComponent, SavingAlertComponent, PropertiesPanelComponent,
    SectionHeadingsComponent, BackwardButtonComponent, ForwardButtonComponent, ButtonWithInvalidSectionsPopupComponent,
    CollapsableContainerComponent, PageSizeSelectionComponent, PageSizeSelectionComponent, OrderBySelectorComponent, RibbonComponent, 
    PrivacyPolicyComponent, CreditplusPrivacyPolicyComponent, DealerplusPrivacyPolicyComponent, ComparecarfinancePrivacyPolicyComponent, ProgressBarComponent,
    VehicleSummaryComponent, DesktopWarningComponent, PanoramicViewerComponent
  ],
  providers: [IsMobileService],
  exports: [
    CommonModule, ReactiveFormsModule, FormsModule, TabsModule, AccordionModule,
    ChildModalComponent, FormErrorMessagesComponent, ControlErrorMessagesComponent,
    LoadingModalComponent, LoadingComponent, LoadingObserverComponent,
    SortComponent, FilterComponent, SearchComponent, RecordsetPagerComponent, RecordNavigatorComponent, ToolbarComponent,
    ButtonGroupComponent, AmendmentComponent, SaveCancelComponent, BreadcrumbComponent, NavbarComponent, PanoramicViewerComponent, FormContainerComponent,
    SortDirectionComponent, NumberPlusMinusComponent, LoginStatusComponent, FullnamePipe, AddressPipe,
    ApplicationHeaderComponent, ApplicationFooterComponent, MinDirective, MaxDirective, AnyofDirective, PasswordPolicyValidatorDirective,
    MinDateDirective, NonBlankDirective, SectionContainerComponent, FocusIfDirective, VehicleDisplayComponent, ShowVehicleComponent,
    SuggestFinanceComponent, SelectAllOnFocusDirective, FormGeneratorComponent, HelpPanelComponent, ValidInvalidIconComponent,
    CustomRadioButtonComponent, YesOrNoComponent, DropzoneComponent, DocumentListerComponent, ProductLinkDirective, TelephoneComponent,
    AddressLookupComponent, AddressComponent, SafePipe, SectionChildContainerComponent, ElementHeightDirective, IconComponent,
    ChangePasswordComponent, NotFoundComponent, SavingAlertComponent, PropertiesPanelComponent,
    SectionHeadingsComponent, BackwardButtonComponent, ForwardButtonComponent, ButtonWithInvalidSectionsPopupComponent,
    CollapsableContainerComponent, PageSizeSelectionComponent, OrderBySelectorComponent,RibbonComponent,PrivacyPolicyComponent,
    CreditplusPrivacyPolicyComponent, DealerplusPrivacyPolicyComponent, ComparecarfinancePrivacyPolicyComponent, ProgressBarComponent,
    VehicleSummaryComponent, DesktopWarningComponent
  ]
})
export class SharedModule {

}
