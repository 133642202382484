import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { LocationStrategy } from '../../../../../node_modules/@angular/common';
import { Application } from '../../../core/services/types';
import { QuotesService } from '../../../core/services/quotes/quotes.service';

@Component({
  selector: 'showroom-vehicle-details',
  templateUrl: './showroom-vehicle-details.component.html',
  styleUrls: ['./showroom-vehicle-details.component.scss']
})
export class ShowroomVehicleDetailsComponent implements OnInit {

  @Input()
  cardId: number;

  @Input() application: Application;

  @Input() selectedVehicle: any;

  @Output() displayShowroom = new EventEmitter();

  vehicleImages: any;
  mainImage: string;
  @Output() displayBreakDown = new EventEmitter();
  monthlyPayment: number;
  balloon: number;
  financeType:string;
  constructor(private actionsService: ActionsService, private quoteService: QuotesService) {
  }

  ngOnInit() {
    this.vehicleImages = Object.keys(this.selectedVehicle).filter(x => x.match("imageUrl_More")).map(key => this.selectedVehicle[key]).filter(x => x != null);
    this.mainImage = this.selectedVehicle.imageUrl_Main;
    this.getMonthlyPayment();
  }

  onSelect() {
    this.displayShowroom.emit(false);
    this.actionsService.broadcast(
      {
        action: 'showroom',
        behaviour: 'selectedVehicle',
        value: { vehicle: this.selectedVehicle, id: this.cardId }
      });
  }

  back(value) {
    this.displayShowroom.emit(value);
  }

  setMainImage(imageSelected) {
    this.mainImage = imageSelected;
  }

  scrollImage(direction, image) {
    var imagePosition = this.vehicleImages.indexOf(image);
    if (direction == 'right') {
      var nextImage = imagePosition + 1;
      if (nextImage == this.vehicleImages.length) {
        nextImage = 0;
      }
    }
    if (direction == 'left') {
      nextImage = imagePosition - 1;
      if (nextImage == -1) {
        nextImage = this.vehicleImages.length-1;
      }
    }
    this.mainImage = this.vehicleImages[nextImage];
  }

  returnShowroom() {
    this.actionsService.broadcast({
      action: 'back',
      behaviour: 'showroom',
      value: true
    });
  }

  async getMonthlyPayment() {
    const quote = await this.quoteService.getFinanceBreakdown(this.application.id.toString(), this.application.quotes[0].financeRequest, this.selectedVehicle).toPromise();
    this.monthlyPayment = quote.monthlyPayment;
    this.financeType = quote.financeType;
    this.balloon = quote.balloon;
  }

  financeBreakdown() {
    this.displayBreakDown.emit(this.selectedVehicle);
  }
}
