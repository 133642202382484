import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { LoanOffer, Application, Quote } from '../../../core/services/types';
import { ApplicationStatusService } from '../../../core/services/application-status/application-status.service';
import { ApplyService } from '../../../core/services/apply/apply.service';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { UserService } from '../../../core/services/user/user.service';
import { QuotesService } from '../../../core/services/quotes/quotes.service';
import { dealerCommission, Offer } from '../../../core/services/types-loan-offer';
import { environment } from '../../../../environments/environment';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { Vehicle } from '../../../shared/models/vehicle.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit, OnChanges {

  @ViewChild('filterContentOuterWindow') filterContentOuterWindow: ElementRef;
  @ViewChild('filterContentOuterWindow') filteringWindowContentDynamic: ElementRef;
  @ViewChild('mainSearchWindow') mainSearchWindow: ElementRef;

  showPopup:boolean = false;

  viewButtonHidden:boolean=false;

  filterContentOuterHeight:number = 0;
  filterContentInnerHeight:number = 0;
  filterContentScrolledBottom:boolean = false;
  filterContentScrolledTop:boolean = true;
  needsToBeScrolledTop:boolean = false;
  mainWindowScrollPosition:number = 0;  

  //this needs to be changed on initialisation if not actually true
  hpAvailable:boolean = true;
  pcpAvailable:boolean = true;
  loanTypeSelected:string = "HirePurchase";

  //customer's loan choices
  chosenDeposit:number;
  chosenTerm:number;
  chosenMileage:number;

  //loan choices selected in filters
  newChosenDeposit:number;
  newChosenTerm:number;
  newChosenMileage:number;

  //hard minimum deposit value of 300. Can overwrite if there is data from elsewhere,
  //but enforce this as minimum otherwise
  minDeposit:number = 300;
  maxDeposit:number = 10000;

  minTerm:number = 12;
  maxTerm:number = 60;

  minMileage:number = 5000;
  maxMileage:number = 50000;

  @Input()
  application: Application;

  @Output()
  selectedOffer: EventEmitter<LoanOffer> = new EventEmitter<LoanOffer>();

  customerId:string;
  bestValueOffer: number = 9999;
  status: string;
  offers: Array<LoanOffer>;
  displayedOffers: Array<LoanOffer> = null;
  offerTitle: string;
  currentQuote: Quote;
  telephone: any;
  filterLevel: any;
  filtersVisible:boolean=false;

  offersAcceptedCount:number = 0;
  offersReferredCount:number = 0;
  vehicleInfo: Vehicle;

  depositOptions: Options;
  termOptions: Options;
  mileageOptions: Options; 
  maxLoanApproved: number;
  constructor(
    private actionsService: ActionsService, private quotesService: QuotesService,
    private applyService: ApplyService,
    private applicationStatusService: ApplicationStatusService, private userService: UserService,
    private titleService: Title) {
    this.telephone = this.applicationStatusService.configuration.contact.telephone;
  }

  ngOnInit() {
    this.titleService.setTitle("Payment Options");
    //document.body.style.background = '#eeeff3'
    //console.log(this.application.loanProposalResponses);    
    this.vehicleInfo = JSON.parse(localStorage.getItem("vehicleObject"));
    //console.log(this.application.loanProposalResponses)
    this.displayedOffers = this.filterAccordingToConfiguration(this.application.loanProposalResponses);

    //console.log(this.displayedOffers);
    //this.displayedOffers = this.displayedOffers.filter(f => f.loanOffer.loanAmount >= this.vehicleInfo.price - 300) //300 needs to be changed to deposit if deposit values changed
    
    //this.displayedOffers=[];
    if (this.displayedOffers!=null && this.displayedOffers.length > 0){
    this.sortProposalsAlphabetically();
    this.offersAcceptedCount = (this.displayedOffers.filter(o => o.status === 'APPLICATION_ACCEPTED') || []).length;
    this.offersReferredCount = (this.displayedOffers.filter(o => o.status === 'APPLICATION_REFERRED') || []).length;
    
    //this.broadcastLowestOffer()
    //this.getDealerCommission(this.displayedOffers);

    //default values should be auto generated from journey?
    this.chosenDeposit = 300;
    this.chosenTerm = 36;
    this.chosenMileage = 10000;

    //default values stored for filters. Should only be changed on customer submit, so separated from standard values
    this.newChosenDeposit = this.chosenDeposit;
    this.newChosenTerm = this.chosenTerm;
    this.newChosenMileage = this.chosenMileage;
 
    }
    //generate options for sliders
    //Deposit isn't allowed to go down on slider, so set minDeposit to the initally chosen deposit
    this.minDeposit = this.chosenDeposit;
    this.depositOptions = {
      floor: Math.floor(this.minDeposit/100)*100,
      ceil: Math.ceil(this.maxDeposit/100)*100,
      step: 100,
      animateOnMove: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '£ ' + value
          case LabelType.High:
            return '£ ' + value
          default:
            return '£ ' + value;
        }
      }
    };

    this.termOptions  = {
      floor: this.minTerm,
      ceil: this.maxTerm,
      step: 1,
      animateOnMove: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return value + ' mths'
          case LabelType.High:
            return value + ' mths'
          default:
            return value + ' mths'
        }
      }
    };

    this.mileageOptions = {
      floor: Math.floor(this.minMileage/1000)*1000,
      ceil: Math.ceil(this.maxMileage/1000)*1000,
      step: 1000,
      animateOnMove: true,
    };

    this.customerId = this.application.id.toString();
    var tempValue = 0;
    var acceptance:LoanOffer = null;
    for (var i = 0; i < this.application.loanProposalResponses.length; i++){
      //console.log("loan response amount = " + this.application.loanProposalResponses[i].loanOffer.loanAmount);
      // look for approved loan offers
      if (this.application.loanProposalResponses[i].status.toLowerCase()== "application_accepted") {
        // if approved loan offer is greater than vehicle price, add to acceptance
        if (this.application.loanProposalResponses[i].loanOffer.loanAmount >= this.vehicleInfo.Price){
          // but only add acceptance if the apr is the best available
          if (acceptance==null || acceptance.loanOffer.apr > this.application.loanProposalResponses[i].loanOffer.apr){
            acceptance = this.application.loanProposalResponses[i];
          }
        }
     
        if(this.application.loanProposalResponses[i].loanOffer.loanAmount > tempValue) {
        tempValue = this.application.loanProposalResponses[i].loanOffer.loanAmount;
        }
        //console.log("temp value = " + tempValue);
      }
    }
    
    this.maxLoanApproved = tempValue;

    //console.log("acceptance in offer list = ");
    //console.log(acceptance);
    //console.log("vehicle in offer list = ");
    //console.log(this.vehicleInfo);

    if (acceptance != null)
    {
      var fractionOfAccepted = 1;
      if (this.vehicleInfo.Price <= acceptance.loanOffer.loanAmount)
      {
        fractionOfAccepted = this.vehicleInfo.Price / acceptance.loanOffer.loanAmount;
      }

      var quotedOffer = new LoanOffer();
      quotedOffer.loanOffer = new Offer();
      quotedOffer.loanOffer.apr = acceptance.loanOffer.apr;
      quotedOffer.loanOffer.balloon = acceptance.loanOffer.balloon;
      quotedOffer.loanOffer.deposit = acceptance.loanOffer.deposit == 0 ? 300 : acceptance.loanOffer.deposit;
      quotedOffer.loanOffer.loanAmount = this.vehicleInfo.Price - quotedOffer.loanOffer.deposit;
      quotedOffer.loanOffer.totalPayable = acceptance.loanOffer.totalPayable * fractionOfAccepted;
      quotedOffer.loanOffer.term = acceptance.loanOffer.term;
      quotedOffer.loanOffer.product = acceptance.loanOffer.product;
      quotedOffer.loanOffer.payment = (acceptance.loanOffer.payment * fractionOfAccepted) + 1; //Add £1 to monthly payment to allow for loss of precision
      quotedOffer.status = acceptance.status;
    
      if(this.vehicleInfo.Price < this.maxLoanApproved || this.displayedOffers.length == 0 ){
        this.displayedOffers = [quotedOffer]
      }
    }    
  }

  getData() {
    return this.offers;
  }

  ngOnChanges() {
    this.currentQuote = this.application.quotes.find(q => q.isSelected);
  }

  filterAccordingToConfiguration(data: Array<LoanOffer>): Array<LoanOffer> {
    if (data == null) {
      return [];
    }

    let result: Array<LoanOffer>;
    result = data.filter(o => o.status.includes('APPLICATION_ACCEPTED') && o.status.includes('APPLICATION_REFERRED') || o.status.includes('APPLICATION_ACCEPTED') || o.status.includes('APPLICATION_REFERRED'));

    return this.clearDuplicateOffer(result);
  }

  async getProposalStatusUpdate() {
    if (environment.name != "PROD") {
      this.displayedOffers.forEach(element => {
        element.status = 'APPLICATION_ACCEPTED';
      });
      this.offerTitle = this.displayedOffers.filter(x => x.status = 'APPLICATION_ACCEPTED').length === 1
        ? 'We have an offer for you:'
        : 'Congratulations! You have been accepted by ' + this.displayedOffers.filter(x => x.status = 'APPLICATION_ACCEPTED').length + ' lenders. You can see your offers below.';
      this.sortProposalsAlphabetically();
      this.displayedOffers = this.clearDuplicateOffer(this.displayedOffers);
    }
    else {
      var results = await this.applyService.updateProposalStatus(this.application.id).toPromise();
      for (var result of results) {
        for (var offer of this.displayedOffers) {
          if (offer.lenderid == result.lenderId) {
            offer.status = result.status
          }
        }
      }
      this.sortProposalsAlphabetically();
      this.displayedOffers = this.clearDuplicateOffer(this.displayedOffers);
    }
  }
  goToFilterLevel(term: string) {
    this.filterLevel = term;
    this.waitThenCompareFilterHeights();
  }


  // compare heights of filter windows to see if scrolling indicator is needed
  compareFilterWindowHeights(){
    this.filterContentOuterHeight=this.filterContentOuterWindow.nativeElement.clientHeight;
    this.filterContentInnerHeight=this.filteringWindowContentDynamic.nativeElement.scrollHeight;
    ////console.log("outer height = " + this.filterContentOuterWindow.nativeElement.offsetHeight);
    ////console.log("inner height = " + this.filterContentInnerHeight);
    if (this.filteringWindowContentDynamic.nativeElement.scrollHeight - this.filteringWindowContentDynamic.nativeElement.scrollTop 
      - this.filteringWindowContentDynamic.nativeElement.clientHeight < 1){
      this.filterContentScrolledBottom = true;
    }
    else{
      this.filterContentScrolledBottom = false;
    }
    if (this.filteringWindowContentDynamic.nativeElement.scrollTop == 0){
      this.filterContentScrolledTop = true;
    }
    else{
      this.filterContentScrolledTop = false;
    }
    if (this.needsToBeScrolledTop == true){
      this.needsToBeScrolledTop = false;
      this.filteringWindowContentDynamic.nativeElement.scrollTop = 0;
    }
    ////console.log("scrolled bottom = " + this.filterContentScrolledBottom);
    ////console.log("scrolled top = " + this.filterContentScrolledTop);
  }

  async waitThenCompareFilterHeights(){
    setTimeout(() => {
      this.compareFilterWindowHeights();      
    } , 10);
  }
  sortProposalsAlphabetically() {
    //This will order the offers by status alphabetic, wich works well in our use case
    //since we want acceptances first and refers last
    this.displayedOffers = this.displayedOffers.sort((n1, n2) => {
      if (n1.status > n2.status) {
        return 1;
      }
      if (n1.status < n2.status) {
        return -1;
      }
      return 0;
    })
  }

  clearDuplicateOffer(offerList: LoanOffer[]) {
    var result = [];
    for (var val of offerList) {
      if (val.loanOffer != null) {
        if (!result.some(x => x.lendername == val.lendername)) {
          if (val.loanOffer.payment < this.bestValueOffer) {
            this.bestValueOffer = val.loanOffer.payment;
          }
          val.display = (val.status == "APPLICATION_REFERRED" && result.filter(r => r.status == "APPLICATION_REFERRED").length > 0) ? false : true;
          result.push(val);
        }
      }
    }
    return result;
  }

  broadcastLowestOffer() {
    if (this.bestValueOffer == 9999) {
      this.bestValueOffer = this.application.quotes[0].financeResponse.monthlyPayment;
    }
    this.actionsService.broadcast(
      {
        action: 'offers',
        behaviour: 'lowestPrice',
        value: this.bestValueOffer
      }
    );
  }




  
  async getDealerCommission(offers) {
    let commissionResult = await this.quotesService.getLenderCommission(offers).toPromise();
    for (var i = 0; i < this.displayedOffers.length; i++) {
      for (var commissionVal of commissionResult) {
        if (this.displayedOffers[i].lenderid == commissionVal.lenderTier.lenderId) {
          if (commissionVal != null) {
            commissionVal.dealerCut = Math.round(commissionVal.dealerCut * 1) / 1;
            this.displayedOffers[i].commission = commissionVal;
          }
        }
      }
    }
  }

  selectOffer(){
    
  }

  setLoanTypeSelected(loanType:string){
    this.loanTypeSelected = loanType;
  }

  showFilters(show:boolean){
    if (show==true){
    this.filtersVisible=true;
    }
    else {
      this.filtersVisible=false;
    }
  }

  submitLoanChanges(){
    // this is just to display the changes for demoing, I imagine this would require an actual API call
    this.chosenMileage=this.newChosenMileage;
    this.chosenDeposit=this.newChosenDeposit;
    this.chosenTerm=this.newChosenTerm;
    this.showFilters(false);
  }

  hidePopup(){
    this.showPopup=false;
  }

  displayPopup(){
   //console.log("I'm in display popup");
    this.showPopup=true;
  }


}
