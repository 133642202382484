import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[elementHeight]'
})
export class ElementHeightDirective {

  constructor(private _elementRef: ElementRef) { }

  @HostBinding('style.height.px')
  // height: number=this._elementRef.nativeElement.scrollHeight;
  height: number;
  
  ngAfterViewInit() {

    this.height = this._elementRef.nativeElement.scrollHeight;

  }

}