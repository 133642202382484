import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Application, ProposedVehicle } from '../../../core/services/types';
import { SECTIONS } from '../../../core/services/configuration/configuration';

import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { DisplayVehicle } from '../../../core/services/types-quote';

@Component({
  selector: 'acceptance-status',
  templateUrl: './acceptance-status.component.html',
  styleUrls: ['./acceptance-status.component.scss']
})

export class AcceptanceStatusComponent implements OnInit, OnDestroy {

  @Input()
  application: Application;

  vehicles: ProposedVehicle[];
  name = SECTIONS.ACCEPTANCE_STATUS;
  stateSubscriptions: Subscription[];

  constructor(
    private applicationStatusService: ApplicationStatusService,
    private actionsService : ActionsService ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.stateSubscriptions = this.applicationStatusService.registerSectionStatusObservers(this.name, this);
    this.vehicles = this.application.quotes.filter(q => q.isSelected).map(q => q.vehicle);
    this.actionsService.registerObserver(
      'offers.lowestPrice',
      action => {
        this.application.quotes[0].financeResponse.monthlyPayment = action.value;
      },
      this
    );
    this.broadcastVehicle();
  }

  broadcastVehicle(){
    const quote = this.application.quotes[0];
    const vehicle: DisplayVehicle = {
      make: quote.vehicle.make,
      model: quote.vehicle.model,
      variant: quote.vehicle.model,
      source: quote.vehicle.source="quote generated",
      registration: quote.vehicle.registration,
      regYear: Number(quote.vehicle.dateOfRegistration.split("-")[0]),
      term: quote.financeRequest.term,
      monthlyPayments: quote.financeResponse.monthlyPayment,
      financeType: quote.financeRequest.financeType,
      balloon: quote.financeResponse.balloon,
      imageUrl_Main: quote.vehicle.imageUrl
    }
    this.actionsService.broadcast({
      action: 'displayVehicle',
      behaviour: 'updateVehicle',
      value: { vehicle: vehicle, status: this.application.status, loanOffer: null}
      }
    );
  }

  ngOnDestroy() {
    this.applicationStatusService.cancelSectionStatusSubscriptions(this.stateSubscriptions);
  }
}

