enum WORKFLOWS {
    ENQUIRY_RECEIVED = 'ENQUIRY_RECEIVED',
    PROVISIONALLY_ACCEPTED = 'PROVISIONALLY_ACCEPTED',
    QUOTES_STARTED = 'QUOTES_STARTED',
    DETAILS_STARTED = 'DETAILS_STARTED',
    APPLICATION_PENDING = 'APPLICATION_PENDING',
    APPLICATION_SENT = 'APPLICATION_SENT',
    APPLICATION_DECLINED = 'APPLICATION_DECLINED',
    APPLICATION_REFERRED = 'APPLICATION_REFERRED',
    APPLICATION_ACCEPTED = 'APPLICATION_ACCEPTED',
    APPLICATION_ERROR = 'APPLICATION_ERROR',
    APPLICATION_MANUAL = 'APPLICATION_MANUAL',
    DOCS_TO_BE_ISSUED = 'DOCS_TO_BE_ISSUED',
    DOCS_ISSUED = 'DOCS_ISSUED',
    DOCS_RECEIVED = 'DOCS_RECEIVED',
    DOCS_WITH_LENDER = 'DOCS_WITH_LENDER',
    AWAITING_PROOFS = 'AWAITING_PROOFS',
    PROOFS_RECEIVED = 'PROOFS_RECEIVED',
    PAID_OUT = 'PAID_OUT',
    CLIENT_CANCELLED = 'CLIENT_CANCELLED',
    ABANDONED = 'ABANDONED',
    CANNOT_LEND = 'CANNOT_LEND',
    Further_Info_Required = 'Further_Info_Required'
};

export {
    WORKFLOWS
}
