<div class="navigation-bg">
  <div class="top-nav">
    <nav class="navbar nav-pages container">
      <ul class="nav nav-pills">
        <ng-container *ngIf="!showActiveItemOnly">
            <li *ngFor="let item of pages" class="nav-item" [ngClass]="{'active':page==item.name}">
              <span class="nav-link">{{item.label}}</span>
            </li>
          </ng-container>
          <ng-container *ngIf="showActiveItemOnly">
            <ng-container *ngFor="let item of pages">
              <li *ngIf="page==item.name" class="nav-item" [ngClass]="{'active':page==item.name}">
                <span class="nav-link">{{item.label}}</span>
              </li>
            </ng-container>
          </ng-container>
      </ul>
      <span class="navbar-text ml-auto">
        <telephone isMobile class="ml-auto"></telephone>
      </span>
      <ng-content></ng-content>
    </nav>
  </div>
</div>