<div class="car-card" >
  <div class="image--wrapper">
    <!--<img src={{vehicle.imageUrl_Main}} (error)="updateUrl()" class="car-image" />-->
    <div class="car-image" [style.background-image]="'url(' + vehicle.imageUrl_Main + ')'" (click)="selectedVehicle()"></div>
    <span class="rac">RAC Approved</span>
    <span class="pics">
      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 2.46875C11 2.18945 10.8926 1.95312 10.6992 1.73828C10.4844 1.54492 10.248 1.4375 9.96875 1.4375H8.07812L7.82031 0.728516C7.73438 0.535156 7.60547 0.384766 7.43359 0.255859C7.26172 0.126953 7.06836 0.0625 6.85352 0.0625H4.14648C3.93164 0.0625 3.73828 0.126953 3.56641 0.255859C3.39453 0.384766 3.26562 0.535156 3.17969 0.728516L2.92188 1.4375H1.03125C0.730469 1.4375 0.494141 1.54492 0.300781 1.73828C0.0859375 1.95312 0 2.18945 0 2.46875V8.65625C0 8.95703 0.0859375 9.19336 0.300781 9.38672C0.494141 9.60156 0.730469 9.6875 1.03125 9.6875H9.96875C10.248 9.6875 10.4844 9.60156 10.6992 9.38672C10.8926 9.19336 11 8.95703 11 8.65625V2.46875ZM8.07812 5.5625C8.07812 6.03516 7.94922 6.48633 7.73438 6.87305C7.49805 7.25977 7.17578 7.58203 6.78906 7.79688C6.40234 8.0332 5.97266 8.14062 5.5 8.14062C5.02734 8.14062 4.57617 8.0332 4.18945 7.79688C3.80273 7.58203 3.48047 7.25977 3.26562 6.87305C3.0293 6.48633 2.92188 6.03516 2.92188 5.5625C2.92188 5.08984 3.0293 4.66016 3.26562 4.27344C3.48047 3.88672 3.80273 3.56445 4.18945 3.32812C4.57617 3.11328 5.02734 2.98438 5.5 2.98438C5.97266 2.98438 6.40234 3.11328 6.78906 3.32812C7.17578 3.56445 7.49805 3.88672 7.73438 4.27344C7.94922 4.66016 8.07812 5.08984 8.07812 5.5625ZM7.39062 5.5625C7.39062 5.04688 7.19727 4.61719 6.83203 4.23047C6.44531 3.86523 6.01562 3.67188 5.5 3.67188C4.98438 3.67188 4.5332 3.86523 4.16797 4.23047C3.78125 4.61719 3.60938 5.04688 3.60938 5.5625C3.60938 6.07812 3.78125 6.5293 4.16797 6.89453C4.5332 7.28125 4.98438 7.45312 5.5 7.45312C6.01562 7.45312 6.44531 7.28125 6.83203 6.89453C7.19727 6.5293 7.39062 6.07812 7.39062 5.5625Z"
          fill="white" />
      </svg>
      {{validImagesCount}}</span>
  </div>
  <div class="details--wrapper" (click)="selectedVehicle()">
    <div class="name">
      <h3>{{vehicle.make}}</h3>
      <h2>{{vehicle.model}}</h2>
    </div>
    <div class="details">
      <ul>
        <li>
          <label>Engine</label>
          {{vehicle.engineSize}}
        </li>
        <li>
          <label>Fuel type</label>
          {{vehicle.fuelType}}
        </li>
        <li>
          <label>Transmission</label>
          {{vehicle.transmission}}
        </li>
        <li>
          <label>Mileage</label>
          {{vehicle.mileage}} mi
        </li>
        <li>
          <label>Year</label>
          {{vehicle.registrationYear}}
        </li>
        <li>
          <label>Colour</label>
          {{vehicle.colour}}
        </li>
      </ul>
    </div>
  </div>
  <div class="prices--wrapper">
    <div class="total">
      <h4>&pound;{{vehicle.price}}</h4>
      <label>Total Price</label>
    </div>
    <div class="monthly desktop-only">
      <a class="desktop-only" (click)="financeBreakdown()">Finance breakdown</a>
    </div>
  </div>
  <div class="mobile-only breakdown">
    <a (click)="financeBreakdown()">Finance breakdown</a>
  </div>
</div>