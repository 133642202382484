import { CONFIGURATION } from './configuration-groups-base';
import { CONFIGURATION_DEALER } from './configuration-groups-dealer';
import { CONFIGURATION_CCF } from './configuration-groups-ccf';

const GROUPS = {
    CONSUMER: {
        name: 'customer',
        configuration: CONFIGURATION
    },
    DEALER: {
        name: 'dealerplus dealer',
        configuration: CONFIGURATION_DEALER
    },
    CCF: {
        name: 'ccf customer',
        configuration: CONFIGURATION_CCF
    },
    TEMP: {
        name: 'temporary user',
        configuration: CONFIGURATION
    },
    DpCustomer: {
        name: 'dealerplus customer',
        configuration: CONFIGURATION_DEALER
    }
};

export {
    GROUPS
};
