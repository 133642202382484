<div *ngIf="loading" class="loading" [ngClass]="classes">
  <!--<img [src]="imagesource"/>-->
  <div class="small-loader">  
    <div class="loader-main-border">
      <div class="loader-inside-border">
        <div class="digit-main"></div>
        <div class="digit-main"></div>
        <div class="digit-main"></div>
        <div class="digit-main"></div>
        <div class="digit-main"></div>
        <div class="digit-main"></div>
        <div class="digit-secondary"></div>
        <div class="digit-secondary"></div>
        <div class="digit-secondary"></div>
        <div class="digit-secondary"></div>
        <div class="digit-secondary"></div>
        <div class="animated-line-outer"><div class="animated-line-inner"></div></div>
        <div class="pointer"></div>
        <div class="mileage">[][][][]</div>
      </div>
    </div>
  </div>
  <div style="display:flex;flex-direction:row;justify-content:center;">
    <h3>{{text}}</h3>
  </div>
  
</div>

