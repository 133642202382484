<toolbar>
  <div class="nav navbar-nav">
    <amendment></amendment>
    <save-cancel></save-cancel>
    <sort [model]="model"></sort>
    <sort-direction></sort-direction>
    <button-group [model]="dropdownmodel"></button-group>
    <button-group [model]="buttonmodel"></button-group>
  </div>
  <p (click)="gotoNextRecord()" class="navbar-text">Click me!</p>
  <search></search>
</toolbar>
<navbar>
  <record-navigator></record-navigator>
  <recordset-pager></recordset-pager>
</navbar>
<div class="row">
  <div class="col-sm-6">
    <button-group [model]="listgroupmodel"></button-group>
  </div>
  <div class="col-sm-6">
    <input type="range" value="10" min="5" max="11" />
    <number-plus-minus [(model)]="numberplusminusmodel"></number-plus-minus>
  </div>
</div>
