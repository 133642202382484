
import {finalize, map} from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { AuthenticationResult } from './loginmodels';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Subject ,  Observable ,  Subscription } from 'rxjs';




import { TokenService } from './../token/token.service';
import { TimedActionService } from '../timed-action/timed-action.service';
import { ActionsService } from '../actions/actions.service';
import { Configuration, CONFIGURATION } from '../configuration/configuration';
import { GROUPS } from '../configuration/configuration-groups';
import { LoadingModalService } from '../modal/loading-modal.service';

const dealerplusDomain = 'dealerplus';
const creditplusDomain = 'creditplus';
const comparecarfinanceDomain = 'comparecarfinance';

@Injectable()
export class LoginService {

  redirectUrl = '';
  private subscriptionsForceExpire: Subscription[];
  private logoutSubscription: Subscription;
  private loginStatusSource = new Subject<boolean>();
  loginStatusAnnounced$ = this.loginStatusSource.asObservable();
  urls: any = {
    loginDealer: environment.base + '/portalauth/internal',
    loginConsumer: environment.base + '/portalauth',
    register: environment.base + '/useraccount/createandregister',
    temporaryRegister: environment.base + '/useraccount/registertemp',
    changePassword: environment.base + '/useraccount/changepassword',
    forgottenPassword: environment.base + '/useraccount/resetforgottenpassword',
    registerDpCustomer: environment.base + '/useraccount/registerDpCustomer'
  };

  constructor(
    private http: HttpClient,
    private timedActionService: TimedActionService,
    private tokenService: TokenService,
    private actionsService: ActionsService,
    private loadingModalService: LoadingModalService) {

    this.registerObservers();
  }

  registerObservers() {
    /*
      Listens for expiration broadcasts and sets the login status to false when received
    */
    this.subscriptionsForceExpire = this.actionsService.registerObservers(
      ['authentication.forceExpiration', 'authentication.expiration'],
      action => this.announceLoginStatus(false),
      this
    );

    /*
      Listens for logout broadcasts
    */
    this.logoutSubscription = this.actionsService.registerObserver(
      'authentication.logout',
      action => this.logout(),
      this
    );
  }

  announceLoginStatus(loggedIn: boolean) {
    this.loginStatusSource.next(loggedIn);
  }

  temporaryRegister(userName: string, customerId: string): Observable<AuthenticationResult> {
    this.loadingModalService.show();
    return this.http
      .post(this.urls.temporaryRegister, { customerId: customerId, username: userName }).pipe(
      map((response) => this.extractLoginResult(response, userName)),
      finalize(() => this.loadingModalService.hide()),);
  }

  register(userName: string, customerId: string, password: string, source: string): Observable<AuthenticationResult> {
    return this.http
      .post(this.urls.register, { customerId: customerId, password: password, username: userName, source: source }).pipe(
      map((response) => this.extractLoginResult(response, userName)))
  }


  registerDpCustomer(userName: string, customerId: string, source: string): Observable<AuthenticationResult> {
    return this.http
      .post(this.urls.registerDpCustomer, { customerId: customerId, username: userName, source: source }).pipe(
      map((response) => this.extractLoginResult(response, userName)))
  }

  // Checked and working in angular 6
  login(body: Object, passwordPolicyKey: string): Observable<AuthenticationResult> {
    const loginUrl = (passwordPolicyKey === GROUPS.DEALER.name) ? this.urls.loginDealer : this.urls.loginConsumer;
    this.loadingModalService.show();
    return this.http
      .post(loginUrl, body, {headers: this.getHeaderOptions(false)}).pipe(
      map((response) => this.extractLoginResult(response, body['username'])),
      finalize(() => this.loadingModalService.hide()),);
  }

  changePassword(body: Object): Observable<AuthenticationResult> {
    this.loadingModalService.show();
    return this.http
      .put(this.urls.changePassword, body, {headers: this.getHeaderOptions(true)}).pipe(
      map((response) => this.extractLoginResult(response, this.tokenService.userName)),
      finalize(() => this.loadingModalService.hide()),);

  }

  forgottenPassword(body: Object): Observable<AuthenticationResult> {
    this.loadingModalService.show();
    return this.http
      .put(this.urls.forgottenPassword, body, {headers: this.getHeaderOptions(false)}).pipe(
      map((response) => this.extractLoginResult(response, body['username'])),
      finalize(() => this.loadingModalService.hide()),);
  }


  private extractLoginResult(response: any, userName: string): AuthenticationResult {
    if (response!=null) {
      const body = response;
      const lifetime = body.lifetime * 1000;


      if (body.applicationStatus != null) {
        return <AuthenticationResult>{
          bearerToken: body.token,
          username: userName,
          id: body.contactId,
          lifetime: lifetime,
          expires: this.tokenService.calculateExpires(lifetime),
          roles: body.roles,
          applicationStatus: body.applicationStatus
        };
      } else {
        return <AuthenticationResult>{
          bearerToken: body.token,
          username: userName,
          id: body.contactId,
          lifetime: lifetime,
          expires: this.tokenService.calculateExpires(lifetime),
          roles: body.roles
        };
      }

    }

    return null;

  }

  public setUserInformation(authenticationResult: AuthenticationResult) {

    // Update the token service with authentication info
    this.tokenService.setUserInformation(authenticationResult);

    // Start the token expiration timer - MUST take place after token service info set
    this.timedActionService.startTimer();

    // Broadcast the login to subscribers
    this.announceLoginStatus(true);
  }

  logout() {
    this.tokenService.clearUserInformation();
    this.announceLoginStatus(false);
    this.timedActionService.clearTimer();
  }

  getHeaderOptions(includeAuthorization: boolean): HttpHeaders {

    var headers = new HttpHeaders(
      { 'Content-Type' : 'application/json'}
    );

    if (includeAuthorization) {
    headers = headers.append('Authorization' , 'Bearer ' + this.tokenService.bearerToken);
    }

    return headers;

  }

  getPasswordPolicykey() {
    var result = GROUPS.CONSUMER.name;
    if (window.location.href.toLowerCase().indexOf(dealerplusDomain) != -1) {
      result = GROUPS.DEALER.name;
    } else if (window.location.href.toLowerCase().indexOf(comparecarfinanceDomain) != -1) {
      result = GROUPS.CCF.name;
    }
    return result
  }

  getPasswordPolicy(key: string) {
    const foundGroup = Object.keys(GROUPS).find(
      (group) => GROUPS[group].name === key
    );

    return foundGroup
      ? GROUPS[foundGroup].configuration.authentication.passwordPolicy
      : CONFIGURATION.authentication.passwordPolicy;

  }

  getInitialPasswordPolicy() {
    const passwordPolicyKey = this.getPasswordPolicykey();
    return this.getPasswordPolicy(passwordPolicyKey);
  }

  getLoginRedirectRoute(id: string) {
    const loginRedirectRoute: string = Configuration.loadCurrentJourney(this.tokenService.roles).redirectUrls.login.route;
    return loginRedirectRoute.replace('{{id}}', id);
  }

  getRegisterRedirectRoute(id: string) {
    const registerRedirectRoute: string = Configuration.loadCurrentJourney(this.tokenService.roles).redirectUrls.register.route;
    return registerRedirectRoute.replace('{{id}}', id);
  }
}
