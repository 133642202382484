import { Injectable } from '@angular/core';

import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { TokenService } from '../token/token.service';

import { Configuration } from '../configuration/configuration';
import { JourneyPage } from '../types';
import { URLS } from '../configuration/configuration.urls';

@Injectable()
export class JourneyPageGuard implements CanActivate {

  constructor(private router: Router, private tokenService: TokenService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (this.checkPageJourneyURL(route)) {
      return true;
    }
    else {
      this.router.navigateByUrl(URLS.NotFound.route);
      return false;
    }

  }

  checkPageJourneyURL(route: ActivatedRouteSnapshot): boolean {

    if (route.params.page == 'current-step') {
      return true;
    }

    /*
      Check to see if the page route exists in the journey pages associated with the user roles
    */
    var journeyPages: JourneyPage[] = Configuration.loadCurrentJourneyPages(this.tokenService.roles);

    return journeyPages.some(journeyPage => journeyPage.route == route.params.page);

  }

}