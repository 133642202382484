<section class="body">
  <!-- <div class="flex">
    <div>
      <h1 class="mobile-only">Your Quotes</h1>
    </div>
    <div>
      <h3 class="mobile-only">Compare up to three personalised quotes and edit your loan requirements here.</h3>
    </div>
  </div>
  <section id="section">
    <section id="quote-zone">
      <article *ngFor="let vehicle of vehicleList; let id=index" [ngClass]="vehicle.open ?'with-car':'without-car'">
        <div class="mySlides">
          <quote-vehicle-item (updateVehicleCard)="updateVehicleCard($event)" [id]="id" [vehicle]="vehicle" [term]="term" [application]="application"
            [deposit]="deposit" [apiErrorMessages]="apiErrorMessages"></quote-vehicle-item>
        </div>
      </article>
    </section>
  </section>
  <div class="mobile-only width">
    <div class="centre">
      <div>
        <i class="fas fa-arrow-left arrow" (click)="currentSlides(2, 'left')" *ngIf="slideIndex != 1"></i>
      </div>
      <div>
        <i class="fas fa-circle dot active"></i>
        <i class="fas fa-circle dot"></i>
        <i class="fas fa-circle dot"></i>
      </div>
      <div>
        <i class="fas fa-arrow-right arrow" (click)="currentSlides(1, 'right')" *ngIf="slideIndex != 3"></i>
      </div>
    </div>
  </div> -->


  <section id="section">
    <section id="quote-zone">
      <article *ngFor="let vehicle of vehicleList; let id=index" [ngClass]="vehicle.open ?'with-car':'without-car'">
        <div class="mySlides">
          <quote-vehicle-item (updateVehicleCard)="updateVehicleCard($event)" [id]="id" [vehicle]="vehicle" [term]="term" [application]="application"
            [deposit]="deposit" [apiErrorMessages]="apiErrorMessages"></quote-vehicle-item>
        </div>
      </article>
    </section>
  </section>
</section>

