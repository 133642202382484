
const PATTERNS = {
    BANK_ACCOUNT_NUMBER: '[0-9]{8}',
    SORT_CODE: '[0-9]{6}',
    UK_MOBILE_NUMBER: '[0-9]{11}',
    UK_TELEPHONE_NUMBER: '[0-9]{10,11}',
    UK_POSTCODE: '^([A-Za-z](([0-9][0-9]?)|([A-Za-z][0-9][0-9]?)|([A-Za-z]?[0-9][A-Za-z])) ?[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2})$',
    URL: new RegExp('^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})', 'i'),
    VEHICLE_REGISTRATION: '(?<Current>^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(?<Prefix>^[A-Z][0-9]{1,3}[A-Z]{3}$)|(?<Suffix>^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(?<DatelessLongNumberPrefix>^[0-9]{1,4}[A-Z]{1,2}$)|(?<DatelessShortNumberPrefix>^[0-9]{1,3}[A-Z]{1,3}$)|(?<DatelessLongNumberSuffix>^[A-Z]{1,2}[0-9]{1,4}$)|(?<DatelessShortNumberSufix>^[A-Z]{1,3}[0-9]{1,3}$)|(?<DatelessNorthernIreland>^[A-Z]{1,3}[0-9]{1,4}$)',
    TEXT_NAME_OR_PLACE:  new RegExp('^[A-Za-z0-9\ \-\.\'\"\&]*', 'i'),
    SIX_DIGITS_MAXIMUM:'^[0-9]{1,6}',
    SEVEN_DIGITS_MAXIMUM:'^[0-9]{1,7}'
}


export {
    PATTERNS
};
