import { Configuration } from './../../../core/services/configuration/configuration';
import { Component, OnInit, Input, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoginService } from './../../../core/services/login/login.service';
import { UserService } from './../../../core/services/user/user.service';
import { GlobalModalService } from './../../../core/services/modal/global-modal.service';
import { ActionsService } from "../../../core/services/actions/actions.service";
import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { BaseForm } from './../../../core/components/models.class';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment/moment';

@Component({
  selector: 'base-form',
  templateUrl: 'base-form.component.html',
  styleUrls: ['base-form.component.scss']
})

export class BaseFormComponent implements BaseForm, OnInit, OnChanges {
  @Input()
  model: any;
  name: string;
  form: FormGroup;
  formDefinition: any;
  formErrors: any = {};
  formGroupErrors: any[] = [];
  formErrorsArray: string[];
  formValidationMessages: any;
  defaultFormValidationMessages: any;
  id: string;
  errorMessage: string;
  modalSettings: any;
  @Input()
  editing: boolean = true;
  valid: boolean;
  enabled: boolean = true;
  visible: boolean = true;
  newRecord: boolean = false;
  showHelp: boolean = Configuration.loadCurrentJourneyType().help.show;

  constructor(public formBuilder: FormBuilder, public loginService: LoginService, public userService: UserService, public globalModalService: GlobalModalService, public actionsService: ActionsService) {
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    /*
      We need to use the section container set-open broadcast to set the initial open value as the valid setting has not been fully evaluated if we
      just use the section container component initialization to set the value. This is an issue on certain components that contain a child component 
      with a form that is evaluated as part of the overall component valid settings.
    */
    this.actionsService.broadcast(
      {
        action: `${this.name}-section-container`,
        behaviour: 'set-open',
        value: this.form.valid
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isUpdate(changes)) {
      this.setFormData();
    }
  }

  private isUpdate(changes: SimpleChanges): Boolean {
    return changes["model"] && !changes["model"].isFirstChange();
  }

  // Called by can-deactivate-guard.service
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.valid && !this.form.dirty) {
      return true;
    }
    return this.globalModalService.show(this.modalSettings);
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  createForm() {
    this.form = this.formDefinition.controls;
    this.setFormData();
    this.initializeFormDefinition();
    this.form.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  initializeFormDefinition() {
    this.initializeFormDefinitionRecursive(this.formDefinition.controls.controls, this.formErrors, true);
  }

  initializeFormDefinitionRecursive(controls, formErrors, setMetaData: boolean) {
    for (var key in controls) {
      if (typeof controls[key].value == 'object' && controls[key].value != null) {
        formErrors[key] = {};
        this.initializeFormDefinitionRecursive(controls[key].controls, formErrors[key], true);
      }
      else {
        if (setMetaData) {
          this.setMetaData(key);
        }
        formErrors[key] = [];
      }
    }
    if (setMetaData) {
      this.setMetaData('formGroupErrors');
    }
  }

  getFormErrorsObjectRecursive(controls, formErrors) {
    for (var key in controls) {
      if (typeof controls[key].value == 'object') {
        formErrors[key] = {};
        this.getFormErrorsObjectRecursive(controls[key].controls, formErrors[key]);
      }
      else {
        this.setMetaData(key);
        formErrors[key] = [];
      }
    }
  }

  setMetaData(key: string) {
    var metadata = this.formDefinition.metadata;
    if (typeof metadata[key] == 'undefined') {
      metadata[key] = {
        label: key,
        errormessages: Object.assign({}, this.formDefinition.defaultErrorMessages)
      };
    }
    else {
      if (typeof metadata[key].label == 'undefined') {
        metadata[key].label = key;
      }
      if (typeof metadata[key].errormessages == 'undefined') {
        metadata[key].errormessages = Object.assign({}, this.formDefinition.defaultErrorMessages);
      }
      else {
        for (var errorkey in this.formDefinition.defaultErrorMessages) {
          if (typeof metadata[key].errormessages[errorkey] == 'undefined') {
            metadata[key].errormessages[errorkey] = this.formDefinition.defaultErrorMessages[errorkey];
          }
        }
      }
    }
  }

  setFormData() {
    this.form.patchValue(this.model);
  }

  getFormData(): any {
    return this.form.value;
  }

  edit() {
    this.editing = true;
  }

  cancel() {
    this.editing = false;
    this.setFormData();
    this.form.markAsPristine();
  }

  onValueChanged(data?: any) {
    if (this.form) {
      this.formErrorsArray = [];
      this.onValueChangedRecursive(this.formErrors);
      this.formErrors = Object.assign({}, this.formErrors);
    }
    this.validSubscription();
    this.dirtySubscription();
  }

  protected validSubscription() {
    this.actionsService.broadcast({
      action: this.name,
      behaviour: 'valid',
      value: this.form.valid
    }
    );
  }

  protected dirtySubscription() {
    if (this.form.dirty) {
      this.actionsService.broadcast({
        action: this.name,
        behaviour: 'dirty',
        value: this.form.dirty
      });
    }
  }

  onValueChangedRecursive(formErrors, formGroup?) {
    const form = this.form;
    var messages: any, message: any;
    for (const field in formErrors) {

      /*
        Get the control associated with the formError key
      */
      const control = (typeof formGroup == 'undefined') ? form.get(field) : formGroup.get(field);
      /*
        If we are dealing with a formErrors object then recursively set the formErrors for that object
      */
      if (!(formErrors[field] instanceof Array)) {
        /*
          If the control is null then delete the formErrors for the control. This occurs when an array item has been removed from the form.
          The last formErrors item will still exist for the array until we remove it
        */
        if (control == null) {
          delete formErrors[field];
        }
        else {
          this.onValueChangedRecursive(formErrors[field], control);
        }
      }
      else {
        /*
          If we are dealing with a formErrors array then set the errors based upon the control state
        */

        formErrors[field] = [];

        if (control && control.dirty && !control.valid) {
          messages = this.formDefinition.metadata[field].errormessages;
          for (const key in control.errors) {
            message = control.errors[key];
            if (typeof messages[key] != 'undefined') {
              message = messages[key].replace('{{field}}', this.formDefinition.metadata[field].label);
              if (field == 'registration') {
                message = message.replace('.', ', or search one of our cars');
              }
              for (const value in control.errors[key]) {
                if (control.errors[key][value] instanceof Date) {
                  message = message.replace('{{' + value + '}}', moment(control.errors[key][value]).format('MMMM Do YYYY'));
                }
                else {
                  message = message.replace('{{' + value + '}}', control.errors[key][value]);
                }
              }
            }
            formErrors[field].push(message);
            this.formErrorsArray.push(message);
          }
        }
      }
    }
    this.formGroupErrors = [];
    for (const groupError in this.form.errors) {
      this.formGroupErrors.push(this.form.errors[groupError]);
    }
  }

  initialiseFormArrayItems(data: any, formArray: FormArray, controls: FormGroup) {
    data.forEach(
      item => {
        formArray.push(controls)
      }
    );
  }

  addItem(formArray: FormArray, controls: FormGroup, formErrorsObject: any) {
    var formErrorItem: any = {};
    /*
      Get a formErrors object for the new item
    */
    this.initializeFormDefinitionRecursive(controls.controls, formErrorItem, false);
    /*
      Set formErrors object for the new item
    */
    formErrorsObject[Object.keys(formErrorsObject).length] = formErrorItem;
    /*
      Add the controls for the item to the form array
    */
    formArray.push(controls);
    /*
      Mark the form as dirty as angular doesn't do this by default (it's a feature not a bug apparently!)
    */
    this.form.markAsDirty();
  }

  removeItem(formArray: FormArray, i: number) {
    this.form.markAsDirty();
    formArray.removeAt(i);
  }
}
