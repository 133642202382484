import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

 source:string;

  constructor() { }

  ngOnInit() {
    if(window.location.href.indexOf("dealerplus") != -1){
      this.source = 'dealer';
    }
    else if(window.location.href.indexOf("comparecarfinance") != -1){
      this.source = 'ccf';
    }
    else{
      this.source = 'customer';
    }
  }
}
