import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Configuration } from '../../../core/services/configuration/configuration';

@Component({
  selector: 'forward-button',
  templateUrl: './forward-button.component.html',
  styleUrls: ['./forward-button.component.scss']
})
export class ForwardButtonComponent {
  @Input()
  valid:boolean

  @Output()
  forward: EventEmitter<any> = new EventEmitter<any>();

  showButton:boolean=Configuration.loadCurrentJourneyType().sectionNavigation.pagingMethod=='BACKWARDS_AND_FORWARDS'?true:false;

  goForward(){
    this.forward.emit();
  }

}
