import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { Component, OnInit, OnChanges, Input, HostBinding, Directive, SimpleChanges } from '@angular/core';

@Directive({
  selector: 'product-link, [product-link]'
})
export class ProductLinkDirective implements OnChanges {

  constructor (private applicationStatusService : ApplicationStatusService) {
    
  }

  @Input()
  product: string;

  @HostBinding('href') private link:string;

  ngOnChanges(changes: SimpleChanges): void {
    switch(changes["product"].currentValue){
      case 'HP': this.link = this.applicationStatusService.configuration.financeOptions.HP.productLink; break;
      case 'LP': this.link = this.applicationStatusService.configuration.financeOptions.LP.productLink; break;
      case 'PCP': this.link = this.applicationStatusService.configuration.financeOptions.PCP.productLink; break;
    }
  }

}
