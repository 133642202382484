
import { throwError as observableThrowError, Observable } from 'rxjs';

import { finalize, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpResponse
  //Response, Headers, RequestOptions
} from '@angular/common/http';



import { LoginService } from './../login/login.service';
import { environment } from './../../../../environments/environment';
import { ApplicationHistoryCustomer } from '../types';
import { TimedActionService } from '../timed-action/timed-action.service';
import { LoadingModalService } from '../modal/loading-modal.service';


@Injectable()
export class CustomersService {

  constructor(private loginService: LoginService, private http: HttpClient, private timedActionService: TimedActionService,
    private loadingModalService: LoadingModalService) { }

  /*
    NOTE: These services have not yet been implemented within the API and are meant as a phase 2+ piece of work 
    in conjunction with the CustomersComponent & CustomerComponent
    RPT - 26/09/2017
  */
  urls: any = {
    getCustomers: environment.base + '/customer/application/search',
  };

  /*
    LastSevenDays
    OneToTwoWeeksAgo
    TwoToFourWeeksAgo
    MoreThanFourWeeksAgo
  */

  getCustomers(dateRange: string, pageSize: number, pageNumber: number, status?: string): Observable<ApplicationHistoryCustomer> {
    var body: any = {
      dateRange: dateRange,
      pageSize: pageSize,
      pageNumber: pageNumber
    };

    if (typeof status != 'undefined') {
      body.portalStatusLabel = status;
    }

    this.loadingModalService.show();
    return this.http.post(this.urls.getCustomers, body, { headers: this.loginService.getHeaderOptions(true) }).pipe(
      map(
        (res: HttpResponse<any>) => {

          this.timedActionService.resetTimer();
          return res.body;

        }
      ),
      catchError(
        (error: any) => {

          this.timedActionService.checkResetTimerOnError(error);
          return observableThrowError(error);

        }
      ),

      finalize(() => this.loadingModalService.hide()));
  }

  createPasswordLink(email: string) {
    var url = environment.base + "/customer/application/create-forgot-password-link";
    var demo = environment.demo.toString();
    var retval = this.http.post(url, null, { params: { customerEmail: email, isDemo: demo } });
    return retval;
  }

  verifyPasswordLink(verifyCode: string) {
    var url = environment.base + "/customer/application/verify-forgot-password-link"
    var retVal = this.http.post(url, null, { params: { verifyCode: verifyCode } });
    return retVal;
  }

  getApplicantbyID(id: string) {
    var url = environment.base + "crm/applicant"
    var retVal = this.http.post(url, null, { params: { id: id} });
    return retVal;
  }

}
