import { Provider } from '@angular/core';
import { XhrFactory, HttpXhrBackend } from '@angular/common/http';

export function getBackEnd(
  browser: XhrFactory) {

  return new HttpXhrBackend(browser);
}

export let xhrProvider: Provider = {
  provide: HttpXhrBackend,
  useFactory: getBackEnd,
  deps: [XhrFactory]
}

export const environment = {
  production: false,
  isDebugging: true,
  base: "https://quickdealapi-dev.creditplus.co.uk/api",
  provider: xhrProvider,
  name: "Dev",
  demo: true
};
