
import {finalize, catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse
  // Response 
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Quote, QuoteServiceResponse, Application } from '../types';
import { Observable } from 'rxjs';

import { LoginService } from './../login/login.service';
import { TimedActionService } from '../timed-action/timed-action.service';
import { AnalyticsData, FinanceRequest } from '../types-quote';
import { LoadingModalService } from '../modal/loading-modal.service';
import { LoanOffer } from '../types-loan-offer';
import { CalculatorRequest } from '../CalculatorRequest';

@Injectable()
export class QuotesService {
  private verifyLenderRequirements: string = environment.base + '/quotes/verifyLenderRequirements';
  private quotesUrl: string = environment.base + '/quotes/getquote';
  private financeBreakdown: string = environment.base + '/quotes/getFinanceBreakdown';
  private calculator: string = environment.base + '/quotes/calculator';
  private updateLenderCriteriaUrl: string = environment.base + '/quotes/updatelendingcriteria';
  private PCPLenders: string = environment.base + '/quotes/getDynamicLenderOffersPCP';
  private lenderCommissionDisplay: string = environment.base + '/quotes/getDealerCommission';
  private getPassedCriteriaLendersUrl: string = environment.base + '/quotes/getpassedcriterialenders';
  private saveNotes: string = environment.base + '/quotes/saveQuotesToNotes';

  private body: any = {
    applicationId: null,
    financeRequest: null,
    vehicle: null
  };

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private timedActionService: TimedActionService,
    private loadingModalService: LoadingModalService) { }

    saveNote(quotes: Quote): Observable<any> {
      this.body = quotes;
      return this.http.post(this.saveNotes, this.body, {headers: this.loginService.getHeaderOptions(true)})
    }

  getQuotes(applicationId: string, quoteFinanceRequest: FinanceRequest, vehicle: any): Observable<any> {

    this.body.applicationId = applicationId;
    this.body.financeRequest = quoteFinanceRequest;
    this.body.vehicle = vehicle;


    return this.http.post(this.quotesUrl, this.body, { headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        // this.logQuoteLookupquote, true);
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        // this.logQuoteLookup(quote, false);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);

  }
  getFinanceBreakdown(applicationId: string, quoteFinanceRequest: FinanceRequest, vehicle: any): Observable<any> {

    this.body.applicationId = applicationId;
    this.body.financeRequest = quoteFinanceRequest;
    this.body.vehicle = vehicle;


    return this.http.post(this.financeBreakdown, this.body, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        // this.logQuoteLookup(quote, true);
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        // this.logQuoteLookup(quote, false);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);

  }

  getCalculatorResults(Request: CalculatorRequest): Observable<any> {

    this.body = Request;

    this.loadingModalService.displayMessage(6);
    return this.http.post(this.calculator, this.body, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        // this.logQuoteLookup(quote, true);
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        // this.logQuoteLookup(quote, false);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);

  }
  
  updateLendingCriteria(applicationId: number): Observable<any> {
    this.loadingModalService.show();
    return this.http.post(this.updateLenderCriteriaUrl, Number(applicationId), {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);
  }

  getPassedCriteriaLenders(applicationId: number): Observable<any> {
    this.loadingModalService.show();
    return this.http.post(this.getPassedCriteriaLendersUrl, JSON.stringify(applicationId), {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response;
      }),
      catchError((error) => {
        console.log(error)
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);
  }

  private logQuoteLookup(quote: Quote, success: boolean) {

    const analyticsData: AnalyticsData = {
      vehicle: {
        vrm: quote.vehicle.registration,
        mileage: quote.vehicle.mileage,
        price: quote.vehicle.price,
        cap_valuation: quote.vehicle.valuation ? quote.vehicle.valuation.retail : quote.vehicle.price
      },
      terms: {
        loanAmount: quote.financeRequest.loanAmount,
        months: quote.financeRequest.term,
        deposit: quote.financeRequest.deposit.cash,
        financeType: quote.financeRequest.financeType
      }
    };

    if (success) {
      window['dataLayer'].push({
        event: 'service-response',
        label: 'successful-quote-lookup',
        data: analyticsData,
        timestamp: new Date().toISOString()
      });
    } else {
      window['dataLayer'].push({
        event: 'service-response',
        label: 'unsuccessful-quote-lookup',
        data: analyticsData,
        timestamp: new Date().toISOString()
      });
    }
  }
  
  checkLenderRequirements(application: Application): Observable<any> {

    return this.http.post(this.verifyLenderRequirements, application.id, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);
  }
  getDynamicLenderOffersPCP(application: Application): Observable<any> {

    return this.http.post(this.PCPLenders, application.id , {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);
  }

  getLenderCommission(LendersResponse): Observable<any> {

    return this.http.post(this.lenderCommissionDisplay, LendersResponse, { headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);
  }
}
