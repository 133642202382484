import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Application } from '../../../core/services/types';
@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Input()
  application: Application;
  constructor(private parentRouter: Router, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Help Centre");
  }
}
