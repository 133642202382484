<div class="card properties" *ngIf="designActiveControl!=null">
  <div class="card-header">
    <h1>Properties</h1>
  </div>
  <div class="card-block">
      <ng-container *ngIf="designActiveControl then formControlPropertiesPanel"></ng-container>
  </div>

  <ng-template #formControlPropertiesPanel>
    <div class="list-group">
        <div class="list-group-item" (click)="setActivePropertyName('control')">
        <label>control:</label>
        <span>{{designActiveControl.formControlName}}</span>
      </div>
      <div class="list-group-item" (click)="setActivePropertyName('type')">
        <label>type:</label>
        <span>{{designActiveControl.type}}</span>
      </div>
      <div class="list-group-item" (click)="setActivePropertyName('label')">
          <label>label:</label>
          <input *ngIf="activePropertyName=='label'" type="text" [(ngModel)]="designActiveControl.label"/>
          <span *ngIf="activePropertyName!=='label'">{{designActiveControl.label}}</span>
        </div>
      <div class="list-group-item" (click)="setActivePropertyName('order')">
        <label>order:</label>
        <input *ngIf="activePropertyName=='order'" type="number" min="1" [max]="configuration.controls.length" [(ngModel)]="designActiveControl.order" (ngModelChange)="changeControlOrder($event)"/>
        <span *ngIf="activePropertyName!=='order'">{{designActiveControl.order}}</span>
      </div>
      <ng-container *ngIf="designActiveControl.selectionOptions">
        <div class="list-group-item"  (click)="setActivePropertyName('option')">
          <label>Options</label>
          <span><i class="fa fa-plus pull-right" (click)="addOptionValue()"></i></span>
        </div>
        <div class="list-group-item" *ngFor="let option of designActiveControl.selectionOptions"  (click)="setActivePropertyName('option.'+ option.label.toLowerCase())">
          <label>&nbsp;</label>
          <input *ngIf="activePropertyName==('option.'+ option.label.toLowerCase())" type="text" [(ngModel)]="option.label"/>
          <span *ngIf="activePropertyName!==('option.'+ option.label.toLowerCase())">{{option.label}}
            <i class="fa fa-trash pull-right" (click)="removeOptionValue(option)"></i>
            <i class="fa fa-chevron-up pull-right" (click)="moveOptionValueUp(option)"></i>
            <i class="fa fa-chevron-down pull-right" (click)="moveOptionValueDown(option)"></i>
          </span>
        </div>
      </ng-container>
    </div>
    {{activePropertyName}}
  </ng-template>

</div>
