<div class="mobile-only">
  <div class="main-body">
    <div class="page-title">Documents</div>
    <div class="raised-card" routerLink="/applications/{{this.application.id}}/proofs">
      <div class="link-card-text">
        <span class="link-card-icon">5​</span>Upload Proofs
      </div>
    </div>
    <div class="raised-card" routerLink="/applications/{{this.application.id}}/documents">
      <div class="link-card-text">
        <span class="link-card-icon">F</span>Sign Agreements
      </div>
    </div>
  </div>
  <navbar [currentSection]="'documents'"></navbar>
</div>

<!--<desktop-warning></desktop-warning>-->
