import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss']
})
export class HelpPanelComponent implements OnInit {

  @Input()
  showHelp:boolean;

  @Input()
  infoText?:string='';

  @Input()
  whyText?:string='';

  constructor() { }

  ngOnInit() {
  }

}
