import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from "@angular/forms";
import { ActionsService } from '../../../core/services/actions/actions.service';

@Component({
  selector: 'page-size-selection',
  templateUrl: './page-size-selection.component.html',
  styleUrls: ['./page-size-selection.component.scss']
})
export class PageSizeSelectionComponent implements OnInit {

  public form: FormGroup;
  
  @Input()
  pageSizeOptions: any[];

  constructor(public formBuilder: FormBuilder, private actionsService: ActionsService) {

    this.form = formBuilder.group({
      pageSize: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.form.setValue({pageSize: this.pageSizeOptions[0].value});
  }

  changePageSize() {

    this.actionsService.broadcast({
      action: 'pageSizeSelection',
      behaviour: 'change',
      value: this.form.controls.pageSize.value
    });
  }



}
