import { Directive, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, ValidationErrors } from "@angular/forms";
import { isANumber } from "../utilities";

export function max(max: number): ValidatorFn {
  if (!isANumber(max)) {
    throw Error("The maximum is expected to be numerical");
  }

  return (control: AbstractControl): ValidationErrors => {
    if (control.value != null) {
      let value = Number(control.value);

      if (isANumber(value) && value > max) {
        return {
          'max': {
            actualValue: value,
            maximumValue: max
          }
        }
      }
    }

    return null;
  };
}

@Directive({
  selector: '[maximum]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxDirective, multi: true }]
})
export class MaxDirective implements Validator, OnChanges {
  @Input()
  maximum: number;

  validator: ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['maximum'];

    if (change) {
      this.validator = max(change.currentValue);
    }
    else {
      this.validator = null;
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.validator) {
      return this.validator(control)
    }
  }
}
