import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injectable } from '@angular/core';

import { AuthenticationResult } from "../login/loginmodels";
import { ActionsService } from "../actions/actions.service";


@Injectable()
export class TokenService {

  // public currentUser: string;
  public changedUser: boolean;

  constructor(private actionsService:ActionsService) { }

  isAuthenticated(): boolean {

    
    if (this.bearerToken != null && (this.lifetime==0 || (this.expires!=null && new Date().getTime()<this.expires))) {
      return true;
      
    }
    return false;
    


  }

  //checked and working in angular 6
  public setUserInformation(authenticationResult: AuthenticationResult) {

    this.bearerToken = authenticationResult.bearerToken;
    this.changedUser = (this.userName == authenticationResult.username) ? false : true;
    this.userName = authenticationResult.username;
    this.currentUser = authenticationResult.id;
    this.lifetime = authenticationResult.lifetime;
    this.expires = authenticationResult.expires;
    this.roles = authenticationResult.roles;
  
  }



  public calculateExpires(lifetime:number): number {
    
    var expires=new Date().getTime()+lifetime;
    return expires;
    
  }
    
  public updateExpires() {

    this.expires=this.calculateExpires(this.lifetime);

  }

  public get bearerToken(): string {
    return localStorage.getItem('bearerToken');
  }

  public set bearerToken(value: string) {
    localStorage.setItem('bearerToken', value);
  }

  public get userName(): string {
    return localStorage.getItem('userName');
  }

  public set userName(value: string) {
    localStorage.setItem('userName', value);
  }

  public get currentUser(): string {
    return localStorage.getItem('currentUser');
  }

  public set currentUser(value: string) {
    localStorage.setItem('currentUser', value);
  }

  public get lifetime(): number {
    return parseInt(localStorage.getItem('lifetime'));
  }

  public set lifetime(value: number) {
    localStorage.setItem('lifetime', value.toString());
  }

  public get expires(): number {
    return parseInt(localStorage.getItem('expires'));
  }

  public set expires(value: number) {

    localStorage.setItem('expires', value.toString());

    this.actionsService.broadcast(
      {
        action:'authentication',
        behaviour:'expires',
        value:value
      }

    );
  }

  public get roles(): string[] {

    var roles = localStorage.getItem('roles');
    return roles?localStorage.getItem('roles').split(','):[];

  }

  public set roles(value: string[]) {
    localStorage.setItem('roles', value.join(','));
  }

  clearUserInformation() {

    localStorage.removeItem('bearerToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('expires');
    localStorage.removeItem('lifetime');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('roles');
 
  }

}
