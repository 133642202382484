import { Directive, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, ValidationErrors } from "@angular/forms";
import { isDate } from "../utilities";

export function minDate(minDate: Date): ValidatorFn {
  if (!isDate(minDate)) {
    throw Error("The minimum date is expected to be a date");
  }

  return (control: AbstractControl): ValidationErrors => {
    if (control.value != null) {
      let value = new Date(control.value);

      if (isDate(value) && value < minDate) {
        return {
          'minDate': {
            actualValue: value,
            minimumValue: minDate
          }
        }
      }
    }

    return null;
  };
}

@Directive({
  selector: '[minimumDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDateDirective, multi: true }]
})
export class MinDateDirective implements Validator, OnChanges {
  @Input()
  minimumDate: Date;

  validator: ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['minimumDate'];

    if (change) {
      this.validator = minDate(change.currentValue);
    }
    else {
      this.validator = null;
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.validator) {
      return this.validator(control)
    }
  }
}
