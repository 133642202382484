import { Component, OnInit, Input } from '@angular/core';
import { Application } from '../../../core/services/types';
import { DisplayVehicle } from '../../../core/services/types-quote';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { Subscription } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'agreements-documentation',
  templateUrl: './agreements-documentation.component.html',
  styleUrls: ['./agreements-documentation.component.scss']
})
export class AgreementsDocumentationComponent implements OnInit {

  pageCount: boolean;
  @Input()
  application: Application;
  agreementsObserve: Subscription;

  constructor(private actionsService: ActionsService) {
    this.pageCount = false;
  }

  ngOnInit() {
    document.body.style.background = '#eeeff3'
    this.agreementsObserve = this.actionsService.registerObserver(
      'force.reload',
      action => {
        location.reload();
      },
      this
    );
    this.broadcastVehicle();
    this.pageCount =true;
  }

  broadcastVehicle() {
    const quote = this.application.quotes[0];
    const vehicle: DisplayVehicle = {
      make: quote.vehicle.make,
      model: quote.vehicle.model,
      variant: quote.vehicle.model,
      source: quote.vehicle.source = "quote generated",
      registration: quote.vehicle.registration,
      regYear: Number(quote.vehicle.dateOfRegistration.split("-")[0]),
      term: quote.financeRequest.term,
      financeType: quote.financeRequest.financeType,
      balloon: quote.financeResponse.balloon,
      monthlyPayments: quote.financeResponse.monthlyPayment,
      imageUrl_Main: quote.vehicle.imageUrl
    }

    this.actionsService.broadcast({
      action: 'displayVehicle',
      behaviour: 'updateVehicle',
      value: {
        vehicle: vehicle,
        status: this.application.status,
        loanOffer: this.application.loanProposalResponses.filter(loan => loan.isSelected)[0].loanOffer
      }
    }
    );
  }
}
