import { CommercialAddress } from "./types-address";

/*
  Employer
*/
class Employer {
    public status: string;
    public jobIndustry: string;
    public employmentStatus: string;
    public name: string;
    public address: CommercialAddress;
    public telephone: string;
    public jobTitle: string;
    public startDate: Date;
    public endDate: Date;
    public JobType: string;
}


export {
    Employer
}