
class Document {
    clicked: boolean;
    id: string;
    title: string;
    status?: string;
    fileName: string;
    links: DocumentLink[];
}

class DocumentLink {
    url: string;
    isEsignLink: boolean;
    downloadTimestamp: string;
    description: string;
}

export {
    Document, DocumentLink
}