<form *ngIf="model" [formGroup]="form">
  <fieldset [disabled]="!editing">
    <div class="form-group">
      <label for="postcode">Postcode</label>
      <addressLookup formControlName="postcode" [isInvalid]="formErrors.postcode.length > 0" (address)="populateForm($event)"></addressLookup>
    </div>
    <div *ngIf="postcodeNotFound" class="alert alert-danger alert-dismissible fade show" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button> The postcode that you entered was not found
    </div>
    <control-error-messages [model]="formErrors.postcode"></control-error-messages>
    <input type="hidden" class="form-control" formControlName="id">
    <div class="form-group">
      <label for="buildingName">House Name</label>
      <input type="text" class="form-control" formControlName="buildingName">
    </div>
    <control-error-messages [model]="formErrors.buildingName"></control-error-messages>
    <div class="form-group">
      <label for="buildingNumber">House Number</label>
      <input type="text" class="form-control" formControlName="buildingNumber">
    </div>
    <control-error-messages [model]="formErrors.buildingNumber"></control-error-messages>
    <div class="form-group">
      <label for="address1">Address</label>
      <input type="text" class="form-control" formControlName="address1">
    </div>
    <control-error-messages [model]="formErrors.address1"></control-error-messages>
    <div class="form-group">
      <!-- <label for="address2"></label> -->
      <input type="text" class="form-control" formControlName="address2">
    </div>
    <control-error-messages [model]="formErrors.address2"></control-error-messages>
    <div class="form-group">
      <label for="town">Town</label>
      <input type="text" class="form-control" formControlName="town">
    </div>
    <control-error-messages [model]="formErrors.town"></control-error-messages>
    <div class="form-group">
      <label for="county">County</label>
      <input type="text" class="form-control" formControlName="county">
    </div>
    <control-error-messages [model]="formErrors.county"></control-error-messages>
    <div class="form-group">
      <label for="country">Country</label>
      <input type="text" class="form-control" formControlName="country">
    </div>
    <control-error-messages [model]="formErrors.country"></control-error-messages>
  </fieldset>
</form>
