
import { Condition } from "../types";
import { PageState } from "./configuration-page-states";

class AuthenticationConfiguration {
    public passwordPolicy: PasswordConfiguration;
    public expirationCountdown: number;
}

class PasswordConfiguration {
    public minimumLength: number;
    public numbers: boolean;
    public letters: boolean;
    public specialCharacters: string;
}


class ErrorsConfiguration {
    generic: ErrorConfiguration;
    notfound: ErrorConfiguration;
    unauthorised: ErrorConfiguration;
    validationError:ErrorConfiguration;
    offline: ErrorConfiguration;
    quote_generic: ErrorConfiguration;
    quote_validation: ErrorConfiguration;
    vehicle_generic: ErrorConfiguration;
    vehicle_validation: ErrorConfiguration;
    postcode_generic: ErrorConfiguration;
    postcode_validation: ErrorConfiguration;
    offer_generic: ErrorConfiguration;
    offer_validation: ErrorConfiguration;
    proofs_upload: ErrorConfiguration;
    proofs_delete: ErrorConfiguration;
    proofs_download: ErrorConfiguration;
    notifications_generic: ErrorConfiguration;
    save_generic: ErrorConfiguration;
    creditRating_generic: ErrorConfiguration;
}

class ErrorConfiguration {
    title: string;
    popupText: string;
    includeExceptioninfo: boolean;
    reDirect?: boolean;
    type: string;
}

class ErrorMessage {
    code: string;
    message: string;
}

class CustomError {
    public errorConfiguration: ErrorConfiguration;
    public error: any;
}

class LookupsConfiguration {
    public residentialStatuses: LookupItem;
    public titles: LookupItem;
    public maritalStatuses: LookupItem;
    public gender: LookupItem;
    public drivingLicence: LookupItem;
    public preferredContactMethods: LookupItem;
    public preferredContactTimes: LookupItem;
    public employmentStatuses: LookupItem;
}

class LookupItem {
    public controlType: 'select' | 'buttons';
    public items: Array<SelectListItem>;
}

class SelectListItem {
    label: string;
    value: string;
    img?: string;
}

class AcceptanceConfiguration {
    displayDeclinedResponses: boolean;
    displayReferredResponses: boolean;
    displayErrorResponses: boolean;
    applyTimeout: number;
}

class IdentityConfiguration {
    minAge: number;
    maxAge: number;
}


class EmploymentConfiguration {
    minHistoryMonths: number;
}

class AddressesConfiguration {
    minHistoryMonths: number;
}

class NavigationConfiguration {
    public validInvalidIconStyle: 'TICK_AND_WARNING' | 'TICK_AND_ITEM_ICON' | 'NONE';
    public includeNextSectionButton: boolean;
    public sectionCollapse: 'ALL_COLLAPSED' | 'ALL_OPEN' | 'INVALID_OPEN';
    public gotoFirstInvalidSection: boolean;
    public pagingMethod: 'FORWARDS_ONLY' | 'BACKWARDS_AND_FORWARDS';
}

class OutgoingsConfiguration {
    public optional: boolean;
}

class ContactConfiguration {
    public telephone: string;
    public telephoneComplaints: string;
    public email: string;
    public emailComplaints: string;
    public address: string;
    public defaultPreferredContactMethod: string;
    public defaultPreferredContactTime: string;
}

class QuotesConfiguration {
    public copyVehicleOnAddQuote: boolean;
    public copyFinanceRequestOnAddQuote: boolean;
    public maximumPermitted: number;
    public getQuoteTimeoutCountdown: number;
}

class PageSCrollConfiguration {
    public adjustHash: boolean;
    public offset: number;
    public duration: number;
}

class HelpConfiguration {
    public show: boolean;
}

class SaveConfiguration {
    public method: 'global' | 'paged' | 'auto';
    public savePeriod: number;
}

class ProductsConfiguration {
    public noThanksId: number;
}

class PostcodeLookupConfiguration {
    public addressListUrl: string;
    public addressDetailUrl: string;
}

class FinanceRequestConfiguration {
    public maximumLTVPercentage: number;
    public minimumLoan: number;
    public minimumDeposit: number;
    public term: SliderConfiguration;
    public annualMileage: SliderConfiguration;
}

class BaseRateConfiguration {
    public maximumLTVPercentage: number;
    public minimumLoan: number;
    public minimumDeposit: number;
    public term: SliderConfiguration;
    public annualMileage: SliderConfiguration;
}

class SliderConfiguration {
    public minimum: number;
    public maximum: number;
    public step: number;
}

class FinanceOptionConfiguration {
    public label: string;
    public requiresAnnualMileage: boolean;
    public maximumDeposit: number;
    public productLink?: string;
    public content: string;
    public conditions: Condition[];
}

class VehicleConfiguration {
    public maximumSearchesPermitted: number;
    public maximumAgeAtStartOfLoan: number;
    public maximumAgeAtEndOfLoan: number;
    public maximumMileageAtStartOfLoan: number;
    public minRegistrationLength;
    public maxRegistrationLength;
}

class WarrantyConfiguration {
    public maximumAge;
    public maximumMileage;
}

class CreditRatingConfiguration {
    public ratingMethod: 'questions' | 'softSearch'
}

class ProofsConfiguration {
    public maximumFileSize: number;
    public immediateDragDropUpload: boolean;
    public immediateFileSelectUpload: boolean;
    public confirmFileReplacements: boolean;
    public acceptedFileFormats: AcceptedFileFormat[];
}

class AcceptedFileFormat {
    public label: string;
    public extention: string;
    public mimeType: string;
}

declare type FinanceOptions = { [type: string]: FinanceOptionConfiguration }

declare type Journeys = { [type: string]: JourneyConfiguration };

class MenuConfiguration {
    public show: boolean;
    public validInvalidIconStyle: 'TICK_AND_WARNING' | 'TICK_AND_ITEM_ICON' | 'NONE';
    public showActiveItemOnly?: boolean;
}

class AppearanceConfiguration {
    public logo: string;
    public mobileLogo?: string;
    public phoneIcon: string;
    public title: string;
    public favIcon: string;
}

class JourneyConfiguration {
    public topMenu: MenuConfiguration;
    public secondaryMenu: MenuConfiguration;
    public sectionNavigation: NavigationConfiguration;
    public help: HelpConfiguration;
    public defaultPastStepState: PageState;
    public defaultActiveStepState: PageState;
    public defaultFutureStepState: PageState;
}

export {
    AppearanceConfiguration, AuthenticationConfiguration, LookupsConfiguration, LookupItem, SelectListItem, AcceptanceConfiguration, EmploymentConfiguration, AddressesConfiguration, NavigationConfiguration,
    OutgoingsConfiguration, IdentityConfiguration, ContactConfiguration, QuotesConfiguration, PageSCrollConfiguration, HelpConfiguration, SaveConfiguration, ProductsConfiguration,
    PostcodeLookupConfiguration, FinanceRequestConfiguration, SliderConfiguration, FinanceOptionConfiguration, VehicleConfiguration, WarrantyConfiguration,
    FinanceOptions, Journeys, MenuConfiguration, JourneyConfiguration, ErrorsConfiguration, ErrorConfiguration, PasswordConfiguration, CreditRatingConfiguration, ProofsConfiguration
};
