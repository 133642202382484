<form #form *ngIf="model.visible" class="navbar-form navbar-right search" role="search">
  <div class="form-group">
    <div class="input-group">
      <input type="text" class="form-control" [placeholder]="model.placeholder" required [(ngModel)]="model.value" #searchvalue="ngModel" name="searchvalue" />
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" [disabled]="!searchvalue.valid" (click)="search()">
          <i class=" fa fa-search " aria-hidden="true "></i>
        </button>
      </span>
    </div>
  </div>
</form>
