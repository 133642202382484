import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Application } from '../../../core/services/types';
import { QuotesService } from '../../../core/services/quotes/quotes.service';

@Component({
  selector: 'finance-breakdown',
  templateUrl: './finance-breakdown.component.html',
  styleUrls: ['./finance-breakdown.component.scss']
})
export class FinanceBreakdownComponent implements OnInit {
  @Input() selectedVehicle: any;
  @Input() application: Application;
  @Output() financeBreakdownClicked = new EventEmitter();
  constructor(private quoteService: QuotesService) { }

  MonthlyPayment: number; 
  TotalAmountPayable: number;
  TotalAmountOfCredit: number;
  TotalChargesPayable: number;
  apr: number;
  loanAmount: number;
  balloon: number;
  term: number;
  deposit: number;
  financeType: string;

  ngOnInit() {
    this.getFinanceCalcs();
  }
  async getFinanceCalcs(){
    const quote = await this.quoteService.getFinanceBreakdown(this.application.id.toString(), this.application.quotes[0].financeRequest, this.selectedVehicle).toPromise();
    
    this.MonthlyPayment = quote.monthlyPayment;
    this.TotalAmountPayable = quote.totalAmountPayable;
    this.loanAmount = quote.loanAmount;
    this.apr = quote.apr;
    this.deposit = quote.deposit;
    this.term = quote.term;
    this.balloon = quote.balloon;
    this.financeType = quote.financeType;
    this.TotalChargesPayable = this.TotalAmountPayable - this.loanAmount;
  }

  CloseModal(){
    this.financeBreakdownClicked.emit(false);
  }

}
