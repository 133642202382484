import { Component, Input } from '@angular/core';

@Component({
  selector: 'control-error-messages',
  templateUrl: 'control-error-messages.component.html'
})

export class ControlErrorMessagesComponent  {

  @Input()
  model:any[];

  constructor() {}

}
