/*
  Contact
*/
class Contact {
    public email: string;
    public telephone: string;
    public mobile: string;
}

export {
    Contact
}