import { Component, Input, OnChanges, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { LoadingModalService } from '../../../core/services/modal/loading-modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-modal',
  templateUrl: 'loading-modal.component.html',
  styleUrls: ['loading-modal.component.scss']
})
export class LoadingModalComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input()
  loading: boolean;
  config: any;
  isVisible = false;
  resolver: any;
  blockNgOnChangesCheck: boolean;
  
  subscription1: Subscription;
  subscription2: Subscription;
  promise: Promise<boolean>;
  source: string;
  registerationMessage = false;
  currentPromise;
  proposalMessage = false;
  documentsMessage= false;
  dealersApplyPageMessage= false;
  getQuotes= false;
  carLookup= false;
  calculator= false;
  message: string = "Gathering loan options";
  docsMessage: string = "Preparing your documents";
  applyMessage: string = "Credit searching...";
  getQuoteMessage: string = "Generating quote...";
  vehicleLookupMessage: string = "Searching vehicle...";
  calculatorMessage: string = "Calculating...";
  
  constructor(public loadingModalService: LoadingModalService) {

    if (window.location.href.indexOf("dealerplus") != -1) {
      this.source = 'dealer';
    }
    else if (window.location.href.indexOf("comparecarfinance") != -1) {
      this.source = 'ccf';
    }
    else {
      this.source = 'consumer';
    }
    // Subscribe to showAnnounced in the Loading Modal service and show the Modal when it is broadcast
    this.subscription1 = this.loadingModalService.showAnnounced$.subscribe(
      () => {
        this.show();
      }
    );    
    
    // Subscribe to showAnnounced in the Loading Modal service and show the Modal when it is broadcast
    this.subscription1 = this.loadingModalService.display$.subscribe(
      (step) => {
        this.displayMessage(step);
      }
    );

    // Subscribe to hideAnnounced in the Loading Modal service and hide the Modal when it is broadcast
    this.subscription1 = this.loadingModalService.hideAnnounced$.subscribe(
      () => {
        this.hide();
      }
    );

    this.blockNgOnChangesCheck = true;
    this.config = {
      ignoreBackdropClick: true,
      keyboard: false
    };
  }
  
  public displayMessage(step){
    if(step == 1){
    this.proposalMessage = true;
    }
    if(step == 2){
      this.documentsMessage = true;
    }
    if(step == 3){
      this.dealersApplyPageMessage = true;
    }
    if(step == 4){
      this.getQuotes = true;
    }
    if(step == 5){
      this.carLookup = true;
    }
    if(step == 6){
      this.calculator = true;
    }
  }

  public show(): Promise<any> {
    if (window.location.href.includes("register")) {
      this.registerationMessage = true;
    }
    const result = new Promise(
      (resolve, reject) => {
        this.isVisible = true;
        this.resolver = resolve;
      }
    );
    result.then(value => {
      this.hide();
    });
    return result;
  }

  public hide(): void {
    this.registerationMessage = false;
    this.proposalMessage = false;
    this.documentsMessage = false;
    this.dealersApplyPageMessage = false;
    this.getQuotes = false;
    this.carLookup = false;
    this.isVisible = false;
    this.calculator = false;
  }

  public checkloading() {
    if (this.loading) {
      this.show();
    } else {
      this.hide();
    }
  }

  ngAfterViewInit() {
    this.checkloading();
  }

  ngOnChanges() {
    if (this.blockNgOnChangesCheck) {
      this.blockNgOnChangesCheck = false;
    } else {
      this.checkloading();
    }
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
