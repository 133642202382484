import { environment } from './../../../../environments/environment';
import { VehicleService } from './../../../core/services/vehicle/vehicle.service';
import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { ProposedVehicle, Application } from '../../../core/services/types';
import { RecordsetPager } from '../../../shared/components/models.class';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'quote-showroom',
  templateUrl: './quote-showroom.component.html',
  styleUrls: ['./quote-showroom.component.scss']
})
export class QuoteShowroomComponent implements OnInit {

  showroomVehicleResults: ProposedVehicle[];
  parentName: string;
  pagerModel: RecordsetPager;
  pricePerMonth: boolean = true;
  monthClass: string;
  totalClass: string;
  loadingValues: boolean;
  displayVehiclesMobile: boolean = false;
  scrollable: boolean;
  selectedVehicle: any;
  @Input()
  public form: FormGroup;

  @Input()
  application: Application;

  @Input()
  cardId: number;

  @Output()
  public vehicleChange: EventEmitter<any> = new EventEmitter<ProposedVehicle>();

  @Output() displayVehicleDetails = new EventEmitter();
  @Output() displayBreakDown = new EventEmitter();

  showroomObserver: Subscription[];
  vehicle: ProposedVehicle = {};
  isDemo: boolean = environment.demo;
  vehicle_details_page_on: boolean = false;

  filterOption = [
    { label: "Price (low to high)", value: 1 },
    { label: "Price (high to low))", value: 2 },
    { label: "Mileage (low to high)", value: 3 },
    { label: "Mileage (high to low)", value: 4 },
    { label: "Age (newestFirst)", value: 5 },
    { label: "Age (oldest First)", value: 6 },
    { label: "Engine (low to high)", value: 7 },
    { label: "Engine (high to low)", value: 8 }
  ];


  constructor(public actionsService: ActionsService, public vehicleService: VehicleService) {
    this.pagerModel = {
      page: 1,
      pageSize: 12,
      arrownavigators: true,
      visible: true,
      totalRecords: 0
    };
    this.parentName = 'showRoom';

    this.showroomVehicleResults = [];
  }

  ngOnInit() {
    this.showroomObserver = this.actionsService.registerObservers(
      ['loadvehicles.searchresults'],
      action => {
        this.setShowroomVehicles(action.value.vehicles, action.value.infinitScroll);
      },
      this
    );
    this.showroomObserver = this.actionsService.registerObservers(
      ['display.list'],
      action => {
          this.displayVehiclesMobile = false;
      },
      this
    );
  }

  ngOnDestroy() {
    this.actionsService.cancelSubscriptions(this.showroomObserver);
  }

  isVehicleShowroomEmpty() {
    return this.showroomVehicleResults == null || this.showroomVehicleResults.length == 0;
  }

  setShowroomVehicles(showRoomVehicles: any, infinitScroll?: boolean) {
    if (!infinitScroll) {
      this.showroomVehicleResults = showRoomVehicles.vehicles;
      this.pagerModel.page = 1;
    } else {
      this.showroomVehicleResults = this.showroomVehicleResults.concat(showRoomVehicles.vehicles);
    }
    this.pagerModel.totalRecords = showRoomVehicles.totalRecords;
    this.loadingValues = false;
  }

  async vehicleFound(newVehicle) {
    let vehicle;
    try {
      if (environment.production) {
        vehicle = await this.vehicleService.vehicleLookup({ applicationId: 0, registration: newVehicle.registration, mileage: newVehicle.mileage, price: newVehicle.retailPrice, showroom: true, isSuggested: false }).toPromise();
      } else {
        vehicle = await this.vehicleService.vehicleLookup({ applicationId: 0, registration: 'CN15 OED', mileage: newVehicle.mileage, price: newVehicle.retailPrice, showroom: true, isSuggested: false }).toPromise();
      }
      if (vehicle != null) {
        if (environment.production) {
          this.vehicle = vehicle;
        } else {
          this.vehicle = newVehicle;
          this.vehicle.valuation = vehicle.valuation;
          this.vehicle.dateOfRegistration = newVehicle.registrationYear + "-03-20";
          this.vehicle.id = vehicle.id;
        }
        if (Object.keys(this.vehicle).length > 0) {
          this.form.markAsDirty();
          this.form.controls['registration'].setValue(this.vehicle.registration);
          this.form.controls['mileage'].setValue(this.vehicle.mileage);
          if (environment.production) {
            this.form.controls['price'].setValue(this.vehicle.valuation.retail);
          } else {
            this.form.controls['price'].setValue(newVehicle.price);
          }
        }
        this.vehicleChange.emit(this.vehicle);
        this.form.updateValueAndValidity();


        this.actionsService.broadcast({
          action: 'vehicle',
          behaviour: 'change',
          value: {
            formParent: this.form.parent,
            valid: this.form.valid,
            vehicle: this.vehicle
          }
        });
      }
    }
    catch (error) {
    }
  }

  toggleArchive(value: boolean) {
    this.pricePerMonth = value;
  }
  @HostListener('window:scroll', ['$event']) onScroll(): void {
    if (window.scrollY >= window.innerHeight + ((this.pagerModel.page - 1) * 1400) && !this.loadingValues) {
      this.pagerModel.page++;
      this.loadingValues = true;
      this.actionsService.broadcast({
        action: 'load',
        behaviour: 'update',
        value: true,
      });
    }
  }
  backButton() {
    this.displayVehiclesMobile = false;
  }

  scrollToTop() {

    window.scroll(0, 0);
  }

  vehicleSelected(vehicle) {
    this.displayVehicleDetails.emit(vehicle);
  }

  financeBreakdownClicked(vehicle){
    this.displayBreakDown.emit(vehicle);
  }
  
  clearFilterValues(){
    this.actionsService.broadcast({
      action: 'clear',
      behaviour: 'filters',
      value: true
    });
  }

  displayFilters(){
    this.displayVehiclesMobile = true;
  }
  returnQuotes() {
    this.actionsService.broadcast({
      action: 'back',
      behaviour: 'quotes',
      value: true
    });
    window.scroll(0, 0);
  }
}