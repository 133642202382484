import { Injectable } from '@angular/core';

import { Subject ,  Observable } from 'rxjs';


@Injectable()
export class LoadingModalService {

  constructor () {}

  // Show modal subscription
  private showSource = new Subject<any>();
  showAnnounced$ = this.showSource.asObservable();

//show message
  private displaySource = new Subject<any>();
  display$ = this.displaySource.asObservable();

  // modal response subscription
  private hideSource = new Subject<any>();
  hideAnnounced$ = this.hideSource.asObservable();

  // Broadcast show modal
  show(): Observable<any> {
    this.showSource.next();
    return this.hideAnnounced$;
  }

  displayMessage(number){
    this.displaySource.next(number);
  }
  // Broadcast hide
  hide() {
    this.hideSource.next();
  }
}
