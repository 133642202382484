import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Application, Proof, RequiredProof } from '../../../core/services/types';
import { Router } from '@angular/router';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { LoadingModalService } from '../../../core/services/modal/loading-modal.service';
import { TokenService } from '../../../core/services/token/token.service';
import { environment } from '../../../../environments/environment';
import { Applicant } from '../../../shared/models/onfido/Applicant';
import { Address } from '../../../shared/models/onfido/Address';
import * as moment from 'moment/moment'
import { HttpClient, HttpHeaders,
  //RequestOptionsArgs, RequestOptions, ResponseContentType 
} from '@angular/common/http';
import { ProofsService } from '../../../core/services/proofs/proofs.service';
import {LoginService} from '../../../core/services/login/login.service';
import { Title } from '@angular/platform-browser';
class DropZone {
  uploader: FileUploader;
  label: string;
  id: string;
  requiredProof: RequiredProof;
  addingProof: boolean;
}

class EmailRequest{
  customer : Customer
  Metadata : MetaData
}
class Customer{
contactid: number
fname: string
lname: string
email: string

}

class MetaData {
  EmailTemplateId: number
  Optional_text: Array<string>
}


@Component({
  selector: 'proofs-no-onfido',
  templateUrl: './proofs-no-onfido.component.html',
  styleUrls: ['./proofs-no-onfido.component.scss']
})

export class ProofsNoOnfidoComponent implements OnInit {
  @Input()
  application: Application;
  dropZone: DropZone;
  availableProofs: Proof[]
  uploadProofUrl: string;
  checkProofUrl: string;
  //requestArgs: RequestOptionsArgs;
  fileFound;
  allowedExtensions: string[];
  incomeProofCount;
  @ViewChild('licenceFrontUpload') drivingLicenceUpload: ElementRef;
  @ViewChild('licenceBackUpload') drivingLicenceBackUpload: ElementRef;
  @ViewChild('addressProofUpload') addressProofUpload: ElementRef;
  @ViewChild('incomeProofUpload') incomeProofUpload: ElementRef;
  @ViewChild('licenceModal') licenceModal: ElementRef;
  @ViewChild('incomeModal') incomeModal: ElementRef;
  @ViewChild('addressModal') addressModal: ElementRef;
  @ViewChild('fileFormatModal') fileFormatModal: ElementRef;
  @ViewChild('incomeProofMaxModal') incomeProofMaxModal: ElementRef;

  constructor(private http: HttpClient,
    private parentRouter: Router,
    private loadingModalService: LoadingModalService,
    private tokenService: TokenService,
    private proofService: ProofsService,
    private loginService:LoginService,
    private titleService: Title,
  ) {
  }

  ngOnInit() {   

    this.titleService.setTitle("Proofs");
    this.incomeProofCount = 0;
    this.allowedExtensions = [".png", ".jpg", ".jpeg", ".gif", ".tif", ".tiff"]
    this.fileFound =
    {
      drivingLicenceFront: false,
      drivingLicenceBack: false,
      addressProof: false,
      employmentProof: false,
    }

    var requiredProof = {
      label: "Driving licence Front",
      id: "drivingLicenseFront",
      description: "Driving Licence (front)",
      docsRequired: 1
    };

    this.dropZone = {
      uploader: new FileUploader({ removeAfterUpload: true }),
      label: "Driving licence Front",
      id: "drivingLicenseFront",
      requiredProof: requiredProof,
      addingProof: true
    };

    this.uploadProofUrl = environment.base + `/docs/?applicationId=${this.application.id}`;
    this.availableProofs = [];
    this.checkProofUrl = environment.base + `/docs/download/?applicationId=${this.application.id}`;
    this.computeMissingDocuments(this.dropZone);
    this.bindEvents(this.dropZone);
    this.getFiles();
  }

  importFile(file: File, addingProofs: boolean) {
    this.loadingModalService.show();
    this.dropZone.addingProof = addingProofs;
    this.dropZone.uploader.addToQueue([file]);
    this.loadingModalService.hide();
  }

  getProof(item: FileItem, id: string) {
    return <Proof>{
      dateUploaded: moment(new Date()).format("DD-MM-YYYY"),
      filename: item._file.name,
      id: 0,
      title: item._file.name,
      type: id
    };
  }

  

  bindEvents(dropZone: DropZone) {
    dropZone.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.removeAnyDuplicateAvailableProof(item);
      if (dropZone.addingProof) {
        this.availableProofs.push(this.getProof(item, dropZone.requiredProof.id));
      } else {
        this.dropZone.addingProof = true;
      }
    };
    dropZone.uploader.onCompleteAll = () => {
      this.computeMissingDocuments(this.dropZone);
    };
  };

  computeMissingDocuments(dropZone: DropZone) {
    dropZone.uploader.setOptions({
      url: this.uploadProofUrl,
      queueLimit: 4,
      authToken: 'Bearer ' + this.tokenService.bearerToken,
      additionalParameter: {
        label: dropZone.id
      }
    });
  }

  removeAnyDuplicateAvailableProof(item: FileItem) {
    var foundProof = this.availableProofs.find(availableProof => availableProof.filename == item.file.name);
    if (foundProof != null) {
      this.removeProofFromAvailableProofs(foundProof);
    }
  }

  removeProofFromAvailableProofs(proof: Proof) {
    let existingProof = this.availableProofs.find(p => p.filename === proof.filename);
    if (existingProof == null) {
      throw new Error("The deleted proof does not exist");
    }
    let proofIndex = this.availableProofs.indexOf(existingProof);
    if (proofIndex === -1) {
      throw new Error("No index could be found for the existing index");
    }
    this.availableProofs.splice(proofIndex, 1);
  }

  fileEvent(fileInput: Event, filename) {
    var incomeCountMaxed = false;
    var file = (fileInput.target as HTMLInputElement).files[0];
    var fileExtension = "." + file.name.split(".")[1];
    if (this.allowedExtensions.indexOf(fileExtension.toLowerCase()) != -1) {
      switch (filename.toLowerCase()) {
        case ("drivinglicencefront"):
          this.drivingLicenceUpload.nativeElement.classList.add("file-to-upload")
          this.drivingLicenceUpload.nativeElement.classList.remove("no-file-image")
          this.drivingLicenceUpload.nativeElement.classList.remove("file-found-image")
          this.fileFound.drivingLicenceFront = true;
          break;

        case ("drivinglicenceback"):
          this.drivingLicenceBackUpload.nativeElement.classList.add("file-to-upload")
          this.drivingLicenceBackUpload.nativeElement.classList.remove("no-file-image")
          this.drivingLicenceBackUpload.nativeElement.classList.remove("file-found-image")
          this.fileFound.drivingLicenceBack = true;
          break;

        case ("addressproof"):
          this.addressProofUpload.nativeElement.classList.add("file-to-upload")
          this.addressProofUpload.nativeElement.classList.remove("file-found-image")
          this.addressProofUpload.nativeElement.classList.remove("no-file-image")
          this.fileFound.addressProof = true;
          break;

        case ("incomeproof"):
          if(this.incomeProofCount >= 15){
            incomeCountMaxed = true;
          } else {
          this.fileFound.incomeProof = true;
          filename = filename + (this.incomeProofCount + 1).toString();
          this.incomeProofCount++;
          }
          this.incomeProofUpload.nativeElement.classList.add("file-to-upload")
          this.incomeProofUpload.nativeElement.classList.remove("file-found-image")
          this.incomeProofUpload.nativeElement.classList.remove("no-file-image")
          break;


        default: break;
      }
      if(!incomeCountMaxed){
      let filev2 = new File([file], filename + fileExtension.toLowerCase(), { type: file.type });
      this.importFile(filev2, true);
      //this.updateFiles();
      } else {
        this.showModal("incomeProofMaxModal")
      }
    } else {
      this.showModal("fileFormatError")
    }
  }

  updateFiles() {
    if (this.fileFound.drivingLicenceFront == true) {
      this.drivingLicenceUpload.nativeElement.classList.remove("no-file-image")
      this.drivingLicenceUpload.nativeElement.classList.remove("file-to-upload")
      this.drivingLicenceUpload.nativeElement.classList.add("file-found-image")
    }
    else {
      this.drivingLicenceUpload.nativeElement.classList.add("no-file-image")
      this.drivingLicenceUpload.nativeElement.classList.remove("file-found-image")
    }

    if (this.fileFound.drivingLicenceBack == true) {
      this.drivingLicenceBackUpload.nativeElement.classList.remove("no-file-image")
      this.drivingLicenceBackUpload.nativeElement.classList.remove("file-to-upload")
      this.drivingLicenceBackUpload.nativeElement.classList.add("file-found-image")
    }
    else {
      this.drivingLicenceBackUpload.nativeElement.classList.add("no-file-image")
      this.drivingLicenceBackUpload.nativeElement.classList.remove("file-found-image")
    }

    if (this.fileFound.addressProof == true) {
      this.addressProofUpload.nativeElement.classList.remove("no-file-image")
      this.addressProofUpload.nativeElement.classList.remove("file-to-upload")
      this.addressProofUpload.nativeElement.classList.add("file-found-image")
    }
    else {
      this.addressProofUpload.nativeElement.classList.add("no-file-image")
      this.addressProofUpload.nativeElement.classList.remove("file-found-image")
    }
    if (this.fileFound.incomeProof == true) {
      this.incomeProofUpload.nativeElement.classList.remove("no-file-image")
      this.incomeProofUpload.nativeElement.classList.remove("file-to-upload")
      this.incomeProofUpload.nativeElement.classList.add("file-found-image")
    }
    else {
      this.incomeProofUpload.nativeElement.classList.add("no-file-image")
      this.incomeProofUpload.nativeElement.classList.remove("file-found-image")
    }    
    
  }

  getFiles() {
    this.proofService.getAllFiles(this.application.id.toString()).subscribe(response => {
      //Body is returned as a long string. Converting to array.
      //Remove "[ ]"
      var fileList = response._body;
      fileList = fileList.substr(1, fileList.length - 2)
      fileList = fileList.split(",");
      var incomeProofCountTemp = 0;
      var incomeProofNumber = 0;
      for (var i = 0; i < fileList.length; i++) {
        //Removing all whitespace, and quotation marks, then separating the extension.
        fileList[i] = fileList[i].replace(/\s/g, "").replace('"', "").replace('"', "").toLowerCase();
        fileList[i] = fileList[i].split(".")[0];
        if(fileList[i].includes("incomeproof")){
          incomeProofNumber = parseInt(fileList[i].replace("uploaded-incomeproof","") ) 
          if(incomeProofCountTemp < incomeProofNumber ){
            incomeProofCountTemp = incomeProofNumber; 
          }
        }
      }
      this.incomeProofCount = incomeProofCountTemp;



      if (fileList.indexOf("uploaded-drivinglicencefront") != -1) {
        this.fileFound.drivingLicenceFront = true;
      }

      if (fileList.indexOf("uploaded-drivinglicenceback") != -1) {
        this.fileFound.drivingLicenceBack = true;
      }

      if (fileList.indexOf("uploaded-addressproof") != -1) {
        this.fileFound.addressProof = true;
      }

      if (fileList.indexOf("uploaded-incomeproof1") != -1) {
        this.fileFound.incomeProof = true;
      }

      this.updateFiles();
    })
  }

  closeModal(modalName) {
    switch (modalName) {
      case ("fileFormatError"):
        this.fileFormatModal.nativeElement.style.display = "none";
        break;

      case ("licenceModal"):
        this.licenceModal.nativeElement.style.display = "none";
        break;

      case ("incomeModal"):
        this.incomeModal.nativeElement.style.display = "none";
        break;


      case ("addressModal"):
        this.addressModal.nativeElement.style.display = "none";
        break;

      case ("incomeProofMaxModal"):
        this.incomeProofMaxModal.nativeElement.style.display = "none";
        break;
      
      default:
        break;
    }
  }

  showModal(modalName) {
    switch (modalName) {
      case ("fileFormatError"):
        this.fileFormatModal.nativeElement.style.display = "block";
        break;

      case ("licenceModal"):
        this.licenceModal.nativeElement.style.display = "block";
        break;
      case ("incomeModal"):
        this.incomeModal.nativeElement.style.display = "block";
        break;


      case ("addressModal"):
        this.addressModal.nativeElement.style.display = "block";
        break;
    
      case ("incomeProofMaxModal"):
        this.incomeProofMaxModal.nativeElement.style.display = "block";
        break;

      default:
        break;
    }
  }

  
  doneButton() {    
    this.updateFiles();
    this.fileFound.drivingLicenceFront=false;
    this.fileFound.drivingLicenceBack=false;
    this.fileFound.addressProof=false;
    this.fileFound.employmentProof=false;
   //console.log(this.fileFound);
  //  this.emailSalesperson();
  }
}

