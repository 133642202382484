import { LookupsConfiguration } from "./configuration.types";

const LOOKUPS: LookupsConfiguration = {
    employmentStatuses: {
        controlType: 'buttons',
        items: [
            { label: "Employed", value: "EMP" },
            { label: "Self-employed", value: "SEMP" },
            { label: "Retired", value: "RT" },
            { label: "Student", value: "STD" },
            { label: "Unemployed", value: "UNEMP" }
        ]
    },
    residentialStatuses: {
        controlType: 'buttons',
        items: [
            { label: "Home Owner", value: "OW" },
            { label: "Living with Parents", value: "LP" },
            { label: "Military/Married Quarters", value: "MT" },
            { label: "Other/None", value: "OT" },
            { label: "Tenant-Council", value: "CT" },
            { label: "Tenant-Private", value: "PT" }
        ]
    },
    titles: {
        controlType: 'buttons',
        items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Doctor", value: "Dr" },
            { label: "Reverend", value: "Rev" },
            { label: "Other", value: "Unknown" }
        ]
    },
    maritalStatuses: {
        controlType: 'buttons',
        items: [
            { label: "Married", value: "MR" },
            { label: "Single", value: "SG" },
            { label: "Living with Partner", value: "LP" },
            { label: "Civil Partnership", value: "CP" },
            { label: "Widowed", value: "WD" },
            { label: "Separated", value: "SP" },
            { label: "Divorced", value: "DV" },
            { label: "Other", value: "OT" }
        ]
    },
    gender: {
        controlType: 'buttons',
        items: [
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' }
        ]
    },
    drivingLicence: {
        controlType: 'buttons',
        items: [
            { label: "Full UK driving license", value: "FULL" },
            { label: "Provisional UK driving license", value: "PROV" },
            { label: "European license", value: "EU" },
            { label: "International license", value: "INTL" },
            { label: "No license", value: "NONE" }
        ]
    },
    preferredContactMethods: {
        controlType: 'buttons',
        items: [
            { label: "Mobile", value: "Mobile" },
            { label: "Telephone", value: "Telephone" },
            { label: "Email", value: "Email" }
        ]
    },
    preferredContactTimes: {
        controlType: 'buttons',
        items: [
            { label: "No Preference", value: "No Preference" },
            { label: "Morning", value: "Morning" },
            { label: "Afternoon", value: "Afternoon" },
            { label: "Lunch", value: "Lunch" },
            { label: "Evening", value: "Evening" }
        ]
    }
};


export { 
    LOOKUPS
}