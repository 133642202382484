import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Application } from "../services/types";
import { UserService } from "../services/user/user.service";

@Injectable()
export class ApplicationResolver implements Resolve<Application> {
  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    if (route.parent == null) {
      throw Error("A parent route has not been found");
    }

    let applicationId = parseInt(route.params["id"]);

    if (applicationId) {
      return this.userService.getApplicationById(applicationId);
    }
    else {
      throw new Error("The application id is required - I've broken here")
    }
    
  }
}
