import { GROUPS } from "./configuration-groups";

const ROLES = {
    CUSTOMER: {
        name: 'customer',
        group: GROUPS.CONSUMER.name
    },
    ADMIN: {
        name: 'admin',
        group: GROUPS.CONSUMER.name
    },
    SALES: {
        name: 'sales',
        group: GROUPS.CONSUMER.name
    },
    DEALER: {
        name: 'dealerplus dealer',
        group: GROUPS.DEALER.name,
    },
    DEALER_CUSTOMER: {
        name: 'dealerpluscustomer',
        group: GROUPS.DEALER.name
    },
    CCF: {
        name: 'ccf customer',
        group: GROUPS.CCF.name
    },
    TEMP: {
        name: 'temporary user',
        group: GROUPS.TEMP.name
    },
    dealerplusCustomer: {
        name: 'dealerplus customer',
        group: GROUPS.DpCustomer.name
    }
};

export {
    ROLES
}