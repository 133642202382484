import { FormGroup } from '@angular/forms';

class ButtonGroup {
    public group :string;
    public type :string;
    public btnclass: string;
    public navbar :boolean;
    public selection :string;
    public visible :boolean;
    public items :ButtonGroupItem[];

    constructor(model:any) {

        this.group=model.group;
        this.type=model.type;
        this.btnclass=model.btnclass;
        this.navbar=model.navbar;
        this.selection=model.selection;
        this.visible=model.visible;
        this.items=model.items;

    }

    public getActiveKeys() {

        var keys=[];

        this.items.map(
            item => {
                if (item.active) {
                    keys.push(item.action);
                }
            }
        );
        return keys;

    }

}

class ButtonGroupItem {

    public text? :string;
    public icon? :string;
    public action :string;
    public disabled :boolean;
    public active :boolean;
    public visible :boolean;
    public candelete? :boolean;

}

class Search {
    public value :string;
    public placeholder :string;
    public visible :boolean;
}

class RecordNavigator {
    public items :any[];
    public position :number;
    public visible :boolean;
}

class RecordsetPager {
    public page :number;
    public pageSize :number;
    public arrownavigators :boolean;
    public visible :boolean;
    public totalRecords :number;
}

class NumberPlusMinus {
    public value :number;
    public min :number;
    public max :number;
    public visible :boolean;
    public enabled :boolean;
}

interface Navigate {
    first();
    last();
    next();
    previous();
    goto(page :number);
}

interface Visibility {
    show(key?:string);
    hide(key?:string);
    toggle(key?:string);
}

interface Enablement {
    enable(key?:string);
    disable(key?:string);
}

interface Activation {
    activate(key:string);
    deactivate(key:string);
}

interface SetValue {
    setValue(key: string, itemkey: string, value:boolean);
    setValue(key: string, itemkey: string, value:string);
}

interface Broadcast {
    
    broadcast();
    broadcast(item:any) :any;

}
    
interface AcceptSetValueRequests {

    registerAcceptsSetValue(group: string);
    acceptSetValue(values :any);

}

interface AcceptGetValueRequests {

    registerAcceptsGetValue(group: string);
    acceptGetValue(values :any);

}

interface QueryParameters {

    getQueryParameters(params: any[]);
    setQueryParameters();

}

interface BaseForm {

    form:FormGroup;
    formDefinition: any;
    formErrors: any;
    formValidationMessages: any;
    defaultFormValidationMessages: any;
    id: string;
    model: any;
    errorMessage :string;
    modalSettings :any;

    createForm();
    initializeFormDefinition();
    getModelData();
    setFormData();
    onValueChanged(data?: any);
    submit();
    cancel();
}

interface Amendments {
    new();
    edit();
    display();
    delete();
}

interface SaveCancel {
    save();
    cancel();
}

interface Help {
    showHelp:boolean;
    toggleHelp();
}

interface Valid {
    valid:boolean;
} 

interface Dirty {
    dirty:boolean;
} 

export {
    ButtonGroup,
    Search,
    RecordNavigator,
    RecordsetPager,
    Navigate,
    Visibility,
    Enablement,
    Activation,
    SetValue,
    Broadcast,
    AcceptSetValueRequests,
    AcceptGetValueRequests,
    QueryParameters,
    BaseForm,
    NumberPlusMinus,
    Help,
    Valid,
    Dirty
}
