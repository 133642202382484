import { Component, Input, OnInit } from '@angular/core';

import { ActionsService } from './../../../../core/services/actions/actions.service';

import { ButtonGroup, Visibility, Enablement, Activation, SetValue, Broadcast, AcceptSetValueRequests, AcceptGetValueRequests } from './../../models.class';

import { Subscription } from 'rxjs';

@Component({
    moduleId:module.id,
    selector: 'button-group',
    templateUrl: 'button-group.component.html',
    styleUrls: ['button-group.component.scss']
})
export class ButtonGroupComponent implements OnInit, Visibility, Enablement, Activation, SetValue, Broadcast, AcceptSetValueRequests, AcceptGetValueRequests {

    @Input()
    model: ButtonGroup;

    acceptSetValueObserver :Subscription;
    acceptGetValueObserver :Subscription;

    constructor(public actionsService :ActionsService) {

    }

    ngOnInit() {
    }

    ngOnDestroy(){

        if (typeof this.acceptSetValueObserver!='undefined') {
            this.actionsService.cancelSubscription(this.acceptSetValueObserver);
        }

        if (typeof this.acceptGetValueObserver!='undefined') {
            this.actionsService.cancelSubscription(this.acceptGetValueObserver);
        }

    }

    registerAcceptsSetValue(group) {

        this.acceptSetValueObserver=this.actionsService.registerAcceptsSetValue(
            group,
            values => {
                this.acceptSetValue(values);
            },
            this
        );

    }

    acceptSetValue(values :any) {

        this.setValue(values.key, values.itemkey, values.value);

    }

    registerAcceptsGetValue(group) {

        this.acceptGetValueObserver=this.actionsService.registerAcceptsGetValue(
            group,
            values => {
                this.acceptGetValue(values);
            },
            this
        );

    }

    acceptGetValue(values :any) {

        var index=this.getItemIndex(values.key);

        if (index!=-1) {
        }
        return this.model.items[index][values.itemkey];

    }


    action(item) {

        if (this.model.selection=='single') {
            this.setActiveIndex(item);
        }
        else if (this.model.selection=='multiple') {
             this.toggleActive(item);
        }

        this.broadcast(item);

    }

    delete(item) {

        var index=this.model.items.indexOf(item);

        if (index!=-1) {
            item.behaviour='delete';
            this.broadcast(item);

            this.model.items.splice(index,1);
        }

    };

    broadcast(item?: any) {

        var action={
            group:this.model.group,
            action:item.action,
            behaviour:(typeof item.behaviour!='undefined')?item.behaviour:'click'
        };
        this.actionsService.broadcast(action);

    }

    getItemIndex(action: string) {

        var i=-1;

        this.model.items.map(
            (item, index) => {
                 if (item.action==action) {
                     i=index;
                     return;
                 }
            }
        );
        return i;
    };

    setActiveIndex(item: any) {

        this.model.items.map(
            mapitem => { mapitem.active = (mapitem==item)?true:false }
        );

    }

    toggleActive(item: any) {

        var index=this.model.items.indexOf(item);

        if (index!=-1) {
            this.model.items[index].active=(!this.model.items[index].active);
        }

    }

    enable(key :string) {

        this.setValue(key, 'disabled', false);

    }

    disable(key :string) {

        this.setValue(key, 'disabled', true);

    }

    show(key :string) {

        this.setValue(key, 'visible', true);

    }

    hide(key: string) {

        this.setValue(key, 'visible', false);

    }

    toggle(key: string) {

        this.setValue(key, 'visible', 'toggle');

    }

    activate(key: string) {

        this.setValue(key, 'active', true);

    }

    deactivate(key :string) {

        this.setValue(key, 'active', false);

    }

    setValue(key: string, itemkey: string, value) {

        this.model.items.map(
            item => {
                if (item.action.toLowerCase()==key.toLowerCase()) {

                    if (itemkey=='active' && this.model.selection=='single') {
                        this.setActiveIndex(item);
                    }
                    else {
                        item[itemkey]=(value=='toggle')?(!item[itemkey]):value;
                    }
                    return false;
                }
            }
        );

    }
}
