import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { LoginService } from '../base-form/base-form-testing.barrel.spec';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  subscription: Subscription;

  redirectRoute: any;

  constructor(private loginService: LoginService) { 
    
  }

  ngOnInit() {
    this.subscription = this.loginService.loginStatusAnnounced$.subscribe(loggedIn => { this.loginStatusAnnounced(loggedIn); })
    this.loginStatusAnnounced(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loginStatusAnnounced(loggedIn: boolean) {
    if (loggedIn) {
      this.redirectRoute = '/login';
    } 
  }
}
