import { OnChanges } from '@angular/core/core';
import { AddressLookupResult } from '../../../core/services/address-lookup/address-lookup';
import { Component, OnInit, SimpleChanges, Input } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { BaseFormComponent } from '../base-form/base-form.component';

import { LoginService } from '../../../core/services/login/login.service';
import { UserService } from '../../../core/services/user/user.service';
import { GlobalModalService } from '../../../core/services/modal/global-modal.service';
import { Address, ResidentialAddress } from "../../../core/services/types";

import { defaultErrorMessages } from "../base-form/base-form.config";
import { ActionsService } from "../../../core/services/actions/actions.service";
import { PATTERNS } from "../../../core/services/validationservice/validation.config";


@Component({
  selector: 'details-address',
  templateUrl: 'address.component.html'
})
export class AddressComponent extends BaseFormComponent implements OnChanges {
  @Input()
  name: string;

  postcodeNotFound: boolean = false;

  constructor(public formBuilder: FormBuilder, public loginService: LoginService, public userService: UserService, public globalModalService: GlobalModalService, actionsService: ActionsService) {

    super(formBuilder, loginService, userService, globalModalService, actionsService);

    this.editing = false;

    this.modalSettings = {
      title: 'Discard Changes',
      content: 'Are you sure that you want to discard the changes?',
      yes: 'Yes',
      no: 'No',
      config: {
        ignoreBackdropClick: true,
        keyboard: false
      }
    };

    this.formDefinition = {
      controls: this.formBuilder.group({
        id: ['', []],
        buildingNumber: ['', [Validators.maxLength(50)]],
        buildingName: ['', [Validators.maxLength(50)]],
        address1: ['', [Validators.required, Validators.maxLength(100)]],
        address2: ['', [Validators.maxLength(100)]],
        town: ['', [Validators.required, Validators.maxLength(100)]],
        county: ['', [Validators.required, Validators.maxLength(100)]],
        postcode: ['', [Validators.required, Validators.pattern(PATTERNS.UK_POSTCODE)]],
        country: ['', [Validators.required, Validators.maxLength(100)]]
      }),
      defaultErrorMessages: defaultErrorMessages,
      metadata: {
        id: {
          label: 'Id'
        },
        buildingName: {
          label: 'House Name'
        },
        buildingNumber: {
          label: 'House Number'
        },
        address1: {
          label: 'Address Line 1'
        },
        address2: {
          label: 'Address Line 2'
        },
        town: {
          label: 'Town'
        },
        county: {
          label: 'County'
        },
        postcode: {
          label: 'Postcode'
        },
        country: {
          label: 'Country'
        }
      }
    };
  }

  ngOnInit() {
    if (this.model == null) {
      this.model = <Address>{
        id: "-1",
        buildingName: '',
        buildingNumber: '',
        address1: '',
        address2: '',
        country: 'UK',
        county: '',
        postcode: '',
        town: ''
      }
    }

    super.ngOnInit();
  }

  populateForm(address: any) {
    
    if (address != null) {
      this.postcodeNotFound=false;
      delete address.id;
      this.form.patchValue(address);
      this.form.markAsDirty();
    }
    else {
      this.postcodeNotFound=true;
    }

  }

}
