<div class="mobile-only">
  <div class="main-body-narrow-padding">
    <div class="medium-title"> Account settings</div>
    <div class="raised-card">
      <div class="account-card-title"> {{ this.application.mainApplicant.identity.forename | titlecase}} {{ this.application.mainApplicant.identity.surname | titlecase}}</div>
      <div class="account-info">Reference Number: {{ this.application.id }}</div>
      <div class="account-info">Personal Consultant: {{ this.application.salesPerson }}</div>

      <div class="horizontal-divider"></div>
      <div class="account-action-row">
        <div class="action-item" routerLink="/applications/{{application.id}}/settings/personal-details">Personal Details</div>
        <div class="action-chevron" routerLink="/applications/{{application.id}}/settings/personal-details">M</div>
      </div>


      <div class="horizontal-divider"></div>
      <div class="account-action-row">
        <div class="action-item" routerLink="/applications/{{application.id}}/settings/change-password">Change Password</div>
        <div class="action-chevron" routerLink="/applications/{{application.id}}/settings/change-password">M</div>
      </div>

      <!--<div class="horizontal-divider"></div>
      <div class="account-action-row">
        <div class="action-item" routerLink="/applications/{{application.id}}/edit-profile">Edit Profile</div>
        <div class="action-chevron" routerLink="/applications/{{applicat ion.id}}/edit-profile">M</div>
      </div>-->

      <div class="horizontal-divider"></div>
      <div class="account-action-row">
        <div class="action-item" routerLink="/applications/{{application.id}}/help">Help Centre</div>
        <div class="action-chevron" routerLink="/applications/{{application.id}}/help">M</div>
      </div>

      <div class="horizontal-divider"></div>
      <div class="account-action-row">
        <div class="action-item" routerLink="/applications/{{application.id}}/settings/marketing-preferences">Marketing Preferences</div>
        <div class="action-chevron" routerLink="/applications/{{application.id}}/settings/marketing-preferences">M</div>
      </div>

      <div class="horizontal-divider"></div>
      <div class="account-action-row">
        <div class="action-item" (click)="logout()">Logout</div>
        <div class="action-chevron" (click)="logout()">M</div>
      </div>
    </div>
  </div>
  <navbar [currentSection]="'account'"></navbar>
</div>

<!--<desktop-warning></desktop-warning>-->
