/*
  Bank account
*/
class BankAccount {
    public bankName?: string;
    public accountName?: string;
    public sortCode?: string;
    public accountNumber?: string;
    public dateOpened: string;
}

export {
    BankAccount
}