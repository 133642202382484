
import {catchError, map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse
  // ResponseContentType, Headers, RequestOptions
 } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Proof } from "../types";
import { LoginService } from '../login/login.service';
import { TimedActionService } from "../timed-action/timed-action.service";
import { TokenService } from '../token/token.service';

@Injectable()
export class ProofsService {

  deleteProofUrl = environment.base + "/docs/delete?applicationId=:applicationId";
  getAllFilesUrl = environment.base + "/docs/?applicationId=:applicationId";
  downloadProofUrl = environment.base + "/docs/download?fileName=:filename&proofType=:proofType&applicationId=:applicationId";

  constructor(private http: HttpClient, private loginService: LoginService, private TokenService: TokenService, private timedActionService: TimedActionService) { }

  downloadProof(proof: Proof, applicationId:string): Observable<any> {
    let url = this.downloadProofUrl.replace(":filename", proof.filename).replace(":proofType", proof.type).replace(":applicationId", applicationId);
    let options = this.loginService.getHeaderOptions(true);
    options.append('Response-Type', 'Blob');
    return this.http.get(url, { headers: options}).pipe(
      map(
      (response) => {
        this.timedActionService.resetTimer();
        return response;
      }
      ),
      catchError(
      (error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }
      ),);
  }

  deleteProof(proof: Proof, applicationId:string): Observable<any> {
    let url = this.deleteProofUrl.replace(":applicationId", applicationId);
    return this.http.post(url, proof, { headers: this.loginService.getHeaderOptions(true)}).pipe(
    map(
      (response: HttpResponse<any>) => {
        this.timedActionService.resetTimer();
        return response.status === 200 ? true : false;
      }
      ),
      catchError(
      (error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }
      ),);
  }

  getAllFiles(applicationId:string): Observable<any> {
    let url = this.getAllFilesUrl.replace(":applicationId", applicationId);
    var headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.TokenService.bearerToken);
    return this.http.get(url, { headers: headers }).pipe(
    map(
      (response) => {
          return(response)
      }
      ),
      catchError(
      (error) => {
        return "No files found"
      }
      ),);
  }
}
