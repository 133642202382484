import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from './../../../core/services/login/login.service'
import { PasswordConfiguration } from '../../../core/services/configuration/configuration.types';
import { GROUPS } from '../../../core/services/configuration/configuration-groups';
import { CustomersService } from '../../../core/services/customers/customers.service';

@Component({
  selector: 'forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})

export class ForgottenPasswordComponent {

  isMobile:boolean = false;
  isDesktop:boolean = false;

  accessDenied: boolean = false;
  showPassword:boolean = false;

  verifyingInProgress:boolean = true;
  verificationPassed:boolean = false;
  verifyKey:string;
  passwordError:boolean = false;

  model = {
    customerId:'',
    username: '',
    newpass: ''
  };

  errorMessage = '';

  consumerLogin: boolean = true;
  passwordPolicy: PasswordConfiguration;

  constructor(private loginService: LoginService, private parentRouter: Router, private customerService: CustomersService,
    private activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.queryParams.subscribe(
        params => {
          this.verifyKey = params['key'];
        }
      );
  }

  ngAfterViewInit(){
    this.verifyPasswordLink();    
  }

  async verifyPasswordLink(){
    
    this.customerService.verifyPasswordLink(this.verifyKey).subscribe(response => 
      {
        this.verificationReturned(response)
      },    
    error => {
      if (error.status==500){
        console.log("Error: Verification failed");
        this.verificationFailed();
      }
      else {
        console.log("Error: api unreachable");
        this.verificationFailed();
      }
    });
  }
  
  verificationReturned(result){
    this.verificationPassed = true;
    this.verifyingInProgress = false;
    this.model.customerId = result.split("££")[0];
    this.model.username = result.split("££")[1];
    this.model.newpass = "";
  }

  verificationFailed(){
    this.verificationPassed = false;
    this.verifyingInProgress = false;
  }
  
  login() {

    this.loginService.forgottenPassword(this.model)
      .subscribe(
      response => {

        if (typeof response != 'undefined') {

          this.accessDenied = false;

          this.loginService.setUserInformation(response);
          if (this.isMobile==true){
          this.parentRouter.navigateByUrl("/login");
          }
          else{
            this.parentRouter.navigateByUrl("/");
          }
        }
      },
      error => {
        if (error.status == 401) {
          this.accessDenied = true;
        } else {
          this.errorMessage = <any>error;
        }
      }
      );

  }

  showHidePassword(){
    if (this.showPassword==true){
      this.showPassword=false;
    }
    else{
      this.showPassword=true;
    }
  }

  checkPassword(){
    var password = this.model.newpass;
    if (/[a-z]/.test(password)==false||/[A-Z]/.test(password)==false||
         /\d/.test(password)==false||password.length < 8){
      this.passwordError = true;       
    }
    else{
      this.passwordError = false;
    }
    
  }

  mobileSubmit(){
    this.isMobile = true;
    this.isDesktop = false;
  }

  desktopSubmit(){
    this.isDesktop = true;
    this.isMobile = false;
  }

}
