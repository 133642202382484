
import {of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';

import { environment } from "../../../../environments/environment";

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse
  // Response, Headers, RequestOptions
 } from '@angular/common/http';



import { LoginService } from './../login/login.service';
import { NotificationMessage } from '../types';
import { ApplicantNotes } from '../types-applicantNotes';


const NOTIFICATIONS_URL = 'assets/notifications.json';

@Injectable()
export class NotificationsService {

  items: NotificationMessage[]=[];
  loaded:boolean=false;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getNotifications(): Observable<any> {

    // if (this.items === null) {
      if (!this.loaded) {
        
      return this.http.get<HttpResponse<any>>(NOTIFICATIONS_URL).pipe(
        map(
        (res) => {
          this.items = res.body;
          this.loaded=true;
          return this.items; 
        }
        ),
        catchError(
        (error: any) => observableThrowError(error.json().error || 'Server error')
        ),);
    }

    return observableOf(this.items);

  }

  filterNotifications(notifications: NotificationMessage[], type: string, status: string) {

    return notifications.filter(
      notification => {

        var now = new Date();
        var lowerCaseType=type.toLowerCase();

        return new Date(notification.startDate) < now && new Date(notification.endDate) > now 
              && notification.type.toLowerCase() == lowerCaseType
              && (lowerCaseType=='dashboard' || (lowerCaseType=='workflow' && notification.workflow.indexOf(status)!=-1));

      }
    );

  }

}
