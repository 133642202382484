import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActionsService } from '../../../core/services/actions/actions.service';

@Component({
  selector: 'button-with-invalid-sections-popup',
  templateUrl: './button-with-invalid-sections-popup.component.html',
  styleUrls: ['./button-with-invalid-sections-popup.component.scss']
})
export class ButtonWithInvalidSectionsPopupComponent implements OnInit {

  @Input()
  key: string;

  @Input()
  label: string;

  @Input()
  disabled: boolean;

  @Input()
  hidden: boolean;

  @Output()
  proceed: EventEmitter<any> = new EventEmitter<any>();
  
  invalidSubSet: Array<any> = [];

  validSectionsSubscription: Subscription;
  
  @ViewChild('popoverDirective') popoverDirective: PopoverDirective;

  @ViewChild(TemplateRef) popoverTemplate: TemplateRef<any>;
  
  constructor(private actionsService: ActionsService) { }

  ngOnInit() {

    this.validSectionsSubscription = this.actionsService.registerObserver(
      `componentSubsetValidItems.${this.key}`,
      action => {

        this.refreshPopover(action);

      },
      this
    );
  
  }

  ngOnDestroy() {

    this.actionsService.cancelSubscription(this.validSectionsSubscription);

  }

  refreshPopover(action) {

    this.invalidSubSet = action.value.invalidSubSet;
    this.popoverDirective.hide();

    if (!action.value.valid && !this.hidden) {
      this.popoverDirective.popover = this.popoverTemplate;
      this.popoverDirective.show();
    }

  }

  scrollToSection(key: string) {

    /*
      Broadcast a scroll to the popup section clicked
    */
    this.actionsService.broadcast(
      {
        action: 'section',
        behaviour: 'scroll',
        value: key
      }
    );

  }

}
