import { Sms, UserService } from './../../../core/services/user/user.service';
import { AuthenticationResult } from './../../../core/services/login/loginmodels';
import { LoginService } from './../../../core/services/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { PasswordConfiguration } from '../../../core/services/configuration/configuration.types';
import { ApplicationStatusService, ActionsService } from '../../../shared/components/base-form/base-form-testing.barrel.spec';
import { FavIconService } from '../../../core/services/fav-icon/fav-icon.service';
import { Title } from '../../../../../node_modules/@angular/platform-browser';
import { Applicant, Application, Employer, Income } from '../../../core/services/types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { isElementAccessExpression } from 'typescript';
import { ApplicantNotes } from '../../../core/services/types-applicantNotes';


const comparecarfinanceDomain = 'comparecarfinance';

@Component({
  selector: 'register-panel',
  templateUrl: './register-panel.component.html',
  styleUrls: ['./register-panel.component.scss']
})
export class RegisterPanelComponent {
  // start of account card
  initialTitle: string = "Choose from your personalised offer and search our cars";
  initialTitle2: string = "View your personalised details and search our cars";
  //initialTitle2: string = "To get personalised finance offers please complete your details";
  employmentAndPhoneTitle:string ="Get access to over 15 years car & finance experience";
  vehicleTitle:string="Choose a car from a dealer or get a quality Creditplus car delivered";
  registrationTitle: string =this.initialTitle;
  registrationTitle2: string = this.initialTitle2;
  imputValue: boolean = false;

  username: string;
  password: string;
  passwordError: boolean = true;
  display: boolean = true;

  contactId: string;

  currentDate: Date = new Date();

  // start of telephone card1
  telephone: string;
  telephonePattern = "^[^0][0-9]{9,10}$";

  // start of employment card
  jobTitle: string;
  jobTitleError: boolean = false;
  startDate: string;
  startDateError: boolean = false;
  startDateFutureError: boolean = false;
  startDateDuplicateError: boolean = false;
  dateHistoryError: boolean = false;

  historyRequiredYears: number;
  historyRequiredMonths: number;

  updatingEmployment:boolean = false;

  monthlyIncome: string;
  monthlyIncomeError: boolean = false;
  companyName: string;
  companyNameError: boolean = false;
  companyAddress: string;
  //loqate entry block inside employment card
  buildingNumber: string;
  buildingName: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  postCode: string;

  registrationError: string = null;

  vehicleAlreadyFound: boolean = null;

  firstEmploymentAddress: boolean = true;
  employmentStatus: string; //now swapped over to referring to employment type, eg, employed, self employed or retired
  employmentStatusError: boolean = false;
  employmentIndustry: string;
  employmentIndustryError: boolean = false;

  employmentAddressSearchResults: any;

  showPassword: boolean = false;
  showConfirmPassword: boolean = true;
  apiErrorMessages: any[] = [];
  registering: boolean = false;
  nextPageUrl: string;
  passwordPolicy: PasswordConfiguration;
  passwordPolicyKey: string;
  showRules: boolean = false;
  passwordErrors: boolean = true;
  permanentUser: boolean = false;
  source: string;
  passwordCheck: RegExp;
  sectionName: string = "createAccount";
  createAccountComplete: boolean = false;
  phoneComplete: boolean = false;
  employmentComplete: boolean = false;
  affordabilityComplete: boolean = false;
  vehicleNeedsComplete: boolean = false;
  registrationComplete: boolean = false;
  partnerJourney: boolean = true;
  application: Application = null;
  showEmployment: boolean = false; //need to change to false after testing
  showPhone: boolean = false;
  showVehicleNeeds:boolean = false;
  partnerImgSrc: string = "/assets/partnerLogos/gocologo_green.png";
  mobileNumberDisabled: boolean = false;
  @ViewChild('registerPanel') registerPanel: ElementRef;
  @ViewChild('entirePage') entirePage: ElementRef;
  @ViewChild('titleBlock') titleBlock: ElementRef;
  @ViewChild('accountPanel') accountPanel: ElementRef;
  @ViewChild('phonePanel') phonePanel: ElementRef;
  @ViewChild('employmentPanel') employmentPanel: ElementRef;
  @ViewChild('affordabilityPanel') affordabilityPanel: ElementRef;
  @ViewChild('vehicleNeedsPanel') vehicleNeedsPanel: ElementRef;
  constructor(private activatedRoute: ActivatedRoute,
    private actionsService: ActionsService,
    private loginService: LoginService,
    private router: Router,
    private applicationStatusService: ApplicationStatusService,
    private titleService: Title, private favIconService: FavIconService,
    private userService: UserService, private http: HttpClient) {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.username = params['username'];
        this.contactId = params['customerId'];
        this.permanentUser = params['perm'] ? true : false;
        this.source = params['source'];
      }
    );
    this.loginService.logout();
  }

  changeTitle(_titleNum){
    switch(_titleNum){
      case 1:
        this.registrationTitle=this.initialTitle;
        break;
        case 2:
          this.registrationTitle=this.employmentAndPhoneTitle;
          break;
          case 3:
            this.registrationTitle=this.vehicleTitle;
            break;
            default:
              this.registrationTitle=this.initialTitle;
              break;
    }
  }

  ngOnInit() {
    this.passwordCheck = new RegExp('(?=.{8,})(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*');
    if (this.source == null) {
      if (window.location.href.indexOf("comparecarfinance") != -1) {
        this.source = 'ccf';
      }
      else if (window.location.href.indexOf("dealerplus") != -1) {
        this.source = 'DP';
      }
      else {
        this.source = 'customer';
      }
    }
    var key = this.loginService.getPasswordPolicykey();
    this.setPasswordPolicy(key);
    this.actionsService.broadcast(
      {
        action: 'header',
        behaviour: 'getId',
        value: this.contactId
      }
    );
    this.nextPageUrl = 'applications/' + this.contactId + '/vehicle';
    this.titleService.setTitle("Register");
  }

  ngAfterViewInit() {
    this.employmentStatus = "";
    this.employmentIndustry = "";
  }

  getEmploymentAddressAuto() {
    this.http.get(environment.base + "/customer/application/get-employment-address?companyName=" + this.companyName)
      .subscribe((result) => { this.employmentAddressSearchResults = result; this.handleEmploymentAddressResults() });
  }

  handleEmploymentAddressResults() {
    //return the first active employer that meets company name.
    //this may be incorrect company but was asked to import first company for now and let this be handled by
    //sales admin team later
    var firstActiveEmployer;
    for (var i = 0; i < this.employmentAddressSearchResults.items.length; i++) {
      if (this.employmentAddressSearchResults.items[i].company_status == "active") {
        firstActiveEmployer = this.employmentAddressSearchResults.items[i];
        break;
      }
    }

    //clear unused fields
    this.addressLine2 = null;
    this.buildingName = null;
    this.buildingNumber = null;
    //populate minimum required fields for auto prop with details found from api
    if (firstActiveEmployer!=null){
    this.addressLine1 = firstActiveEmployer.address.premises + " " + firstActiveEmployer.address.address_line_1;    
    this.town = firstActiveEmployer.address.locality;
    this.postCode = firstActiveEmployer.address.postal_code;
    }
    else{
      this.addressLine1 = "Not Found";
      this.town = "Not Found";
      this.postCode = "Not Found";
    }

    //add created address to applicant details
    this.addAddress();

  }

  pickSection(nameIn) {
    this.sectionName = nameIn;
    if (nameIn == 'createAccount') {
      this.goAccount();
    }
    if (nameIn == 'phone') {
      this.goPhone();
    }
    if (nameIn == 'employment') {
      this.goEmployment();
    }
    if (nameIn == 'affordability') {
      this.goAffordability();
    }
    if (nameIn == 'vehicle-needs'){
      this.goVehicleNeeds();
    }
  }

  showHidePassword() {
    if (this.showPassword == true) {
      this.showPassword = false;
    }
    else {
      this.showPassword = true;
    }
  }

  showHideConfirmPassword() {
    if (this.showConfirmPassword == true) {
      this.showConfirmPassword = false;
    }
    else {
      this.showConfirmPassword = true;
    }
  }

  checkPassword() {
    var password = this.password;
    if (/[a-z]/.test(password) == false || /[A-Z]/.test(password) == false ||
      /\d/.test(password) == false || password.length < 8) {
      this.passwordError = true;
    }
    else {
      this.passwordError = false;
    }

  }

  checkPhoneInput() {
    var tempNumber = this.telephone;
    for (var i = 0; i < tempNumber.length; i++) {
      if (!(tempNumber.charAt(i) >= '0' && tempNumber.charAt(i) <= '9') || (tempNumber.charAt(i) == '0' && i == 0)) {
        //console.log("invalid character detected at character " + i);
        if (i == 0) {
          //console.log("telephone string length = " +tempNumber.length)
          if (tempNumber.length == 1) {
            tempNumber = ""
          }
          else {
            tempNumber = tempNumber.slice(1);
          }
        }
        else if (i == tempNumber.length) {
          tempNumber = tempNumber.slice(0, tempNumber.length);
        }
        else {
          //console.log("slice(0, i-1) = " + tempNumber.slice(0, i-1));
          tempNumber = tempNumber.slice(0, i) + tempNumber.slice(i + 1);
        }
        i--;
      }
    }
    //console.log(tempNumber);
    var tempMobileNumberDisabled  = false;
    if (tempNumber.length >= 10) {
      tempMobileNumberDisabled = true;
    }
    setTimeout(() => { this.telephone = tempNumber, this.mobileNumberDisabled = tempMobileNumberDisabled  }, 10);
    this.application.mainApplicant.contact.telephone = this.telephone;
    this.application.mainApplicant.contact.mobile = this.telephone;
  }

  checkIncomeInput() {

    var tempNumber = this.enforceNumbersOnly(this.monthlyIncome, true);
    setTimeout(() => {
      this.monthlyIncome = tempNumber;
      if (this.application.mainApplicant.income == null) {
        this.application.mainApplicant.income = new Income();
        this.application.mainApplicant.income.monthlyIncome = 0;
      }
      this.application.mainApplicant.income.monthlyIncome = parseFloat(this.monthlyIncome);
      //console.log(this.application.mainApplicant);
    }, 10);
  }

  enforceNumbersOnly(tempNumber: string, isDecimal: boolean) {
    const checkDecimal: boolean = isDecimal;
    for (var i = 0; i < tempNumber.length; i++) {
      if (!(tempNumber.charAt(i) >= '0' && tempNumber.charAt(i) <= '9')) {
        if (i == 0) {
          if (tempNumber.length == 1) {
            tempNumber = ""
          }
          else {
            tempNumber = tempNumber.slice(1);
          }
        }
        else if (isDecimal == true && (tempNumber.charAt(i) == '.' || tempNumber.charAt(i) == ',')) {
          if (tempNumber.charAt(i) == ',') {
            tempNumber = tempNumber.replace(",", ".");
          }
          isDecimal = false;
          i++;
        }
        else if (i == tempNumber.length) {
          tempNumber = tempNumber.slice(0, tempNumber.length);
        }
        else {
          tempNumber = tempNumber.slice(0, i) + tempNumber.slice(i + 1);
        }
        i--;
      }
    }
    if (checkDecimal == true) {
      tempNumber = this.checkDecimal(tempNumber);
    }
    return tempNumber;
  }

  checkDecimal(tempNumber: string) {
    for (var i = 0; i < tempNumber.length; i++) {
      if (tempNumber.charAt(i) == '.') {
        if (i == tempNumber.length) {
          tempNumber = tempNumber.slice(0, tempNumber.length);
        }
        else if (i < tempNumber.length - 3) {
          tempNumber = tempNumber.slice(0, i + 3);
        }
      }
    }
    return tempNumber;
  }

  checkEmploymentType() {
    if (this.application.mainApplicant.income == null) {
      this.application.mainApplicant.income = new Income();
      this.application.mainApplicant.income.monthlyIncome = 0;
    }
    if (this.employmentStatus == "SEMP") {
      var addressToCopy = this.application.mainApplicant.addresses[0]
      this.addressLine1 = "";
      if (addressToCopy.buildingName != null && addressToCopy.buildingName.replace(/\s+/g, '') != "") {
        this.addressLine1 = addressToCopy.buildingName + ", ";
      }
      if (addressToCopy.buildingNumber != null && addressToCopy.buildingNumber.replace(/\s+/g, '') != "") {
        this.addressLine1 = this.addressLine1 + addressToCopy.buildingNumber + " ";
      }
      this.addressLine1 = this.addressLine1 + addressToCopy.address1;
      this.addressLine2 = addressToCopy.address2;
      this.town = addressToCopy.town;
      this.postCode = addressToCopy.postcode;
      //console.log("self employed address prefilled")
    }
  }

  async addAddress() {
    //create new employment object
    var employmentToAdd = new Employer();

    // only need address info and job industry for employed or self employed periods
    if (this.employmentStatus == "EMP" || this.employmentStatus == "SEMP") {

      employmentToAdd.jobIndustry = this.employmentIndustry;
      employmentToAdd.jobTitle = this.jobTitle;

      //instantiate new address type (not a class so can't use new operator)
      employmentToAdd.address =
      {
        id: null,
        buildingNumber: null,
        buildingName: null,
        address2: null,
        county: null,
        company: null,
        department: null,
        address1: this.addressLine1,
        town: this.town,
        postcode: this.postCode
      }
      //address line 2 only populated for self employed
      if (this.employmentStatus == "SEMP") {
        employmentToAdd.address.address2 = this.addressLine2;
      }
    }

    employmentToAdd.startDate = new Date(this.startDate);
    employmentToAdd.startDate.setMonth(employmentToAdd.startDate.getMonth());
    employmentToAdd.name = this.companyName;
    employmentToAdd.status= this.employmentStatus;

    if (this.firstEmploymentAddress == true) {
      this.firstEmploymentAddress = false;

      if (this.application.mainApplicant.employment == null) {
        this.application.mainApplicant.employment = [];
        this.application.mainApplicant.employment[0] = employmentToAdd;
      }
      else {
        this.application.mainApplicant.employment[0].address = employmentToAdd.address;
        this.application.mainApplicant.employment[0].jobIndustry = employmentToAdd.jobIndustry;
        this.application.mainApplicant.employment[0].status = employmentToAdd.status;
        this.application.mainApplicant.employment[0].jobTitle = employmentToAdd.jobTitle;
        this.application.mainApplicant.employment[0].startDate = employmentToAdd.startDate;
        this.application.mainApplicant.employment[0].name = employmentToAdd.name;
        this.application.mainApplicant.employment[0].employmentStatus = null;
      }
    }

    else {
      this.application.mainApplicant.employment.push(employmentToAdd);
    }

    //console.log(this.application.mainApplicant.employment);

    var dateCheck = new Date(this.startDate);
    var dateThreeYearsAgo = new Date();
    dateThreeYearsAgo.setFullYear(dateThreeYearsAgo.getFullYear() - 3);

    // make sure we have 3 years worth of history
    if (dateCheck < dateThreeYearsAgo) {
      //date is older than 3 years ago so can submit form
      this.employmentComplete = true;
      this.application = await this.userService.updateApplication(this.application, 5).toPromise();

      // if all stages complete
      this.sectionName = 'vehicle-needs';
    }
    else {
      //date is newer than 3 years ago so more history required
      this.addressLine1 = null;
      this.addressLine2 = null;
      this.town = null;
      this.postCode = null;
      this.employmentIndustry = null;
      this.employmentStatus = "";
      this.jobTitle = null;
      this.startDate = null;
      this.companyName = null;
      this.monthlyIncome = null;
      this.application.mainApplicant.employment.sort((a, b) => (a.startDate < b.startDate) ? 1 : -1);
      this.application.mainApplicant.employment[0].endDate = null;
      for (var i = 1; i < this.application.mainApplicant.employment.length; i++) {
        this.application.mainApplicant.employment[i].endDate = this.application.mainApplicant.employment[i - 1].startDate;
      }
      var earliestStartDate = this.application.mainApplicant.employment[this.application.mainApplicant.employment.length-1].startDate;
      this.historyRequiredYears = earliestStartDate.getFullYear() - dateThreeYearsAgo.getFullYear();
      if (earliestStartDate.getMonth()-dateThreeYearsAgo.getMonth() < 0){
        this.historyRequiredYears = this.historyRequiredYears - 1;
        this.historyRequiredMonths = 12 + (earliestStartDate.getMonth()-dateThreeYearsAgo.getMonth())
      }
      else{
        this.historyRequiredMonths = earliestStartDate.getMonth()-dateThreeYearsAgo.getMonth();
      }
    }
    //console.log(this.application.mainApplicant.employment);
  }

  async submit() {
    this.display = false;
    this.registrationError = null;
    try {
      this.registering = true;
      this.apiErrorMessages = [];
      if (this.source == null) {
        if (window.location.href.toLowerCase().indexOf(comparecarfinanceDomain) != -1) {
          this.source = 'ccf customer';
        }
        else if (window.location.href.indexOf("dealerplus") != -1) {
          this.source = 'DP';
        }
        else {
          this.source = 'customer';
        }

      }
      let registrationResult: AuthenticationResult = await this.loginService.register(this.username, this.contactId, this.password, this.source).toPromise();
      if (this.isValidAnswer(registrationResult)) {
        this.loginService.setUserInformation(registrationResult);

        const app = await this.userService.getItem(this.contactId.toString()).toPromise();
        this.application = app.applications[0];

        //if (this.application.mainApplicant.employment[0].address != null) {
        //  this.showEmployment = this.application.mainApplicant.employment[0].address.postcode ? false : true;
        //}
        //else{
        //  this.showEmployment = true;
        //}

        this.showVehicleNeeds = true;

        this.showPhone = this.application.mainApplicant.contact.mobile == null || this.application.mainApplicant.contact.mobile.length < 5 ? true : false;

        //I'm not sure if the employment details are always going to be null, but they bug out the code normally so they're in this try block to stop them interfering 
        //with the rest of the registering process
        try {
          this.jobTitle = this.application.mainApplicant.employment[0].jobTitle;
          this.monthlyIncome = this.application.mainApplicant.income.monthlyIncome.toString();
          this.buildingName = this.application.mainApplicant.employment[0].address.buildingName;
          this.buildingNumber = this.application.mainApplicant.employment[0].address.buildingNumber;

          this.companyName = this.application.mainApplicant.employment[0].name;
        }
        catch (error) {
          //no employment details provided
        }

        this.telephone = this.application.mainApplicant.contact.mobile;
        this.createAccountComplete = true;

        if ((this.showEmployment == false && this.showPhone == false)) {
          this.sectionName = "vehicle-needs"
          this.pickSection("vehicle-needs");
        }
        else if (this.showPhone == false && this.showEmployment == true) {
          this.sectionName = "employment";
          this.pickSection("employment");
          
        }
        else if (this.showPhone == true) {
          this.sectionName = "phone";
          this.pickSection("phone");
        }
      }
    }
    catch (error) {
      if(error==null){
        return;
      }
      this.registering = false;
      if (error.status == 422) {

        for (var i = 0; error.error.length > i; i++){
          if (error.error[i].code == "010105"){           
            this.registrationError = "A portal account has already been linked to this email address";
          }
          else if (error.error[i].code == "010106"){            
            //this.registrationError = "A portal account already exists for this reference number";
            //break;
          }
        }
        this.apiErrorMessages = error
      }
      else {
        if (error != null) {
          throw {
            customError: {
              errorConfiguration: this.applicationStatusService.configuration.errors.generic,
              error: error
            }
          }
        }
      }
    }
  }

  private editEmployment(index: number) {
    this.updatingEmployment = true;
    this.employmentStatus = this.application.mainApplicant.employment[index].status;
    this.startDate = this.application.mainApplicant.employment[index].startDate.getFullYear() +
      "-" + (this.application.mainApplicant.employment[index].startDate.getMonth() + 1);
    if (this.employmentStatus == 'SEMP') {
      this.addressLine1 = this.application.mainApplicant.employment[index].address.address1;
      this.addressLine2 = this.application.mainApplicant.employment[index].address.address2;
      this.postCode = this.application.mainApplicant.employment[index].address.postcode;
      this.town = this.application.mainApplicant.employment[index].address.town;
      this.jobTitle = this.application.mainApplicant.employment[index].jobTitle;
    }
    else if (this.employmentStatus == 'EMP') {
      this.jobTitle = this.application.mainApplicant.employment[index].jobTitle;
      this.employmentIndustry = this.application.mainApplicant.employment[index].jobIndustry;
      this.companyName = this.application.mainApplicant.employment[index].name;
    }
    if (this.application.mainApplicant.employment.length > 1) {
      this.application.mainApplicant.employment.splice(index, 1);
    }
    else {
      if (this.application.mainApplicant.income!=null && this.application.mainApplicant.income.monthlyIncome!=null){
      this.monthlyIncome = this.application.mainApplicant.income.monthlyIncome + "";
      }
      this.application.mainApplicant.employment=null;
      this.firstEmploymentAddress = true;
    }
  }

  private async sendSms() {

    let sms: Sms = {
      PhoneNumber: "000",
      SenderName: "Creditplus",
      MessageBody: "GOTO PORTAL https://portal.creditplus.co.uk/login"
    }

    await this.userService.sendSms(sms).toPromise();
  }

  private isValidAnswer(result: AuthenticationResult) {
    return result
      && result.username
      && result.bearerToken
      && result.id
  }

  setPasswordPolicy(passwordPolicyKey: string) {
    this.passwordPolicyKey = passwordPolicyKey;
    this.passwordPolicy = this.loginService.getPasswordPolicy(passwordPolicyKey);
  }

  toggleSecurityRequirement() {
    if (this.showRules == true) {
      this.showRules = false;
    }
    else {
      this.showRules = true;
    }
  }
  displayErrors() {
    this.passwordErrors = true;
  }

  getCurrentStep(registrationResult): string {
    var result = 'applications/' + this.contactId + '/vehicle';
    return result;
  }

  complete() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      //this.router.navigateByUrl('/docs');
    } else {
      // false for not mobile device
      this.sendSms();
      this.registrationComplete = true;

    }
  }

  broadcastDocumentChangeCSSClass(value: string) {
    this.actionsService.broadcast(
      {
        action: 'document',
        behaviour: 'changeCSSClass',
        value: value
      }
    );
    this.actionsService.broadcast(
      {
        action: 'header',
        behaviour: 'changeSource',
        value: value
      }
    );
  }

  async goAccount() {
    setTimeout(() => {
      var scrollTopTarget = this.accountPanel.nativeElement.offsetTop - (this.titleBlock.nativeElement.offsetTop + this.titleBlock.nativeElement.offsetHeight) - 5;
      this.scrollElement(scrollTopTarget, 0);
    }, 10);
  }

  async goPhone() {
    setTimeout(() => {
      var scrollTopTarget = this.phonePanel.nativeElement.offsetTop - (this.titleBlock.nativeElement.offsetTop + this.titleBlock.nativeElement.offsetHeight) - 5;
      this.scrollElement(scrollTopTarget, 0);
    }, 10);
  }

  async goEmployment() {
    setTimeout(() => {
      var scrollTopTarget = this.employmentPanel.nativeElement.offsetTop - (this.titleBlock.nativeElement.offsetTop + this.titleBlock.nativeElement.offsetHeight) - 5;
      this.scrollElement(scrollTopTarget, 0);
    }, 10);
  }

  async goAffordability() {
    setTimeout(() => {
      var scrollTopTarget = this.affordabilityPanel.nativeElement.offsetTop - (this.titleBlock.nativeElement.offsetTop + this.titleBlock.nativeElement.offsetHeight) - 5;
      this.scrollElement(scrollTopTarget, 0);
    }, 10);
  }

  async goVehicleNeeds() {
    setTimeout(() => {
      var scrollTopTarget = this.vehicleNeedsPanel.nativeElement.offsetTop - (this.titleBlock.nativeElement.offsetTop + this.titleBlock.nativeElement.offsetHeight) - 5;
      this.scrollElement(scrollTopTarget, 0);
    }, 10);
  }

  async scrollElement(scrollTopTarget, i) {

    // 30 iterations
    if (scrollTopTarget < 0) {
      scrollTopTarget = 0;
    }
    if (i < 30) {
      if (i == 29) {
        this.registerPanel.nativeElement.scrollTop = scrollTopTarget;
      }
      else {
        var scrollTop = (scrollTopTarget - this.registerPanel.nativeElement.scrollTop) / (30 - i);
        this.scrollElementSmoother(i, scrollTop, scrollTopTarget);
      }
    }
  }

  async scrollElementSmoother(i, scrollTop, scrollTopTarget) {
    await setTimeout(() => {
      this.registerPanel.nativeElement.scrollTop = this.registerPanel.nativeElement.scrollTop + scrollTop;

      i++;
      this.scrollElement(scrollTopTarget, i);
    }, 5);
  }

  redirectToLogin() {
    this.router.navigateByUrl('/login');
  }

  validatePhoneNumber() {
    this.phoneComplete;
        this.telephone = "0" + this.telephone.toString()
        this.submitPhoneNumber();
  }

  toggleSecurityRequirementPhoneNumber() {
    // logic goes here
  }

  async submitPhoneNumber() {
    this.application.mainApplicant.contact.telephone = this.telephone;
    this.application.mainApplicant.contact.mobile = this.telephone;
    this.application = await this.userService.updateApplication(this.application, 5).toPromise();
    this.phoneComplete = true;
    if (this.showEmployment == true){
      this.sectionName = 'employment';
    }
    else{
      this.sectionName = 'vehicle-needs';
    }
  }

  async validateEmployment() {
    if (this.employmentStatus == "") {
      this.employmentStatusError = true;
    }
    else {
      this.employmentStatusError = false;
    }
    // if not an employment period, don't collect employment details
    if (this.employmentStatus == 'STD' || this.employmentStatus == 'RT' || this.employmentStatus == 'UNEMP' || this.employmentStatus == 'OTHER') {
      this.employmentIndustry = "";
      this.employmentIndustryError = false;
      this.jobTitle = "";
      this.jobTitleError = false;
      this.monthlyIncome = "";
      this.monthlyIncomeError = false;
      this.companyName = "";
      this.companyNameError = false;
      this.addressLine1 = null;
      this.addressLine2 = null;
      this.town = null;
      this.postCode = null;
      this.buildingName = null;
      this.buildingNumber = null;
    }
    else {
      if (this.employmentIndustry == "") {
        if (this.employmentStatus == "EMP") {
          this.employmentIndustryError = true;
        }
        else {
          this.employmentIndustryError = false;
        }
      }
      else {
        this.employmentIndustryError = false;
      }
      if (this.jobTitle == null || this.jobTitle == "") {
        this.jobTitleError = true;
      }
      else {
        this.jobTitleError = false;
      }
      if (this.monthlyIncome == null || this.monthlyIncome == "") {
        if (this.firstEmploymentAddress == true) {
          this.monthlyIncomeError = true;
        }
        else {
          this.monthlyIncomeError = false;
        }
      }
      else {
        this.monthlyIncomeError = false;
      }
      if (this.companyName == null || this.companyName == "") {
        if (this.employmentStatus == "EMP") {
          this.companyNameError = true;
        }
        else {
          this.companyNameError = false;
        }
      }
      else {
        this.companyNameError = false;
      }
    }
    var startDateCheck = new Date(parseInt(this.startDate.split("-")[0]), parseInt(this.startDate.split("-")[1]) - 1);
    if (this.startDate == null) {
      this.startDateError = true;
    }
    else if (startDateCheck > new Date()) {
      this.startDateFutureError = true;
    }
    else {
      this.startDateError = false;
      this.startDateFutureError = false;
    }
    this.startDateDuplicateError = false;
    if (this.firstEmploymentAddress==false && this.application.mainApplicant.employment != null) {
      for (var i = 0; i < this.application.mainApplicant.employment.length; i++) {
        //console.log("start date check = " + startDateCheck);
        //console.log("array date = " + this.application.mainApplicant.employment[i].startDate);
        if (this.application.mainApplicant.employment[i].startDate.toString() == startDateCheck.toString()) {
          this.startDateDuplicateError = true;
        }
      }
      if (this.startDateDuplicateError == true) {
        this.startDateError = true;
      }
    }
    if (this.employmentIndustryError == false && this.employmentStatusError == false && this.jobTitleError == false
      && this.companyNameError == false && this.startDateError == false && this.monthlyIncomeError == false) {
        this.updatingEmployment = false;
      if (this.employmentStatus == 'EMP') {
        //console.log("going to auto add employed address")
        this.getEmploymentAddressAuto();
      }
      else {
        //console.log("going to add other employment address");
        this.addAddress();
      }
    }
  }

  checkInput() {

    if (this.companyName == null || this.companyName == '') {
      this.imputValue = false;
    }
    else {
      this.validateCompleteButton();
    }

  }

  ValidateDateHistory() {
    this.dateHistoryError = true;
    var dateCheck = new Date(this.startDate);
    var dateThreeYearsAgo = new Date();
    dateThreeYearsAgo.setFullYear(dateThreeYearsAgo.getFullYear() - 3);

    // make sure we have 3 years worth of history
    if (dateCheck < dateThreeYearsAgo) {
      //date is older than 3 years ago so can submit form
      this.dateHistoryError = false;

    }
    else {
      //date is newer than 3 years ago so more history required
      this.dateHistoryError = true;
      this.imputValue = false;
    }
  }

  validateCompleteButton() {

    this.ValidateDateHistory();

    if (this.jobTitle == null || this.jobTitle == "") {
      this.jobTitleError = true;
    }
    else {
      this.jobTitleError = false;
      this.imputValue = false;
    }
    if (this.employmentStatusError == false && this.employmentIndustryError == false && this.jobTitleError == false && this.monthlyIncomeError == false && this.companyNameError == false && this.dateHistoryError == false ) {
      this.imputValue = true;
    }
  }

  submitRegistration() {
    //registration saving code here
    // decide which page to route to after setting up register
    if (this.entirePage.nativeElement.clientWidth >= 700) {
      //path to desktop registration page
      this.userService.updateApplication(this.application, 5)
      this.router.navigate(['/registered']);

    }
    else {
      //path to login (only available on mobile devices)
      this.userService.updateApplication(this.application, 5)
      this.router.navigate(['/login']);
    }
  }

  vehicleFound(found: boolean){
    if (found == true){
    this.vehicleAlreadyFound = true;
    }
    else{
      this.vehicleAlreadyFound = false;
    }
  }

  submitVehicleNeeds(){
    this.userService.updateVehicleNeeds(this.application.id, this.vehicleAlreadyFound).subscribe(
      (result)=>{
        if (result=="ok"){
          this.vehicleNeedsComplete = true;
          var customerNote = new ApplicantNotes();
          customerNote.contactid = this.application.id;
          customerNote.addedBy = 1;
          customerNote.addedByUsername = "portal";
          customerNote.DateAdded = null;
          if (this.vehicleAlreadyFound == true){
          customerNote.noteSubject = "Customer has found a car";
          customerNote.note = "Customer has already found a car they would like to finance.";
          }
          else {
            customerNote.noteSubject = "Customer has not found a car";
            customerNote.note = "Customer may need help finding a new car. Please qualify this lead."
          }         
          this.redirectToLogin();
          this.userService.createNewNote(customerNote).toPromise();
        }
        else{
          //console.log(result);
        }
      }
    )
  }
}
