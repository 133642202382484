<div class="mobile-only">
  <div class="fixed-header">
    <div class="fixed-page-title-vehicle-serarch fixed-page-title-vehicle-serarch-backbtn">
      <a routerLink="..">
        <div class="raised-back-button">
          <div class="raised-back-button-icon">L</div>
        </div>
      </a>
    </div>
  </div>
  <div class="main-body-narrow-padding">
    <div></div>
    <p class="medium-title">Marketing preferences</p>
    <div class="raised-card">
      <p>
        Please choose which method of contact you would like to recieve.
      </p>
      <div class="contact-row">Email:</div>
      <div style="position: absolute; right:40px; margin-top:-25px;">
        <label class="switch" [ngStyle]="visibleProperty==true ? {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
          <!--<input checked type="checkbox" #emailCheckbox>-->
          <input id="emailCheck" [(ngModel)]="emailCheck" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="contact-row" style="margin-top:10px;">Phone:</div>
      <div style="position: absolute; right: 40px; margin-top: -25px;">
        <label class="switch" [ngStyle]="visibleProperty==true ? {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
          <input id="phoneCheck" [(ngModel)]="phoneCheck" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="contact-row" style="margin-top:10px;">SMS:</div>
      <div style="position: absolute; right: 40px; margin-top: -25px;">
        <label class="switch" [ngStyle]="visibleProperty==true ? {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
          <input id="smsCheck" [(ngModel)]="smsCheck" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="contact-row" style="margin-top:10px;">POST:</div>
      <div style="position: absolute; right:40px; margin-top:-25px;">
        <label class="switch" [ngStyle]="visibleProperty==true ? {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
          <input id="postCheck" [(ngModel)]="postCheck" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="raised-card">
      <textarea id="detailsRequest" [(ngModel)]="detailsRequest" style="width:100%; height:100px; margin-right:-50px; max-width:98.6%;" placeholder="If you would like to change your details, please specify here" (input)="checkInput()" [ngStyle]="imputValue2==false ?
                 {} : {'opacity' : '0.5', 'pointer-events' : 'none'}"></textarea>
      <div class="access-denied" *ngIf="notFound">
        Your request is being reviewed.
      </div>
      <button *ngIf="display" class="standard-button login-button" style="margin-top:10px; width:100%;" (click)="updatePreferences()" [ngStyle]="imputValue==false ?
                 {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
        Save preferences
      </button>

        <!--<textarea style="width:100%; height:100px; margin-right:-50px; max-width:98.6%;" placeholder="If you would like to change other details, please specify here"></textarea>
      <button class="standard-button login-button" style="margin-top:10px; width:100%;" (click)="updatePreferences()" routerLink="../settings">Save preferences</button>-->
    </div>

  </div>
  <navbar [currentSection]="'account'"></navbar>
</div>
