import { DomSanitizer, Title } from '@angular/platform-browser';
import { TokenService } from './../../../core/services/token/token.service';
import { environment } from './../../../../environments/environment';
import { Component, Input } from '@angular/core';
import { ActionsService } from "../../../core/services/actions/actions.service";
import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { Application, Proof, RequiredProof, Document } from "../../../core/services/types";
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import * as moment from 'moment/moment'
import { Router } from '@angular/router';
import { Subscription } from '../../../../../node_modules/rxjs';
import { UserService } from '../../../core/services/user/user.service';
import { LoadingModalService } from '../../../core/services/modal/loading-modal.service';

class DropZone {
  uploader: FileUploader;
  label: string;
  requiredProof: RequiredProof;
  id: string;
  addingProof: boolean;
}

@Component({
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {

  @Input()
  application: Application;

  public proofId: string;
  customerPack: Document;
  customerPackDocsUrl: string;;
  customerPackEsignUrl: string;
  lenderPack: Document;
  lenderPackDocsUrl: string;
  lenderPackEsignUrl: string;
  uploadProofUrl: string
  emptyDropzone: DropZone = new DropZone();
  dropZone: DropZone;
  totalRequired: number = 0;
  availableProofs: Proof[];
  requiredProofs: RequiredProof[];
  documents: Array<Document>
  documentsObservers: Subscription;
  downloadableFiles: number = 0;
  source: string;
  verificationModel: boolean = false;
  showExamples: boolean = false;
  customerPackEsign: boolean;
  lenderPackEsign: boolean = false;
  nextPageUrl: string;
  results: any[];
  CreditRating: string;
  isStaging: boolean = false;

  constructor(public actionsService: ActionsService,
    public applicationStatusService: ApplicationStatusService,
    private tokenService: TokenService,
    private domSanitizer: DomSanitizer,
    private parentRouter: Router,
    private userService: UserService,
    private loadingModalService: LoadingModalService,
    protected titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Sign Agreements");
    if (environment.name != "PROD") {
      this.isStaging = true;
      this.lenderPackEsign = true;
    }
    this.source = window.location.href.indexOf("dealerplus") != -1 ? 'dealer' : '';
    this.CreditRating = this.application.creditRating.category;
    this.setUpDocuments();
    this.documentsObservers = this.actionsService.registerObserver(
      'close.toggle',
      action => { this.showExamples = false; },
      this
    );
    this.nextPageUrl = 'applications/' + this.application.id + '/final-page';
    this.uploadProofUrl = environment.base + `/docs/?applicationId=${this.application.id}`;
    this.tryPrepareFileBlobForNonLink();
    this.requiredProofs = this.application.loanProposalResponses.filter(loan => loan.isSelected)[0].loanOffer.requiredProofs;
    var requiredProof  = {
      label: "Driving licence Front",
      id: "drivingLicenseFront",
      description: "Driving Licence (front)",
      docsRequired: 1
    };
    this.dropZone = {
      uploader: new FileUploader({ removeAfterUpload: true }),
      label: "Driving licence Front",
      requiredProof: requiredProof,
      id: "drivingLicenseFront",
      addingProof: true
    };
    this.availableProofs = [];
    this.computeMissingDocuments(this.dropZone);
    this.bindEvents(this.dropZone);
  }

  setUpDocuments() {
    if (this.application.documents && Object.keys(this.application.documents).length > 0) {
      this.customerPack = this.application.documents.filter(doc => doc.id == "CUSTOMER_PACK")[0];
      if (this.customerPack) {
        for (var i = 0; i < this.customerPack.links.length; i++) {
          if (this.customerPack.links[i].isEsignLink) {
            this.customerPackEsign = true;
            this.customerPackEsignUrl = this.customerPack.links[i].url;
          }
        }
        if (!this.customerPackEsign) {
          this.downloadableFiles++;
        }
      }
      this.lenderPack = this.application.documents.filter(doc => doc.id == "LENDER_PACK")[0];
      if (this.lenderPack  != undefined || this.lenderPack != null ) {
        for (var i = 0; i < this.lenderPack.links.length; i++) {
          if (this.lenderPack.links[i].isEsignLink) {
            this.lenderPackEsign = true;
            this.lenderPackEsignUrl = this.lenderPack.links[i].url;
          }
        }
        if (!this.lenderPackEsign) {
          this.downloadableFiles++;
        }
      }
    }
  }

  tryPrepareFileBlobForNonLink() {
    if (this.customerPack) {
      for (var i = 0; i < this.application.documents[0].links.length; i++) {
        if (!this.application.documents[0].links[i].isEsignLink) {
          this.application.documents[0].links[i].url = this.createPdfBlob(this.application.documents[0].links[i].url);
          this.customerPackDocsUrl = this.application.documents[0].links[i].url;
        }
      }
    }
    if (this.lenderPack) {
      for (var i = 0; i < this.application.documents[1].links.length; i++) {
        if (!this.application.documents[1].links[i].isEsignLink) {
          this.application.documents[1].links[i].url = this.createPdfBlob(this.application.documents[1].links[i].url);
          this.lenderPackDocsUrl = this.application.documents[1].links[i].url
        }
      }
    }
    this.customerPack = this.application.documents.filter(doc => doc.id == "CUSTOMER_PACK")[0];
    this.lenderPack = this.application.documents.filter(doc => doc.id == "LENDER_PACK")[0];
  }

  createPdfBlob(base64String: string): string {
    var byteCharacters = atob(base64String);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: 'application/pdf' })
    return URL.createObjectURL(blob);
  }

  removeAnyDuplicateAvailableProof(item: FileItem) {
    var foundProof = this.availableProofs.find(availableProof => availableProof.filename == item.file.name);
    if (foundProof != null) {
      this.removeProofFromAvailableProofs(foundProof);
    }
  }

  bindEvents(dropZone: DropZone) {
    dropZone.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.removeAnyDuplicateAvailableProof(item);
      if (dropZone.addingProof) {
        this.availableProofs.push(this.getProof(item, dropZone.requiredProof.id));
      } else {
        this.dropZone.addingProof = true;
      }
    };
    dropZone.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (environment.name === "MOCK") {
        dropZone.uploader.onSuccessItem(item, response, 200, headers);
        this.mockItemUpload(dropZone, item);
      }
      else {
        throw {
          customError: {
            errorConfiguration: this.applicationStatusService.configuration.errors.proofs_upload,
            error: { status: status }
          }
        };
      }
    }
    dropZone.uploader.onCompleteAll = () => {
      this.computeMissingDocuments(this.dropZone);
    };
  }

  mockItemUpload(dropZone: any, item: FileItem) {
    var index = dropZone.uploader.queue.indexOf(item, 0);
    if (index > -1) {
      dropZone.uploader.queue.splice(index, 1);
    }
  }

  computeMissingDocuments(dropZone: DropZone) {
    dropZone.uploader.setOptions({
      url: this.uploadProofUrl,
      queueLimit: dropZone.requiredProof.docsRequired,
      authToken: 'Bearer ' + this.tokenService.bearerToken,
      additionalParameter: {
        label: dropZone.id
      }
    });
  }

  importFile(event, addingProofs: boolean) {
    this.loadingModalService.show();
    this.dropZone.addingProof = addingProofs;
    this.dropZone.uploader.addToQueue(event.target.files);
    this.loadingModalService.hide();
  }

  getProof(item: FileItem, id: string) {
    return <Proof>{
      dateUploaded: moment(new Date()).format("DD-MM-YYYY"),
      filename: item._file.name,
      id: 0,
      title: item._file.name,
      type: id
    };
  }

  removeProofFromAvailableProofs(proof: Proof) {
    let existingProof = this.availableProofs.find(p => p.filename === proof.filename);
    if (existingProof == null) {
      throw new Error("The deleted proof does not exist");
    }
    let proofIndex = this.availableProofs.indexOf(existingProof);
    if (proofIndex === -1) {
      throw new Error("No index could be found for the existing index");
    }
    this.availableProofs.splice(proofIndex, 1);
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  goForward() {
    this.application.proofs = this.availableProofs;
    this.saveQuotes();
  }

  toogleEsign(isCustomerPack: boolean, turnEsignOn: boolean) {
    if (isCustomerPack) {
      this.customerPackEsign = turnEsignOn;
    }
    else {
      this.lenderPackEsign = turnEsignOn;
    }
    if (turnEsignOn) {
      this.downloadableFiles--;
    } else {
      this.downloadableFiles++;
    }
  }

  async saveQuotes() {
    if (this.availableProofs.length > this.downloadableFiles) {
      this.applicationStatusService.setState(this.application, 'PROOFS_RECEIVED')
    } else {
      this.applicationStatusService.setState(this.application, 'AWAITING_PROOFS');
    }
    this.application = await this.userService.updateApplication(this.application, 4).toPromise();
    this.parentRouter.navigateByUrl(this.nextPageUrl);
  }

  displayVerificationModel(bool) {
    this.verificationModel = bool;
    window.scroll(0, 0);
  }

  displayHelpBox(string) {
    if (string == "employment") {
      if (this.application.mainApplicant.employment[0].status == "SEMP") {
        string = "Self-employed";
      }
    }
    this.proofId = string;
    this.showExamples = true;
  }
}
