import { Directive, ElementRef } from '@angular/core';
import { IsMobileService } from "./is-mobile.service";

@Directive({
  selector: '[isMobile], isMobile'
})
export class IsMobileDirective {
  constructor(private el: ElementRef, private isMobileService:IsMobileService){}

  ngOnInit(){
    this.el.nativeElement.dispatchEvent(this.checkIfOnMobileAndReturnCustomEvent());
  }

  private checkIfOnMobileAndReturnCustomEvent(){
    return new CustomEvent("isMobile", { detail: this.isMobileService.isMobile(), bubbles: false});
  }
}
