
import {throwError as observableThrowError,  Subscription ,  Observable ,  of } from 'rxjs';

import {map, catchError, finalize, share, mergeMap} from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse
  // Response,
 } from '@angular/common/http';
import { User, Application, IndustryTypes, Preference, employedStatus } from './../types';
import { LoginService } from './../login/login.service';
import { TimedActionService } from '../timed-action/timed-action.service';
import { TokenService } from '../token/token.service';
import { LoadingModalService } from '../modal/loading-modal.service';


import { ApplicantNotes } from '../types-applicantNotes';

@Injectable()
export class UserService {

  firstTime = true;
  user: User;
  industry: number;
  currentApplicationId: number;
  category: string;
  urls: any = {
    user: environment.base + '/customer/application?id=:id',
    save: environment.base + '/customer/application/save',
    vehicle: environment.base + '/customer/application/vehicle?id=:id',
    empindustry: environment.base + '/lookUp/jobindustry',
    empStatus: environment.base + '/lookUp/employedStatus',
    LenderTarget: environment.base + '/lookUp/LenderTarget',
    ContactNotes: environment.base + '/lookUp/customerNotes?contactid=:id',
    apply: environment.base + '/customer/application/apply',
    notes: environment.base + '/customer/application/create-note',
    sms: environment.base  + '/customer/application/SendSms'
  };
  loginStatusSubscription: Subscription;
  getUserSubscription: Subscription;
  getUserObservable: Observable<User>;
  getVehicleObservable: Observable<any>

  constructor(
    private loginService: LoginService,
    private tokenService: TokenService,
    private http: HttpClient,
    private timedActionService: TimedActionService,
    private loadingModalService: LoadingModalService) {
    this.loginStatusSubscription = loginService.loginStatusAnnounced$.subscribe(
      loggedIn => {
        if (!loggedIn) {
          this.clearUserCache();
        }
      }
    );
  }

  getItem(userId?: string): Observable<User> {
    if (this.user != null) {
      return of(this.user);
    }
    if (typeof userId === 'undefined') {
      userId = this.tokenService.currentUser;
    }
    ////console.log("I'm in get user ID");
    const url = this.urls.user.replace(':id', userId);
    this.loadingModalService.show();
    this.getUserObservable = this.http.get<User>(url, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((res) => {
      // //console.log("I made it to the map process");
      ////console.log(res);
        this.timedActionService.resetTimer();
        this.user = res;
        return res;
      }),
      catchError((error: any) => {
        this.timedActionService.checkResetTimerOnError(error);
        return observableThrowError('An error occurred when retrieving the user ' + JSON.stringify(error));
      }),
      finalize(() => this.loadingModalService.hide()),
      share()
    );
    return this.getUserObservable;
  }

  getVehicle(userId: number): Observable<any> {
    const url = this.urls.vehicle.replace(':id', userId);
    this.loadingModalService.show();
    return this.http.get<HttpResponse<any>>(url, {headers: this.loginService.getHeaderOptions(false)}).pipe(
      map((response) => {
        return response.body;
      }),
      catchError((error: any) => {
        this.timedActionService.checkResetTimerOnError(error);
        return observableThrowError('An error occurred when retrieving the user vehicle ' + error);
      }),
      finalize(() => this.loadingModalService.hide())
    );
  }

  getCurrentApplication(): Observable<Application> {
    return this.getItem().pipe(map(
      (user: User) => {
        return user.applications.find(
          application =>
            application.id === this.currentApplicationId, this
        );
      }
    ));
  }

  applyFormNewCustomer(application: Application) {
    var body: any = { Application: application };
    const url = this.urls.apply;
    this.loadingModalService.show();
    this.loadingModalService.displayMessage(3);

    return this.http.post<HttpResponse<any>>(url, body, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        // this.logQuoteLookup(quote, true);
        return response.body;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        // this.logQuoteLookup(quote, false);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide())
    );
  }

  createNewNote(customerNote: ApplicantNotes) {
    var body = customerNote;
    const url = this.urls.notes;
   //console.log(url);
    return this.http.post<HttpResponse<any>>(url, body, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        // this.logQuoteLookup(quote, false);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide())
    );
  }

  sendSms(sms: Sms) {
    var body = sms;
    const url = this.urls.sms
    //this.loadingModalService.show();
    return this.http.post<HttpResponse<any>>(url, body, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        // this.logQuoteLookup(quote, false);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide())
    );
  }

  getApplicationById(applicationId: number): Observable<Application> {
    return this.getItem(applicationId.toString()).pipe(map(
      (user: User) => {
        return user.applications.find(
          application =>
            application.id === applicationId, this
        );
      }
    ));
  }

  updateIndustry(): Observable<IndustryTypes> {
    return this.http.get<HttpResponse<any>>(this.urls.empindustry, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response.body;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      })
    );
  }

  updateEmploymentStatus(): Observable<employedStatus> {
    return this.http.get<HttpResponse<any>>(this.urls.empStatus, { headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response.body;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      })
    );
  }

  getCustomerNotes(contactid: number): Observable<ApplicantNotes[]> {
    this.loadingModalService.show();
    const url = this.urls.ContactNotes.replace(':id', contactid);
    return this.http.get<HttpResponse<any>>(url, { headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response.body;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide())
    );
  }

  getLenderTargetMarket(Category: string): Observable<any> {
    this.category = Category;
    return this.http.post<HttpResponse<any>>(this.urls.LenderTarget, JSON.stringify(this.category), { headers: this.loginService.getHeaderOptions(true)}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response.body;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      })
    );
  }


  updateApplication(application: Application, step: number): Observable<any> {
    const existingApp = this.user.applications.find(a => a.id === application.id);
   //console.log(existingApp)
    const index: number = this.user.applications.indexOf(existingApp);
    if (existingApp) {
      const updatingApp = JSON.parse(JSON.stringify(existingApp));
      for (const key in application) {
        if (key === 'mainApplicant') {
          for (const mainApplicantKey of Object.keys(application['mainApplicant'])) {
            updatingApp['mainApplicant'][mainApplicantKey] = application['mainApplicant'][mainApplicantKey];
          }
        } else {
          updatingApp[key] = application[key];
        }
      }
      const url = this.urls.save;
      application.feedCategories = [];
      application.bestSellers = [];
      if (application['loanProposalResponses'] == null
        && updatingApp.loanProposalResponses != null
        && updatingApp.loanProposalResponses.some(r => r.isSelected)) {
        application['loanProposalResponses'] = updatingApp.loanProposalResponses;
      }
      var body: any = { Application: application, Step: step };
      if (window.location.href.indexOf("dealerplus") != -1) {
        this.loadingModalService.show();
        if (step == 3) {  
          this.loadingModalService.displayMessage(2);
        }
      }
      else {
        if (step == 3) {

          this.loadingModalService.displayMessage(2);
        }
      }
     //console.log(this.urls.save)

      return this.http.post<HttpResponse<Application>>(this.urls.save, body, { headers: this.loginService.getHeaderOptions(true)}).pipe(
        mergeMap(saveApplication => {
         var app:Application = JSON.parse(JSON.stringify(saveApplication));
          this.user.applications[index] = app;
          // Reset the token expiration timer
          this.timedActionService.resetTimer();
          return of(saveApplication);
        }),
        catchError(error => {
         console.log(error)
          this.timedActionService.checkResetTimerOnError(error);
          return observableThrowError('An error occurred when saving the application: ' + error);
        }),
        finalize(() => 
        this.loadingModalService.hide())
      );
    }
    
       
  }

  getOnboardingQuote(contactId, username){
    var url = environment.base + '/customer/application/onboarding-details?userId=' + contactId + "&userEmail=" + username;
    return this.http.get(url);
  }

  updatePreference(preference: Preference) {
    this.user.preference.marketing = preference.marketing;
    localStorage.setItem('user', JSON.stringify(this.user));
    return of({ status: 'OK' });
  }

  updateUserCache(application: Application) {
    const foundApplication = this.user.applications
      .find(findApplication => findApplication.id === application.id);
    if (foundApplication != null) {
      const index = this.user.applications.indexOf(foundApplication);
      if (index !== -1) {
        this.user.applications[index] = application;
      }
    }
  }

  clearUserCache() {
    this.user = null;
  }

  clearUserCacheIfUserIsDifferent(id: number) {
    if (this.user && this.user.id !== id) {
      this.clearUserCache();
    }
  }

  updateVehicleNeeds(applicationId: any, vehicleAlreadyFound: boolean){
    var vehicleNeeded = vehicleAlreadyFound ? false : true;
    return this.http.post(environment.base + "/customer/application/vehicle-needs?id=" + applicationId + "&vehicleNeeded=" + vehicleNeeded, null);
   }
}

export class Sms {
  public PhoneNumber: string;
  public SenderName: string;
  public MessageBody: string;
}
