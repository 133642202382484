import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
    selector: 'icon-component',
    templateUrl: 'icon-component.html'
})
export class IconComponent {

    @Input()
    icons: Array<Icon> = []

    @Input()
    creationTimeInSeconds: number;
    
    ngOnChanges(changes:SimpleChanges){
        if(changes["icons"]){
            this.icons = changes["icons"].currentValue.filter(i => i.value);
            this.LogIfDiffrentStatusIcon(this.icons[0]);
        }
    }

    private LogIfDiffrentStatusIcon(icon: Icon) {
        var time = new Date();
        if(time.getSeconds() != this.creationTimeInSeconds){
            if (icon.classes.includes("icon-invalid")){
                window['dataLayer'].push({event: 'validation-status', label: icon.title, status: 'invalid-section', timestamp: time.toISOString()})
            } 
            else if (icon.classes.includes("icon-tick")){
                window['dataLayer'].push({event: 'validation-status', label: icon.title, status: 'valid-section', timestamp: time.toISOString()})
            }
        }
    }

}

export class Icon {
    classes:string;
    value:boolean;
    title:string;
}