
import {catchError, map} from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Application } from '../../../core/services/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../../../core/services/token/token.service';
import { LoadingModalService } from '../../../core/services/modal/loading-modal.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { Title } from '@angular/platform-browser';
import { ApplicantNotes } from '../../../core/services/types-applicantNotes';
import { UserService } from '../../../core/services/user/user.service';
import * as pako from 'pako';


@Component({
  selector: 'marketing-preferences',
  templateUrl: './marketing-preferences.component.html',
  styleUrls: ['./marketing-preferences.component.scss']
})
export class MarketingPreferencesComponent implements OnInit {
  @Input()
  application: Application;
  detailsRequest: String;
  imputValue: boolean = true;
  imputValue2: boolean = false;
  notFound: boolean = false;
  display: boolean = true;
  visibleProperty: boolean = false;

  emailCheck: boolean;
  phoneCheck: boolean;
  smsCheck: boolean;
  postCheck: boolean;
  constructor(private http: HttpClient,
              private tokenService: TokenService,
    private loadingModalService: LoadingModalService,
    protected activatedRoute: ActivatedRoute, private userService: UserService, protected actionsService: ActionsService, private titleService: Title ) { }

  ngOnInit() {
    this.titleService.setTitle("Marketing Preferences");
//    this.emailCheck = this.application.
    this.phoneCheck = true;
    this.smsCheck = true;
    this.postCheck = true;
    //this.showLoading()
    //this.getPreferences();
    this.loadApplication();
  }

  loadApplication() {
    var retrievedArr = JSON.parse(localStorage.getItem('_sdma'));
    if (retrievedArr != null) {
      const restored = JSON.parse(pako.inflate(retrievedArr, { to: 'string' }));
      this.application = restored;
    }

    this.phoneCheck = this.application.dataProtection.phone;
    this.smsCheck = this.application.dataProtection.sms;
    this.postCheck = this.application.dataProtection.post;
    this.emailCheck = this.application.dataProtection.email;
  }

  sendNote() {
    var customerNote = new ApplicantNotes();
    customerNote.contactid = this.application.id;
    customerNote.addedBy = 1;
    customerNote.addedByUsername = "portal";
    customerNote.DateAdded = null;
    customerNote.noteSubject = "Customer request - personal details";
    customerNote.note = "Customer message: " + this.detailsRequest;
    this.userService.createNewNote(customerNote).toPromise();
  }

  updatePreferences() {
    this.notFound = true;
    this.imputValue2 = true;
    this.display = false;
    //    this.sendNote();
    //    console.log(this.detailsRequest);
  }

  getPreferences() {
    this.getPreferences2().subscribe(response => {
      var contactMethod;
      var contactAllowed;
      var formatBody = response._body
      formatBody = formatBody.replace("{", "");
      formatBody = formatBody.replace("}", "");
      var body = formatBody.split(",");
      for (var i = 0; i < body.length; i++) {
        body[i] = body[i].replace('"', "");
        body[i] = body[i].replace('"', "");
        body[i] = body[i].replace(',', "");
        body[i] = body[i].replace(/\s/g, '');
        contactMethod = body[i].split(":")[0];
        contactAllowed = body[i].split(":")[1];

        switch (contactMethod) {
          case "email":
            if (contactAllowed == "true") {
              this.emailCheck = true;
            } else {
              this.emailCheck = false;
            }
            break;

          case "post":
            if (contactAllowed == "true") {
              this.postCheck = true;
            } else {
              this.postCheck = false;
            }
            break;

          case "phone":
            if (contactAllowed == "true") {
              this.phoneCheck = true;
            } else {
              this.phoneCheck = false;
            }
            break;

          case "sms":
            if (contactAllowed == "true") {
              this.smsCheck = true;
            } else {
              this.smsCheck = false;
            }
            break;

          default:
            break;
        }
      }
      this.hideLoadingModal();
    })
  }

  //checkPreferences(){
  //  this.smsCheck = this.smsCheckbox.nativeElement.checked;
  //  this.postCheck = this.postCheckbox.nativeElement.checked;
  //  this.phoneCheck = this.phoneCheckbox.nativeElement.checked;
  //  this.emailCheck = this.emailCheckbox.nativeElement.checked;
  //}

  getPreferences2(): Observable<any>{
    let url = environment.base + "/customer/application/gdpr?id=" + this.application.id;
    var headers = new HttpHeaders( );
    headers = headers.append('Authorization', 'Bearer ' + this.tokenService.bearerToken);
    return this.http.get(url, ({ headers: headers }) ).pipe(
    map((response) => {
      return response
    }),catchError((error) => {
      throw error;
    }),)
  }

  updatePreferencesCall(){
    let url = environment.base + "/customer/application/gdpr/update?id=" + this.application.id + 
      "&email=" + this.emailCheck +
      "&phone=" + this.phoneCheck +
      "&sms=" + this.smsCheck +
      "&post=" + this.postCheck;
    var headers = new HttpHeaders( );
    headers = headers.append('Authorization', 'Bearer ' + this.tokenService.bearerToken);
    return this.http.get(url, ({ headers: headers })).pipe(
    map((response) => {
      return response
    }),catchError((error) => {
      throw error;
    }),)    
  }

  showLoading(){
    this.loadingModalService.show();
  } 

  hideLoadingModal(){
    this.loadingModalService.hide();
  }

  checkInput() {

    var ngModel = this.detailsRequest;

    if (ngModel == null || ngModel == '') {
      this.imputValue = true;
    }
    else {
      this.imputValue = false;
    }

  }


}
