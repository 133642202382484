import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Directive, forwardRef, SimpleChanges, Input } from '@angular/core';
import { PasswordConfiguration } from '../../../../core/services/configuration/configuration.types';


export function passwordPolicy(config: PasswordConfiguration): ValidatorFn {

  return (passwordControl: AbstractControl): ValidationErrors => {

    let password: string = passwordControl.value;
    let errors: any = {};

    if (password == null) {
      // https://stackoverflow.com/questions/7844359/password-regex-with-min-6-chars-at-least-one-letter-and-one-number-and-may-cont
      errors.passwordRequired = true;
      password = "";
    }

    if (password.length < config.minimumLength) {
      errors.passwordTooShort = true;
    }
    if (config.numbers && password.search(/\d/) == -1) {
      errors.passwordNoNumbers = true;
    }
    if (config.letters && password.search(/[a-z]/) == -1) {
      errors.passwordNoLetters = true;
    }
    if (config.letters && password.search(/[A-Z]/) == -1) {
      errors.passwordNoUpperLetters = true;
    }
    // if (config.specialCharacters != '' && config.specialCharacters != null) {
    //   let specialspecialCharactersRegex = new RegExp('[' + config.specialCharacters.split('').join('\\') + ']');
    //   if (password.search(specialspecialCharactersRegex) == -1) {
    //     errors.passwordSpecialCharacters = true;
    //   }
    // }

    return errors;

  };
}

@Directive({
  selector: '[passwordPolicyValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordPolicyValidatorDirective), multi: true }
  ]
})

export class PasswordPolicyValidatorDirective implements Validator {

  @Input('passwordPolicyValidator')
  policy: PasswordConfiguration;

  validator: ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {

    const change = changes['policy'];

    if (change) {
      this.validator = passwordPolicy(change.currentValue);
    }
    else {
      this.validator = null;
    }

  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.validator) {
      return this.validator(control)
    }
  }

}
