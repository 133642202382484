import { Component, OnInit, Input } from '@angular/core';
import { DisplayVehicle } from '../../../core/services/types-quote';
import { Subscription } from '../../../../../node_modules/rxjs';
import { min } from '../../directives/validation/min/min.directive';
import { ActionsService } from '../../../core/services/actions/actions.service';

@Component({
  selector: 'suggest-finance',
  templateUrl: './suggest-finance.component.html',
  styleUrls: ['./suggest-finance.component.scss']
})
export class SuggestFinanceComponent implements OnInit {
  
  @Input() displayVehicle: DisplayVehicle[];
  vehicle: any;
  
  constructor(private actionsService: ActionsService) { }
  
  ngOnInit() {
    this.vehicle = this.displayVehicle[0];
    for (var dv of this.displayVehicle){
      if (dv.monthlyPayments < this.vehicle.monthlyPayments ) {
        this.vehicle = dv;
      }
    }
  }
}

