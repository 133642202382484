import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { init } from 'onfido-sdk-ui';


@Component({
  selector: 'onfido-sdk',
  templateUrl: './onfido-sdk.component.html',
  styleUrls: ['./onfido-sdk.component.scss']
})
export class OnfidoSdkComponent implements OnInit {

  @Input() sdkToken: string;
  @Output() onfidoFinished = new EventEmitter();

  
  dataHolder: any;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    var Onfido = require('onfido-sdk-ui')


    Onfido.init({
      customUI: {"colorBorderSurfaceModal" : "#fbfbfb", "colorBackgroundButtonPrimary": "#c50084",
       "colorContentButtonPrimaryText": "white", "colorBackgroundButtonPrimaryHover": "#c50084",
      "colorBorderButtonPrimary" : "#c50084", "colorBackgroundLinkHover" : "white",
       "colorContentLinkTextHover" : "black", "colorContentBody" : "#203757", "colorContentTitle": "#203757",
       "colorContentSubtitle": "#203757"},
      token: this.sdkToken,
      containerId: 'onfido-mount',
      onComplete: (data)=> {
        this.afterOnfido(data);        
      },
      steps: [
        {"type" : "welcome",
        "options": {
          "title" : "Protect your identity",
          "descriptions" : ["Complete our quick ID check to get your finance accepted and protect you from fraud.",
           "It's as simple as taking a photo of the front and rear of your driving licence and taking a selfie to verify it's you."],
          "nextButton" : "Proceed"
        }
      },
        {
          "type": "document",
          "options": {
            "documentTypes": {
              "driving_licence": {
                "country": "GBR"
              }
            }
          }
        },
        "face"
      ]

  })
}

onfidoEnd(value: string){
  //console.log("I'm inside the parent element with + " + value)
}

afterOnfido(data: any){
  //console.log(data);
  //console.log("This data was in parent thingy");
  this.onfidoFinished.emit(data);

}


}
