import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Application } from '../../../core/services/types';
import { LoginService } from './../../../core/services/login/login.service';
import { TokenService } from './../../../core/services/token/token.service';

import { Subscription } from 'rxjs';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { ApplicationStatusService } from '../base-form/base-form-testing.barrel.spec';
import { CONFIGURATION } from '../../../core/services/configuration/configuration';
import { CONFIGURATION_CCF } from '../../../core/services/configuration/configuration-groups-ccf';
import { CONFIGURATION_DEALER } from '../../../core/services/configuration/configuration-groups-dealer';
import { Router, NavigationEnd } from '../../../../../node_modules/@angular/router';

const comparecarfinanceDomain = 'comparecarfinance';
const dealerplusDomain = 'dealerplus';

@Component({
  moduleId: module.id,
  selector: 'application-header',
  templateUrl: 'application-header.component.html',
  styleUrls: ['application-header.component.scss']
})
export class ApplicationHeaderComponent implements OnInit {
  @Input()
  application: Application;

  loggedIn: boolean;
  homeRoute: string;
  logo: string;
  phoneIcon: string;
  contact: string;
  loginSubscription: Subscription;
  routerOutletSubscription: Subscription;
  changeLogoSubscription: Subscription;
  headerObservers: Subscription[];
  cssClass: string;
  contactId: number;
  cp: boolean = false;
  dp: boolean = false;
  ccf: boolean = false;
  dealerPortal: boolean = false;
  @ViewChild('headerbar') headerbar: ElementRef;
  @ViewChild('mobilelogo') mobileLogo: ElementRef;

  constructor(private loginService: LoginService, private actionsService: ActionsService, private parentRouter: Router,
    private tokenService: TokenService, private applicationStatusService: ApplicationStatusService, private elementRef: ElementRef) {
    this.loginSubscription = loginService.loginStatusAnnounced$.subscribe(loggedIn => { this.loginStatusAnnounced(loggedIn); })
    this.loginStatusAnnounced(this.tokenService.isAuthenticated());

    // parentRouter.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd) {
    //     if (parentRouter.url.indexOf("dashboard") != -1) {
    //       this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#203757';
    //       this.headerbar.nativeElement.classList.add("dashTestClass");
    //       this.headerbar.nativeElement.classList.remove("noDashTestClass");
    //       this.mobileLogo.nativeElement.style.color = "#fff";
    //     } else {
    //       this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fbfbfb';
    //       this.headerbar.nativeElement.classList.add("noDashTestClass");
    //       this.headerbar.nativeElement.classList.remove("DashTestClass");
    //       this.mobileLogo.nativeElement.style.color = "#c50084";
    //     }
    //   }
    // });
  }


  ngOnInit() {
    if (window.location.href.indexOf("dealerplus") != -1) {
      this.dp = true;
      var group = this.applicationStatusService.getGroup("dealerplus dealer");
    }
    else if (window.location.href.indexOf("comparecarfinance") != -1) {
      this.ccf = true;
      var group = this.applicationStatusService.getGroup("ccf customer");
    }
    else {
      this.cp = true;
      var group = this.applicationStatusService.getGroup("customer");
    }
    if (group) {
      this.logo = group.configuration.appearance.logo;
      this.contact = group.configuration.contact.telephone;
    }
    //this.cssClass = "navbar-header";
    this.headerObservers = this.actionsService.registerObservers(
      ['header.changeCSSClass', 'header.getId', 'navigation.login', 'display.dealer_menu'],
      action => {
        if (action.action == 'header' && action.behaviour == 'getId') {
          this.contactId = action.value;
        }
        if (action.action == 'navigation' && action.behaviour == 'login') {
          this.landingOnLoginPage();
        }
        if (action.action == 'display') {
          this.dealerPortal = action.value
        }
      },
      this
    );

  }

  ngOnDestroy() {

    //this.loginSubscription.unsubscribe();
    this.actionsService.cancelSubscriptions([this.routerOutletSubscription, this.changeLogoSubscription]);
  }

  landingOnLoginPage() {
    this.contactId = null;
  }


  loginStatusAnnounced(loggedIn: boolean) {
    this.loggedIn = loggedIn;
    this.homeRoute = 'login';
  }

  redirectToPortal() {
    this.actionsService.broadcast({
      action: 'change',
      behaviour: 'view',
      value: 4
    })
    if (window.location.href.includes("dealer-portal")) {
      this.parentRouter.navigate(['dealer-portal']);
    }
    else {
      this.parentRouter.navigate(['dealer-portal'])
        .then(() => {
          window.location.reload();
        });
    }

  }
}
