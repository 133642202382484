import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';


@Injectable()
export class GlobalModalService {

  constructor () {}

  // Show modal subscription
  private showSource = new Subject<any>();
  showAnnounced$ = this.showSource.asObservable();

  // modal response subscription
  private responseSource = new Subject<any>();
  responseAnnounced$ = this.responseSource.asObservable();

  // Broadcast show modal
  show(model: any): Observable<any> {
    this.showSource.next(model);
    return this.responseAnnounced$;
  }

  // Broadcast modal response
  response(value: boolean) {
    this.responseSource.next(value);
  }

}
