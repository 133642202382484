import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { LoginService } from './../../../core/services/login/login.service'
import { TokenService } from "../../../core/services/token/token.service";
import { URLS } from '../../../core/services/configuration/configuration.urls';


@Component({
  selector: 'old-login-status',
  templateUrl: 'login-status.component.html'
})

export class OldLoginStatusComponent {

  loggedIn: boolean;
  username: string;

  subscription: Subscription;

  constructor(private loginService: LoginService, private parentRouter: Router, private tokenService:TokenService) {

    this.subscription = this.loginService.loginStatusAnnounced$.subscribe(loggedIn => { this.loginStatusAnnounced(loggedIn); })
    this.loggedIn = this.tokenService.isAuthenticated();
    this.username = this.tokenService.userName;

  }

  ngOnChanges() {

    this.username = this.tokenService.userName;

  }

  logout() {

    this.loginService.logout();
    this.parentRouter.navigateByUrl(URLS.Login.route);

  }

  login() {

    this.parentRouter.navigateByUrl(URLS.Login.route);

  }

  loginStatusAnnounced(loggedIn: boolean) {

    this.loggedIn = loggedIn;
    this.username = this.tokenService.userName;

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
