
import {filter} from 'rxjs/operators';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, ResolveEnd, ActivatedRoute } from '@angular/router';
import { Component, ChangeDetectorRef } from '@angular/core';
import { ActionsService } from './core/services/actions/actions.service';
import { Subscription } from 'rxjs';
import { LoginService } from './core/services/login/login.service';
import { TokenService } from './core/services/token/token.service';
import { ApplicationStatusService } from './core/services/application-status/application-status.service';
import { Title } from '@angular/platform-browser';
import { FavIconService } from './core/services/fav-icon/fav-icon.service';
import { GROUPS } from './core/services/configuration/configuration-groups';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  isLoading: boolean = false;
  routerOutletSubscription: Subscription;
  cssClass: string = "sub-body";
  cssSource: string = 'customer';
  logoSource: string = "customer";
  posStyle: string = "initial";

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private loginService: LoginService,
    private actionsService: ActionsService, private tokenService: TokenService, private applicationStatusService: ApplicationStatusService,
    private titleService: Title, private favIconService: FavIconService) {
    this.routerOutletSubscription = loginService.loginStatusAnnounced$.subscribe(loggedIn => { this.loginStatusAnnounced(loggedIn); });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (router.url.indexOf("dashboard") != -1) {
          this.posStyle = 'fixed';
        } else {
          this.posStyle = 'initial';
        }
      }
    });

  }

  ngOnInit() {
    this.loginStatusAnnounced(this.tokenService.isAuthenticated());

    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart && event.url.indexOf("applications") !== -1))
      .subscribe((event) => this.isLoading = true);

    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart && !event.url.includes("current-step")))
      .subscribe((event) => window['dataLayer'].push({ event: 'navigation', timestamp: new Date().toISOString() }));

    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart && event.url.includes("login")))
      .subscribe((event) => { this.actionsService.broadcast({ action: 'navigation', behaviour: 'login', value: true }); })

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe((event) => this.isLoading = false);

    if (window.location.href.indexOf("dealerplus") != -1) {
      this.cssSource = 'dealer';
    } else if (window.location.href.indexOf("comparecarfinance") != -1) {
      this.cssSource = 'ccf';
    }

    this.cssClass = 'sub-body ' + this.cssSource;

    this.registerChangeCSSClassObserver();
  }

  ngAfterViewChecked() {

    this.changeDetectorRef.detectChanges();

  }

  ngOnDestroy() {

    this.actionsService.cancelSubscription(this.routerOutletSubscription);

  }

  registerChangeCSSClassObserver() {
    if (window.location.href.toLowerCase().indexOf("comparecarfinance") != -1) {
      this.logoSource = 'ccf customer';
    } if (window.location.href.indexOf("dealerplus") != -1) {
      this.logoSource = 'dealerplus dealer';
    }
    this.actionsService.broadcast(
      {
        action: 'header',
        behaviour: 'changeSource',
        value: this.logoSource
      }
    );

    this.routerOutletSubscription = this.actionsService.registerObserver(
      'document.changeCSSClass',
      action => {
        if (action.value == 'ccf customer') {
          this.cssClass = "sub-body ccf";
        } else if (window.location.href.indexOf("dealerplus") != -1) {
          this.cssSource = 'dealer';
        } else {
          this.cssClass = "sub-body " + action.value;
        }
      },
      this
    );
  }

  loginStatusAnnounced(loggedIn: boolean) {
    if (window.location.href.toLowerCase().indexOf("dealerplus") != -1) {
      this.setTitleAndFavIcon("dealerplus dealer");
      this.broadcastDocumentChangeCSSClass("dealerplus dealer");
    }
    else if (window.location.href.toLowerCase().indexOf("comparecarfinance") != -1) {
      this.broadcastDocumentChangeCSSClass("ccf customer");
      this.setTitleAndFavIcon("ccf customer");
    } else {
      this.broadcastDocumentChangeCSSClass("customer");
      this.setTitleAndFavIcon("customer");
    }
  }

  broadcastDocumentChangeCSSClass(value: string) {
    this.actionsService.broadcast(
      {
        action: 'document',
        behaviour: 'changeCSSClass',
        value: value
      }
    );
    this.actionsService.broadcast(
      {
        action: 'header',
        behaviour: 'changeSource',
        value: value
      }
    );
  }
  setTitleAndFavIcon(key: string) {
    var foundGroup = Object.keys(GROUPS).find(
      (group) => GROUPS[group].name == key
    );
    var appearance = (foundGroup) ? GROUPS[foundGroup].configuration.appearance : this.applicationStatusService.configuration.appearance;
    this.titleService.setTitle(appearance.title);
    this.favIconService.set(appearance.favIcon);

  }
}
