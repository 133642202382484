import { Component, Input, forwardRef, EventEmitter, Output, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'custom-radio-button',
  templateUrl: './custom-radio-button.component.html',
  styleUrls: ['./custom-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomRadioButtonComponent),
      multi: true,
    }]
})
export class CustomRadioButtonComponent implements ControlValueAccessor {

  @Input()
  editing:boolean=true;

  @Input()
  label: string;

  @Input()
  items: { [key: string]: any; };
  
  @Output()
  selected: EventEmitter<any> = new EventEmitter();
  
  private propagateChange = (_: any) => {
  };
  
  value?: any;
  
  /*
    If this setting is true then each items key will need to have a string property called content containing popup html  
  */
  @Input()
  includeHoverPopups: boolean=false;

  @ViewChild(TemplateRef) popoverTemplate: TemplateRef<any>
  popoverHTML :string = '';


  constructor() { }

  setValue(newValue) {
    this.value = newValue;
    this.propagateChange(this.value);
    this.selected.emit(this.value);
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  onShown(item) {

    /*
      Popup template content is set in the onShown event triggered when the popup is hovered over. There is only one template reference used as there are issues
      otherwise with the popup
    */

    this.popoverHTML=item.popoverTemplate;

  }

}
