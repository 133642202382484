<div class="desktop-only">
  <div style="display:flex; justify-content: center" *ngIf="applicationStatus =='APPLICATION_SENT' || applicationStatus =='APPLICATION_ACCEPTED' || applicationStatus =='APPLICATION_REFERRED' || applicationStatus =='DOCS_ISSUED' || applicationStatus == 'ENQUIRY_RECEIVED'">
    <article class="article-one-car" *ngFor="let vehicle of displayVehicle">
      <div class="real-car-card">
        <div class="image-container">
          <img [src]="vehicle.imageUrl_Main" />
        </div>
        <div [ngClass]="(applicationStatus == 'DOCS_ISSUED')?'article-details-with-example':'article-details'">
          <div class="car-details">
            <p class="intro">You are looking to buy this </p>
            <p class="make-model"> {{vehicle.make}} {{vehicle.model}} </p>
            <p class="reg">Registration: {{vehicle.registration}} ({{vehicle.regYear}})</p>

          </div>
          <div class="car-lending-terms" *ngIf="applicationStatus == 'DOCS_ISSUED' && vehicle.financeType == 'HP'">
            <br/>
            <p class="price-title">{{vehicle.term}} monthly payments from</p>
            <p class="price">{{vehicle.monthlyPayments | currency:'GBP'}}</p>
            <p *ngIf="applicationStatus == 'DOCS_ISSUED'">{{lendingTerms.loanAmount | currency:'GBP'}} at {{lendingTerms.apr}}% APR</p>
            <p *ngIf="applicationStatus == 'DOCS_ISSUED'">({{lendingTerms.totalPayable | currency:'GBP'}} total repayable)</p>
            <br/>
          </div>
          <div class="car-lending-terms" *ngIf="applicationStatus == 'DOCS_ISSUED'  && vehicle.financeType == 'PCP'">
            <span class="divider"></span>
            <div class="flex">
              <div class="vehicle-card-payment">
                <span>
                  from
                </span>
                <h2>{{vehicle.monthlyPayments | currency:'GBP':true:'1.1-1'}}</h2>
                <span>
                  per month for {{vehicle.term}} months
                </span>
              </div>
              <div class="line"></div>
              <div class="vehicle-card-payment">
                <span>With a </span>
                <h2>£{{vehicle.balloon}}</h2>
                <span>purchase option</span>
              </div>
            </div>
          </div>
          <div class="representative-example" *ngIf="applicationStatus == 'DOCS_ISSUED'">
            <h5>
              <b>Representative example</b>
            </h5>
            <p>Borrowing £7,500 over 48 months with a representative APR of 18.9%, an annual interest rate of 18.9% (Fixed)
              and a deposit of £0.00, the amount payable would be £223.86 per calendar month, with a total cost of credit
              of £3,245.06 and a total amount payable of £10,745.06.
            </p>
          </div>
        </div>
      </div>
    </article>
  </div>
</div>
<div class="mobile-only">
  <article *ngIf="vehicle">
    <div class="vehicle-card-mobile">
      <div class="image-container">
        <img [src]="vehicle.imageUrl_Main" class="vehicle-image-mobile" />
      </div>
      <div class="div-mobile">
        <div class="article-details-mobile">
          <div class="mobile-title">
            <div *ngIf="vehicle.source == 'quote generated'">
              <p class="intro-mobile">You are looking to buy this </p>
            </div>
            <p class="make-model-mobile"> {{vehicle.make}} {{vehicle.model}}</p>
            <p class="reg-mobile" *ngIf="vehicle.registration">Reg: {{vehicle.registration}} ({{vehicle.regYear}}) </p>
          </div>
          <div class="mobile-payment-period">
            <p class="price-title-mobile" *ngIf="vehicle.source!='quote generated' && vehicle.financeType == 'HP'">{{vehicle.term}} monthly payments from
              <b class="price-mobile" *ngIf="vehicle.financeType == 'HP'">{{vehicle.monthlyPayments | currency:'GBP'}}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>