import { async, ComponentFixture, TestBed } from '@angular/core/testing';

import { BaseFormComponent } from './base-form.component';

import { By } from '@angular/platform-browser';
/*

TESTS
=====

Test the broadcast to section-container set-open takes place for the form name 

  ngOnChanges()
  isUpdate()
  canDeactivate()
  Test toggleHelp() toggles the showHelp value
  createForm()
  Test initializeFormDefinition() sets this.formDefinition.metadata correctly
  initializeFormDefinitionRecursive()
  getFormErrorsObjectRecursive()
  
  Test setMetaData() adds a metadata object with default settings where the key parameter doesn't exist on this.formDefinition.metadata
  Test setMetaData() adds a label to the metadata for the key parameter when no label exists
  Test setMetaData() adds an errorMessages object to the metadata for the key parameter when no errorMessages object exists
  Test setMetaData() adds a default errorMessages item to the metadata when an errorMessages object exists but the item doesn't
  Test setMetaData() does not change the errorMessages item on the metadata when an errorMessages object exists with the item
  Test setFormData() sets values successfully for plain data model that matches the form FormGroup
  Test setFormData() sets values successfully for hierarchical data model that matches the form FormGroup
  Test setFormData() sets values successfully for plain data model with array that matches the form FormGroup
  Test setFormData() sets values successfully for hierarchical data model with array that matches the form FormGroup
  Test setFormData() sets values successfully for plain data model that does not match the form FormGroup
  Test setFormData() sets values successfully for hierarchical data model that does not match the form FormGroup
  Test setFormData() sets values successfully for plain data model with array that does not match the form FormGroup
  Test setFormData() sets values successfully for hierarchical data model with array that does not match the form FormGroup
  Test getFormData() returns form data successfully

  getModelData()
  saveModelData()
  
  Test edit() sets this.editing=true
  Test cancel() sets this.editing = false
  Test cancel() runs this.setFormData
  Test cancel() marks the form as pristine
  Test onValueChanged() calls onValueChangedRecursive() if this.form exists
  Test onValueChanged() does not call onValueChangedRecursive() if this.form does not exist
  Test onValueChanged() calls validSubscription()
  Test onValueChanged() calls dirtySubscription()
  Test validSubscription() broadcasts where form.valid==true
  Test validSubscription() does not broadcast where form.value==false
  Test dirtySubscription() broadcasts where form.dirty==true
  Test dirtySubscription() does not broadcast where form.dirty==false
  Test onValueChangedRecursive() adds an error to this.formErrors for a FormGroup field that becomes invalid
  Test onValueChangedRecursive() removes the error from this.formErrors for a FormGroup field that becomes valid
  Test onValueChangedRecursive() adds an error to this.formErrors for a nested/child FormGroup field that becomes invalid
  Test onValueChangedRecursive() removes the error from this.formErrors for a nested/child FormGroup field that becomes valid
  Test onValueChangedRecursive() adds an error to this.formErrors for a FormArray field that becomes invalid
  Test onValueChangedRecursive() removes the error from this.formErrors for a FormArray field that becomes valid
  Test onValueChangedRecursive() that the error message '{{field}}' is replaced with the this.formDefinition.metadata field label for an error that occurs
  Test onValueChangedRecursive() that the error message '{{error value}}' is replaced with the error key value
  Test initialiseFormArrayItems() FormArray size == data array size
  Test addItem() adds a FormArray item
  Test addItem() sets form.dirty==true
  Test removeItem() removes a FormArray item
  Test removeItem() sets form.dirty==true
  
*/

// describe('BaseFormComponent', () => {
//   let component: BaseFormComponent;
//   let fixture: ComponentFixture<BaseFormComponent>;

//   beforeEach(async(() => {
//     TestBed.configureTestingModule({
//       declarations: [ BaseFormComponent ]
//     })
//     .compileComponents();
//   }));

//   beforeEach(() => {
//     fixture = TestBed.createComponent(BaseFormComponent);
//     component = fixture.componentInstance;
//     fixture.detectChanges();
//   });
// });


export class BaseFormTester {

  constructor() { }

  public testApplicationStatusServiceSetState(component, status): boolean {

    component.editing = false;
    component.visible = false;
    component.enabled = false;

    component.applicationStatusService.setState(component.application, status, false);

    return component.editing && component.visible && component.enabled;

  }

  public testSectionCollapseExists(fixture: ComponentFixture<any>): boolean {

    let de = fixture.debugElement.queryAll(By.css('.card-block.collapse'))[0];
    return (typeof de!='undefined');

  }

  public testSectionIsCollapsed(fixture: ComponentFixture<any>): boolean {

    let de = fixture.debugElement.queryAll(By.css('.card-block.collapse'))[0];
    return de.attributes['aria-expanded'] && de.attributes['aria-expanded']=='false';

  }

  public testSectionContainerIcon(fixture: ComponentFixture<any>, className:string): boolean {
    
    let de = fixture.debugElement.queryAll(By.css('icon-component i'))[0];
    return de.attributes['ng-reflect-ng-class'] && de.attributes['ng-reflect-ng-class']==className;
  
  }

}
