<div class="privacy_policy_container">
  <h3> Terms &amp; Conditions</h3>
  <div class="privacy_policy_content">
    <div class="overflow">
      <p>
        <b>1. Acceptance of Terms</b>
      </p>

      <p>1.1. The website www.Dealerplus.co.uk (“the Site”) is a site operated by Whichdeal Limited, trading as ‘Dealerplus’
        ("We, Us, Dealerplus"). We are registered in The United Kingdom under company number 04997789 and our registered
        office address is Waterloo House, Fleets Corner, Nuffield Road, Poole, Dorset, BH27 0HL, UK. We are a limited company.
        </p>

      <p>1.2. We are governed by UK and EU laws. </p>

      <p>1.3. We are a “regulated Business”, directly authorised and regulated by the Financial Conduct Authority (FCA) Registration
        Number 456327. </p>

      <p>1.4. Please make sure you have read these Terms &amp; Conditions carefully, they protect you as a user of the Site
        and prevent the Site from being used for any illegal or unauthorised purposes. They also outline the parameters on
        which you are permitted to use the Site and by accessing and continuing to use this Site you agree to be bound by
        and act in accordance with these Terms &amp; Conditions. </p>

      <p>1.5. Your privacy is very important to us and we process all information about you in accordance with our Privacy Policy
        and Cookies Policy. By accessing and using the Site, you confirm that you have read and agreed to our Privacy Policy
        and Cookies Policy. We recommend that you print or save a copy of these Terms and Conditions for your information/future
        reference.</p>

      <p>1.6. We may amend these Terms &amp; Conditions at any time without prior notice. You are expected to check these Terms
        &amp; Conditions from time to time and your continued use of the Site will mean that you accept any amendments to
        the Terms &amp; Conditions. </p>

      <p>1.7. All rights, including copyright and database rights of the Dealerplus website and its contents are owned by Dealerplus
        or otherwise used by Dealerplus as permitted by applicable law. </p>

      <p>1.8. To apply you must be at least 18 years old and a UK resident (excluding the Channel Islands and the Isle of Man).
        Car finance is also subject to personal circumstance. </p>

      <p>1.9. If you have any questions, queries or concerns relating to these Terms &amp; Conditions, please contact us at
        <a href="mailto:compliance@dealerplus.co.uk">compliance@dealerplus.co.uk</a>
      </p>

      <p>
        <b>2. Website</b>
      </p>

      <p>2.1. The Site is a medium for providing information and access to products and services of third parties, which is
        related to cars including new and used car loans. The site also advertises vehicles for sale by third party sellers
        on our web application(s) as indicated in these Terms &amp; Conditions as “Dealerplus”.</p>

      <p>2.2. We do our utmost to ensure that the information on the Site is accurate, reliable and helpful. However, the information
        is general in nature, and is intended as a guide only to the types of products and services offered by Dealerplus.
        Users are advised to consult with Dealerplus and to check any product or service information for specific information.
        We will not be responsible if any such material is untrue, inaccurate, incomplete and/or contains errors and we disclaim
        all liability and responsibility arising from any reliance you place on such material.</p>

      <p>2.3. All vehicle descriptions are supplied by dealers, and therefore Dealerplus shall not be held responsible for any
        related errors or emissions on pricing or vehicle description. Dealerplus has the right to refuse a sale or adjust
        any details up until an order has been received in writing from the customer at an agreed price set by Dealerplus.
        </p>

      <p>2.4. Any loan or other product or service which you obtain via the Site will be subject to the terms and conditions
        of the relevant product provider. Before proceeding with any loan or other product or service with the product provider,
        you should read its terms and conditions carefully and, where appropriate, take independent advice. </p>

      <p>2.5. The Site and the information supplied for use with it have been obtained from sources believed to be reliable
        and accurate at the date of publication. Dealerplus makes no representation or warranty, express or implied, as to
        its accuracy or completeness. No independent verification has been undertaken in respect of the information supplied.</p>

      <p>2.6. Material contained on the site may be out of date at any given time and, whilst we endeavour to maintain the validity
        of material and to ensure that the information is up to date. However, we are under no obligation to do so and material
        on the site may be subject to change at any time. </p>

      <p>2.7. If a linked site is used, any personal information you provide to that site will be actioned in line with that
        site’s Privacy policy, not the privacy policy of Dealerplus, so please ensure that you have read that site’s Privacy
        Policy and Terms &amp; Conditions before you provide any personal Information and use the services of that website.</p>

      <p>
        <b>3. Your use of the Website</b>
      </p>

      <p>3.1. By accessing Dealerplus web pages, you agree that you will access the contents solely for your own private use
        and not for any commercial or public use. Except as permitted within, you undertake not to copy, store in any medium
        (including in any other website), distribute, transmit, re-transmit, broadcast, modify, or show in public any part
        of Dealerplus web site save for the purposes as laid out in these terms and conditions without the prior written
        permission of Dealerplus or in accordance with the Copyright, Designs and Patents Act 1988. </p>

      <p>3.2. Anyone can access the Dealerplus site using their web browser and associated internet connection. However, if
        you want to access and use Dealerplus website and/or any connected services, you as consumer will need to make an
        application for Motor Finance. To access the afore mentioned services you must be 18 years of age or older and be
        resident within the United Kingdom. </p>

      <p>3.3. You must not under any circumstances attempt to gain unauthorised access to our site, the server on which our
        site is stored, or any server, computer or database connected to our site. You must not seek to undermine the security
        of the site in any way. In particular, but without limitation, you must not attempt to alter or delete any information
        that you are not authorised to access, attack our site via a denial-of-service attack or a distributed denial-of
        service attack. By doing so you would commit a criminal offence under the Computer Misuse Act 1990. </p>

      <p>3.4. You are permitted to print or download extracts from material on the site as a consumer for your personal use
        only, provided you keep intact all or any copyright and proprietary notes contained on the site. No licence is granted
        to you in these Terms &amp; Conditions to use any trade mark of Dealerplus.</p>

      <p>3.5. The site may be used only for lawful purposes and you may not use the site that in any way breaches any local,
        national or international law or regulation. In any way that is unlawful or fraudulent or has any unlawful or fraudulent
        purpose or effect.</p>

      <p>3.6. You are not permitted to use the site for the purpose or intent of attempting to harm minors in any way</p>

      <p>3.7. You may not use the site to knowingly transmit, send upload or conceal any data or other material that may contain
        viruses, spyware, adware or any other harmful programs or similar computer designed code(s) to damage, disrupt or
        adversely affect the operation of the site or any computer software/hardware.</p>

      <p>
        <b>4. Disclaimer/Liability</b>
      </p>

      <p>4.1. Neither the Site nor the information derived from it constitutes an offer by Dealerplus. Prices contained within
        the site and/or Quotations subsequently obtained are subject to confirmation by Dealerplus. We have the right to
        decline any proposal. No contract will be binding on Dealerplus unless it is in writing and signed by a duly authorised
        representative of Dealerplus. </p>

      <p>4.2. Any photographs and colours used in the site are for illustrative purposes only and do not form part of any contract.
        </p>

      <p>4.3. We do not make any promises as to the availability, accessibility and or reliability of the site(s)when you wish
        to access, the content on it or the services we provide will be available or delivered uninterrupted in a timely
        manner or error free and it may be necessary to suspend, restrict or terminate access by users at any time. If a
        fault does occur in the service you experience, you should report it to us and we will attempt to reinstate the service
        as soon as we reasonably can.</p>

      <p>4.4. Accordingly, neither Dealerplus nor its directors, representatives or employees, accept any liability whatsoever
        for any direct, indirect or consequential loss howsoever arising from the use of this Site and the information supplied
        in connection therewith. </p>

      <p>4.5. By using the Site the User further agrees to indemnify and hold Dealerplus harmless from any and all claims, expenses
        and liability of any kind arising out of or in connection with any use of the Site. </p>

      <p>4.6. The Site contains links to other websites. When you activate any of these links, you will leave the Site and we
        accept no responsibility for the availability or content of any linked websites or for any loss or damage that may
        arise from your use of them. The links are provided for your convenience and any such link does not imply endorsement
        by us of a website or any association with the operators of a website. </p>

      <p>4.7. In assessing your car loan application, we will make enquiries about you including searching your records held
        by Equifax Plc and/or Experian Ltd. You consent to us disclosing details of your finance application and we may use
        a 'credit scoring' or other automated process in deciding whether to accept your application. Your information may
        also be sent to a third party for further loan/insurance option purposes. Loan offers will be valid for 30 days.
        </p>

      <p>4.8. Any access to the Dealerplus site is permitted on a temporary basis, as we reserve the right to amend or withdraw
        the service we provide on the site at any time and without notice. We will not be held liable if, for any reason,
        the site is unavailable at any time or for any period of time.</p>

      <p>
        <b>5. New and Pre-registered Cars supplied by Dealerplus</b>
      </p>

      <p>5.1. All New and Pre-registered cars supplied by Dealerplus will be covered by the relevant Manufacturer’s warranty.
        Where a vehicle is Pre-registered the balance of manufacturer’s warranty shall apply with no additional warranties
        being supplied by Dealerplus. It is the customer’s responsibility to be fully aware of the parts not covered by the
        manufacturer’s car warranty (if any). All New &amp; Pre-registered cars are supplied on the basis that the client
        understands Dearlerplus will not rectify or compensate for any car repairs outside of the car warranty. </p>

      <p>5.2. If a New or Pre-registered car is returned the customer bears the cost of return along with a cancellation fee
        of 5% of the car value or a minimum of £500, whichever is the greater. </p>

      <p>
        <b>6. Used Cars Supplied by Dearlerplus</b>
      </p>

      <p>6.1. Cars supplied by Dealerplus will be covered by a car warranty for a minimum of 6 months. It is the customer's
        responsibility to be fully aware of the parts not covered by the car warranty (if any). The car is supplied on the
        basis that the client understands Dearlerplus will not rectify or compensate for any car repairs outside of the car
        warranty. For further information visit our car warranties explained page (/help-and-advice/making-anapplication/joint-applications/guarantor/).
        </p>

      <p>6.2. If a car is returned the customer bears the cost of return and a cancellation fee of 5% of the car value or a
        minimum of £500, whichever is the greater. </p>

      <p>6.3. The Dearlerplus Vehicle Standards Guideline can be found here (/media/1255/vehiclestandards.pdf). </p>

      <p>6.4. On delivery of a vehicle supplied by Dealerplus, you must inspect it and sign for its acceptance. If you allow
        another individual to do this, it will be taken that they are acting fully on your behalf and that you personally
        have accepted the vehicle. If you require a test drive of the vehicle, you need to notify the driver who will allow
        you to do so. </p>

      <p>6.5. The customer has a 14-day notice period from delivery, for notification of any mechanical or body defects providing
        they are not commensurate with the vehicle age and mileage or notified to you before delivery. </p>

      <p>6.6. Cancellation after accepting the vehicle will only be allowed provided the goods are deemed faulty and that Dearlerplus
        cannot repair such items within 30 days. The cancellation fee does not apply if the vehicle order is cancelled under
        these terms. Order pricing is valid 02/01/2020 Terms and Conditions https://www.dealerplus.co.uk/terms-and-conditions/
        4/8 pp y p g for 14 days. If you are purchasing the vehicle using finance, you need to refer to the lenders terms
        and conditions. This does not affect your statutory rights as per distance selling rights. </p>

      <p>
        <b>7. Data Protection</b>
      </p>

      <p>7.1 If you make an application, your details will be held on computer and used in a credit scoring or other automated
        decision-making process when assessing your application. An enquiry will be made with Credit Reference Agencies,
        who will record it so that it can be viewed by other Agency users. Your information will also be checked with fraud
        prevention agencies and if fraud is suspected Dealerplus ("we" or "us") will record this and those details will be
        shared with other subscribing organisations. For the purposes of this application you may be treated as financially
        linked and your application will be assessed with reference to any "associated" records. If you are a joint applicant
        or if you have told us of some other financial association with another person, you must be sure that you are entitled
        to:</p>

      <p>1.1. Disclose information about your joint applicant and anyone referred to by you </p>

      <p>1.2. Authorise Dealerplus to search, link or record information at credit reference agencies about you and anyone referred
        to by you </p>

      <p>7.2 You authorise us to make all enquiries necessary to verify this information to enable us to consider this and or
        any subsequent application. By agreeing to the terms and conditions when making your application, you are giving
        your consent for your information to be used by Dealerplus for administrative, underwriting, marketing and revenue
        purposes. This may include partners or related 3rd parties. This also includes any of our sister sites. If you do
        not want your details to be used for marketing please write to Dealerplus, Waterloo House, Fleets Corner, Nuffield
        Road, Poole, Dorset, BH27 0HL. Under the terms of the act you have the right to obtain a copy of the information
        held about you upon payment of the appropriate fee. </p>

      <p>7.3 Regulatory requirements and applicable laws state that there are elements of the service that must be communicated
        in writing. Our main communication means with you will be electronic. We will contact you by letter or email. For
        contractual purposes, you consent to electronic means of communication and acknowledge that all information, notices,
        contracts, agreements and other communications we provide to you as customer electronically, comply with and regulatory
        and legal requirement that such communication be in writing. This condition in no way affects your statutory rights.</p>

      <p>
        <b>8. Additional Fees</b>
      </p>

      <p>8.1 At the request of the customer, Dealerplus will disclose to the customer, in good time before a regulated credit
        agreement or a regulated consumer hire agreement is entered into, the amount (or if the precise amount is not known,
        the likely amount) of any commission or fee or other remuneration payable to Dearlerplus by the lender or owner or
        a third party. </p>

      <p>8.2 Dealerplus reserve the right to charge an administration fee to cover the cost of sourcing a suitable vehicle or
        vetting an appropriate dealer. The administration charge is kept to a minimum to cover costs only. In the event of
        a cancelled order by the client, or the client changing supplier the administration fee will not be returned by Dealerplus.
        </p>

      <p>8.3 If a customer wants payment from a lender within 24 hours of pay-outs, Dealerplus will charge a £25 administration
        fee. </p>

      <p>8.4 As per Road Fund Licence (Road Tax) regulation changed on 1
        <sup>st</sup> October 2014, Dealerplus are not able to provide any road tax with any vehicle, new or used. It is the responsibility
        of the customer to contact the DVLA and arrange road tax payment. Dealerplus will not be held responsible for any
        loss incurred by the customer for failure to comply with the new regulations. 02/01/2020Terms and Conditions https://www.dealerplus.co.uk/terms-and-conditions/
        5/8 </p>

      <p>8.5 All contacts supplied by Dealerplus will be in English. </p>

      <p>
        <b>9. Information about our Financial Services</b>
      </p>

      <p>9.1 Dealerplus is a specialist vehicle financier and vehicle supplier, strictly governed by its relationships with
        lenders and dealers, and cannot be held responsible for any change to vehicle specification or finance rates or any
        other variable outside of its control so imposed by those companies. </p>

      <p>9.2 The term of any contract we offer will be between 12-60 months. We do not offer contracts for less than 12 months.
        </p>

      <p>9.3 Any offers provided by Dealerplus will be valid for 30 days, from the date issued. </p>

      <p>9.4 The Consumer Rights Act 2015 exists to protect the customers from businesses that operate without reasonable care
        and skill. All services supplied by Dealerplus are governed by the Consumer Rights act 2015 which superseded the
        Supply of Goods &amp; Services act of 1982. You can find the act with full details here
        <a href="http://www.legislation.gov.uk/ukpga/2015/15/pdfs/ukpga_20150015_en.pdf">http://www.legislation.gov.uk/ukpga/2015/15/pdfs/ukpga_20150015_en.pdf</a> ). </p>

      <p>9.5 You will be provided with a standardised form with any credit agreement called Standard European Consumer Credit
        Information (SECCI). This document allows you to see exactly what your finance agreement contains. For more information
        visit our car finance glossary, SECCI explained page (/why-us/our-values/ethical-lending-policy/). </p>

      <p>9.6 Quotes generated by the 'Build your finance quote' form are powered by the vehicle and finance settings chosen
        by the user. All the quotes are based on prime interest rates and are subject to change. In addition, the quotes
        do not take into account any customer information or circumstances which can affect an individual’s chance of getting
        finance for a vehicle. The figures provided by this form are quotes and do not represent an acceptance for a loan
        of any kind. As the quotes are subject to a user’s personal circumstances and lender requirements, full confirmation
        on the price to be paid (including all related lender fees) will be detailed in the lender’s customer documentation
        pack. </p>

      <p>
        <b>10.Complaints</b>
      </p>

      <p>10.1 If you have a complaint then we have full details of the complaint’s procedure available on our complaints page.
        Any general complaints or feedback please contact us at
        <a href="mailto:complaints@dealerplus.co.uk">complaints@dealerplus.co.uk</a> or by post to Dealerplus.</p>

      <p>10.2 If 8 weeks has passed since making your complaint and you are still not happy then you may contact the Financial
        Ombudsman Service (FOS). Details on FOS and how to contact them can be found on our complaints page. </p>

      <p>10.3 As a company we respect the intellectual property rights of other users of our site are prohibited from uploading,
        posting, submitting or transmitting any materials that impair, violate or infringe any other person’s intellectual
        property rights. It is our policy to comply with clear notices of alleged copyright infringement. If you are aware
        of, or wish too submit a notice of alleged copyright infringement or lodge a counter notice, please contact us by
        emailing
        <a href="mailto:compliance@dealerplus.co.uk">compliance@dealerplus.co.uk</a>
      </p>

      <p>
        <b>11.Cancellation Policy</b>
      </p>

      <p>11.1 The customer has 14 days from the date of funding to cancel the agreement with no penalty. </p>

      <p>11.2 If the car is delivered the customer has 14 days from the day of delivery to cancel without penalty under the
        Consumer Contracts Regulations 2014 and the Consumer Rights Act of 2015. However, the vehicle supplier has the right
        to claim reasonable compensation for any true costs incurred. </p>

      <p>
        <b>12.Compensation</b>
      </p>

      <p>You agree to only use the site in accordance with these Terms and Conditions and further agree to compensate us (and
        our agents, suppliers, employees and officers) in full for any costs, losses, damages and expenses, including reasonable
        legal fees, that we incur, arising from or in connection with any breach of these Terms and Conditions. This includes
        any actions that you take which disrupts access to and/or the functioning of the sites(S), or any liability we incur
        as a result of your use of the site(s).</p>

      <p>
        <b>13.Changes</b>
      </p>

      <p>These Terms and Conditions may change at any time as the conditions or terms alter. It is the responsibility of the
        users of the site to ensure that they review our Terms &amp; Conditions should they use the site on each occasion.
        Continued us of the site means that you accept any such changes.</p>

      <p>
        <b>14. Got a question for us?</b>
      </p>

      <p>If you require further assistance, our team of Customer Advisors are here to help. We're open seven days a week - you
        can view our opening hours here <a href="https://www.dealerplus.co.uk/contact-us/">Contact us</a> - and we're more than happy to answer your questions. </p>

      <p>Please call 0333 222 4070</p>

    </div>
  </div>
  <div class="button">
    <a (click)="hidePolicy()">
      <button class="primary-button" value="Agree and continue">Agree and continue</button>
    </a>
  </div>
</div>