<div class="main-body">
  <div class="desktop-only-content" style="text-align:center;">
    <div class="desktop-welcome">Welcome to</div>
    <img src="assets/cp-logo.png" class="desktop-logo">
    <!--<div [hidden]="!partnerJourney" class="partner-message">In partnership with
    <img src={{partnerImgSrc}}>
  </div>-->
  </div>
  <div class="display-only-mobile">
    <img src="assets/cp-logo.png" class="desktop-logo">
  </div>
  <h1 class="password-reset-text">Password reset</h1>

  <div [hidden]="verifyingInProgress==false">
    Verifying password reset code. Please wait.
  </div>

  <div [hidden]="verifyingInProgress==true || verificationPassed == true">
    The password reset link provided is invalid or has expired.
    <div routerLink="/login" class="redirect">Return to log in page</div>
  </div>

  <div [hidden]="verifyingInProgress==true || verificationPassed == false" class="raised-card">

    <form (ngSubmit)="login()" #loginForm="ngForm">
      <div class="form-group">
        <label for="customerId" class="card-input-label">Reference number</label>
          <input type="number" class="form-control" id="customerId" placeholder="Customer reference"
            [(ngModel)]="model.customerId" name="customerId" #customerId="ngModel" required disabled />
      </div>
      <div class="form-group">
        <label for="username" class="card-input-label">Email address</label>
          <input type="text" class="form-control" id="username" placeholder="User name"
            [(ngModel)]="model.username" spellcheck="false" name="username" #username="ngModel" required disabled />
      </div>
      <div class="form-group">
        <div class="input-group">
        <label for="newpass" class="card-input-label">New password</label>
          <input [type]="(showPassword === true) ? 'text' : 'password'" class="form-control" id="newpass" maxlength="20"
            placeholder="New password" [(ngModel)]="model.newpass" name="newpass" #newpass="ngModel" required
            (input)="checkPassword()"/>
            <div class="password-eye">
              <div class="eye" (click)="showHidePassword()" title="show/hide password">E</div>
            </div>
      </div>
      </div>
      <div class="password-info-container">
        <div [hidden]="passwordError==false"
          class="password-pass-fail password-cross">!</div>
        <div [hidden]="passwordError==true"
          class="password-pass-fail password-tick">​8</div>
        <p class="password-info">
          Your password must include at least 8 characters, a mixture of both uppercase and
          lowercase letters and a number.
        </p>
      </div>
      <div class="reset-button display-only-mobile" (mouseenter)="mobileSubmit()">
      <button type="submit" class="standard-button" 
        [disabled]="newpass.errors">Reset Password</button>
        </div>
        <div class="reset-button desktop-only-content" (mouseenter)="desktopSubmit()">
        <button type="submit" class="standard-button"
        [disabled]="newpass.errors">Reset Password</button>
      </div>
    </form>
  </div>
</div>