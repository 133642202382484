import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProposedVehicle, Application } from '../../../core/services/types';
import { RecordsetPager } from '../../../shared/components/models.class';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { Subscription } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'quote-showroom-item',
  templateUrl: './quote-showroom-item.component.html',
  styleUrls: ['./quote-showroom-item.component.scss']
})
export class QuoteShowroomItemComponent implements OnInit {

  @Input() vehicle: any;

  @Input() id: number;
  @Input() application: Application;

  @Output()
  public vehicleChange: EventEmitter<any> = new EventEmitter<ProposedVehicle>();

  @Output() vehicleSelected = new EventEmitter();
  @Output() financeBreakdownClicked = new EventEmitter();

  validImagesCount: number;
  parentName : string;
  showroomFilterObservers: Subscription[];
  
  constructor(private actionsService: ActionsService) { }

  ngOnInit() {
    //get the number of images excluding the nulls 
    this.validImagesCount = Object.keys(this.vehicle).filter(x => x.match("image")).map(key => this.vehicle[key]).filter(x => x != null).length;
  }

  selectedVehicle() {
    this.vehicleSelected.emit(this.vehicle);
  }

  updateUrl(){
    this.vehicle.imageUrl_Main = './assets/car-placeholder2.png';
  }

  financeBreakdown(){
    this.financeBreakdownClicked.emit(this.vehicle);
  }

  
}
