import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { User } from "../services/types";
import { UserService } from "../services/user/user.service";

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private userService: UserService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    if (route.parent == null) {
      throw Error("A parent route has not been found");
    }

    return this.userService.getItem();

  }
}
