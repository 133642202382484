class NotificationMessage {
    public message: string;
    public startDate: string;
    public endDate: string;
    public type: 'dashboard' | 'workflow';
    public class: string;
    public workflow: string[];
    public roles: string[];
}

export {
    NotificationMessage
}
