import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActionsService } from '../../../core/services/actions/actions.service';

@Component({
  selector: 'order-by-selector',
  templateUrl: './order-by-selector.component.html',
  styleUrls: ['./order-by-selector.component.scss']
})
export class OrderBySelectorComponent implements OnInit {

  public form: FormGroup;
  
  @Input()
  filterOptions: any[];

  constructor(public formBuilder: FormBuilder, private actionsService: ActionsService) {

    this.form = formBuilder.group({
      filterOption: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.form.setValue({filterOption: this.filterOptions[0].value});
  }

  changeFilterOption() {
    this.actionsService.broadcast({
      action: 'filterOption',
      behaviour: 'change',
      value: this.form.controls.filterOption.value,
    });
  }
}
