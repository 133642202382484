import { Configuration } from "./configuration";
import { ERRORS } from "./configuration-errors";
import { FINANCE_OPTIONS } from "./configuration-finance-options";
import { LOOKUPS } from "./configuration-lookups";

var CONFIGURATION: Configuration = {
    currentJourney: 'FORWARDS_ONLY',
    // currentJourney: 'BACKWARDS_AND_FORWARDS',
    appearance: {
        logo: './assets/CpLogo.PNG',
        phoneIcon: './assets/call-button.png',
        title: 'Creditplus Portal',
        favIcon: 'favicon.ico'
    },
    authentication: {
        passwordPolicy: {
            //note for 
            minimumLength: 6,
            numbers: true,
            letters: true,
            specialCharacters: '!@#$%^&*()_+'
        },
        expirationCountdown: 30000
    },
    acceptanceConfiguration: {
        displayReferredResponses: true,
        displayDeclinedResponses: true,
        displayErrorResponses: true,
        applyTimeout: 60000
    },
    contact: {
        telephone: '0333 6000 290',
        telephoneComplaints: '01202 684 898',
        email: 'sales@creditplus.co.uk',
        emailComplaints: 'complaints@creditplus.co.uk',
        address: '52 Willis Way, Poole, Dorset, BH15 3SY, UK',
        defaultPreferredContactMethod: 'Mobile',
        defaultPreferredContactTime: 'No Preference'
    },
    identity: {
        minAge: 18,
        maxAge: 75
    },
    creditRating: {
        ratingMethod: 'softSearch'
    },
    errors: ERRORS,
    pageScroll: {
        adjustHash: true,
        offset: 116,
        duration: 300
    },
    outgoings: {
        optional: true
    },
    products: {
        noThanksId: -1
    },
    vehicle: {
        maximumSearchesPermitted: 5,
        maximumAgeAtStartOfLoan: 5,
        maximumAgeAtEndOfLoan: 9,
        maximumMileageAtStartOfLoan: 120000,
        minRegistrationLength: 2,
        maxRegistrationLength: 8
    },
    financeRequest: {
        maximumLTVPercentage: 1.2,
        minimumLoan: 3000,
        minimumDeposit: 500,
        term: {
            minimum: 24,
            maximum: 60,
            step: 6
        },
        annualMileage: {
            minimum: 0,
            maximum: 40000,
            step: 1000
        }
    },
    quotes: {
        copyVehicleOnAddQuote: true,
        copyFinanceRequestOnAddQuote: true,
        maximumPermitted: 3,
        getQuoteTimeoutCountdown: 1000
    },
    proofs: {
        maximumFileSize: 5120000,
        immediateDragDropUpload: true,
        immediateFileSelectUpload: true,
        confirmFileReplacements: true,
        acceptedFileFormats: [
            { label: 'Bitmap', extention: 'bmp', mimeType: 'image/bmp' },
            { label: 'High Efficiency Image File Format', extention: 'heif', mimeType: 'image/heif' },
            { label: 'JPEG', extention: 'jpg', mimeType: 'image/jpeg' },
            { label: 'Portable Network Graphics', extention: 'png', mimeType: 'image/png' },
            { label: 'Portable Document Format', extention: 'pdf', mimeType: 'application/pdf' },
            { label: 'Tagged Image Format File', extention: 'tif', mimeType: 'image/tif' },
            { label: 'WebP', extention: 'webp', mimeType: 'image/webp' }
        ]
    },
    proofVerify: {
        maximumFileSize: 5120000,
        immediateDragDropUpload: true,
        immediateFileSelectUpload: true,
        confirmFileReplacements: true,
        acceptedFileFormats: [
            { label: 'Bitmap', extention: 'bmp', mimeType: 'image/bmp' },
            { label: 'High Efficiency Image File Format', extention: 'heif', mimeType: 'image/heif' },
            { label: 'JPEG', extention: 'jpg', mimeType: 'image/jpeg' },
            { label: 'Portable Network Graphics', extention: 'png', mimeType: 'image/png' },
            { label: 'Portable Document Format', extention: 'pdf', mimeType: 'application/pdf' },
            { label: 'Tagged Image Format File', extention: 'tif', mimeType: 'image/tif' },
            { label: 'WebP', extention: 'webp', mimeType: 'image/webp' }
        ]
    },
    save: {
        method: 'paged',
        savePeriod: 2700000
    },
    financeOptions: FINANCE_OPTIONS,
    addressLookup: {
        addressListUrl: "/address?postcode=:postcode&id=:id",
        addressDetailUrl: "/address/Retrieve?id=:id"
    },
    employment: {
        minHistoryMonths: 24
    },
    addresses: {
        minHistoryMonths: 36
    },
    lookupConfiguration: LOOKUPS
};


export {
    CONFIGURATION
}