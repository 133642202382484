
import { finalize, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ProposedVehicle } from "../../../core/services/types";
import { environment } from "../../../../environments/environment";

import { TimedActionService } from "../timed-action/timed-action.service";
import { LoginService } from '../login/login.service';
import { AnalyticsData } from '../types-quote';
import { LoadingModalService } from '../modal/loading-modal.service';
import { ApplicantVehicle, Vehicle } from '../../../shared/models/vehicle.model';
import * as pako from 'pako';


@Injectable()
export class VehicleService {
  vehiclesUrl = environment.base + "/vehiclelookup?registration=:registration&currentMileage=:mileage&applicationId=:applicationId&isSuggested=:isSuggested";

  vehicleShowroomUrl = environment.base + `/showroom/updatefilter`;

  getVehicleListUrl = environment.base + "/showroom/all-vehicles";

  getVehicleByReg = environment.base + "/showroom/carbyregistration?reg=:registration";

  getVehicleShowroom = environment.base + `/showroom/search`;

  vehicleDetailsOnly = environment.base + `/vehiclelookup/detailsOnly?registration=:registration`;

  residualValue = environment.base + `/vehiclelookup/getFutureValuation?registerationDate=:registerationDate&endOfTermMileage=:endOfTermMileage&capId=:capId&term=:term`;

  constructor(private http: HttpClient, private timedActionService: TimedActionService, private loginService: LoginService,
    private loadingModalService: LoadingModalService) { }

    private extractVehicleData(response: HttpResponse<any>): any {
      return response != null ? response : null;
    }

    private body: any = {
      Makes: null,
      Models: null,
      MinimumPrice: null,
      MaximumPrice: null,
      MaximumMileage: null,
      registrationYear: null,
      Transmissions: null,
      Colours: null,
      fuelType: null,
      sortBy: null,
      sortDescending: null,
    };

  getVehicleList(financeRequestBody) {
    return this.http.post(this.getVehicleListUrl, financeRequestBody, { headers: this.loginService.getHeaderOptions(true) });
  }

  getVehicleListFromFile(financeRequestBody) {
    return this.http.get<any>("../../../../assets/GetVehicleList.json");
  }

  getVehicleListFromZip() {
    return this.http.get("../../../../assets/GetVehicleList.json.gz", {
      responseType: 'arraybuffer'
    });
  }

  getStandingDataFromZip() {
    return this.http.get("../../../../assets/PortalStandingData.json.gz", {
      responseType: 'arraybuffer'
    });
  }


  getVehicleByRegistration(registration: string) {
    var url = this.vehicleDetailsOnly.replace(':registration', registration);
    return this.http.get<any>(url, { headers: this.loginService.getHeaderOptions(true) });
  }

  getcarByRegistration(registration: string) {
    var reg = registration.replace(/\s/g, '');
//    registration = registration.replace(/^\s+|\s+$/gm, ''); 
    var url = this.getVehicleByReg.replace(':registration', reg );
    return this.http.get<any>(url, { headers: this.loginService.getHeaderOptions(true) });
  }

  getVehicleDetails(registration: string): Observable<any> {
    this.loadingModalService.show();
    this.loadingModalService.displayMessage(5);

    var url = this.vehicleDetailsOnly.replace(':registration', registration);
    if (registration) {
      return this.http.get<HttpResponse<any>>(url, { headers: this.loginService.getHeaderOptions(true) }).pipe(
        map(
          (response) => {
            this.timedActionService.resetTimer();
           //console.log(response)
            return response;
          }
        ),
        catchError((error: any) => {
          this.timedActionService.checkResetTimerOnError(error);
          throw error;
        }
        ),
        finalize(() => this.loadingModalService.hide()));

    }
  }

  getShowroomVehicles(selectedFilterValues: any): any {
    //console.log(selectedFilterValues);
     this.body.Makes = selectedFilterValues.Makes == null ? [] : selectedFilterValues.Makes;
     this.body.Models = selectedFilterValues.Models == null ? [] : selectedFilterValues.Models;
     this.body.MinimumPrice = selectedFilterValues.MinimumPrice == null ? '' : selectedFilterValues.MinimumPrice;
     this.body.MaximumPrice = selectedFilterValues.MaximumPrice == null ? '' : selectedFilterValues.MaximumPrice;
     this.body.MaximumMileage = selectedFilterValues.MaximumMileage == null ? '' : selectedFilterValues.MaximumMileage;
     this.body.MinYear = selectedFilterValues.minYear == null ? '' : selectedFilterValues.minYear;
     this.body.MaxYear = selectedFilterValues.maxYear == null ? '' : selectedFilterValues.maxYear;
     this.body.FuelType = selectedFilterValues.fuelTerms ==null ? '' : selectedFilterValues.fuelTerms
     this.body.Transmissions = selectedFilterValues.transmissionTerms == null ? '' : selectedFilterValues.transmissionTerms;
     this.body.Colours = selectedFilterValues.cosmeticTerms.colours == null ? '' : selectedFilterValues.cosmeticTerms.colours;
     this.body.Doors = selectedFilterValues.functionTerms.doors == null ? '' : selectedFilterValues.functionTerms.doors;
     this.body.PageSize = selectedFilterValues.pageSize == null ? '' : selectedFilterValues.pageSize;
     this.body.PageNumber = selectedFilterValues.pageNumber == null ? '' : selectedFilterValues.pageNumber;
    this.body.MaxMonthlyPrice = selectedFilterValues.maxMonthlyPrice == null ? '' : selectedFilterValues.maxMonthlyPrice;
    this.body.BodyTypes = selectedFilterValues.functionTerms.bodyTypes == null ? '' : selectedFilterValues.functionTerms.bodyTypes;
 
    //console.log("BODY: G:G:")
    //console.log(this.body)
     return this.http.post(this.getVehicleShowroom, JSON.stringify(this.body), { headers: this.loginService.getHeaderOptions(true)}).pipe(
       map(
         (response) => {
           return response;
         }
       ),
       catchError((error: any) => {
         throw error;
       }),);
 }

 vehicleLookup({ applicationId, registration, mileage, price, showroom, isSuggested }: { applicationId: number; registration: string; mileage: number; price: number; showroom: boolean; isSuggested: boolean; }): Observable<ProposedVehicle> {
  this.loadingModalService.show();
  this.loadingModalService.displayMessage(5);
  if (mileage == null) {
    mileage = 0;
  }

  if (applicationId == null) {
    throw new Error("An application id should be passed");
  }

  var url = this.vehiclesUrl.replace(':registration', registration).replace(':mileage', String(mileage)).replace(':applicationId', String(applicationId)).replace(':isSuggested', String(Boolean(isSuggested)));
  if (registration) {
    return this.http.get<HttpResponse<any>>(url, { headers: this.loginService.getHeaderOptions(true) }).pipe(
      map(
        (response) => {
          this.timedActionService.resetTimer();
          this.logVehicleLookup(registration, mileage, price, true, showroom);
          // //console.log(this.extractVehicleData(response));
          return this.extractVehicleData(response);
        }
      ),
      catchError((error: any) => {
        this.timedActionService.checkResetTimerOnError(error);
        this.logVehicleLookup(registration, mileage, price, false, showroom);
        throw error;
      }
      ),
      finalize(() => this.loadingModalService.hide()));

  }
}

private logVehicleLookup(registration: string, mileage: number, price: number, success: boolean, showroom: boolean) {

  var label;
  var analyticsData = new AnalyticsData;
  analyticsData = {
    vehicle: {
      vrm: registration,
      mileage: mileage,
      price: price,
    }
  }
  if (showroom) {
    if (success) {
      label = 'successful-vehicle-showroom-lookup'
    } else {
      label = 'unsuccessful-vehicle-showroom-lookup'
    }
  } else {
    if (success) {
      label = 'successful-vehicle-reg-lookup'
    } else {
      label = 'unsuccessful-vehicle-reg-lookup'
    }
  }
  if (success) {
    window['dataLayer'].push(
      {
        event: 'service-response',
        label: label,
        data: analyticsData,
        timestamp: new Date().toISOString()
      })
  } else {
    window['dataLayer'].push(
      {
        event: 'service-response',
        label: label,
        data: analyticsData,
        timestamp: new Date().toISOString()
      })
  }
}


  getResidualValue(registerationDate, endOfTermMileage, capId, term): Observable<any> {
    this.loadingModalService.show();
    var url = this.residualValue.replace(':registerationDate', registerationDate).replace(':endOfTermMileage', endOfTermMileage).replace(':capId', capId).replace(':term', term);
    return this.http.get<HttpResponse<any>>(url, { headers: this.loginService.getHeaderOptions(true) }).pipe(
      map(
        (response) => {
          this.timedActionService.resetTimer();
          return response.body;
        }
      ),
      catchError((error: any) => {
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }
      ),
      finalize(() => this.loadingModalService.hide()));

  }

  startVehicleTimer(customerId: string) {
    var url = environment.base + "/timers/vehicle-search-new-user-check";
    if (customerId != null) {
      this.http.get(url, { headers: this.loginService.getHeaderOptions(true), params: { customerId: customerId } }).toPromise()
        .then(async (resp: any) => {

         //console.log(resp);
        }).catch((error) => {

         console.log(error);

        });
    }
  }

  checkVehicleTimer(customerId: string) {
    var url = environment.base + "/timers/vehicle-search-removal-check";
    if (customerId != null) {
      this.http.get(url, { headers: this.loginService.getHeaderOptions(true), params: { customerId: customerId } }).toPromise()
        .then(async (resp: any) => {

         //console.log(resp);
        }).catch((error) => {

         console.log(error);

        });
    }
  }

  sendDocsToBeIssuedEmail(customerId:string, customerFirstName:string,customerLastName:string,salesPerson:string ){
    var url = environment.base + "/customer/application/loan-confirmed";
    this.http.post(url, null, { headers: this.loginService.getHeaderOptions(true), 
      params: { customerId: customerId, firstName: customerFirstName, lastName: customerLastName, salesPerson: salesPerson, isDemo: environment.demo.toString() } }).toPromise()
    .then(async (resp: any) => {

     //console.log(resp);
    }).catch((error) => {

     console.log(error);

    });
}

sendPortalLinkEmail(customerId:string, customerEmail: string){
  var url = environment.base + "/customer/application/portal-link";
  this.http.post(url, null, { headers: this.loginService.getHeaderOptions(true), 
    params: { customerId:customerId, customerEmail: customerEmail, isDemo: environment.demo.toString() } }).toPromise()
  .then(async (resp: any) => {

   //console.log(resp);
  }).catch((error) => {

   console.log(error);

  });
}

VehicleToApplicantVehicle(vehicle: Vehicle){
  var applicantVehicle = new ApplicantVehicle();
  
  applicantVehicle.bodyType = vehicle.BodyType;
  applicantVehicle.capDetails = vehicle.CapDetails;
  applicantVehicle.colour = vehicle.Colour;
  applicantVehicle.description = vehicle.Description;
  applicantVehicle.doors = vehicle.Doors;
  applicantVehicle.engineSizeCC = vehicle.EngineSizeCC;
  applicantVehicle.fuelType = vehicle.FuelType;
  applicantVehicle.id = vehicle.Id;
  applicantVehicle.imageUrl_Main = vehicle.ImageUrl_Main;
  applicantVehicle.imageUrl_More1 = vehicle.ImageUrl_More1;
  applicantVehicle.imageUrl_More2 = vehicle.ImageUrl_More2;
  applicantVehicle.imageUrl_More3 = vehicle.ImageUrl_More3;
  applicantVehicle.imageUrl_More4 = vehicle.ImageUrl_More4;
  applicantVehicle.imageUrl_More5 = vehicle.ImageUrl_More5;
  applicantVehicle.imageUrl_More6 = vehicle.ImageUrl_More6;
  applicantVehicle.imageUrl_More7 = vehicle.ImageUrl_More7;
  applicantVehicle.imageUrl_More8 = vehicle.ImageUrl_More8;
  applicantVehicle.imageUrl_More9 = vehicle.ImageUrl_More9;
  applicantVehicle.isMock = vehicle.IsMock;
  applicantVehicle.make = vehicle.Make;
  applicantVehicle.mileage = vehicle.Mileage;
  applicantVehicle.model = vehicle.Model;
  applicantVehicle.monthlyPrice = vehicle.MonthlyPrice;
  applicantVehicle.price = vehicle.Price;
  applicantVehicle.registration = vehicle.Registration;
  applicantVehicle.registrationYear = vehicle.RegistrationYear;
  applicantVehicle.retailPrice = vehicle.RetailPrice;
  applicantVehicle.transmission = vehicle.Transmission;
  applicantVehicle.transmissionType = vehicle.TransmissionType;
  applicantVehicle.variant = vehicle.Variant;  

  return applicantVehicle;
}

ApplicantVehicleToVehicle(applicantVehicle: ApplicantVehicle){
  var vehicle = new Vehicle();
  
  vehicle.BodyType = applicantVehicle.bodyType;
  vehicle.CapDetails = applicantVehicle.capDetails;
  vehicle.Colour = applicantVehicle.colour;
  vehicle.Description = applicantVehicle.description;
  vehicle.Doors = applicantVehicle.doors;
  vehicle.EngineSizeCC = applicantVehicle.engineSizeCC;
  vehicle.FuelType = applicantVehicle.fuelType;
  vehicle.Id = applicantVehicle.id;
  vehicle.ImageUrl_Main = applicantVehicle.imageUrl_Main;
  vehicle.ImageUrl_More1 = applicantVehicle.imageUrl_More1;
  vehicle.ImageUrl_More2 = applicantVehicle.imageUrl_More2;
  vehicle.ImageUrl_More3 = applicantVehicle.imageUrl_More3;
  vehicle.ImageUrl_More4 = applicantVehicle.imageUrl_More4;
  vehicle.ImageUrl_More5 = applicantVehicle.imageUrl_More5;
  vehicle.ImageUrl_More6 = applicantVehicle.imageUrl_More6;
  vehicle.ImageUrl_More7 = applicantVehicle.imageUrl_More7;
  vehicle.ImageUrl_More8 = applicantVehicle.imageUrl_More8;
  vehicle.ImageUrl_More9 = applicantVehicle.imageUrl_More9;
  vehicle.IsMock = applicantVehicle.isMock;
  vehicle.Make = applicantVehicle.make;
  vehicle.Mileage = applicantVehicle.mileage;
  vehicle.Model = applicantVehicle.model;
  vehicle.MonthlyPrice = applicantVehicle.monthlyPrice;
  vehicle.Price = applicantVehicle.price;
  vehicle.Registration = applicantVehicle.registration;
  vehicle.RegistrationYear = applicantVehicle.registrationYear;
  vehicle.RetailPrice = applicantVehicle.retailPrice;
  vehicle.Transmission = applicantVehicle.transmission;
  vehicle.TransmissionType = applicantVehicle.transmissionType;
  vehicle.Variant = applicantVehicle.variant;  

  return vehicle;
}

}

