export function isANumber(value: any): boolean {
  return !isNaN(value);
}


export function isDate(obj: any): boolean {
  return !/Invalid|NaN/.test(new Date(obj).toString());
}

export function isStringNumber(value: string): boolean {
  return isANumber(Number(value));
}

export function containsElement(collection: Array<string | number>, value:number) : boolean {
    return collection.find(x => x === value) !== undefined;
}