<div class="mobile-only">

  <div class="fixed-header">
    <div style="width:100%; max-width:900px; margin:0 auto;">
      <a routerLink="/vehicle/{{customerId}}/details">
        <div class="raised-back-button">
          <div class="raised-back-button-icon">L</div>
        </div>
      </a>
    </div>
  </div>
  <div class="fixed-header-fade"></div>

  <div class="filter-window" [hidden]="filtersVisible==false">
    <div class="filter-window-title"><span class="filter-back-arrow" (click)="showFilters(false)">L</span>Payment
      Options</div>
    <div class="filter-window-divider"></div>
    <div class="option-title">Deposit</div>
    <div class="slider-container single-slider-pink">
      <ngx-slider [(value)]="newChosenDeposit" [options]="depositOptions"></ngx-slider>
    </div>
    <div class="filter-disclaimer">A deposit is required to reserve the vehicle. The full amount is
      required within 24 hours of checkout.
    </div>
    <div class="option-title">Term</div>
    <div class="slider-container single-slider-pink">
      <ngx-slider [(value)]="newChosenTerm" [options]="termOptions"></ngx-slider>
    </div>
    <div [hidden]="loanTypeSelected=='PCP'" class="option-title">Annual Mileage</div>
    <div [hidden]="loanTypeSelected=='PCP'" class="slider-container single-slider-pink">
      <ngx-slider [(value)]="newChosenMileage" [options]="mileageOptions"></ngx-slider>
    </div>
    <div style="width:100%; height:160px;"></div>
    <div class="submit-options-container">
      <div class="standard-button submit-options-button" (click)="submitLoanChanges()">Submit changes</div>
      <div class="submit-button-fade"></div>
      <div class="filter-disclaimer-row">
        <div class="disclaimer-triangle"><i class="fas fa-exclamation-triangle"></i></div>
        <div class="filter-disclaimer bottom-disclaimer">If you adjust these parameters your application will be resent
          for approval.
          This can take up to 2 hours.
        </div>
      </div>
    </div>
  </div>

  <div class="screen-height-scroller-container">
    <div class="fixed-header-offset"></div>



    <div class="main-body">
      <div class="payment-title" id="paymentHeader">Payment Options</div>

      <div [hidden]="offersAcceptedCount <= 0">
        <div id="deal-type" class="hp-pcp-container" [hidden]="hpAvailable==false&&pcpAvailable==false">
          <div class="hp-pcp-row" (click)="setLoanTypeSelected('HirePurchase')"
            [ngStyle]="loanTypeSelected=='HirePurchase' ? {'opacity' : '1'} : {'opacity' : '0.3'}" [hidden]="hpAvailable==false">
            <div class="hp-pcp-title">HP <div class="info-icon" (click)="displayPopup()">i</div>
            </div>
            <div class="hp-pcp-underline"></div>
          </div>
          <!--
          <div class="hp-pcp-row" (click)="setLoanTypeSelected('PersonalContractPurchase')"
            [ngStyle]="loanTypeSelected=='PersonalContractPurchase' ? {'opacity' : '1'} : {'opacity' : '0.3'}"
            [hidden]="pcpAvailable==false">
            <div class="hp-pcp-title">PCP <div class="info-icon" (click)="displayPopup()">i</div>
            </div>
            <div class="hp-pcp-underline"></div>
          </div> -->
        </div>
       <!-- <div class="loan-choices">
          <div class="loan-choice-item"><span>Deposit:</span>£{{chosenDeposit | number : ''}}
          </div>
          <div class="loan-choice-item"><span>Term:</span>{{chosenTerm}} months
          </div>
          <div class="loan-choice-item"><span>Annual Mileage:</span>{{chosenMileage | number : ''}}
          </div>
        </div>
        <div class="change-choices" (click)="showFilters(true)">Change</div> -->

        <div class="no-offers-in-category">There are no available offers for 
          <span *ngIf="loanTypeSelected=='HirePurchase'">hired purchase. Please select PCP.</span>
          <span *ngIf="loanTypeSelected=='PersonalContractPurchase'">personal contract purchase. Please select HP.</span>
        </div>
        <offers-list-item *ngFor="let offer of displayedOffers; let last = last;" [application]="application"
          (selectedOffer)="selectOffer($event)" [isLast]="last" [offer]="offer" ></offers-list-item>
      </div>

      <div [hidden]="offersAcceptedCount > 0 || offersReferredCount >0">
        <div class="loading-message-text">
          <span style="font-weight:bold;">We need more time to find your best finance options.</span>
          We will contact you by phone to discuss.
        </div>
        <div class="return-home-button">
        <div class="standard-button" routerLink="/dashboard/{{application.id}}">Return Home</div>
      </div>
      </div>

      <div [hidden]="offersAcceptedCount > 0 || offersReferredCount <= 0">
          <div class="loading-message-text">
            <span style="font-weight:bold;">Your finance offers are loading. This can take up to 2 hours.</span>
            Or enough time to watch the first episode of 'The Queen's Gambit' - we'd recommend giving it a watch!
          </div>
          <div class="loans-loading-spinner">
            <img width="150" src="assets/spinner.gif">
          </div>
        </div>      
    </div>
  </div>
</div>

<div class="popup-container" *ngIf="showPopup==true">
  <div class="raised-card popup-card">
    <div class="close-button" (click)="hidePopup()">X</div>
    <div class="popup-title">Hire Purchase (HP)</div>
    <div class="popup-text">Buy outright over a fixed length
      <div class="popup-text">There's no mileage restrictions and you own the car at the end.</div>
    </div>
    <div class="horizontal-divider"></div>
    <div class="popup-title">Personal Contract Purchase (PCP)</div>
    <div class="popup-text">Repay monthly then buy, exchange or hand back.
      <div class="popup-text">Usually lower monthly repayments and flexibility at the end.</div>
    </div>
  </div>
</div>