
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { LoadingComponent } from "./loading.component";

@Component({
  selector: 'loading-observer',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingObserverComponent extends LoadingComponent implements OnChanges {
  @Input()
  observable:Observable<any>;
  
  private loadingSubscription : Subscription;
  
  ngOnChanges(changes: SimpleChanges): void {
    this.refreshSubscription();
  }

  ngOnDestroy(): void {
    if(this.loadingSubscription){
      this.loadingSubscription.unsubscribe();
    }
  }

  private refreshSubscription() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.observable){
      this.loading = true;
      this.loadingSubscription = this.observable.pipe(finalize(() => this.loading = false)).subscribe();
    }
  }

}
