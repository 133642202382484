<div class="mobile-only">
  <div [hidden]="passwordChanged==true">
  <div class="fixed-header">
    <div class="fixed-page-title-vehicle-serarch fixed-page-title-vehicle-serarch-backbtn">
      <a routerLink="/applications/{{applicationId}}/settings">
        <div class="raised-back-button">
          <div class="raised-back-button-icon">L</div>
       </div>
      </a>
    </div>
  </div>
  <div class=fixed-header-fade></div>
  <div class="fixed-header-offset"></div>
<div class="main-body-narrow-padding">
  <div class="medium-title">Change password </div>
  <div class="raised-card">
    <form (ngSubmit)="submit()" #form="ngForm" class="form-group">
      <label class="card-label" for="password">Current password</label>
      <div class="input-group">
      <input [type]="(showPassword === true) ? 'text' : 'password'" class="form-control" id="password"
        placeholder="Current password" [(ngModel)]="password" name="password" #passwordModel="ngModel"
        [passwordPolicyValidator]="passwordPolicy" spellcheck="false" 
        [ngStyle]="password==null || password=='' ? {'background-color' : 'white'} : null" />
        <div class="password-eye">
          <div class="eye" (click)="showHidePassword()" title="show/hide password">E</div>
        </div>
      </div>
      <label class="card-label" for="newPassword" style="margin-top: 40px;">New password</label>      
      <div class="form-group">
        <div class="input-group">
          <input [type]="(showNewPassword === true) ? 'text' : 'password'"
                 [ngClass]="(newPasswordModel.errors != null && newPasswordModel.value!=null &&newPasswordModel.value!='')?'form-control form-error':'form-control'"
                 id="password" [(ngModel)]="newPassword" name="newPassword" #newPasswordModel="ngModel"
                 [passwordPolicyValidator]="passwordPolicy" spellcheck="false" placeholder="Create a password"
                 [ngStyle]="newPassword == null || newPassword == '' ? {'background-color' : 'white'} : {}">
                 <div class="password-eye">
                   <div class="eye" (click)="showHideNewPassword()" title="show/hide password">E</div>
                  </div>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <input [type]="(showConfirmNewPassword === true) ? 'text' : 'password'"
                 [ngClass]="( newPasswordModel.value != confirmNewPasswordModel.value || (newPasswordModel.errors!=null && newPasswordModel.value!=null))
                  && (confirmNewPassword != null && confirmNewPassword != '' && confirmNewPasswordModel.touched)
                 ? 'form-control form-error':'form-control'"
                 id="confirmPassword" [(ngModel)]="confirmNewPassword" name="confirmNewPassword" #confirmNewPasswordModel="ngModel"
                 required spellcheck="false" placeholder="Re-enter a password"
                 [ngStyle]="confirmNewPassword == null || confirmNewPassword == '' ? {'background-color' : 'white'} : {}">
                 <div class="password-eye" >
                   <div class="eye" (click)="showHideConfirmNewPassword()" title="show/hide password">E</div>
                  </div>
        </div>
        <div class="password-info-container">
          <div [hidden]="newPasswordModel.errors==null && newPasswordModel.value==confirmNewPasswordModel.value" class="password-pass-fail password-cross">!</div>
          <div [hidden]="newPasswordModel.errors!=null || newPasswordModel.value!=confirmNewPasswordModel.value" class="password-pass-fail password-tick">​8</div>
        <p class="password-info">            
          Your password must include at least 8 characters, a mixture of both uppercase and
          lowercase letters and a number.
        </p>
        </div>
      </div>
    </form>
  </div>  
</div>

<div class="change-password-button">
  <div class="standard-button"
    [ngStyle]="(passwordModel.errors || newPasswordModel.errors || confirmNewPasswordModel.errors || newPasswordModel.value !== 
    confirmNewPasswordModel.value || newPasswordModel.value == passwordModel.value ) ? {'opacity' : '0.5', 'pointer-events' : 'none'} : {}"
    type="submit" (click)="submit()">Change password</div>
</div>
<div style="height:100px"></div>

<navbar [currentSection]="'account'"></navbar>
</div>

<div [hidden]="passwordChanged==false">
    <div class="password-sucess">Your password was succesfully changed.</div>
</div>
</div>
