import { Directive, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[focusIf]'
})
export class FocusIfDirective implements OnChanges {

  @Input() focusIf: boolean
  isOn: any;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.isOn = changes['focusIf'];
  }

  ngAfterViewChecked() {

    if (this.isOn.currentValue) {
      this.isOn.currentValue=false;
      this.el.nativeElement.focus();
    }
  }

}
