import { UserService } from './../../../core/services/user/user.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpVehicle, Quote } from '../../../core/services/types-quote';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { Router } from '@angular/router';
import { ApplyService } from '../../../core/services/apply/apply.service';
import { Application } from '../../../core/services/types';
import { ApplicationStatusService } from '../../../shared/components/base-form/base-form-testing.barrel.spec';
import { Subscription } from 'rxjs';
import { QuotesService } from '../../../core/services/quotes/quotes.service';

@Component({
  selector: 'quote-vehicle-item',
  templateUrl: './quote-vehicle-item.component.html',
  styleUrls: ['./quote-vehicle-item.component.scss']
})
export class QuoteVehicleItemComponent implements OnInit {
  @Input()
  vehicle: any
  @Input()
  id: number;
  @Input()
  application: Application;
  @Input()
  term: number;
  @Input()
  deposit: number;
  @Input()
  apiErrorMessages: any;
  @Output()
  public updateVehicleCard: EventEmitter<any> = new EventEmitter<number>();
  annualMileage: number;
  quoteVehicleItemObservable: Subscription;
  source: string;
  registrationYear: string;
  form: FormGroup;
  lookupVehicle: LookUpVehicle;
  nextPageUrlSuccess;
  mileageValue: number;
  regLookupEnabled: boolean = false;
  isTemporaryUser: boolean = false;
  priceValue: number;
  dealerPlusUnsuccess;
  dealerPlussuccess;
  constructor(private formBuilder: FormBuilder,
    private actionsService: ActionsService,
    private parentRouter: Router,
    private applyService: ApplyService,
    private applicationStatusService: ApplicationStatusService,
    private userService: UserService,
    private quotesService: QuotesService) {
      this.form = this.formBuilder.group({
        regNumber: ['', [Validators.required, Validators.maxLength(8)]],
        price: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        mileage: ['', Validators.required]
      })
    }

  ngOnInit() {
    this.isTemporaryUser = this.application.roles.indexOf('temporary user') > -1;
    this.applicationStatusService.setState(this.application, 'ENQUIRY_RECEIVED');
    this.dealerPlussuccess = 'applications/' + this.application.id + '/loan-offer';
    this.dealerPlusUnsuccess = 'applications/' + this.application.id + '/final-page';
    this.quoteVehicleItemObservable = this.actionsService.registerObserver(
      'quoteFilters.update',
      action => {
        this.annualMileage = action.value.annualMillage;
        this.vehicle.term = action.value.term;
        this.vehicle.deposit = action.value.deposit;
        this.application.quotes[0].financeRequest.financeType = action.value.financeType;
        this.application.quotes[0].financeRequest.annualMileage = action.value.annualMillage;
        this.application.quotes[0].financeRequest.flatRatePercent = action.value.flatRatePercent;
      },
      this
    );

    if (window.location.href.indexOf("dealerplus") != -1) {
      this.source = 'dealer';
      this.regLookupEnabled = true;

    }
    else if (window.location.href.indexOf("comparecarfinance") != -1) {
      this.source = 'ccf';
    }
    else {
      this.source = 'customer';
    }
    this.nextPageUrlSuccess = 'applications/' + this.application.id + '/extra-details';
  }

  ngOnDestroy() {
    this.actionsService.cancelSubscription(this.quoteVehicleItemObservable);
  }

  sanitizeResult() {
    var mileage = this.form.controls['mileage'].value;
    var price = this.form.controls['price'].value;
    this.mileageValue = Math.round(mileage.replace(",", ""));
    this.priceValue = price.replace(",", "");
  }

  search() {
    this.sanitizeResult();
    this.lookupVehicle = {
      regNumber: this.form.controls['regNumber'].value,
      loanTerm: this.term,
      loanDeposit: this.deposit,
      loanAmmount: this.priceValue,
      vehicleMileage: this.mileageValue,
      cardId: this.id,
      cardSuggested: false,
      IsShowroomPicked: false,
    }
    this.application.quotes[0].manualLookUpOccured = true;
    this.actionsService.broadcast(
      {
        action: 'searchVehicle',
        behaviour: 'regNumber',
        value: this.lookupVehicle
      }
    );
  }

  displayshowroom() {
    this.application.quotes[0].showroomVisitOccured = true;
    this.actionsService.broadcast(
      {
        action: 'showroom',
        behaviour: 'display',
        value: { displayShowroom: true, id: this.id }
      }
    );
    window.scroll(0, 0);
  }

  quoteSelected() {
    var quotes = this.application.quotes[0];
    quotes.financeResponse = this.vehicle.quote;
    quotes.financeRequest.term = this.term;
    quotes.financeResponse.term = this.term;
    quotes.financeRequest.deposit.cash = this.deposit;
    quotes.financeRequest.deposit.total = this.deposit;
    quotes.financeResponse.deposit = this.deposit;
    quotes.vehicle = this.vehicle.vehicle;
    quotes.isSelected = true;
    quotes.financeRequest.annualMileage = this.annualMileage;
    quotes.applicationId = this.application.id;
    quotes.vehicle.IsShowroomPicked = this.vehicle.IsShowroomPicked;
    quotes.showroomVisitOccured = this.application.quotes[0].showroomVisitOccured;
    quotes.showroomCarSelectionOccured = this.application.quotes[0].showroomCarSelectionOccured;
    quotes.manualLookUpOccured = this.application.quotes[0].manualLookUpOccured;

    const selectedQuote: Quote = this.application.quotes.find(quote => quote.isSelected);
    if (!selectedQuote) {
      throw Error('No quote was selected');
    }
    else {
      this.addQuoteToNotes(selectedQuote);
    }
  }

  async updateStatus() {
    this.application = await this.userService.updateApplication(this.application, 1).toPromise();
    this.parentRouter.navigateByUrl(this.nextPageUrlSuccess);
  }

  async saveQuotes() {
    this.application = await this.userService.updateApplication(this.application, 1).toPromise();
    this.apply();
  }

  async apply() {
    try {
      const selectedQuote: Quote = this.application.quotes.find(quote => quote.isSelected);
      //await this.quotesService.updateLendingCriteria(this.application.id).toPromise();
      /*var loanOffers = await this.applyService.applyForLoan(selectedQuote.id.toString()).toPromise();
      if (loanOffers == undefined) {
        this.parentRouter.navigateByUrl(this.dealerPlusUnsuccess);
      } else {
        this.application.loanProposalResponses = loanOffers;
        this.userService.updateUserCache(this.application);
      }*/
      if (this.application.loanProposalResponses.filter(offer => offer.status == 'APPLICATION_ACCEPTED').length == 0) {
        if (this.application.loanProposalResponses.filter(offer => offer.status == 'APPLICATION_REFERRED').length == 0) {
          this.parentRouter.navigateByUrl(this.dealerPlusUnsuccess);
        }
        else {
          this.parentRouter.navigateByUrl(this.dealerPlussuccess);
        }
      } else {
        this.parentRouter.navigateByUrl(this.dealerPlussuccess);
      }
    } catch (error) {
      if (error.status == 422) {
        throw {
          customError: {
            errorConfiguration: this.applicationStatusService.configuration.errors.offer_validation,
            error: error
          }
        };
      }
      throw {
        customError: {
          errorConfiguration: this.applicationStatusService.configuration.errors.offer_generic,
          error: error
        }
      };
    }
  }

  async addQuoteToNotes(selectedQuote) {
    await this.quotesService.saveNote(selectedQuote).toPromise();
    if (!this.isTemporaryUser) {
      this.saveQuotes();
    }
    else {
      this.updateStatus();
    }
  }

  changeLayout(bool: boolean) {
    this.regLookupEnabled = bool;
  }
}
