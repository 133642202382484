import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Subscription } from '../../../../../node_modules/rxjs';
import { ActionsService } from '../../../core/services/actions/actions.service';

@Component({
  selector: 'upload-examples',
  templateUrl: './upload-examples.component.html',
  styleUrls: ['./upload-examples.component.scss']
})
export class UploadExamplesComponent implements OnInit {
  @Input()
  proofId;
  helpObserver: Subscription;
  drivingLicenceFront: boolean = false;
  drivingLicenceback: boolean = false;
  proofOfAddress: boolean = false;
  proofOfEmployment: boolean = false;
  Passport: boolean = false;
  customerPack: boolean = false;
  lenderPack: boolean = false;
  selfEmployment: boolean = false;


  constructor(private actionsService: ActionsService) { }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.proofId == "drivingLicenseFront") {
      this.drivingLicenceFront = true
    }
    if (this.proofId == 'drivingLicenseback') {
      this.drivingLicenceback = true
    }
    if (this.proofId == 'address') {
      this.proofOfAddress = true
    }
    if (this.proofId == 'employment') {
      this.proofOfEmployment = true
    }
    if (this.proofId == 'Self-employed') {
      this.selfEmployment = true
    }
    if (this.proofId == 'passport') {
      this.Passport = true
    }
    if (this.proofId == 'CUSTOMERPACK') {
      this.customerPack = true
    }
    if (this.proofId == 'LENDERPACK') {
      this.lenderPack = true
    }
    if (this.proofId == 'selfEmployment') {
      this.selfEmployment = true
    }

  }
  closeToggle() {
    this.actionsService.broadcast(
      {
        action: 'close',
        behaviour: 'toggle',
        value: null
      }
    );
  }
}
