<div class="container">
    <div class="card card-full-width" [ngClass]="(enabled)?'':'disabled'">
      <div class="card-header">
        <h1 (click)="toggle()">
          <span class="section-title">{{title}}</span>
          <span class="pull-right" aria-hidden="true" [ngClass]="(closed)?'icon-down':'icon-up'"></span>
        </h1>
      </div>
      <div class="card-block" [collapse]="closed">
        <loading *ngIf="isLoading" [loading]="isLoading"></loading>
        <ng-content *ngIf="isLoading === false"></ng-content>
      </div>
    </div>
  </div>
