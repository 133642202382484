import { Pipe, PipeTransform } from '@angular/core';
import { Applicant } from "../../../core/services/types";

@Pipe({
  name: 'fullname',
  pure: false
})
export class FullnamePipe implements PipeTransform {

  transform(value: Applicant, args?: any): any {
    if(value == null || value.identity == null || Object.keys(value.identity).length === 0){
      return "";
    }

    let identity = value.identity;

    switch (args) {
      case 'long':
        return identity.title + ' ' + identity.forename + ' ' + identity.surname;

      case 'short':
        return identity.title + ' ' + identity.surname;

      default:
        return identity.title + ' ' + identity.forename + ' ' + identity.surname;
    }
  }

}
