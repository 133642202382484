import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '../../../core/services/types';
import { TimelineService } from '../../../core/services/timeline/timeline.service'
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {
  @Input()
  application: Application;
  homeUrl = "../../../../assets/navbar/home-active.png";
  // docsUrl = "../../../../assets/navbar/documents-active.png";
  // vehicleUrl = "../../../../assets/navbar/vehicles.png";
  accountUrl = "../../../../assets/navbar/account.png";
  formattedStateNames: Array<string> = new Array();
  formattedStateDates: Array<string> = new Array();
  formattedStateTimes: Array<string> = new Array();
  arrayContainer:Array<Array<string>> = new Array();
  lastStateName: string;
  lastStateDate: string;
  lastStateTime: string;
  lastStateDateTime: string;
  constructor(private parentRouter:Router, private timelineService: TimelineService, protected titleService: Title) { }

  ngOnInit() {    
    this.titleService.setTitle("Documents");
  }

  ngAfterViewInit(){
    if(this.application.dealActionHistory.length == 0){
      this.formattedStateDates.push("Enquiry received!")
      this.formattedStateNames.push("Enquiry received!")
    } else {
      this.arrayContainer = this.timelineService.getDealStates(this.application.dealActionHistory);
      this.formattedStateNames = this.arrayContainer[1];
      this.formattedStateDates = this.arrayContainer[2];
      this.formattedStateTimes = this.arrayContainer[3];
      this.lastStateName = this.formattedStateNames.pop();
      this.lastStateDate = this.formattedStateDates.pop();
      this.lastStateTime = this.formattedStateTimes.pop();
      this.lastStateDateTime = this.lastStateDate + " " + this.lastStateTime;

    }
  }
}
