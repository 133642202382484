import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-error-messages',
  templateUrl: 'form-error-messages.component.html',
  styleUrls: ['./form-error-messages.component.scss']
})

export class FormErrorMessagesComponent {

  @Input()
  formErrors: any;

  @Input()
  form: FormGroup;

  constructor() {

  }

}
