import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionsService } from '../../../core/services/actions/actions.service';

@Component({
  moduleId: module.id,
  selector: 'application-footer',
  templateUrl: 'application-footer.component.html',
  styleUrls: ['application-footer.component.scss']
})
export class ApplicationFooterComponent implements OnInit {

  routerOutletSubscription: Subscription;

  cssClass:string;

  constructor(private actionsService: ActionsService) { }
  
  
  ngOnInit() {

    this.routerOutletSubscription=this.actionsService.registerObserver(
      'header.changeCSSClass',
      action => {

          this.cssClass=action.value;
      },
      this
    );

  }

  ngOnDestroy() {

    this.actionsService.cancelSubscription(this.routerOutletSubscription);

  }
}
