import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Duration } from "moment";

@Injectable()
export class DateService {
  computeGap(first:string, second:string) : Duration{
    let firstDate: Date = new Date(first);
    let secondDate: Date = new Date(second);

    return moment.duration(secondDate.valueOf() - firstDate.valueOf());
  }
  
  getTodayDate(): string {
    // http://stackoverflow.com/questions/6040515/how-do-i-get-month-and-date-of-javascript-in-2-digit-format
    let today = new Date();
    let todayFormatted = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

    return todayFormatted;
  }
}
