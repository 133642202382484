import { environment } from './../../../../environments/environment';
import { Component, Input } from '@angular/core';
import { LookUpVehicle,FinanceRequest } from '../../../core/services/types-quote';
import { VehicleService } from '../../../core/services/vehicle/vehicle.service';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { QuotesService } from '../../../core/services/quotes/quotes.service';
import { Application } from '../../../core/services/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'quote-vehicle',
  templateUrl: 'quote-vehicle.component.html',
  styleUrls: ['quote-vehicle.component.scss']
})
export class QuoteVehicleComponent {

  @Input()
  application: Application;

  totalVehicleCards: number;
  vehicleList: any = [];
  vehicleNotFound: boolean;
  unableToValueVehicle: boolean;
  vehicleAgeAtStartOfLoanIsValid: boolean = true;
  term: number;
  deposit: number;
  monthlyPayment: number = 0;
  financeType: string;
  flatRate?: number;
  annualMillage: number;
  slideIndex = 1;
  quoteVehicleObservable: Subscription[];
  lookupVehicle: LookUpVehicle;
  apiErrorMessages: any[] = [];
  selectedFilterValues: any = {
    selectedMake: null,
    selectedModel: null,
    selectedRegistrationYear: null,
    selectedMinimumPrice: null,
    selectedMaximumPrice: null,
  }
  suggestedVehicle: any[];
  numberOfSuggestedSearch: number = 0;
  source: string;

  constructor(
    private vehicleService: VehicleService,
    private quoteService: QuotesService,
    private actionsService: ActionsService
  ) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (window.location.href.indexOf("dealerplus") != -1) {
      this.source = 'dealer';
    }
    this.totalVehicleCards = 3;
    this.initializeCards();
 

    this.quoteVehicleObservable = this.actionsService.registerObservers(
      ['searchVehicle.regNumber', 'quoteFilters.update', 'showroom.selectedVehicle'],
      action => {
        if (action.action == 'searchVehicle') {
          
          this.searchVehicle(action.value)
        }
        if (action.action == 'quoteFilters') {
          this.deposit = action.value.deposit;
          this.term = action.value.term;
          this.financeType = action.value.financeType;
          this.annualMillage = action.value.annualMillage;
          this.flatRate = action.value.flatRatePercent;
          this.updatingAllCardsQuotes();
        }
        if (action.action == 'showroom') {
          this.application.quotes[0].showroomCarSelectionOccured = true;
          this.numberOfSuggestedSearch = 1;
          if (environment.production || environment.demo) {
            var lookupvehicle: LookUpVehicle = {
              cardId: action.value.id,
              cardSuggested: false,
              loanAmmount: action.value.vehicle.price.toString(),
              loanDeposit: this.deposit,
              loanTerm: this.term,
              regNumber: action.value.vehicle.registration,
              vehicleMileage: action.value.vehicle.mileage.toString(),
              IsShowroomPicked: true
              
            }
          } else {
            var lookupvehicle: LookUpVehicle = {
              cardId: action.value.id,
              cardSuggested: false,
              loanAmmount: action.value.vehicle.price.toString(),
              loanDeposit: this.deposit,
              loanTerm: this.term,
              regNumber: action.value.vehicle.registration,
              vehicleMileage: action.value.vehicle.mileage.toString(),
              IsShowroomPicked: true
            }
          }
          this.actionsService.broadcast(
            {
              action: 'showroom',
              behaviour: 'display',
              value: { displayShowroom: false, id: action.value.id }
            }
          );
          this.searchVehicle(lookupvehicle);
        }
      },
      this
    );
  }

  ngOnDestroy() {
    this.actionsService.cancelSubscriptions(this.quoteVehicleObservable);
  }

  async updatingAllCardsQuotes() {
    for (var i = 0; i < 3; i++) {
      if (this.vehicleList[i].valid && this.vehicleList[i].open) {
        await this.updateQuotes(this.vehicleList[i]);
      }
    }
  }


  initializeCards() {
    for (var i = 0; i < this.totalVehicleCards; i++) {
      if (i == 0) {
        if (this.application.quotes[0].vehicle.registration != null && !this.application.quotes[0].vehicle.mockcar) {
          var price;
          if (this.application.quotes[0].vehicle.fullPrice == 0 || null) {
            price = this.application.quotes[0].vehicle.price
          }
          else {
            price = this.application.quotes[0].vehicle.fullPrice;
          }
          this.deposit = this.application.quotes[0].financeResponse.deposit;
          this.vehicleList.push({ valid: false, open: true })
          this.lookupVehicle = {
            regNumber: this.application.quotes[0].vehicle.registration,
            loanTerm: 48,
            loanDeposit: this.deposit,
            loanAmmount: price,
            vehicleMileage: this.application.quotes[0].vehicle.mileage,
            cardId: 0,
            cardSuggested: false,
            IsShowroomPicked: this.application.quotes[0].vehicle.IsShowroomPicked
          }
          this.searchVehicle(this.lookupVehicle);
        }
        else {
          this.vehicleList.push({ valid: false, open: true })
        }
      }
      else {
        this.vehicleList.push({ valid: false, open: false })
      }
    }
  }

  addVehicleCard(vehicle: any, lookUpVehicle: LookUpVehicle, id: number, response: any, cardSuggested: boolean) {
    let newVehicle = {
      valid: true,
      open: true,
      cardSuggested: cardSuggested,
      id: id,
      vehicle: vehicle,
      year: vehicle.dateOfRegistration.split("-")[0],
      loanAmountRequest: (lookUpVehicle.loanAmmount - lookUpVehicle.loanDeposit),
      askingPrice: lookUpVehicle.loanAmmount,
      monthlyPayment: response.financeResponse.monthlyPayment.toFixed(2),
      term: this.term,
      deposit: this.deposit,
      quote: response.financeResponse,
      IsShowroomPicked: lookUpVehicle.IsShowroomPicked
    }
    this.vehicleList.splice(lookUpVehicle.cardId, 1, newVehicle);
  }

  updateVehicleCard(id: number) {
    if (this.vehicleList[id].valid) {
      this.vehicleList.splice(id, 1, { valid: false, open: true });
    } else {
      if (this.vehicleList[id].open) {
        this.vehicleList.splice(id, 1, { valid: false, open: false });
      } else {
        this.vehicleList.splice(id, 1, { valid: false, open: true });
      }
    }
  }

  async getSuggestedVehicles(vehicle, lookUpVehicle) {
    this.selectedFilterValues.selectedMake = vehicle.make;
    this.selectedFilterValues.selectedMinimumPrice = vehicle.price - 2000,
    this.selectedFilterValues.selectedMaximumPrice = vehicle.price + 2000,
    this.selectedFilterValues.selectedRegistrationYear = new Date().getFullYear() - 2;

    //this.selectedFilterValues.selectedModel = vehicle.model;
    await this.vehicleService.getShowroomVehicles(this.selectedFilterValues).subscribe(
      response => {
        this.suggestedVehicle = response.vehicles;
        this.suggestedVehicle = this.suggestedVehicle.sort(function () { return .5 - Math.random() }) // Shuffle array
          .slice(0, 2);
        var card: any = [
          0,
          1,
          2
        ]
        var originalCard = lookUpVehicle.cardId;
        const index = card.indexOf(originalCard, 0);
        if (index > -1) {
          card.splice(index, 1);
        }
        for (var i = 0; i < this.suggestedVehicle.length; i++) {
          this.lookupVehicle = {
            regNumber: this.suggestedVehicle[i].registration,
            loanTerm: this.term,
            loanDeposit: this.deposit,
            loanAmmount: this.suggestedVehicle[i].price,
            vehicleMileage: this.suggestedVehicle[i].mileage,
            cardId: card[i],
            cardSuggested: true,
            IsShowroomPicked : true
          }
          this.searchVehicle(this.lookupVehicle)
        }
      },
      error => {

      }
    );
  }

  async searchVehicle(lookUpVehicle: LookUpVehicle): Promise<void> {
    this.vehicleNotFound = false;
    this.unableToValueVehicle = false;
    try {
      let vehicle = await this.vehicleService.vehicleLookup({ applicationId: this.application.id, registration: lookUpVehicle.regNumber, mileage: lookUpVehicle.vehicleMileage, price: lookUpVehicle.loanAmmount, showroom: false, isSuggested: lookUpVehicle.cardSuggested }).toPromise();
      if (vehicle != null) {
        this.getQuote(vehicle, lookUpVehicle);
        if (this.source != "dealer" && !vehicle.mockcar) {
          if (this.numberOfSuggestedSearch == 0) {
            this.getSuggestedVehicles(vehicle, lookUpVehicle);
            this.numberOfSuggestedSearch = 1
          }
        }
      }
    }
    catch (error) {
      error = error.json();
      this.vehicleList[lookUpVehicle.cardId].errorList = error.filter(x => x.code != "020105");
    }
  }

  async getQuote(vehicle: any, lookUpVehicle: LookUpVehicle) {
    var quoteFinanceRequest: FinanceRequest = {
      loanAmount: lookUpVehicle.loanAmmount - this.deposit,
      term: lookUpVehicle.loanTerm,
      deposit: {
        cash: this.deposit,
        total: this.deposit,
      },
      financeType: this.financeType,
      annualMileage: this.annualMillage,
      flatRatePercent: this.flatRate,
      commissionLenderTiers: this.application.quotes[0].financeRequest.commissionLenderTiers
    }
    vehicle.price = (Number(lookUpVehicle.loanAmmount));
    vehicle.IsShowroomPicked = lookUpVehicle.IsShowroomPicked;
    this.quoteService.getQuotes(this.application.id.toString(), quoteFinanceRequest, vehicle).subscribe(
      response => {
        this.addVehicleCard(vehicle, lookUpVehicle, lookUpVehicle.cardId, response, lookUpVehicle.cardSuggested);
        if (response.financeResponse.dealerCommissionGuide != null) {
          {
            this.actionsService.broadcast(
              {
                action: 'comissionValue',
                behaviour: 'change',
                value: response.financeResponse.dealerCommissionGuide != null ? response.financeResponse.dealerCommissionGuide.mean : 0
              }
            );
          }
        }
      },
      error => {
        error = error.json();
        this.vehicleList[lookUpVehicle.cardId].errorList = error.filter(x => x.code != "020105");
      }
    );
  }

  async updateQuotes(tempVehicle) {
    var quoteFinanceRequest: FinanceRequest = {
      loanAmount: tempVehicle.askingPrice - this.deposit,
      term: this.term,
      deposit: {
        cash: this.deposit,
        total: this.deposit,
      },
      financeType: this.financeType,
      annualMileage: this.annualMillage,
      flatRatePercent: this.flatRate,
      commissionLenderTiers: this.application.quotes[0].financeRequest.commissionLenderTiers
    }
    tempVehicle.vehicle.price = (Number(tempVehicle.loanAmountRequest));
    this.quoteService.getQuotes(this.application.id.toString(), quoteFinanceRequest, tempVehicle.vehicle).subscribe(
      response => {
        this.vehicleList[tempVehicle.id].errorList = [];
        this.vehicleList[tempVehicle.id].monthlyPayment = response.financeResponse.monthlyPayment.toFixed(2)
        this.vehicleList[tempVehicle.id].financeType = response.financeResponse.financeType;
        this.vehicleList[tempVehicle.id].balloon = response.financeResponse.balloon;
        this.vehicleList[tempVehicle.id].quote = response.financeResponse;
        this.vehicleList[tempVehicle.id].flatRate = response.flatRate;
        this.vehicleList[tempVehicle.id].IsShowroomPicked = tempVehicle.IsShowroomPicked;
        this.actionsService.broadcast(
          {
            action: 'comissionValue',
            behaviour: 'change',
            value: response.financeResponse.dealerCommissionGuide != null ? response.financeResponse.dealerCommissionGuide.mean : 0
          }
        );
      },
      error => {
        error = error.json();
        this.vehicleList[tempVehicle.id].errorList = error.filter(x => x.code != "020105");
        
      }
    );
  }
  
  currentSlides(n, string) {
    if (this.slideIndex == 3) {
      this.slideIndex = this.slideIndex - 1;
      this.showSlides(n, string);

    }
    else {
      this.showSlides(this.slideIndex += n, string);
    }
  }
  showSlides(n, string) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    if (slides.length == n && this.slideIndex) {
      if (string == 'left') {
        for (i = 0; i < dots.length; i--) {
          this.slideIndex -= i;
          n -= i;
          dots[i].className = dots[i].className.replace("active", "");
        }
      }
      else {
        //do nothing
      }
    }
    for (i = 0; i < slides.length; i++) {
      if (string == "right") {
        switch (this.slideIndex) {
          case 2: {
            document.getElementById("section").scrollBy(100, 0);
          }
            break;
          case 3: {
            document.getElementById("section").scrollBy(500, 0);
          }
            break;
        }
        document.getElementsByClassName("active")[0].setAttribute("color", "none");
      }
      if (string == "left") {
        switch (this.slideIndex) {
          case 2: {
            document.getElementById("section").scrollBy(-100, 0);
          }
            break;
          case 1: {
            document.getElementById("section").scrollBy(-500, 0);
          }
            break;
        }
      }
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace("active", "");
    }
    dots[this.slideIndex - 1].className += " active";
  }

} 