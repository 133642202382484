import { JourneyPage } from "../types";

import { WORKFLOWS } from "./configuration-workflows";
import { SECTIONS } from "./configuration-sections";
import { PAGES } from "./configuration-pages";

const CONSUMER_JOURNEY: JourneyPage[] = [
    {
        label: 'Quotes',
        name: PAGES.QUOTES.label,
        route: PAGES.QUOTES.route,
        active: true,
        enabled: true,
        completed: false,
        order: '3',
        content: {
            editing: '<p>Now it’s time for the exciting part of your journey! Tell us the car you want and then what loan you are looking for….</p>'
        },
        activePageNextStep: {
            label: 'Proceed using this quote',
            displayProceedButton: false,
            setStatus: 'DETAILS_STARTED'
        },
        sections: [SECTIONS.GET_QUOTES],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Dashboard',
        name: PAGES.DASHBOARD.label,
        route: PAGES.DASHBOARD.route,
        active: true,
        enabled: true,
        completed: false,
        order: '12',
        activePageNextStep: {
        },
        sections: [SECTIONS.DASHBOARD],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Proofs',
        name: PAGES.PROOFS.label,
        route: PAGES.PROOFS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '2',
        activePageNextStep: {
        },
        sections: [SECTIONS.PROOFS],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'ProofVerify',
        name: PAGES.PROOFVERIFY.label,
        route: PAGES.PROOFVERIFY.route,
        active: true,
        enabled: true,
        completed: false,
        order: '2',
        activePageNextStep: {
        },
        sections: [SECTIONS.PROOF_VERIFY],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'FinanceDocuments',
        name: PAGES.FINANCEDOCUMENTS.label,
        route: PAGES.FINANCEDOCUMENTS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '9',
        activePageNextStep: {
        },
        sections: [SECTIONS.FINANCE_DOCUMENTS],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Settings',
        name: PAGES.SETTINGS.label,
        route: PAGES.SETTINGS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '10',
        activePageNextStep: {
        },
        sections: [SECTIONS.SETTINGS],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Help',
        name: PAGES.HELP.label,
        route: PAGES.HELP.route,
        active: true,
        enabled: true,
        completed: false,
        order: '11',
        activePageNextStep: {
        },
        sections: [SECTIONS.HELP],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Marketing',
        name: PAGES.MARKETING.label,
        route: PAGES.MARKETING.route,
        active: true,
        enabled: true,
        completed: false,
        order: '1',
        activePageNextStep: {
        },
        sections: [SECTIONS.MARKETING],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Timeline',
        name: PAGES.TIMELINE.label,
        route: PAGES.TIMELINE.route,
        active: true,
        enabled: true,
        completed: false,
        order: '1',
        activePageNextStep: {
        },
        sections: [SECTIONS.TIMELINE],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Docs',
        name: PAGES.DOCS.label,
        route: PAGES.DOCS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '13',
        activePageNextStep: {
        },
        sections: [SECTIONS.DOCS],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Vehicles',
        name: PAGES.VEHICLES.label,
        route: PAGES.VEHICLES.route,
        active: true,
        enabled: true,
        completed: false,
        order: '14',
        activePageNextStep: {
        },
        sections: [SECTIONS.VEHICLES],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Extra Details',
        name: PAGES.EXTRADETAILS.label,
        route: PAGES.EXTRADETAILS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '4',
        activePageNextStep: {
        },
        sections: [SECTIONS.EXTRADETAILS],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Final page',
        name: PAGES.FINAL.label,
        route: PAGES.FINAL.route,
        active: true,
        enabled: true,
        completed: false,
        order: '8',
        content: {
            editing: '<p>To get you started on your way to getting a new car we need to get some personal details from yourself so that we know who you are! The more information you provide, the better the loan offer!</p>',
            readOnly: '<p>If you have made a slight error entering your details then please contact us on {{telephone}} to make any changes, this is to prevent fraudulent activity.</p>'
        },
        activePageNextStep: {
            label: 'Get me a loan offer',
            displayProceedButton: true,
            setStatus: 'APPLICATION_PENDING'
        },
        sections: [SECTIONS.FINAL_PAGE],
        sectionValidityObservers: [],
        workflows: [
            WORKFLOWS.ENQUIRY_RECEIVED,
            WORKFLOWS.PROVISIONALLY_ACCEPTED,
            WORKFLOWS.QUOTES_STARTED,
            WORKFLOWS.DETAILS_STARTED,
            WORKFLOWS.DOCS_TO_BE_ISSUED,
            WORKFLOWS.DOCS_ISSUED,
            WORKFLOWS.DOCS_RECEIVED,
            WORKFLOWS.DOCS_WITH_LENDER,
            WORKFLOWS.AWAITING_PROOFS,
            WORKFLOWS.PROOFS_RECEIVED,
            WORKFLOWS.PAID_OUT,
            WORKFLOWS.CLIENT_CANCELLED,
            WORKFLOWS.ABANDONED,
            WORKFLOWS.CANNOT_LEND,
            WORKFLOWS.Further_Info_Required,
            WORKFLOWS.APPLICATION_PENDING,
            WORKFLOWS.APPLICATION_SENT,
            WORKFLOWS.APPLICATION_DECLINED,
            WORKFLOWS.APPLICATION_REFERRED,
            WORKFLOWS.APPLICATION_ACCEPTED,
            WORKFLOWS.APPLICATION_ERROR,
            WORKFLOWS.APPLICATION_MANUAL,
            WORKFLOWS.Further_Info_Required
        ],
        states: []
    },
    {
        label: 'Loan offer',
        name: PAGES.CONFIRMATION.label,
        route: PAGES.CONFIRMATION.route,
        active: true,
        enabled: true,
        completed: false,
        order: '5',
        content: {
            editing: '<p>That’s a great choice of car! You’re nearly there! The final part of your application is below, once this is completed simply select SUBMIT MY APPLICATION to secure your finance deal.</p>'
        },
        activePageNextStep: {
            label: 'Accept this offer',
            displayProceedButton: false,
            setStatus: 'DOCS_TO_BE_ISSUED'
        },
        sections: [SECTIONS.ACCEPTANCE_STATUS],
        sectionValidityObservers: [
            {
                id: 'getLoanOffer',
                sections: [SECTIONS.BANK_DETAILS, SECTIONS.DEALER],
            }
        ],
        workflows: [
        ],
        states: [
            {
                workflows: [
                    WORKFLOWS.ENQUIRY_RECEIVED,
                    WORKFLOWS.PROVISIONALLY_ACCEPTED,
                    WORKFLOWS.QUOTES_STARTED,
                    WORKFLOWS.DETAILS_STARTED,
                    WORKFLOWS.DOCS_TO_BE_ISSUED,
                    WORKFLOWS.DOCS_ISSUED,
                    WORKFLOWS.DOCS_RECEIVED,
                    WORKFLOWS.DOCS_WITH_LENDER,
                    WORKFLOWS.AWAITING_PROOFS,
                    WORKFLOWS.PROOFS_RECEIVED,
                    WORKFLOWS.PAID_OUT,
                    WORKFLOWS.CLIENT_CANCELLED,
                    WORKFLOWS.ABANDONED,
                    WORKFLOWS.CANNOT_LEND,
                    WORKFLOWS.APPLICATION_PENDING,
                    WORKFLOWS.APPLICATION_SENT,
                    WORKFLOWS.APPLICATION_DECLINED,
                    WORKFLOWS.APPLICATION_REFERRED,
                    WORKFLOWS.APPLICATION_ACCEPTED,
                    WORKFLOWS.APPLICATION_ERROR,
                    WORKFLOWS.APPLICATION_MANUAL,
                    WORKFLOWS.Further_Info_Required
                ],
                sectionStates: [
                    {
                        section: SECTIONS.BANK_DETAILS,
                        state: 'SECTION_VISIBLE_AND_READONLY'
                    },
                    {
                        section: SECTIONS.DEALER,
                        state: 'SECTION_VISIBLE_AND_READONLY'
                    }
                ]
            },
            {
                workflows: [
                    WORKFLOWS.ENQUIRY_RECEIVED,
                    WORKFLOWS.PROVISIONALLY_ACCEPTED,
                    WORKFLOWS.QUOTES_STARTED,
                    WORKFLOWS.DETAILS_STARTED,
                    WORKFLOWS.DOCS_TO_BE_ISSUED,
                    WORKFLOWS.DOCS_ISSUED,
                    WORKFLOWS.DOCS_RECEIVED,
                    WORKFLOWS.DOCS_WITH_LENDER,
                    WORKFLOWS.AWAITING_PROOFS,
                    WORKFLOWS.PROOFS_RECEIVED,
                    WORKFLOWS.PAID_OUT,
                    WORKFLOWS.CLIENT_CANCELLED,
                    WORKFLOWS.ABANDONED,
                    WORKFLOWS.CANNOT_LEND,
                    WORKFLOWS.APPLICATION_PENDING,
                    WORKFLOWS.APPLICATION_SENT,
                    WORKFLOWS.APPLICATION_DECLINED,
                    WORKFLOWS.APPLICATION_REFERRED,
                    WORKFLOWS.APPLICATION_ACCEPTED,
                    WORKFLOWS.APPLICATION_ERROR,
                    WORKFLOWS.APPLICATION_MANUAL,
                    WORKFLOWS.Further_Info_Required
                ],
                pageDefaults: 'PAGE_ACTIVE_AND_READONLY',
                sectionStates: []
            }
        ]
    },

    {
        label: 'Agreements & Documentation',
        name: PAGES.AGREEMENT.label,
        route: PAGES.AGREEMENT.route,
        active: true,
        enabled: true,
        completed: false,
        order: '6',
        content: {
            editing: '<p>Congratulations! You’ve been accepted! The final part of your journey awaits! In order to secure your finance deal we need to get your proofs approved and tell you about our useful products designed to help you when you’re on the road!</p>'
        },
        sections: [SECTIONS.DOCUMENTS],
        workflows: [],
        states: []
    },
    {
        label: 'Terms & Acceptance',
        name: PAGES.TERMS.label,
        route: PAGES.TERMS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '7',
        content: {
            editing: '<p>To get you started on your way to getting a new car we need to get some personal details from yourself so that we know who you are! The more information you provide, the better the loan offer!</p>',
        },
        sections: [SECTIONS.TERMS],
        workflows: [],
        states: []
    }
];

export {
    CONSUMER_JOURNEY
}
