import { Component, Input } from '@angular/core';

import { ButtonGroupComponent }  from './../button-group/button-group.component';
import { ButtonGroup } from './../../models.class';
import { ActionsService } from './../../../../core/services/actions/actions.service';

const GROUP='SORTDIRECTION';

@Component({
  selector: 'sort-direction',
  templateUrl: 'sort-direction.component.html',
  styleUrls: ['sort-direction.component.scss']
})
export class SortDirectionComponent extends ButtonGroupComponent {

    @Input()
    model :ButtonGroup = new ButtonGroup(
        {
            group:GROUP,
            type:'icons',
            btnclass:'btn-primary',
            navbar:true,
            selection:'single',
            visible:true,
            items:[
                {icon:'fa-sort-alpha-asc', action:'ascending', disabled:false, active:true, visible:true},
                {icon:'fa-sort-alpha-desc', action:'descending', disabled:false, active:false, visible:true}
            ]
        }
    );

    constructor(public actionsService :ActionsService) {

        super(actionsService);

        this.registerAcceptsSetValue(GROUP);

    }

}
