
import {from as observableFrom,  Observable ,  Subscription } from 'rxjs';

import {delay} from 'rxjs/operators';

import { Component, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalModalService } from '../../services/modal/global-modal.service';

var TRANSITION_DURATION = 600;

@Component({
  selector: 'global-modal',
  templateUrl: 'global-modal.component.html',
  styleUrls: ['./global-modal.component.scss'],
  exportAs: 'bs-modal'
})
export class GlobalModalComponent implements OnDestroy {
  @ViewChild('modal')
  modal: ModalDirective;

  model: any;
  subscription: Subscription;
  promise: Promise<boolean>;
  isVisible: boolean;
  collapsedContent: boolean = true;
  
  resolver: any;
  currentPromise;

  constructor(public globalModalService: GlobalModalService, public changeDetectorRef: ChangeDetectorRef) {

    // Subscribe to showAnnounced in the Global Modal service and show the Modal when it is broadcast
    this.subscription = this.globalModalService.showAnnounced$.subscribe(
      model => {
        this.show(model);
      }
    );

    this.model = {
      title: 'Save',
      content: 'Do you want to save the application?',
      yes: 'Yes',
      no: 'No',
      maybe: 'Stay',
      config: {
        ignoreBackdropClick: true,
        keyboard: false
      }
    };

    this.isVisible = false;
  }

  public show(model: any): Promise<boolean> {
    if (this.currentPromise) {
      observableFrom(this.currentPromise).pipe(delay(TRANSITION_DURATION)).subscribe(() => this.triggerPopup(model));
    }
    else {
      this.currentPromise = this.triggerPopup(model);
    }

    return this.currentPromise;

  }

  triggerPopup(model: any): Promise<boolean> {
    this.model = model;

    if (this.currentPromise) {
      this.currentPromise = null;
    }

    var result: Promise<boolean> = new Promise(
      (resolve, reject) => {
        this.changeDetectorRef.detectChanges();

        this.modal.show();
        this.isVisible = true;

        this.resolver = resolve;

      }
    );

    return result;
  }

  collapsedContentToggle() {

    this.collapsedContent = !this.collapsedContent;

  }

  public hide(): void {
    this.modal.hide();
    this.isVisible = false;
  }

  public confirm(): void {
    this.resolver(true);
    this.hide();
    this.globalModalService.response(true);

  }

  public maybe(): void {
    this.resolver(null);
    this.hide();
    this.globalModalService.response(null);
  }

  public reject(): void {
    this.resolver(false);
    this.hide();
    this.globalModalService.response(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
