<ng-container *ngIf="model.visible">
  <div *ngIf="model.type=='buttongroup'" class="btn-group" role="group" aria-label="...">
    <ng-container *ngFor="let item of model.items">
      <button *ngIf="item.visible" button type="button" class="btn {{model.btnclass}}" [class.navbar-btn]="model.navbar" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
        {{item.text}}
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="model.type=='buttons'">
    <ng-container *ngFor="let item of model.items">
      <button *ngIf="item.visible" button type="button" class="btn {{model.btnclass}}" [class.navbar-btn]="model.navbar" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
        {{item.text}}
        <ng-container *ngIf="item.candelete">
          &nbsp;&nbsp;
          <i (click)="delete(item)" class="fa fa-times" aria-hidden="true"></i>
        </ng-container>
      </button>
    </ng-container>
  </ng-container>
  <div *ngIf="model.type=='icons'" class="btn-group" role="group" aria-label="...">
    <ng-container *ngFor="let item of model.items">
      <button *ngIf="item.visible" button type="button" class="btn {{model.btnclass}}" [class.navbar-btn]="model.navbar" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
        <i class="fa {{item.icon}}" aria-hidden="true"></i>
      </button>
    </ng-container>
  </div>
  <div *ngIf="model.type=='dropdown'" class="btn-group">
    <button type="button" class="btn dropdown-toggle {{model.btnclass}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Users
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu">
      <ng-container *ngFor="let item of model.items">
        <li *ngIf="item.visible" [class.active]="item.active" [class.disabled]="item.disabled">
          <a (click)="action(item)">{{item.text}}</a>
        </li>
      </ng-container>
    </ul>
  </div>
  <ul *ngIf="model.type=='iconsnavbar'" class="nav navbar-nav">
    <ng-container *ngFor="let item of model.items">
      <li *ngIf="item.visible" [class.active]="item.active" [class.disabled]="item.disabled">
        <a (click)="action(item)">
          <i class="fa {{item.icon}}" aria-hidden="true"></i>
        </a>
      </li>
    </ng-container>
  </ul>
  <div *ngIf="model.type=='listgroup'" class="list-group" role="group" aria-label="...">
    <ng-container *ngFor="let item of model.items">
      <button *ngIf="item.visible" type="button" class="list-group-item {{model.btnclass}}" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
        {{item.text}}
        <ng-container *ngIf="item.candelete">
          <i (click)="delete(item)" class="fa fa-times" style="float:right;" aria-hidden="true"></i>
        </ng-container>
      </button>
    </ng-container>
  </div>
</ng-container>



<!-- <ng-container *ngIf="model.visible">
  <div [ngSwitch]="model.type">
    <ng-container *ngSwitchCase="buttons">
      <ng-container *ngFor="let item of model.items">
        <button *ngIf="item.visible" button type="button" class="btn {{model.btnclass}}" [class.navbar-btn]="model.navbar" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
          {{item.text}}
          <ng-container *ngIf="item.candelete">
            &nbsp;&nbsp;
            <i (click)="delete(item)" class="fa fa-times" aria-hidden="true"></i>
          </ng-container>
        </button>
      </ng-container>
    </ng-container>
    <template *ngSwitchCase="icons">
      <div class="btn-group" role="group" aria-label="...">
        <ng-container *ngFor="let item of model.items">
          <button *ngIf="item.visible" button type="button" class="btn {{model.btnclass}}" [class.navbar-btn]="model.navbar" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
            <i class="fa {{item.icon}}" aria-hidden="true"></i>
          </button>
        </ng-container>
      </div>
    </template>
    <template *ngSwitchCase="dropdown">
      <div class="btn-group">
        <button type="button" class="btn dropdown-toggle {{model.btnclass}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Users
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <ng-container *ngFor="let item of model.items">
            <li *ngIf="item.visible" [class.active]="item.active" [class.disabled]="item.disabled">
              <a (click)="action(item)">{{item.text}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </template>
    <template *ngSwitchCase="iconsnavbar">
      <ul class="nav navbar-nav">
        <ng-container *ngFor="let item of model.items">
          <li *ngIf="item.visible" [class.active]="item.active" [class.disabled]="item.disabled">
            <a (click)="action(item)">
              <i class="fa {{item.icon}}" aria-hidden="true"></i>
            </a>
          </li>
        </ng-container>
      </ul>
    </template>
    <template *ngSwitchCase="listgroup">
      <div class="list-group" role="group" aria-label="...">
        <ng-container *ngFor="let item of model.items">
          <button *ngIf="item.visible" type="button" class="list-group-item {{model.btnclass}}" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
            {{item.text}}
            <ng-container *ngIf="item.candelete">
              <i (click)="delete(item)" class="fa fa-times" style="float:right;" aria-hidden="true"></i>
            </ng-container>
          </button>
        </ng-container>
      </div>
    </template>
    <template *ngSwitchDefault>
      <div class="btn-group" role="group" aria-label="...">
        <ng-container *ngFor="let item of model.items">
          <button *ngIf="item.visible" button type="button" class="btn {{model.btnclass}}" [class.navbar-btn]="model.navbar" [class.active]="item.active" (click)="action(item)" [disabled]="item.disabled">
            {{item.text}}
          </button>
        </ng-container>
      </div>
    </template>
  </div>
</ng-container> -->
