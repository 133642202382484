import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FileUploader, FileItem } from "ng2-file-upload";
import { GlobalModalService } from './../../../core/services/modal/global-modal.service';
import { Observable } from 'rxjs';

import { Proof } from "../../../core/services/types";


@Component({
  selector: 'dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit {

  @Input()
  dropZone: any;

  @Input()
  availableProof: Proof[] = [];

  @Output()
  public removeProofFromAvailableProofs: EventEmitter<Proof> = new EventEmitter<Proof>();

  @Input()
  isDragAndDrop: boolean = true;

  @Output()
  itemUploaded: EventEmitter<FileItem> = new EventEmitter<FileItem>();

  isFileOver: boolean = false;

  immediateDragDropUpload: boolean;
  immediateFileSelectUpload: boolean;
  maximumFileSize: number;

  dropZoneinitialised: boolean = false;

  constructor(public globalModalService: GlobalModalService, private applicationStatusService : ApplicationStatusService) {
    this.immediateDragDropUpload = this.applicationStatusService.configuration.proofs.immediateDragDropUpload;
    this.immediateFileSelectUpload = this.applicationStatusService.configuration.proofs.immediateFileSelectUpload;
    this.maximumFileSize = this.applicationStatusService.configuration.proofs.maximumFileSize;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (!this.dropZoneinitialised && changes['dropZone'] && typeof this.dropZone.uploader != 'undefined') {
      this.dropZoneInitialise(this.dropZone);
    }

  }

  dropZoneInitialise(dropZone: any) {

    this.dropZoneinitialised = true;

    /*
      The uploader onAfterAddingAll event is used as opposed to the onFileDrop/change events being specified within the html markup.
      This was done because the change event was not firing correctly (the event object was returning an empty file object which should have contained one or more entries). 
    */
    dropZone.uploader.onAfterAddingAll = (fileItems: FileItem[]) => {

      this.onAfterAddingAll(fileItems);

    };

  }

  setFileOver(e: boolean) {

    this.isFileOver = e;

  }

  onFileDrop(fileList: FileList) {

  }

  onAfterAddingAll(fileItems: FileItem[]) {

    this.removeDuplicateFilesFromTheQueue(fileItems);

    if (fileItems.length > this.dropZone.uploader.options.queueLimit) {
      /*
        If too many files provided then remove them and display a modal
      */
      this.displayTooManyFilesModal(fileItems);
    }
    /*
      If drag and drop & immediate uploads are configured then upload files
    */
    else if ((this.isDragAndDrop && this.immediateDragDropUpload) || (!this.isDragAndDrop && this.immediateFileSelectUpload)) {

      if (this.applicationStatusService.configuration.proofs.confirmFileReplacements && this.getFilesAlreadyUploaded(fileItems).length > 0) {
        this.displayFilesAlreadyUploadedModal(fileItems).subscribe(
          answer => {

            if (answer) {
              this.uploadAllFiles();
            }
            else {
              this.removeFilesFromQueue(fileItems);
            }

          }
        );
      }
      else {
        this.uploadAllFiles();
      }

    }
    else {
      /*
        Otherwise check to see if files already uploaded
      */
      if (this.applicationStatusService.configuration.proofs.confirmFileReplacements && this.getFilesAlreadyUploaded(fileItems).length > 0) {
        this.displayFilesAlreadyUploadedModal(fileItems).subscribe(
          answer => {

            if (!answer) {
              this.removeFilesFromQueue(fileItems);
            }

          }
        );
      }

    }

  }

  uploadAllFiles() {

    /*
      Upload all files not already uploading and within
      the file size limit in the queue
    */
    this.dropZone.uploader.queue.forEach(
      (fileItem: FileItem) => {
        if (!fileItem.isUploading && this.isFileSizeValid(fileItem) && this.isFileFormatValid(fileItem)) {
          fileItem.upload();
          this.itemUploaded.emit(fileItem);
        }
      }
    );

  }

  removeDuplicateFilesFromTheQueue(fileItems: FileItem[]) {

    fileItems.forEach(
      (fileItem: FileItem) => {

        var filteredDropZoneFileItems = this.dropZone.uploader.queue.filter((dropZoneFileItem: FileItem) => dropZoneFileItem.file.name == fileItem.file.name);

        if (filteredDropZoneFileItems.length > 1) {
          filteredDropZoneFileItems[0].remove();
        }

      }
    );

  }

  removeFilesFromQueue(fileItems: FileItem[]) {

    fileItems.forEach(
      (fileItem: FileItem) => {
        fileItem.remove();
      }
    );

  }

  isFileSizeValid(fileItem: FileItem) {

    return fileItem.file.size <= this.applicationStatusService.configuration.proofs.maximumFileSize;

  }

  isFileFormatValid(fileItem: FileItem) {

    return this.applicationStatusService.configuration.proofs.acceptedFileFormats.filter( acceptedFileFormat => acceptedFileFormat.mimeType.toLowerCase() == fileItem.file.type.toLowerCase() ).length > 0;

  }

  getFilesAlreadyUploaded(fileItems: FileItem[]) {

    return typeof this.availableProof == 'undefined' ? [] : this.availableProof.filter((proofItem: Proof) => fileItems.filter(item => item.file.name == proofItem.filename).length > 0);

  }

  displayTooManyFilesModal(fileItems: FileItem[]) {

    this.removeFilesFromQueue(fileItems);

    this.globalModalService.show(
      {
        title: `Too Many ${this.dropZone.label} files Provided`,
        content: `You have provided ${fileItems.length} proof documents but we only need ${this.dropZone.uploader.options.queueLimit}. Please try again.`,
        yes: 'Ok',
        config: {
          ignoreBackdropClick: true,
          keyboard: false
        }
      }
    );

  }

  displayFilesAlreadyUploadedModal(fileItems: FileItem[]): Observable<any> {

    var options;

    if (fileItems.length > 1) {

      var fileList = '';

      fileItems.forEach(item => fileList += '<li><b>' + item.file.name + '</b></li>');

      options = {
        title: `Files already uploaded`,
        content: `<p>The following files have already been uploaded. <ul>${fileList}</ul><br>Do you want to replace them?</p>`,
        yes: 'Yes',
        no: 'No',
        config: {
          ignoreBackdropClick: true,
          keyboard: false
        }
      };
    }
    else {

      options = {
        title: `File already uploaded`,
        content: `<p>The file <b>${fileItems[0].file.name}</b> has already been uploaded.<br>Do you want to replace it?</p>`,
        yes: 'Yes',
        no: 'No',
        config: {
          ignoreBackdropClick: true,
          keyboard: false
        }
      };

    }

    return this.globalModalService.show(options);

  }

}
