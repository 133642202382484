<div [hidden]="displayShowRoom || vehicleDetailsPage" class="quotes-page">
  <!-- <div class="quotes-page"> -->
  <quote-vehicle [application]="application"></quote-vehicle>
</div>
<div [hidden]="!displayShowRoom" class="quotes-page">
  <quote-showroom [cardId]="id" [application]="application"  (displayVehicleDetails)='displayVehicleDetails($event)'(displayBreakDown)='displayBreakDown($event)'></quote-showroom>
</div>
<div class="showroom-vehicle-details" *ngIf="vehicleDetailsPage && selectedVehicle != null && selectedVehicle != undefined">
  <showroom-vehicle-details [cardId]="id" [selectedVehicle]="selectedVehicle" (displayShowroom)='displayShowroom($event)' [application]="application"  (displayBreakDown)='displayBreakDown($event)'></showroom-vehicle-details>
</div>
<div class="showroom-vehicle-details" *ngIf="financeBreakdownRequested">
  <finance-breakdown [selectedVehicle]="selectedVehicle" [application]="application" (financeBreakdownClicked)='financeBreakdownClicked($event)'></finance-breakdown>
</div>