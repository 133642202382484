
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { ApplicationStatusService } from './../application-status/application-status.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse
  // Response
 } from '@angular/common/http';
import { AddressLookupResult } from './address-lookup';
import { environment } from "../../../../environments/environment";
import { ProposedVehicle } from "../../../core/services/types";

import { LoginService } from './../../../core/services/login/login.service';
import { TimedActionService } from "../../../core/services/timed-action/timed-action.service";
import { LoadingModalService } from '../modal/loading-modal.service';
import { ActionsService } from '../actions/actions.service';
import { isEmpty } from '../../../../../node_modules/rxjs/operators';

@Injectable()
export class AddressLookupService {

  addressListUrl;

  addressDetailUrl;

  constructor(private http: HttpClient, private actionsService: ActionsService, private loginService: LoginService,
    private loadingModalService: LoadingModalService, private timedActionService: TimedActionService, private applicationStatusService: ApplicationStatusService) {
    this.addressListUrl = environment.base + this.applicationStatusService.configuration.addressLookup.addressListUrl;
    this.addressDetailUrl = environment.base + this.applicationStatusService.configuration.addressLookup.addressDetailUrl;
  }

  Search(postcode: string, id: string) {
    if (postcode == "" && id == "") {
      if (postcode.charAt(0) == " ") {
        return observableOf([]);
      }
    }
    else {
      var url = this.addressListUrl.replace(':postcode', postcode).replace(':id', id);
      return this.http.get<HttpResponse<any>>(url, {headers: this.loginService.getHeaderOptions(true)}).pipe(
        // .catch(error => Observable.throw("An error occurred while performing the postcode lookup: " + error))
        map(
          (response) => {
            // Reset the token expiration timer
            this.timedActionService.resetTimer();
            return response.body;
          }
        ),
        catchError((error: any) => {
          // Reset the token expiration timer depending upon the error code
          this.timedActionService.checkResetTimerOnError(error);
          throw error;
        }
        ),);
    }
  }
  Retrieve(id: string) {
    var url = this.addressDetailUrl.replace(':id', id);
    return this.http.get<HttpResponse<any>>(url, {headers: this.loginService.getHeaderOptions(true)}).pipe(
      // .catch(error => Observable.throw("An error occurred while performing the postcode lookup: " + error))
      map(
        (response) => {
          // Reset the token expiration timer
          this.timedActionService.resetTimer();
          return response.body;
        }
      ),
      catchError((error: any) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }
      ),);
  }

}