import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DisplayVehicle } from '../../../core/services/types-quote';

@Component({
  selector: 'show-vehicle',
  templateUrl: './show-vehicle.component.html',
  styleUrls: ['./show-vehicle.component.scss']
})
export class ShowVehicleComponent implements OnInit {
  @Input() displayVehicle: DisplayVehicle[];
  @Input() applicationStatus: string;
  @Input() lendingTerms;

  vehicle : any;
  regYearFormat: string;
  showVehicleOnserver: Subscription;
  constructor() { }

  ngOnInit() {
    if (!Array.isArray(this.displayVehicle)) {
      this.displayVehicle = [this.displayVehicle];
    }
    this.vehicle = this.displayVehicle[0];
  }
}
