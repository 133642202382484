
/*
  Addresses
*/
class Address {
    public id: string;
    public buildingNumber: string;
    public buildingName: string;
    public address1: string;
    public address2: string;
    public town: string;
    public county: string;
    public postcode: string;
    public country?: string;
  }
  
  class ResidentialInformation {
    public residentialStatus: string;
    public startDate: Date;
    public endDate: string;
  }
  
  declare type ResidentialAddress = Address & ResidentialInformation;
  
  class CommercialInformation {
    public company: string;
    public department: string;
  }
  
  declare type CommercialAddress = Address & CommercialInformation;

  export {
    Address, ResidentialInformation, ResidentialAddress, CommercialInformation, CommercialAddress
  }