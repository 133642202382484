import { Component, Input, OnInit } from '@angular/core';

import { ActionsService } from './../../../core/services/actions/actions.service';

import { NumberPlusMinus } from './../models.class';

@Component({
    selector: 'number-plus-minus',
    templateUrl: 'number-plus-minus.component.html',
    styleUrls: ['number-plus-minus.component.scss']
})
export class NumberPlusMinusComponent implements OnInit {

    @Input()
    model :any;

    constructor() { }

    minus() {
        if (this.model.value>this.model.min) {
            this.model.value--;
        }

    }

    plus() {
        if (this.model.value<this.model.max) {
            this.model.value++;
        }
    }

    ngOnInit() {

    }

}
