import { HostListener } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { ApplicationStatusService } from '../../core/services/application-status/application-status.service';

@Component({
  selector: 'telephone',
  template: `
  <ng-template *ngIf="isMobile;then mobile else desktop"></ng-template>

  <ng-template #mobile>
      <a class="nav-link telephone" href="tel:{{telephone}}">
        <img src="./assets/phone-icons.png" alt="Creditplus phone number">{{telephone}}
      </a>
   </ng-template>
  <ng-template #desktop>
      <a class="nav-link telephone" href="tel:{{telephone}}">
        <img src="./assets/phone-icons.png" alt="Creditplus phone number">{{telephone}}
      </a>
  </ng-template>`
})
export class TelephoneComponent {
  telephone: string;
  isMobile: boolean = false;

  @HostListener("isMobile", ['$event']) isMobileListener($event) {
    this.isMobile = $event.detail;
  }
  constructor(applicationStatusService:ApplicationStatusService) {
    this.telephone = applicationStatusService.configuration.contact.telephone;
  }
}
