<div class="financebreakdown-modal">
  <div class="modal-container">
    <div class="modal-details">
        <i class="fas fa-times" (click)="CloseModal()"></i>
      <h3>{{application.quotes[0].financeRequest.financeType}} Illustrative Example</h3>
      <div class="examples">
        <div class="example-label">Finance Type</div>
        <div class="example-value">{{application.quotes[0].financeRequest.financeType}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Monthly repayments</div>
        <div class="example-value">{{MonthlyPayment | currency:'GBP':true:'1.2-2'}}</div>
      </div>
      <div class="examples" *ngIf="financeType == 'PCP' ">
        <div class="example-label" >Balloon payment</div>
        <div class="example-value">{{balloon | currency:'GBP':true:'1.2-2'}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Term period</div>
        <div class="example-value">{{term}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Cash price</div>
        <div class="example-value">{{selectedVehicle.price | currency:'GBP':true:'1.2-2'}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Cash deposit</div>
        <div class="example-value">{{deposit}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Total amount of credit</div>
        <div class="example-value">{{loanAmount | currency:'GBP':true:'1.2-2'}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Total amount payable</div>
        <div class="example-value">{{TotalAmountPayable | currency:'GBP':true:'1.2-2'}}</div>
      </div>
      <div class="examples">
        <div class="example-label">Representative APR</div>
        <div class="example-value">{{apr}}%</div>
      </div>
      <div class="examples">
        <div class="example-label">Total charges payable</div>
        <div class="example-value">{{TotalChargesPayable | currency:'GBP':true:'1.2-2'}}</div>
      </div>
    </div>
  </div>
</div>