import { Injectable } from '@angular/core';
import *  as moment from 'moment';
import { DealAction } from '../types-deal-action';

@Injectable()
export class TimelineService{
    constructor() { }
   
    getDealStates(dealActions: DealAction[]){
      var statesToDisplay: Array<DealAction> = new Array();
      var formattedStateDates: Array<string> = new Array();
      var formattedStateNames: Array<string> = new Array();
      var formattedStateTimes: Array<string> = new Array(); 
      var formattedStateId: Array<string> = new Array();
      var returnArray: Array<Array<string>> = new Array();
      const dealStates = [1,2,4,5,6,7,9,10,11,12,24,26,44,49,53,55,64,65,67,68,70,80,82]
      var dealStatesFound = [];
      //Get only states we wish to display, ignore others.
      for(var i = 0; i < dealActions.length; i++){
        if(dealStates.indexOf(dealActions[i].dealStateID) != -1 && dealStatesFound.indexOf(dealActions[i].dealStateID) == -1) {
          statesToDisplay.push(dealActions[i]);
          dealStatesFound.push(dealActions[i].dealStateID)
        }
      }
      var date: Date;
      var day: string;
      var month: string;
      var year: string;
      var dateString: string;
      var stateText: string;
      var hour: string;
      var minutes: string;
      var timeString: string;
      
      for(var j = 0; j < statesToDisplay.length; j++){
        //format date.
        date = new Date(statesToDisplay[j].dealActionDateTime);
        (date.getDate() < 10) ? day = "0" + date.getDate().toString() : day = date.getDate().toString();
        (date.getMonth() < 10) ? month = "0" + date.getMonth().toString() : month = date.getMonth().toString();
        year = date.getFullYear().toString();
        dateString = day + "/" + month + "/" + year;

        hour = date.getHours().toLocaleString();
        minutes = date.getMinutes().toLocaleString();
        timeString = hour + ":" + minutes;
        var a = moment(date)
        timeString=  a.format("DD/MM/YY HH:MM");
        //map state id to text to display
        switch(statesToDisplay[j].dealStateID){
  
          case 1: // Enquiry received
            stateText = "Application received";
            break; 
  
          case 2: //Proposal sent
            stateText = "Application with lender";
            break; 
  
          case 4: //Proposal accepted
            stateText = "Application accepted";
            break; 
  
          case 5: //Declined
            stateText = "Application closed";
            break; 
  
          case 6: //Client cancel
            stateText = "Application closed";
            break; 
  
          case 7: //Paid out
            stateText = "Paid-out";
            break; 
  
          case 9: //Docs issued
            stateText = "Please sign agreements";
            break; 
  
          case 10: //Docs received
            stateText = "Agreements received";
            break; 
  
          case 11: //Abandoned
            stateText = "Application closed";
            break; 
  
          case 12: //Further info required.
            stateText = "Further information required";
            break; 
  
          case 24: //Cannot lend
            stateText = "Application closed";
            break; 
  
          case 26: //Docs w/Lender
            stateText = "Awaiting pay-out";
            break; 
  
          case 44: //Lost deal
            stateText = "Application closed";
            break; 
  
          case 49: //Duplicate
            stateText = "Application closed";
            break; 
  
          case 53: //False app
            stateText = "Application closed";
            break; 
  
          // case 55: // Renewal
          // stateText = "";
          // break; 
  
          case 64: //Underwriting
            stateText = "Further information required";
            break; 
  
          case 65: //Provisionally accepted
            stateText = "Application received";
            break; 
  
          // case 67: //Renewal progressed
            // stateText = "";
            // break; 
  
          // case 68: //Renewal not progressed
            // stateText = "";
            // break; 
  
          case 70: //Partial application
            stateText = "Further information required";
            break; 
  
          // case 80: //Rewound
            // stateText = "";
            // break; 
  
          case 82: //Details started
            stateText = "Further information required";
            break; 
  
          default: 
            break;
        }
        formattedStateId.push(statesToDisplay[j].dealStateID.toString());
        formattedStateNames.push(stateText);
        formattedStateDates.push(dateString);
        formattedStateTimes.push(timeString);
      }
      returnArray.push(formattedStateId);
      returnArray.push(formattedStateNames);
      returnArray.push(formattedStateDates);
      returnArray.push(formattedStateTimes)
      return returnArray;
    }
  
  }
  
  