import { Journeys } from "./configuration.types";
import { JourneyPage } from "../types";

import { WORKFLOWS } from "./configuration-workflows";
import { SECTIONS } from "./configuration-sections";
import { PAGES } from "./configuration-pages";

import { CONSUMER_JOURNEY } from "./configuration-journeys-consumer";
import { DEALER_JOURNEY } from "./configuration-journeys-dealer";

import { ROLES } from "./configuration-roles";
import { PAGE_DISABLED, PAGE_ACTIVE_AND_EDITABLE, PAGE_ENABLED_AND_READONLY } from "./configuration-page-states";
import { URLS } from "./configuration.urls";

const JOURNEY_TYPES = {
    FORWARDS_ONLY: 'FORWARDS_ONLY',
    BACKWARDS_AND_FORWARDS: 'BACKWARDS_AND_FORWARDS'
};

let JOURNEYS: Journeys = {
    FORWARDS_ONLY: {
        topMenu: {
            show: true,
            validInvalidIconStyle: 'NONE',
            showActiveItemOnly: false
        },
        secondaryMenu: {
            show: false,
            validInvalidIconStyle: 'TICK_AND_WARNING'
        },
        sectionNavigation: {
            validInvalidIconStyle: 'TICK_AND_WARNING',
            includeNextSectionButton: false,
            sectionCollapse: 'INVALID_OPEN',
            gotoFirstInvalidSection: true,
            pagingMethod: 'FORWARDS_ONLY' //'BACKWARDS_AND_FORWARDS' // 
        },
        help: {
            show: false
        },
        defaultPastStepState: PAGE_DISABLED,
        defaultActiveStepState: PAGE_ACTIVE_AND_EDITABLE,
        defaultFutureStepState: PAGE_DISABLED
    },
    BACKWARDS_AND_FORWARDS: {
        topMenu: {
            show: true,
            validInvalidIconStyle: 'NONE',
            showActiveItemOnly: false
        },
        secondaryMenu: {
            show: false,
            validInvalidIconStyle: 'NONE'
        },
        sectionNavigation: {
            validInvalidIconStyle: 'TICK_AND_WARNING',
            includeNextSectionButton: false,
            sectionCollapse: 'INVALID_OPEN',
            gotoFirstInvalidSection: true,
            pagingMethod: 'BACKWARDS_AND_FORWARDS'
        },
        help: {
            show: true
        },
        defaultPastStepState: PAGE_ENABLED_AND_READONLY,
        defaultActiveStepState: PAGE_ACTIVE_AND_EDITABLE,
        defaultFutureStepState: PAGE_DISABLED
    }
};


const PAGE_JOURNEYS = [
    {
        id: 'CONSUMER',
        title: 'Standard Consumer journey',
        roles: [ROLES.CUSTOMER.name],
        pages: CONSUMER_JOURNEY,
        description: '',
        created: {
            on: '25/04/2018',
            by: 'Ross Tyler'
        },
        redirectUrls: {
            login: URLS.ApplicationCurrentStep,
            register: URLS.ApplicationCurrentStep,
            home: URLS.Login
        }
    },
    {
        id: 'ADMIN',
        title: 'Admin journey',
        roles: [ROLES.ADMIN.name],
        pages: CONSUMER_JOURNEY,
        description: '',
        created: {
            on: '25/04/2018',
            by: 'Ross Tyler'
        },
        redirectUrls: {
            login: URLS.Dealerportal,
            register: URLS.Dealerportal,
            home: URLS.Dealerportal
        }
    },
    {
        id: 'DEALER',
        title: 'Standard Dealer Plus journey',
        roles: [ROLES.DEALER.name],
        pages: DEALER_JOURNEY,
        description: '',
        created: {
            on: '25/04/2018',
            by: 'Ross Tyler'
        },
        redirectUrls: {
            login: URLS.Dealerportal,
            register: URLS.Dealerportal,
            home: URLS.Dealerportal
        }
    },
    {
        id: 'DEALER CUSTOMER',
        title: 'Standard dealer customer',
        roles: [ROLES.DEALER_CUSTOMER.name],
        pages: DEALER_JOURNEY,
        description: '',
        created: {
            on: '25/04/2018',
            by: 'Ross Tyler'
        },
        redirectUrls: {
            login: URLS.ApplicationCurrentStep,
            register: URLS.ApplicationCurrentStep,
            home: URLS.Login
        }
    },
    {
        id: 'ccf customer',
        title: 'Standard CCF journey',
        roles: [ROLES.CCF.name],
        pages: CONSUMER_JOURNEY,
        description: '',
        created: {
            on: '25/04/2018',
            by: 'Ross Tyler'
        },
        redirectUrls: {
            login: URLS.ApplicationCurrentStep,
            register: URLS.ApplicationCurrentStep,
            home: URLS.Login
        }
    },
    {
        id: 'Temporary user',
        title: 'Temporary User journey',
        roles: [ROLES.TEMP.name],
        pages: CONSUMER_JOURNEY,
        description: '',
        created: {
            on: '25/04/2018',
            by: 'Ross Tyler'
        },
        redirectUrls: {
            login: URLS.Quotes,
            register: URLS.Quotes,
            home: URLS.Quotes
        }
    },
    {
        id: 'dealerplus customer',
        title: 'dealerplus customer journey',
        roles: [ROLES.dealerplusCustomer.name],
        pages: DEALER_JOURNEY,
        description: '',
        created: {
            on: '18/02/2019',
            by: 'Tawfik Sabbagh'
        },
        redirectUrls: {
            login: URLS.Documents,
            register: URLS.Quotes,
            home: URLS.Quotes
        }
    }
];

export {
    PAGES, SECTIONS, JOURNEYS, PAGE_JOURNEYS, JOURNEY_TYPES
};
