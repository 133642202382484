const PAGES = {
    AGREEMENT: {
        label: 'agreement',
        route: 'agreement-and-documentation'
    },
    CONFIRMATION: {
        label: 'confirmation',
        route: 'loan-offer'
    },
    DASHBOARD:{
        label: 'dashboard',
        route: 'dashboard'
    },
    DEALERPORTAL:{
        label: 'dealerportal',
        route: 'dealer-portal'
    },
    DETAILS: {
        label: 'details',
        route: 'my-details',
    },
    DOCS:{
        label: 'docs',
        route: 'docs',
    },
    EXTRADETAILS:{
        label: 'extradetails',
        route: 'extra-details'
    },
    FINAL: {
        label: 'final',
        route: 'final-page',
    },
    FINANCEDOCUMENTS:{
        label: 'financedocuments',
        route: 'documents'
    },
    HELP:{
        label: 'help',
        route: 'help'
    },
    PROOFS:{
        label: 'proofs',
        route: 'proofs'
    },
    PROOFVERIFY:{
        label: 'proofverify',
        route: 'proof-verify'
    },
    QUOTES: {
        label: 'quotes',
        route: 'quotes'
    },
    SETTINGS:{
        label: 'settings',
        route: 'settings'
    },
    TERMS: {
        label: 'terms',
        route: 'terms-and-acceptance'
    },
    TIMELINE: {
        label: 'timeline',
        route: 'timeline'
    },
    MARKETING: {
        label: 'marketing',
        route: 'marketing'
    },
    VEHICLES:{
        label: 'vehicles',
        route: 'vehicles',
    },
};
export {
    PAGES
}