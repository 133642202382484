import { Component, Input, OnInit } from '@angular/core';
import { VehicleService } from '../../../core/services/vehicle/vehicle.service';

@Component({
  selector: 'desktop-warning',
  templateUrl: './desktop-warning.component.html',
  styleUrls: ['./desktop-warning.component.css']
})
export class DesktopWarningComponent implements OnInit {
  @Input() contactId: string;
  @Input() contactEmail: string;

  textCopied:boolean = false;
  emailSent:boolean = false;

  constructor(private vehicleService: VehicleService) { }

  ngOnInit(): void {
  }

  copyToClipboard(){
    this.textCopied = true;
    var text:string = "https://portal.creditplus.co.uk/login"
    //console.log("text click as " + text);
    navigator.clipboard.writeText(text);
    setTimeout(()=>{
      this.textCopied=false;
    },2000);
  
  }

  sendLinkEmail(){
    this.vehicleService.sendPortalLinkEmail(this.contactId, this.contactEmail);
    this.emailSent=true;
  }

}
