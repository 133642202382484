const URLS={
    NotFound : {
        label: 'Page not found',
        route: '/not-found'
    },
    Login : {
        label: 'Login',
        route: '/login'
    },
    Quotes : {
        label: 'quotes',
        route: '/applications/{{id}}/quotes'
    },
    Account: {
        label: 'Account',
        route: '/account'
    },
        Dealerportal: {
        label: 'Dealer portal',
        route: '/dealer-portal'
    },
    ApplicationCurrentStep: {
        label: 'Current step',
        route: '/applications/{{id}}/current-step'
    },
    Extradetails: {
        label: 'extra-details',
        route: '/applications/{{id}}/extra-details'
    },
    Registration: {
        label: 'registration',
        route: '/register?customerId={{id}}&username={{email}}&perm=true'
    },
    Documents: {
        label: 'Documents',
        route: '/applications/{{id}}//agreement-and-documentation'
    },
    RegisterPanel:{
        label: 'register-panel',
        route: '/register-panel'
    }
};

export {
    URLS
}