import { Directive, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, ValidationErrors } from "@angular/forms";
import { isANumber } from "../utilities";

export function nonBlankValidator(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors => {
    if (control.value != null) {
      let value = Number(control.value);

      if (control.value.trim().length==0) {
        return {
          'nonBlank': {
            actualValue: value
          }
        }
      }
    }

    return null;
  };
}

@Directive({
  selector: '[nonBlank]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NonBlankDirective, multi: true }]
})
export class NonBlankDirective implements Validator, OnChanges {

  validator: ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['nonBlank'];

    if (change) {
      this.validator = nonBlankValidator();
    }
    else {
      this.validator = null;
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.validator) {
      return this.validator(control)
    }
  }
}
