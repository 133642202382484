<div class="main-body" >
  <div class="raised-card" style="margin-top: 20px; text-align:center;">
    <div class="card-header" style="padding:1rem 0;">
      Oops! 404 Not found error
    </div>
    <div class="card-block">
      <h4 class="card-title">Page not found</h4>
      <p class="card-text" style="padding:1rem 0 2.5rem 0;">The page that you were looking for was not found.<br />Please check that you have entered the correct URL for the page.</p>
      <a *ngIf="redirectRoute" [routerLink]="redirectRoute"><button class="standard-button">Go to the login page</button></a>
    </div>
  </div>
</div>
