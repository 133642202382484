<nav *ngIf="model.visible && model.arrownavigators">
  <ul class="pagination">
    <li (click)="first()" [class.toolbar-button-disabled]="model.page==1">
      <span>
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </span>
    </li>
    <li (click)="previous()" [class.toolbar-button-disabled]="model.page==1">
      <span>
        <i class="fa fa-angle-left" aria-hidden="true"></i>
      </span>
    </li>
    <li *ngFor="let page of pages" [class.active]="page==(model.page)" (click)="goto(page)">
      <span>
        <span>{{page}}</span>
      </span>
    </li>
    <li (click)="next()" [class.toolbar-button-disabled]="model.page==(totalPages) || pages.length==0">
      <span>
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </span>

    </li>
    <li (click)="last()" [class.toolbar-button-disabled]="model.page==(totalPages) || pages.length==0">
      <span>
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </span>
    </li>
  </ul>
</nav>
<nav *ngIf="model.visible && !model.arrownavigators">
  <ul class="pagination">
    <li *ngFor="let page of pages" [class.active]="page==(model.page)" (click)="goto(index)">
      <span>
        <span>{{page}}</span>
      </span>
    </li>
  </ul>
</nav>