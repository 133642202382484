import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'valid-invalid-icon',
  templateUrl: './valid-invalid-icon.component.html',
  styleUrls: ['./valid-invalid-icon.component.scss']
})
export class ValidInvalidIconComponent implements OnInit {

  @Input()
  valid?: boolean = null;

  @Input()
  validIcon?: string = 'icon-tick';

  @Input()
  validIconSmall?: string = 'icon-tick-small';

  @Input()
  invalidIcon?: string = 'fa-exclamation-triangle';

  constructor() { }

  ngOnInit() {
  }

}
