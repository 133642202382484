
import { RequiredProof } from "./types-proof";

/*
  Loan offers
*/
 class LoanOffer {
    id: number;
    lenderid: number;
    lendername: string;
    image: string;
    funderReference: string;
    // product: string;
    status: string;
    loanOffer?: Offer;
    errors: any;
    dateReceived: string;
    isSelected: boolean;
    commission: dealerCommission;
    display?: boolean;
  }
  
 class Offer {
    product: string;
    loanAmount: number;
    payment: number;
    rate: number;
    apr: number;
    fees: number;
    balloon: number;
    deposit: number;
    term: number;
    totalPayable: number;
    requiredProofs?: RequiredProof[];
  }

  class dealerCommission{
    dealerCut: number;
    lenderTier: LenderTier;
    totalValue: number;
  }
  
  class LenderTier{
    lenderId: number;
    tier: number;
  }
  export {
      LoanOffer, Offer, dealerCommission, LenderTier
  }