import { Injectable } from '@angular/core';

@Injectable()
export class FavIconService {

  constructor() { 

  }

  set(href: string) {

    var iconLinkElement = document.querySelector('link[rel=icon]');

    if (iconLinkElement) {
      iconLinkElement.setAttribute( 'href', `${href}?refresh=please`);
    }

  }

}
