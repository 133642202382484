import { Pipe, PipeTransform } from '@angular/core';
import { Address } from "../../../core/services/types";

@Pipe({
  name: 'address',
  pure: false
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, args?: any): String {
    if (value == null) {
      return "";
    }
    
    switch (args) {
      case 'inline': return this.ConcatAddress(value, ',');
      default: return this.ConcatAddress(value, '<br>');
    }
  }

  private ConcatAddress(value: Address, inputdelimiter: String): String {
    var address = '',
      delimiter: String = '';

    var keys = [
      'buildingNumber',
      'buildingName',
      'address1',
      'address2',
      'town',
      'county',
      'postcode',
      'country'
    ];

    keys.map(
      (key) => {
        if (value[key]) {
          address += delimiter + value[key];
          delimiter = inputdelimiter;
        }
      }
    );

    return address;
  }
}
