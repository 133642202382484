import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { Application, JourneyPage } from "../types";

import { ActionsService } from "../actions/actions.service";

import { CONFIGURATION, Configuration, JourneyConfiguration } from "../configuration/configuration";
import { PAGES, JOURNEY_TYPES } from "../configuration/configuration-journeys";
import { ROLES } from '../configuration/configuration-roles';

import { TokenService } from '../token/token.service';
import { LoginService } from '../login/login.service';

import {
  EventStates,
  PageEventPages,
  PageState,
  PageSectionStates,
  PageSectionState,
  PAGE_ENABLED_AND_EDITABLE,
  PAGE_ACTIVE_AND_EDITABLE,
  PAGE_ACTIVE_AND_READONLY,
  SECTION_VISIBLE_AND_EDITABLE,
  SECTION_VISIBLE_AND_READONLY,
  SECTION_HIDDEN,
  SECTION_DISABLED,
  PAGE_DISABLED,
  PAGE_ENABLED_AND_READONLY
} from '../configuration/configuration-page-states';
import { GROUPS } from '../configuration/configuration-groups';
import { CONFIGURATION_CCF } from '../configuration/configuration-groups-ccf';

@Injectable()
export class ApplicationStatusService {


  eventStates: EventStates;
  state: any = {};
  stateObjectCreated: boolean = false;

  broadcastChangesOnly: boolean = false;

  pages: any;

  currentEvent: string;

  pageBehaviours: string[];
  sectionBehaviours: string[];

  defaultPastStepState: any;
  defaultActiveStepState: any;
  defaultFutureStepState: any;

  journeyPages: JourneyPage[];

  loggedInSubscription: Subscription;

  configuration: Configuration = CONFIGURATION;

  constructor(
    private actionsService: ActionsService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private loginService: LoginService, 
    private tokenService: TokenService) {

    this.pageBehaviours = ['enabled', 'active', 'editing'];
    this.sectionBehaviours = ['editing', 'visible', 'enabled'];

    this.setGroupConfiguration();
    /*

    Overview
    ========
    This service is used to automatically set read-only, editing, visible settings for all components within the application based upon a 
    particular event state being applied.
    
    The event states are automatically defined based upon the sections, workflows & states array settings for each page in the journeyPages object

    Page & section state helper settings
    Page state
    ==========
    PAGE_ENABLED_AND_EDITABLE
    PAGE_ENABLED_AND_READONLY
    PAGE_ACTIVE_AND_EDITABLE - Make sure that only one page is active at a time
    PAGE_ACTIVE_AND_READONLY - Make sure that only one page is active at a time
    PAGE_DISABLED
    
    Section state
    =============
    SECTION_VISIBLE_AND_EDITABLE
    SECTION_VISIBLE_AND_READONLY
    SECTION_HIDDEN
    
    */

    this.loggedInSubscription = loginService.loginStatusAnnounced$.subscribe(loggedIn => { this.loginStatusAnnounced(loggedIn); })

    if (this.tokenService.roles.length) {
      this.initializeStateObjects();
    }

  }

  loginStatusAnnounced(loggedIn: boolean) {

    this.setGroupConfiguration();

    if (loggedIn) {
      this.initializeStateObjects();
    }

  }

  initializeState(application: Application) {

    this.setState(application, application.status);

  }

  initializeStateObjects() {

    this.journeyPages = Configuration.loadCurrentJourneyPages(this.tokenService.roles);

    this.setDefaultPageStepStates();

    /* 
    
      These methods should only be called once to initialise when the application is first loaded. (currently in applications-container.component ngAfterViewInit() lifecycle)
      
    */
    this.createStateObject();
    this.createEventStatesObject();

  }

  setState(application: Application, status: string) {
    application.status = status;
    this.broadcast(status);
  }

  setDefaultPageStepStates() {

    var journeyType = this.getJourneyType();

    this.defaultPastStepState = journeyType.defaultPastStepState;
    this.defaultActiveStepState = journeyType.defaultActiveStepState;
    this.defaultFutureStepState = journeyType.defaultFutureStepState;

  }

  redirectRouteIfNotValidBasedOnStatus(application: Application, page: string) {

    /*
      Check that the page can be displayed based upon the current application status and if it can't then re-direct to the active page for the status
    */
    var pageRedirect = this.getValidRouteBasedOnStatus(application.status, page);

    if (pageRedirect != this.getRouteForPageName(page)) {
      this.router.navigate(['/applications', application.id, pageRedirect]);
      return true;
    }
    /*
      If no route was found based upon the status then redirect to the home route setting
    */
    else if (pageRedirect == null) {
      var homeRoute = this.getHomeRedirectRoute();
      this.router.navigate(homeRoute);
    }

    return false;
  }

  getValidRouteBasedOnStatus(status: string, page: string): string {
    /*
      Check that the page can be displayed based upon the current application status and if it can't then re-direct to the active page for the status
    */
    var pages = this.getStatusPages(status);

    /*
      If the page is not enabled then get the re-direct to the page that should be active 
    */
    if (page != null) {

      if (!pages[page].enabled) {
        for (var item in pages) {
          if (pages[item].active) {
            return PAGES[item.toUpperCase()].route;
          }
        }
      }
      else {
        return PAGES[page.toUpperCase()].route;
      }

    }
    /*
      If the page is null then return the active page
    */
    else {
      for (var item in pages) {
        if (pages[item].active) {
          return PAGES[item.toUpperCase()].route;
        }
      }
    }

    return page;

  }


  redirectRouteIfNotValidBasedOnStatusAndRoute(application: Application, routeName: string) {

    var routeRedirect = this.getValidRouteBasedOnStatusAndRoute(application.status, routeName);

    if (routeRedirect != routeName) {
      this.router.navigate(['/applications', application.id, routeRedirect]);
    }

  }

  getValidRouteBasedOnStatusAndRoute(status: string, routeName: string): string {

    var page = this.getPageNameForRoute(routeName);
    return this.getValidRouteBasedOnStatus(status, page);

  }

  getStatusPages(status: string): any {

    /*
      Returns the pages object associated with a particular application status state
    */
    return this.eventStates[status];

  }

  getPageNameForActivatedRoute() {

    var route = this.router.url.split('/')[3];

    return this.getPageNameForRoute(route);

  }

  getPageNameForRoute(routeName: string): string {

    var page = this.journeyPages.find(page => page.route == routeName);

    return (page != null) ? page.name : null;

  }


  getRouteForPageName(pageName: string): string {

    var page = this.journeyPages.find(page => page.name == pageName);

    return (page != null) ? page.route : null;

  }

  isPageEnabled(pageName: string) {

    var page = this.getPage(pageName);

    if (page != null) {
      return page.page.enabled;
    }

    return false;

  }

  getNextPage(pageName: string) {
    var page = this.getPage(pageName),
      journeyType = this.getJourneyType().sectionNavigation.pagingMethod;
    if (page != null && page.index < (this.journeyPages.length - 1) && this.journeyTypeHasNextPage(journeyType, page.page)) {
      return this.journeyPages[page.index + 1];
    }
    return null;
  }

  getPreviousPage(pageName: string) {

    var page = this.getPage(pageName);

    if (page != null && page.index > 0) {
      return this.journeyPages[page.index - 1];
    }

    return null;

  }

  getJourneyType(): JourneyConfiguration {

    return Configuration.loadCurrentJourneyType();

  }

  journeyTypeHasNextPage(journeyType: string, journeyPage: JourneyPage) {

    return (
      journeyType == JOURNEY_TYPES.FORWARDS_ONLY
      || (journeyType == JOURNEY_TYPES.BACKWARDS_AND_FORWARDS && !journeyPage.active));

  }

  getPage(pageName: string) {

    var page = this.journeyPages.find(page => page.name == pageName),
      index;

    if (page != null) {
      return {
        page: page,
        index: this.journeyPages.indexOf(page)
      };
    }

    return null;

  }

  navigateToNextPage(router, activatedRoute, pageName) {

    var route = '../' + this.getNextPage(pageName).route;
    router.navigate([route], { relativeTo: activatedRoute });

  }

  navigateToPreviousPage(router, activatedRoute, pageName) {

    var route = '../' + this.getPreviousPage(pageName).route;
    router.navigate([route], { relativeTo: activatedRoute });
  }

  createStateObject() {

    /* 
    
      Create a state object based upon the page sections settings that will be used to check if a change has happened and needs broadcasting when the 
      application state changes.
      
    */

    this.state = {};

    this.journeyPages.forEach(
      (page) => {

        this.state[page.name] = {};

        this.pageBehaviours.forEach(
          behaviour => this.state[page.name][behaviour] = null
        )
        this.state[page.name].sections = {};

        page.sections.forEach(
          (section) => {

            this.state[page.name].sections[section] = {};

            this.sectionBehaviours.forEach(
              behaviour => this.state[page.name].sections[section][behaviour] = null
            )

          }
        );

      }
    );

  }

  createEventStatesObject() {

    /* 
   
     Create an event state object based upon the page sections settings that will be used to determine the states applied based upon different workflows.
     
   */
    this.eventStates = {
      UNDEFINED: {}
    };

    this.journeyPages.forEach(
      (page, pageIndex) => {

        var currentPage = page;

        this.createEventStatesWorkflow(page, pageIndex);
        this.createEventStatesState(page);

        /*
            Add an UNDEFINED workflow step that can be used when the application workflow status has not been defined in any of the pages in the journey.
            This workflow step will have read-only access.
        */
        this.eventStates.UNDEFINED[page.name] = JSON.parse(JSON.stringify(PAGE_DISABLED));

      }
    );

  }

  createEventStatesWorkflow(page: JourneyPage, pageIndex: number) {

    page.workflows.forEach(
      (workflow) => {

        if (!this.eventStates[workflow]) {
          this.eventStates[workflow] = {}
        }

        /*
          Assign the default settings for a page with an active state to the workflow page
        */
        this.eventStates[workflow][page.name] = JSON.parse(JSON.stringify(this.defaultActiveStepState));

        /*
          Loop through the pages and see if the page is actually before or after the active page and assign the appropriate default settings
        */
        this.journeyPages.forEach(
          (pageItem, index) => {

            if (index != pageIndex) {

              if (index < pageIndex) {
                this.eventStates[workflow][pageItem.name] = JSON.parse(JSON.stringify(this.defaultPastStepState));
              }
              else {
                this.eventStates[workflow][pageItem.name] = JSON.parse(JSON.stringify(this.defaultFutureStepState));
              }

            }

          }
        );
      }
    );

  }

  createEventStatesState(page: JourneyPage) {

    page.states.forEach(
      (state) => {

        state.workflows
          .forEach(
            (workflow) => {

              if (state.pageDefaults) {
                this.eventStates[workflow][page.name] = this.getPageState(page, state.pageDefaults);
              }

              if (state.sectionDefaults) {
                this.eventStates[workflow][page.name].sectionDefaults = this.getSectionState(page, state.sectionDefaults);
              }

              state.sectionStates.forEach(
                (sectionState) => {

                  if (!this.eventStates[workflow][page.name].sections) {
                    this.eventStates[workflow][page.name].sections = {};
                  }
                  this.eventStates[workflow][page.name].sections[sectionState.section] = this.getSectionState(page, sectionState.state, workflow, sectionState.section);
                }
              );
            }
          );

      }
    );

  }

  getSectionState(page, state, workflow?, section?) {

    switch (state) {
      case 'SECTION_VISIBLE_AND_EDITABLE':
        return JSON.parse(JSON.stringify(SECTION_VISIBLE_AND_EDITABLE));

      case 'SECTION_VISIBLE_AND_READONLY':
        return JSON.parse(JSON.stringify(SECTION_VISIBLE_AND_READONLY));

      case 'SECTION_HIDDEN':
        return JSON.parse(JSON.stringify(SECTION_HIDDEN));

      case 'SECTION_DISABLED':
        return JSON.parse(JSON.stringify(SECTION_DISABLED));

      default:
        throw 'Section state ' + state + ' was not found for page ' + page.name + ' ' + section;
    }

  }

  getPageState(page, state) {

    switch (state) {
      case 'PAGE_ENABLED_AND_EDITABLE':
        return JSON.parse(JSON.stringify(PAGE_ENABLED_AND_EDITABLE));

      case 'PAGE_ENABLED_AND_READONLY':
        return JSON.parse(JSON.stringify(PAGE_ENABLED_AND_READONLY));

      case 'PAGE_ACTIVE_AND_EDITABLE':
        return JSON.parse(JSON.stringify(PAGE_ACTIVE_AND_EDITABLE));

      case 'PAGE_ACTIVE_AND_READONLY':
        return JSON.parse(JSON.stringify(PAGE_ACTIVE_AND_READONLY));

      case 'PAGE_DISABLED':
        return JSON.parse(JSON.stringify(PAGE_DISABLED));

      default:
        throw 'Page state ' + state.pageState + ' was not found for page ' + page.name;
    }

  }


  broadcast(status: string) {

    var pages = (this.eventStates[status]) ? this.eventStates[status] : this.eventStates.UNDEFINED;

    var sections;

    for (var key in pages) {

      this.broadcastPage(key, pages[key]);
      this.broadcastSections(key, pages[key]);

    }

  }

  broadcastSections(key: string, page: PageState) {

    var sections = page.sections;
    var journeyPage = this.getPage(key).page;
    /*
    If sectionDefaults have been set up for the page then run broadcastSection for each section apart from those 
    which have been defined in the sections object for the page. 
  */
    if (typeof page.sectionDefaults != 'undefined') {

      for (var pageSection in this.state[key].sections) {

        if (typeof sections == 'undefined' || typeof sections[pageSection] == 'undefined') {
          this.broadcastSection(key, pageSection, page.sectionDefaults, journeyPage);
        }

      }
    }

    /*
      If a sections object has been defined for the page then run broadcastSection for each section defined
    */
    if (typeof sections != 'undefined') {
      for (var pageSection in sections) {
        this.broadcastSection(key, pageSection, page.sections[pageSection], journeyPage);
      }
    }

  }

  broadcastPage(page: string, stateSettings: PageState) {

    /* 
    
      if a particular page (e.g. details) has a changed enabled or active state then update it's the state in the service and broadcast the change so that 
      the subscription within the applications-container component can update the settting
      
    */
    this.pageBehaviours.forEach(
      (behaviour) => {


        if (!this.broadcastChangesOnly || (this.broadcastChangesOnly && this.hasPageStateChanged(page, behaviour, stateSettings[behaviour]))) {
          this.setPageState(page, behaviour, stateSettings[behaviour]);

          this.broadcastAction(
            page + '-page',
            behaviour,
            stateSettings[behaviour]
          );
        }

      }
    );

  }

  broadcastSection(page: string, section: string, stateSettings: any, journeyPage: JourneyPage) {

    /* 
    
      if a particular component/section (e.g. employment) has a changed state then update it's the state in the service and broadcast the change so that the subscription can 
      update the component/section
      
    */
    this.broadcastSectionBehaviours(page, section, stateSettings);

    /* 
      Broadcast the changes to any child sections that were defined for the section when it was registered
    */
    var foundChildSections = this.getPageJourneySectionChildSections(journeyPage, section);

    if (foundChildSections) {
      foundChildSections.sections.forEach(
        // (childSection) => this.broadcastSectionBehaviours(page, childSection, stateSettings)
        (childSection) => {
          this.broadcastSectionBehaviours(page, childSection, stateSettings)
        }
      );
    }

  }


  broadcastSectionBehaviours(page: string, section: string, stateSettings: any) {

    /* 
    
      if a particular component/section (e.g. employment) has a changed state then update it's the state in the service and broadcast the change so that the subscription can 
      update the component/section
      
    */

    this.sectionBehaviours.forEach(
      (behaviour) => {

        if (!this.broadcastChangesOnly || (this.broadcastChangesOnly && this.hasSectionStateChanged(page, section, behaviour, stateSettings[behaviour]))) {
          this.setSectionState(page, section, behaviour, stateSettings[behaviour]);

          this.broadcastAction(
            section + '-section',
            behaviour,
            stateSettings[behaviour]
          );

        }
      }
    )

  }

  broadcastAction(action: string, behaviour: string, value: any) {

    this.actionsService.broadcast(
      {
        action: action,
        behaviour: behaviour,
        value: value
      }
    );

  }

  hasPageStateChanged(page: string, stateName: string, stateValue: boolean) {

    return (this.state[page][stateName] != stateValue) ? true : false;

  }

  hasSectionStateChanged(page: string, section: string, stateName: string, stateValue: boolean) {

    return (this.state[page].sections[section][stateName] != stateValue) ? true : false;

  }

  setPageState(page: string, stateName: string, stateValue: boolean) {

    if (typeof this.state[page] != 'undefined' && typeof this.state[page][stateName] != 'undefined') {
      this.state[page][stateName] = stateValue;
    }

  }

  setSectionState(page: string, section: string, stateName: string, stateValue: boolean) {

    if (typeof this.state[page] != 'undefined' && typeof this.state[page].sections[section] != 'undefined') {
      this.state[page].sections[section][stateName] = stateValue;
    }

  }

  registerSectionStatusObservers(sectionName: string, context: any, childSectionNames?: string[]): Subscription[] {

    /* 
    
      Components/Sections (e.g. employment) need to call this method in their ngOnInit() lifecycle so that their k and visible properties can be automatically updated
      when the application state changes
    
    */
    var sectionObservers = [];

    this.registerJourneyPageChildSectionNames(sectionName, childSectionNames);

    this.sectionBehaviours.forEach(
      (behaviour) => {
        sectionObservers.push(sectionName + '-section.' + behaviour);
      }
    );

    return this.actionsService.registerObservers(
      sectionObservers,
      (action) => {

        if (typeof context[action.behaviour] != 'undefined') {
          context[action.behaviour] = action.value;
        }

      },
      context
    );

  }

  registerJourneyPageChildSectionNames(sectionName: string, childSectionNames?: string[]) {

    if (childSectionNames) {
      var pageName = this.getPageNameForActivatedRoute();
      var journeyPage = this.getPage(pageName).page;

      var foundChildSections = this.getPageJourneySectionChildSections(journeyPage, sectionName);

      if (foundChildSections) {
        foundChildSections.sections = childSectionNames;
      }
      else {
        journeyPage.childSections.push(
          {
            parentSectionName: sectionName,
            sections: childSectionNames
          }
        );
      }

    }

  }

  getPageJourneySectionChildSections(journeyPage: JourneyPage, sectionName: string) {

    journeyPage.childSections = journeyPage.childSections || [];

    return journeyPage.childSections.find(
      (childSection) => childSection.parentSectionName == sectionName
    );

  }

  cancelSectionStatusSubscriptions(subscription: Subscription[]) {

    /* 
    
      Components/Sections (e.g. employment) need to call this method in their ngOnDestroy() lifecycle so that theire subscription to the service can be removed
      
    */
    this.actionsService.cancelSubscriptions(subscription);

  }

  isPageCompleted(application: Application, pageName: string): boolean {

    /*
      Check to see if a particular page is completed based upon the application status
    */

    var statePages = this.getStatusPages(application.status);

    if (!statePages) {
      return false;
    }

    var pageIndex = -1,
      activeIndex = -1;

    this.journeyPages.forEach(
      (page, index) => {

        /*
          Conditionally set the index for the active page. If the page has editing==false then we'll want to return true that it is 
          completed so add 1 to the index so it passes the final check
        */
        if (statePages[page.name].active) {
          activeIndex = statePages[page.name].editing ? index : index + 1;
        }

        /*
          Conditionally set the index for the page.
        */

        if (page.name == pageName) {
          pageIndex = index;
        }

      }
    );

    return (pageIndex < activeIndex) ? true : false;

  }

  getHomeRedirectRoute() {

    var registerRedirectRoute = JSON.parse(JSON.stringify(Configuration.loadCurrentJourney(this.tokenService.roles).redirectUrls.home));
    registerRedirectRoute.route.replace('{{id}}', this.tokenService.currentUser);
    return registerRedirectRoute;

  }

  getLogo() {

    var roleGroupName = this.getRoleGroupName();
    var group = this.getGroup(roleGroupName);

    return group.configuration.appearance.logo;

  }

  getContact() {
    var roleGroupName = this.getRoleGroupName();
    var group = this.getGroup(roleGroupName);

    return group.configuration.contact.telephone;
  }

  getRoleGroupName() {

    /*
      Return the group associated with the first role in the users roles. All roles for a user should belong to the same group as the group will be used to
      load a configuration from configuration.ts
    */
    var roles = this.tokenService.roles;

    if (roles.length) {

      var foundRole = Object.keys(ROLES).find(
        (role) => ROLES[role].name == roles[0]
      );

      return (foundRole) ? ROLES[foundRole].group : '';

    }

    return '';

  }

  getGroup(key: string) {
    var foundGroup = Object.keys(GROUPS).find(
      (group) => GROUPS[group].name == key
    );

    return foundGroup ? GROUPS[foundGroup] : null;

  }

  setGroupConfiguration() {

    var roleGroup = this.getRoleGroupName();
    if (roleGroup == 'temporary user'){
      if (window.location.href.toLowerCase().indexOf('comparecarfinance') != -1) {
        this.configuration = CONFIGURATION_CCF;
      } else {
        this.configuration = CONFIGURATION;
      }
    }
    if (roleGroup != '') {

      var foundGroup = this.getGroup(roleGroup);

      if (foundGroup) {
        this.configuration = Object.assign(JSON.parse(JSON.stringify(CONFIGURATION)), JSON.parse(JSON.stringify(foundGroup.configuration)));
      }

    } else {

      this.configuration = CONFIGURATION;
    }

  }

}


