<div class="login-status desktop-only">
  <div *ngIf="loggedIn" class="btn-group">
    <button type="button" class="btn dropdown-toggle navbar-btn bg-opaque" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>Logged in as:<strong> {{username}}</strong></span>
    </button>
    <ul class="dropdown-menu">
      <div class="arrow-up"></div>
      <img src="./assets/User.PNG" style="padding-right:10px;" />
      <strong> {{username}} </strong>
      <li>
        <a (click)="logout()">Sign out</a>
      </li>
    </ul>
  </div>
</div>
<div class="login-status mobile-only">
    <div *ngIf="loggedIn" class="btn-group">
      <button type="button" class="btn dropdown-toggle navbar-btn bg-opaque" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span>{{username}}</span>
      </button>
      <ul class="dropdown-menu">
        <div class="arrow-up"></div>
        <img src="./assets/User.PNG" style="padding-right:10px;" />
        <strong> {{username}} </strong>
        <li>
          <a (click)="logout()">Sign out</a>
        </li>
      </ul>
    </div>
  </div>
