import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { ActionsService } from "../../../core/services/actions/actions.service";


@Component({
  selector: 'collapsable-container',
  templateUrl: './collapsable-container.component.html',
  styleUrls: ['./collapsable-container.component.scss']
})
export class CollapsableContainerComponent implements OnInit {
  
  constructor(private actionsService: ActionsService) { }

  enabled:boolean=true;

  @Input()
  title: string;

  @Input()
  id: string;

  @Input()
  stayOpen: boolean;

  // @Input()
  // closed: boolean = true;

  closedValue: boolean;

  @Input()
  get closed() {
    return this.closedValue;
  }
  set closed(val) {
    this.closedValue = val;
    this.closedChange.emit(this.closedValue);
  }

  @Output()
  public closedChange: EventEmitter<any> = new EventEmitter<boolean>();


  @Output()
  toggleCollapse: EventEmitter<any> = new EventEmitter<any>();

  observer: Subscription;

  isLoading = false;

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewInit() {

    this.registerLoadingObserver();
  }

  ngOnDestroy() {

    this.actionsService.cancelSubscription(this.observer);

  }

  toggle() {

    if (this.enabled) {
      this.closed = !this.closed;
    }

  }

  open() {

    this.closed = false;

  }

  close() {

    this.closed = true;

  }

  registerLoadingObserver() {

    this.observer = this.actionsService.registerObserver(
      `${this.id}-section-container.set-loading`,
      action => {
        if (action.value === false) {
          this.isLoading = true;
        }
        else {
          this.isLoading = false;
        }
      },
      this
    );

  }

  checkEnabled(): boolean {

    if (!this.enabled) {
      this.closed = true;
      return false;
    }
    return true;

  }

}
