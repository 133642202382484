import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Configuration } from "../../../core/services/configuration/configuration";

@Component({
  selector: 'backward-button',
  templateUrl: './backward-button.component.html',
  styleUrls: ['./backward-button.component.scss']
})
export class BackwardButtonComponent {
  @Input()
  valid:boolean

  @Output()
  backward: EventEmitter<any> = new EventEmitter<any>();

  showButton:boolean=Configuration.loadCurrentJourneyType().sectionNavigation.pagingMethod=='BACKWARDS_AND_FORWARDS'?true:false;

  goBackward(){
    this.backward.emit();
  }

}
