import { Component, OnInit, Input } from '@angular/core';
import { DisplayVehicle } from '../../../core/services/types-quote';
import { ActionsService } from '../base-form/base-form-testing.barrel.spec';

@Component({
  selector: 'vehicle-display',
  templateUrl: './vehicle-display.component.html',
  styleUrls: ['./vehicle-display.component.scss']
})
export class VehicleDisplayComponent implements OnInit {

  displayVehicle: DisplayVehicle[];
  applicationStatus: string;
  lendingTerms;
  quote: any;

  constructor(private actionsService: ActionsService) {
    this.actionsService.registerObserver(
      'displayVehicle.updateVehicle',
      action => {
        this.displayVehicle = action.value.vehicle;
        this.applicationStatus = action.value.status;
        this.lendingTerms = action.value.loanOffer;
      },
      this
    );
  }

  ngOnInit() {
    // if(this.application != null ) {
    //   this.quote = this.application.quotes[0];
    // }
  }
}
