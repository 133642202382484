/*
  Proofs
*/
declare type Proofs = { [type: string]: Array<Proof> }

class Proof {
  public id: number;
  public title: string;
  public type: string;
  public filename: string;
  public url: string;
  public dateUploaded: string;
  public status: string;
}

class RequiredProof {
  public id: string;
  public label: string;
  public description: string;
  public docsRequired: number;
}

export {
    Proofs, Proof, RequiredProof
}