import { Component, OnInit } from '@angular/core';
import { ActionsService } from '../base-form/base-form-testing.barrel.spec';

@Component({
  selector: 'creditplus-privacy-policy',
  templateUrl: './creditplus-privacy-policy.component.html',
  styleUrls: ['./creditplus-privacy-policy.component.scss']
})
export class CreditplusPrivacyPolicyComponent implements OnInit {
  constructor(private actionsService: ActionsService) { }

  ngOnInit() {
  }
  
  hidePolicy() {
    this.actionsService.broadcast({
      action: 'hide',
      behaviour: 'privacy',
      value: true
    });

  }
}
