import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'section-child-container',
  templateUrl: './section-child-container.component.html',
  styleUrls: ['./section-child-container.component.scss']
})
export class SectionChildContainerComponent implements OnInit {

  @Input()
  title: string;

  constructor() { }

  ngOnInit() {
  }

}
