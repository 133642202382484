<div class="content">
  <h1>Here's what we need</h1>
  <div *ngIf="drivingLicenceFront" class="section">
    <p>
      <strong>Your Driving Licence (Front)</strong> scanned in a format shown below and uploaded as JPG, PNG or PDF</p>
    <div class="flex">
      <div class="side">
        <div class="image">
          <img src="../../../../assets/Driving_license_good.png">
        </div>
        <div class="instructions">
          <i class="far fa-check-circle green"></i>
          <span>Clear text and picture</span>
        </div>
      </div>
      <div class="desktop-only line"></div>
      <div>
        <div class="image">
          <img src="../../../../assets/Driving_license_bad.png">
        </div>
        <div class="instructions">
          <i class="far fa-times-circle red"></i>
          <span>Blurred image and text</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="drivingLicenceback" class="section">
    <p>
      <strong>Your Driving Licence (Back)</strong> scanned in a format shown below and uploaded as JPG, PNG or PDF</p>
    <div class="flex">
      <div class="side">
        <div class="image">
          <img src="../../../../assets/Driving-licence-back.png">
        </div>
        <div class="instructions">
          <i class="far fa-check-circle green"></i>
          <span>Clear dates and references</span>
        </div>
      </div>
      <div class="desktop-only line"></div>
      <div class="side">
        <div class="image">
          <img src="../../../../assets/Driving-licence-back-blurred.png">
        </div>
        <div class="instructions">
          <i class="far fa-times-circle red" ></i>
          <span>Blurred or low quality, unreadable or croppped</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="proofOfAddress" class="section">
    <p>
      <strong>A utility bill </strong> scanned in a format shown below and uploaded as JPG, PNG or PDF</p>
    <div class="flex">
      <div class="side">
        <div class="image">
          <img src="../../../../assets/utility_bill_good.png">
        </div>
        <div class="instructions">
          <i class="far fa-check-circle green"></i>
          <span>Visible name and address confirmed, and the bill must be dated in the last 90 days</span>
        </div>
      </div>
      <div class="desktop-only line"></div>
      <div class="side">
        <div class="image">
          <img src="../../../../assets/utility_bill_bad.png">
        </div>
        <div class="instructions">
          <i class="far fa-times-circle red" ></i>
          <span>Either of the details not correct or clearly visible</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="proofOfEmployment" class="section">
    <p>
      <strong>Most recent Three payslips </strong> scanned in a format shown below and uploaded as JPG, PNG or PDF</p>
    <div class="flex">
      <div class="side">
        <div class="image">
          <img src="../../../../assets/payslip_good.png">
        </div>
        <div class="instructions">
          <i class="far fa-check-circle green"></i>
          <span>Employer and employee details seen clearly</span>
        </div>
      </div>
      <div class="desktop-only line"></div>
      <div class="side">
        <div class="image">
          <img src="../../../../assets/payslip_bad.png">
        </div>
        <div class="instructions">
          <i class="far fa-times-circle red" ></i>
          <span>Blurred, cropped or concealed details</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selfEmployment" class="section">
    <p>
      <strong>A bank statement </strong> scanned in a format shown below and uploaded as JPG, PNG or PDF</p>
    <div class="flex">
      <div class="side">
        <div class="image">
          <img src="../../../../assets/bank_statement_good.png">
        </div>
        <div class="instructions">
          <i class="far fa-check-circle green"></i>
          <span>Visible name and address confirmed, and the statement must be dated in the last 90 days</span>
        </div>
      </div>
      <div class="desktop-only line"></div>
      <div class="side">
        <div class="image">
          <img src="../../../../assets/bank_statement_bad.png">
        </div>
        <div class="instructions">
          <i class="far fa-times-circle red"></i>
          <span>Either of the details not correct or clearly visible</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="Passport" class="section">
    <p>
      <strong>Your Passport (ID page) </strong> scanned in a format shown below and uploaded as JPG, PNG or PDF</p>
    <div class="flex">
      <div class="side">
        <div class="image">
          <img src="../../../../assets/passport-good.png">
        </div>
        <div class="instructions">
          <i class="far fa-check-circle green" ></i>
          <span>Not blurry, not cropped, visible picture and text.</span>
        </div>
      </div>
      <div class="desktop-only line"></div>
      <div class="side"> 
        <div class="image">
          <img src="../../../../assets/passport-bad.png">
        </div>
        <div class="instructions">
          <i class="far fa-times-circle red" ></i>
          <span>Reference code cropped or blurry/low quality scan</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="customerPack" class="section">
    <h1>Customer Pack</h1>
    <div class="image"></div>
    <div class="instructions">
      <ul>
        <h4>Instructuions:</h4>
        <li>Do this</li>
        <li>Do that</li>
        <li>that will do this</li>
      </ul>
    </div>
  </div>
  <div *ngIf="lenderPack" class="section">
    <h1>Lender Pack</h1>
    <div class="image"></div>
    <div class="instructions">
      <ul>
        <h4>Instructuions:</h4>
        <li>Do this</li>
        <li>Do that</li>
        <li>that will do this</li>
      </ul>
    </div>
  </div>
-->
  <div class="close-input">
    <button class="secondary-button wide" (click)="closeToggle()">Close</button>
  </div>
</div>