import { Injectable } from '@angular/core';
import { ResidentialAddress } from "../types";

@Injectable()
export class SorterService {

  sort(input:Array<any>): Array<any>{
    let list = this.createTemporaryList(input);
    return list.sort((first, second) => (first.startDate <= second.startDate) ? 1 : -1);
  }

  private createTemporaryList(input:Array<any>): Array<any> {
    return Array.from(input);
  }

}
