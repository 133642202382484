import { Injectable } from '@angular/core';
import { TabDirective } from "ngx-bootstrap/tabs";
import { Router } from "@angular/router";

@Injectable()
export class TabsService {
  constructor(private router: Router){ }
  
  public openTabByRouteName(tabs : Array<TabConfig>, ngxTabs : Array<TabDirective>, route: string, applicationId:number) {
    let selectedTab: TabConfig = this.findTabByRoute(tabs, route);

    // TODO investigate this
    // if(selectedTab == null){
    //   selectedTab = tabs[0];
    // }

    if(selectedTab != null){
      if (selectedTab.isInvalid()) {
        this.navigateToAlternativeRouteForTab(tabs, ngxTabs, applicationId, selectedTab, false);
      } 
      else {
        this.activateNgxTab(ngxTabs, selectedTab.ngxOrder);
      }
    }
  }

  private activateNgxTab(ngxTabs : Array<TabDirective>, index: number) {
    let ngxTab: TabDirective = ngxTabs[index];

    if (ngxTab) {
        ngxTab.active = true;
    } 
    else {
      throw Error("There is no ngx tab matching at the requested index")
    }
  }

  private findTabByRoute(tabs : Array<TabConfig>, route: string): TabConfig {
    let selectedTabs : Array<TabConfig> = tabs.filter(t => this.asRoute(t.name) === route);

    if (selectedTabs) {
      return selectedTabs[0];
    }

    return null;
  }

  private findTabByName(tabs : Array<TabConfig>, name: string): TabConfig {
    let selectedTabs : Array<TabConfig> = tabs.filter(t => t.name === name);

    if (selectedTabs) {
      return selectedTabs[0];
    }

    return null;
  }

  private navigateToAlternativeRouteForTab(tabs : Array<TabConfig>, ngxTabs : Array<TabDirective>, applicationId: number, selectedTab: TabConfig, withRouting:boolean): void {
    let alternativeTab: TabConfig = this.findTabByName(tabs, selectedTab.backfallTab);

    if (alternativeTab) {
      if(!alternativeTab.isInvalid()){
        this.doRoute(ngxTabs, alternativeTab, applicationId, withRouting)
      }
      else{
        throw Error("The alternative path should be valid");
      }
    } 
    else {
      throw Error("An alternative tab was not found");
    }
  }

  private doRoute(ngxTabs : Array<TabDirective>, selectedTab:TabConfig, applicationId: number, withRouting:boolean){      
      if(withRouting){
        this.router.navigate(['/applications', applicationId, this.asRoute(selectedTab.name)]);
      }
      else {
        this.activateNgxTab(ngxTabs, selectedTab.ngxOrder)
      }
  }

  public asRoute(name: string): string {
      return name.toLowerCase().split(' ').join('-');
  }
}

export class TabConfig {
    ngxOrder: number;
    name: string;
    pageName:string;
    invalid: string;
    backfallTab: string;
    isInvalid: () => boolean;
}

