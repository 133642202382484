
import {finalize, catchError, map} from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Document } from './services/types';
import { LoginService } from './services/login/login.service';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { TimedActionService } from './services/timed-action/timed-action.service';
import { LoadingModalService } from './services/modal/loading-modal.service';

@Injectable()
export class DocumentsService {

  downloadDocumentUrl = environment.base + '/docs/download?fileName=:fileName&applicationId=:applicationId';

  constructor(
    private loginService: LoginService,
    private http: HttpClient,
    private timedActionService: TimedActionService,
    private loadingModalService: LoadingModalService) { }

  download(document: Document, applicationId: string): Observable<any> {
    const url = this.downloadDocumentUrl.replace(':fileName', document.fileName).replace(':applicationId', applicationId);
    const options = this.loginService.getHeaderOptions(true);

    options.append('Response-Type','Blob');

    this.loadingModalService.show();
    return this.http.get(url, {headers: options}).pipe(
      map((response) => {
        this.timedActionService.resetTimer();
        return response;
      }),
      catchError((error) => {
        // Reset the token expiration timer depending upon the error code
        this.timedActionService.checkResetTimerOnError(error);
        throw error;
      }),
      finalize(() => this.loadingModalService.hide()),);

  }
}
