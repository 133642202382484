<div class="card-image-no-padding">
  <div class="card-image-aligner">    
    <img src="{{vehicle.ImageUrl_More1}}" onerror="this.src='../../../../assets/image-loading.gif'; this.style.maxHeight='230px'; this.style.width='auto'">  
  </div> 
</div>

<div class="raised-card-text">{{vehicle.Make | titlecase}} {{vehicle.Model | titlecase}}</div>
<div class="raised-card-subtext">
  <span *ngIf="vehicle.Mileage!=null">{{vehicle.Mileage | number: ''}} miles, </span>
  <span *ngIf="vehicle.Transmission!=null && vehicle.Transmission!=''">{{vehicle.Transmission | titlecase}}, </span>
  <span *ngIf="vehicle.FuelType!=null && vehicle.FuelType!=''">{{vehicle.FuelType | titlecase}}, </span>
  <span *ngIf="vehicle.Doors!=null&&vehicle.Doors!=0">{{vehicle.Doors}} doors, </span>
  <span *ngIf="vehicle.Colour!=null && vehicle.Colour!=''">{{vehicle.Colour | titlecase}} </span>
</div>

<!--<div class="button-container" *ngIf="stage=='pickLoan'||stage=='uploadProofs'||stage=='signAgreement'">-->
<div class="button-container" *ngIf="stage=='pickLoan'">
  <div *ngIf="stage=='pickLoan'">
    <div class="standard-button disabled-button" *ngIf="loanOptionsReady==false">Pending loan options</div>
    <a routerLink="/applications/{{customerId}}/loan-offer"><div class="standard-button" *ngIf="loanOptionsReady==true">View loan options</div></a>
  </div>
  <!--<div *ngIf="stage=='uploadProofs'">
    <!--href needs to be updated when element created
    <a routerLink="/proofs"><div class="standard-button">Upload your proofs</div></a>
  </div>
  <div *ngIf="stage=='signAgreement'">
    <div class="standard-button disabled-button" *ngIf="agreementDocsReady==false">Pending agreement</div>
    <!--href needs to be updated when element created
    <a routerLink="/agreement"><div class="standard-button" *ngIf="agreementDocsReady==true">Sign your agreement</div></a>
  </div>-->
</div>