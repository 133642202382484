<ul class="list-group" *ngFor="let item of uploader.queue">

  <ng-container *ngIf="acceptedFileFormats.indexOf(item.file.type)!=-1 then fileFormatAccepted else fileFormatNotAccepted"></ng-container>

  <ng-template #fileFormatAccepted>
    <ng-container *ngIf="item.file.size<=maximumFileSize then fileAccepted else fileTooBig"></ng-container>
  </ng-template>

  <ng-template #fileAccepted>
    <li class="list-group-item">
      <div class="space-between">
        <span>
          <i aria-hidden="true" class="fa fa-file-text-o fa-1"></i>{{item?.file?.name}}
        </span>
        <div class="float-right">
          <button class="btn btn-main button-cp-primary btn-sm" [hidden]="item.isUploading" (click)="onUpload(item)">Upload</button>
          <button class="btn btn-main button-cp-primary btn-sm" [hidden]="item.isUploading" (click)="onRemove(item)">Remove</button>
        </div>
      </div>
      <div class="progress" *ngIf="item.isUploading">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }">{{uploader.progress}}%</div>
      </div>
    </li>
  </ng-template>

  <ng-template #fileTooBig>
    <li class="list-group-item">
      <div class="space-between">
        <span>
          <i aria-hidden="true" class="fa fa-file-text-o fa-1"></i>{{item?.file?.name}}</span>
      </div>
      <div class="space-between">
        <span>This file exceeds the maximum file size restriction of {{maximumFileSize/1024000}}MB so will not be uploaded
          <button class="btn button-cp-primary btn-sm" [hidden]="item.isUploading" (click)="onRemove(item)">Ok</button>
        </span>
      </div>
    </li>
  </ng-template>

  <ng-template #fileFormatNotAccepted>
    <li class="list-group-item">
      <div class="space-between">
        <span>
          <i aria-hidden="true" class="fa fa-file-text-o fa-1"></i>{{item?.file?.name}}</span>
      </div>
      <div class="space-between">
        <span>This file is not a format that we accept so will not be uploaded. The following file types are accepted:
          <b>{{acceptedFileFormatsList}}</b>
          <button class="btn button-cp-primary btn-sm" [hidden]="item.isUploading" (click)="onRemove(item)">Ok</button>
        </span>
      </div>
    </li>
  </ng-template>


</ul>
