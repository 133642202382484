/*
  Quotes
*/
class LookUpVehicle {
    public regNumber: string;
    public loanTerm: number;
    public loanDeposit: number;
    public loanAmmount: number;
    public vehicleMileage: number;
    public cardId: number;
    public cardSuggested: boolean;
    public errorList?: errorList[];
    public IsShowroomPicked?: boolean;
}

class DisplayVehicle {
    public make: string;
    public model: string;
    public variant: string;
    public source?: string;
    public registration: string;
    public regYear: number;
    public term: number;
    public financeType: string;
    public monthlyPayments: number;
    public imageUrl_Main: string;
    public balloon?:number;
}

class Quote {
    public id: number;
    public applicationId: number;
    public vehicle: ProposedVehicle;
    public financeRequest: FinanceRequest;
    public financeResponse: FinanceResponse;
    public dateReceived: string;
    public isSelected: boolean;
    public manualLookUpOccured?: boolean;
    public showroomVisitOccured?: boolean;
    public showroomCarSelectionOccured?: boolean;
}

class BaseVehicle {
    public id?: number;
    public make?: string;
    public model?: string;
    public source?: string;
    public derivative?: string;
    public variant?: string;
    public newcar?: boolean;
    public mockcar?: boolean;
    public bodyType?: boolean;
    public engineSize?: number;
    public fuelType?: string;
    public transmission?: string;
    public doors?: number;
    public colour?: string;
    public description?: string;
    public сO2Emissions?: number;
    public IsShowroomPicked?: boolean;
    public isSuggested?: boolean;
}

class ProposedVehicle extends BaseVehicle {
    public registration?: string;
    public dateOfRegistration?: string;
    public mileage?: any;
    public fullPrice?: number;
    public price?: number;
    public valuation?: VehicleValuation;
    public errors?: errorList;
    public imageUrl?: string;
    public ImageUrl_Main?: string;
    public ImageUrl_Main1?: string;
    public ImageUrl_Main2?: string;
    public ImageUrl_Main3?: string;
    public ImageUrl_Main4?: string;
    public ImageUrl_Main5?: string;
    public ImageUrl_Main6?: string;
    public ImageUrl_Main7?: string;
    public ImageUrl_Main8?: string;
    public ImageUrl_Main9?: string;
    public capDetails?: VehicleCap;
}

class VehicleValuation {
    public average: number;
    public below: number;
    public clean: number;
    public retail: number;
    public future: number;
}

class errorList{
    code: string;
    label: string;
}

class VehicleCap {
    public id: number;
    public code: string;
}

class FinanceRequest {
    public loanAmount: number;
    public term: number;
    public deposit: Deposit;
    public financeType: string;
    public annualMileage?: number;
    public flatRatePercent?:number;
    public commissionLenderTiers? : LenderTier[];
}

class LenderTier {
    public LenderId: number;
    public Tier: number;
}

class FinanceResponse {
    public financeType?: string;
    public loanAmount?: number;
    public monthlyPayment?: number;
    public flatRate?: number;
    public apr?: number;
    public fees?: number;
    public balloon?: number;
    public deposit?: number;
    public term?: number;
    public totalAmountPayable?: number;
}

class Deposit {
    public cash: number;
    public partExchange?: ProposedVehicle;
    public total: number;
}

class QuoteServiceResponse {
    public id: string;
    public applicationId: string;
    public vehicle: ProposedVehicle;
    public financeRequest: ProposedVehicle;
    public financeResponse: FinanceResponse;
    public isSelected: boolean;
    public dateReceived: string;
}

class AnalyticsData {
    public vehicle: VehicleAnalyticsData;
    public terms?: TermsAnalyticsData;
}


class VehicleAnalyticsData {
    vrm: string;
    mileage: number;
    price: number;
    cap_valuation?: number;
}

class TermsAnalyticsData {
    loanAmount?: number;
    months?: number;
    deposit?: number;
    financeType?: string;
}

class Lender {
    code: String;
    financeTypeOffered: String;
    hardSearches: boolean;
    id: number;
    lenderTier: number;
    maxBaseRate: number;
    minBaseRate: number;
    name: String;
}

export {
    LookUpVehicle, Quote, BaseVehicle, ProposedVehicle, VehicleValuation, VehicleCap, FinanceRequest, FinanceResponse, Deposit, QuoteServiceResponse,
     AnalyticsData, VehicleAnalyticsData, TermsAnalyticsData, DisplayVehicle, Lender
}
