import { Directive, OnChanges, SimpleChanges, Input } from '@angular/core';
import { ValidatorFn, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from "@angular/forms";
import { isANumber, containsElement } from "../utilities";

export function anyOf(collection: Array<string | number>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (control.value != null) {
      if (!containsElement(collection, control.value)) {
        return {
          'anyof': {
            actualValue: control.value,
            requiredValue: collection
          }
        }
      }
    }

    return null;
  }
}

@Directive({
  selector: '[anyof]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AnyofDirective, multi: true }]
})
export class AnyofDirective implements Validator, OnChanges {
  @Input()
  anyof: any;

  validator: ValidatorFn;

  validate(control: AbstractControl): ValidationErrors {
    if (this.validator) {
      return this.validator(control)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['anyof'];

    if (change) {
      this.validator = anyOf(change.currentValue);
    }
    else {
      this.validator = null;
    }
  }
}
