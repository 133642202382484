import { AuthenticationResult } from './../../../core/services/login/loginmodels';
import { LoginService } from './../../../core/services/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';

import { Configuration } from "../../../core/services/configuration/configuration";
import { Form, FormGroup } from '@angular/forms';
import { PasswordConfiguration } from '../../../core/services/configuration/configuration.types';
import { Application } from '../../../core/services/types';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  applicationId: string;
  password: string;
  confirmNewPassword: string;
  newPassword: string;
  showPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmNewPassword: boolean = false;
  passwordChanged: boolean = false;
  changingPassword: boolean = false;
  passwordPolicy: PasswordConfiguration;
  passwordPolicyKey: string;
  showHelp: boolean = Configuration.loadCurrentJourneyType().help.show;

  @ViewChild('form') form: FormGroup;

  constructor(private activatedRoute: ActivatedRoute, private loginService: LoginService, private router: Router,
    protected titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle("Change Password");
    var key = this.loginService.getPasswordPolicykey();
    this.setPasswordPolicy(key);
    this.applicationId=localStorage.getItem("currentUser");
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  async submit() {
    this.changingPassword = true;
    let registrationResult: AuthenticationResult = await this.loginService.changePassword(this.getBody()).toPromise();
    if (this.isValidAnswer(registrationResult)) {
      this.form.reset();
      this.passwordChanged = true;
      this.loginService.setUserInformation(registrationResult);
    }
    this.passwordChanged=true;
    setTimeout(()=> {
    this.loginService.logout();
    this.router.navigateByUrl("/login");
    this.changingPassword = false;
    }, 2000);
  }

  getBody() {
    return {
      currentPass: this.password,
      newPass: this.newPassword
    };
  }

  closeAlert() {
    this.passwordChanged = false;
  }

  private isValidAnswer(result: AuthenticationResult) {
    return result
      && result.username
      && result.bearerToken
      && result.id
  }

  setPasswordPolicy(passwordPolicyKey: string) {
    this.passwordPolicyKey = passwordPolicyKey;
    this.passwordPolicy = this.loginService.getPasswordPolicy(passwordPolicyKey);
  }

  showHidePassword(){
    if (this.showPassword==true){
      this.showPassword=false;
    }
    else{
      this.showPassword=true;
    }
  }

  showHideNewPassword(){
    if (this.showNewPassword==true){
      this.showNewPassword=false;
    }
    else{
      this.showNewPassword=true;
    }
  }

  showHideConfirmNewPassword(){
    if (this.showConfirmNewPassword==true){
      this.showConfirmNewPassword=false;
    }
    else{
      this.showConfirmNewPassword=true;
    }
  }
}