import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { CustomRadioButtonComponent } from "../custom-radio-button/custom-radio-button.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'yes-or-no',
  templateUrl: './custom-radio-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesOrNoComponent),
      multi: true,
    }]
})
export class YesOrNoComponent extends CustomRadioButtonComponent {

  @Input()
  editing: boolean = true;

  constructor() { super(); }

  ngOnInit() {
    this.items = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
  }

}
