<div class="full-width">
  <div class="desktop-only">
    <div class="container-right" *ngIf="displayVehicle">
      <div class="right-div">
        <div class="flex">
          <show-vehicle [displayVehicle]="displayVehicle" [applicationStatus]="applicationStatus" [lendingTerms]="lendingTerms"></show-vehicle>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-only">
    <div *ngIf="displayVehicle">
      <show-vehicle [displayVehicle]="displayVehicle" [applicationStatus]="applicationStatus" [lendingTerms]="lendingTerms"></show-vehicle>
    </div>
  </div>
</div>