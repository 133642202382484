import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from './../../../core/services/login/login.service';
import { TokenService } from "../../../core/services/token/token.service";

import { LookupItem } from "../../../core/services/configuration/configuration";
import { environment } from '../../../../environments/environment';
import { PasswordConfiguration } from '../../../core/services/configuration/configuration.types';
import { NgForm } from '@angular/forms';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { GROUPS } from '../../../core/services/configuration/configuration-groups';

import { Title } from '@angular/platform-browser';
import { ApplicationStatusService } from '../../../core/services/application-status/application-status.service';
import { FavIconService } from '../../../core/services/fav-icon/fav-icon.service';
import { ProposedVehicle } from '../../../core/services/types';
import { Meta } from '@angular/platform-browser';
import { CustomersService } from '../../../core/services/customers/customers.service';
//import { ApplicationHeaderComponent } from  '../../../shared/components/application-header/application-header.component'
const comparecarfinanceDomain = 'comparecarfinance';
const dealerplusDomain = 'dealerplus';


@Component({
  selector: 'login-panel',
  templateUrl: './login-panel.component.html',
  styleUrls: ['./login-panel.component.scss']
})
export class LoginPanelComponent {

  @ViewChild('loginForm') loginForm: NgForm;
  @ViewChild('forgottenPasswordModal') forgottenPasswordModal: ElementRef;
  vehicle: ProposedVehicle

  //values for handling new customers
  username:string;
  contactEmail:string;
  contactId:string;
  registerLink:string;

  isDemo: boolean = environment.demo;
  accessDenied: boolean = false;
  showPassword: boolean = false;
  logintype: string;
  loggingIn: boolean = false;
  platform: string;
  nextPageDpDealerUrl: string;
  cssClass: string;
  model = {
    username: '',
    password: '',
    source: ''
    
  };
  password: string;
  passwordError: boolean = true;
  forgotPassword:boolean = false;
  passwordEmailSuccess:boolean = false;
  passwordEmailFail:boolean = false;
  verifyingPasswordEmail:boolean = false;

  errorMessage = '';

  passwordPolicy: PasswordConfiguration;
  passwordPolicyKey: string;

  displayLoginTypes: boolean;

  loginTypes: LookupItem = {
    controlType: 'buttons',
    items: [
      { label: "Customer", value: GROUPS.CONSUMER.name },
      { label: "Dealer", value: GROUPS.DEALER.name },
      { label: "Compare Car Finance", value: GROUPS.CCF.name }]
  };

  nextPageUrl: string;

  constructor(private loginService: LoginService, private meta: Meta, private parentRouter: Router,
    private tokenService: TokenService, private actionsService: ActionsService,
    private titleService: Title, private favIconService: FavIconService,
    private applicationStatusService: ApplicationStatusService, private customerService: CustomersService,
    private activatedRoute: ActivatedRoute,) {this.activatedRoute.queryParams.subscribe(
      params => {
        this.username = params['username'];
        this.contactId = params['customerId'];
      }
    );
      if (this.tokenService.isAuthenticated()) {
        this.parentRouter.navigateByUrl(this.getCurrentStep("test", this.tokenService.currentUser));
    }

  }

  ngOnInit() {
    this.titleService.setTitle("Login");
    this.registerLink = "/register?customerId="+ this.contactId + "&username=" +this.username;

    if (window.location.href.toLowerCase().indexOf("localhost") != -1) {
      this.displayLoginTypes = true;
    }
    if (window.location.href.toLowerCase().indexOf(comparecarfinanceDomain) != -1) {
      this.model.source = 'ccf customer'
    } else if (window.location.href.toLowerCase().indexOf(dealerplusDomain) != -1) {
      this.model.source = 'dealerplus dealer'
      this.platform = 'dp'
    } else {
      this.model.source = 'customer'
    }
    var key = this.model.source;
    this.changeLoginType(key);

    this.nextPageUrl = 'applications/';
    this.nextPageDpDealerUrl = 'dealer-portal'
    this.contactEmail = this.username;
    
  }

  login() {
    if (this.platform == "dp") {
      var element = <HTMLInputElement>document.getElementById("source");
      this.model.source = element.value;
    }
    this.loggingIn = true;
    this.loginService.login(this.model, this.passwordPolicyKey)
      .subscribe(
        response => {
          if (response.roles.indexOf("dealerplus dealer") != -1) {
            this.loginService.setUserInformation(response);
            this.accessDenied = false;
            this.actionsService.broadcast(
              {
                action: 'document',
                behaviour: 'changeCSSClass',
                value: this.logintype
              }
            );
            this.parentRouter.navigateByUrl(this.nextPageDpDealerUrl);
          }

          else if (response.roles.indexOf("dealerplus customer") != -1) {
            this.loginService.setUserInformation(response);
            this.accessDenied = false;
            this.actionsService.broadcast(
              {
                action: 'document',
                behaviour: 'changeCSSClass',
                value: this.logintype
              }
            );
            this.parentRouter.navigateByUrl(this.getCurrentStep(response.applicationStatus, response.id));
          }
          else {
            if (typeof response != null) {
              this.loginService.setUserInformation(response);
              this.parentRouter.navigateByUrl(this.getCurrentStep(response.applicationStatus, response.id));
              this.accessDenied = false;
            }
          }
        },
        error => {
          this.loggingIn = false;
          if (error.status == 401) {
            this.accessDenied = true;
          }
          else {
            throw error;
          }
        }
      );
  }

  forgottenPassword(forgotten:boolean){
    if (forgotten==true){
    this.forgotPassword = true;
    }
    else{
      this.forgotPassword = false;
    }
  }

  // send email for people who forgot their password
  sendPasswordEmail(){
    this.verifyingPasswordEmail = true;
      this.customerService.createPasswordLink(this.model.username).subscribe(
        response => {this.sendPasswordEmailSuccess();},
        error => {this.sendPasswordEmailFail()}
      );
  }

  sendPasswordEmailSuccess(){
    this.verifyingPasswordEmail = false;
    this.passwordEmailSuccess = true;
  }

  sendPasswordEmailFail(){
    this.verifyingPasswordEmail = false;
    this.passwordEmailFail = true;
  }

  changingForgottenPasswordEmail(){
    if (this.verifyingPasswordEmail!=true){
    this.passwordEmailSuccess = false;
    this.passwordEmailFail = false;
    }
  }

  changeLoginType(loginType: string) {
    this.setPasswordPolicy(loginType);
    this.model.source = loginType;
    this.logintype = loginType;
  }

  setPasswordPolicy(passwordPolicyKey: string) {
    this.passwordPolicyKey = passwordPolicyKey;
    this.passwordPolicy = this.loginService.getPasswordPolicy(this.passwordPolicyKey);
  }

  getCurrentStep(status, contactId): string {
    var result;
    switch (status) {
      // case 'EnquiryReceived':
      //   result = 'applications/' + contactId + '/quotes';
      //   break;
      // case 'FurtherInfoRequired':
      //   result = 'applications/' + contactId + '/extra-details';
      //   break;
      // case 'ProposalSent':
      // case 'ProposalAccepted':
      // case 'ProposalReferred':
      //   result = 'applications/' + contactId + '/loan-offer';
      //   break;
      // case 'DocsIssued':
      //   result = 'applications/' + contactId + '/agreement-and-documentation';
      //   break;
      // case 'ProofsReceived':
      // case 'AwaitingProofs':
      // case 'Declined':
      //   result = 'applications/' + contactId + '/final-page';
      //   break;
      default:
        result = 'vehicle/' + localStorage.getItem("currentUser"); //+ contactID needs to be added
        break;
    }
    return result;
  }


  closeModal() {

        this.forgottenPasswordModal.nativeElement.style.display = "none";

  }

  showModal() {

        this.forgottenPasswordModal.nativeElement.style.display = "block";

    
  }

 

  showHidePassword() {
    if (this.showPassword == true) {
      this.showPassword = false;
    }
    else {
      this.showPassword = true;
    }
  }

  checkPassword() {
    var password = this.model.password;
    if (/[a-z]/.test(password) == false || /[A-Z]/.test(password) == false ||
      /\d/.test(password) == false || password.length < 8) {
      this.passwordError = true;
    }
    else {
      this.passwordError = false;
    }

  }


}


