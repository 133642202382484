import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  imports: [HttpClientModule, BrowserModule, CoreModule, SharedModule, LoginModule, NgxSliderModule, AppRoutingModule ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
  ]
})
export class AppModule { }
