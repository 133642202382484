<ng-template #popoverTemplate>
  <div [innerHTML]="popoverHTML"></div>
</ng-template>

<div class="form-group" [ngClass]="'columns-'+items.length">
  <h1>{{label}}</h1>
  <div class="btn-group columns" role="group" aria-label="Basic example">
    <a class="login-as-title">Login as:</a>
    <a *ngFor="let item of items" type="button" (click)="setValue(item.value);">{{item.label}}</a>
  </div>
</div>
