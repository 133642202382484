/*
  Income & Expentidture
*/
class Income {
    public salary?: number;
    public monthlyIncome?: number;
}

export {
    Income
}