import { Injectable } from '@angular/core';

import { Application, Condition, ProposedVehicle } from "../../../core/services/types";

import * as moment from 'moment/moment';

@Injectable()
export class ConditionsService {

  constructor() { }

  public checkConditions(application: Application, conditions: Condition[]) {

    var vehicle = this.getSelectedVehicle(application);

    return this.checkVehicleConditions(application, vehicle, conditions);

  }

  public checkVehicleConditions(application: Application, vehicle: ProposedVehicle, conditions: Condition[]) {

    var conditionMet: boolean = true;

    return conditions.every(
      condition => {

        if (Object.keys(vehicle).length == 0 && condition.key.toLowerCase() != 'netmonthlyincome') {
          return false;
        }

        switch (condition.key.toLowerCase()) {
          case 'retailprice':
            conditionMet = this.checkCondition(vehicle.valuation.retail, condition);
            break;

          case 'enginesize':
            conditionMet = this.checkCondition(vehicle.engineSize, condition);
            break;

          case 'netmonthlyincome':
            conditionMet = this.checkCondition(application.mainApplicant.income.monthlyIncome, condition);
            break;

          case 'mileage':
            conditionMet = this.checkCondition(vehicle.mileage, condition);
            break;

          case 'vehicleage':
            var dateOfApplication = moment(application.dateOpened);
            var duration = moment.duration(dateOfApplication.diff((vehicle.dateOfRegistration)));
            conditionMet = this.checkCondition(duration.asYears().toFixed(2), condition);
            break;

          default:
            conditionMet = false;
        }

        return conditionMet;
      }
    );

  }

  public checkCondition(value, condition: Condition): boolean {

    var show: boolean = true;

    switch (condition.comparison) {
      case '=':
        show = (value == condition.value);
        break;

      case '>=':
        show = (value >= condition.value);
        break;

      case '<=':
        show = (value <= condition.value);
        break;

      case '<':
        show = (value < condition.value);
        break;

      case '>':
        show = (value > condition.value);
        break;

      case '<>':
        show = (value != condition.value);
        break;

      default:
        show = false;
    }

    return show;

  }

  public getSelectedVehicle(application: Application) {

    if (typeof application.quotes != 'undefined') {
      var quote = application.quotes.find(quote => quote.isSelected == true);

      if (typeof quote != 'undefined') {
        return quote.vehicle;
      }
    }
    return {};
  }

}
