import { Component, Input } from '@angular/core';

import { ButtonGroupComponent }  from './../button-group/button-group.component';

import { ActionsService } from './../../../../core/services/actions/actions.service';

import { ButtonGroup } from './../../models.class';

const GROUP='SORT';

@Component({
    selector: 'sort',
    templateUrl: 'sort.component.html',
    styleUrls: ['sort.component.scss']
})
export class SortComponent extends ButtonGroupComponent {

    constructor(public actionsService :ActionsService) {

        super(actionsService);
        this.registerAcceptsSetValue(GROUP);

    }

}
