import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

import { ActionsService } from './../../../../core/services/actions/actions.service';

import { RecordsetPager, Navigate, Visibility, Broadcast } from './../../models.class';
import { Subscription } from 'rxjs';

const GROUP = 'pager';

@Component({
    moduleId: module.id,
    selector: 'recordset-pager',
    templateUrl: 'recordset-pager.component.html',
    styleUrls: ['recordset-pager.component.scss']
})
export class RecordsetPagerComponent implements OnInit, Navigate, Visibility, Broadcast {

    @Input()
    public model: RecordsetPager;

    @Input()
    public pagerId: string;

    @Input()
    public parentName: string;

    pages: any[];

    paginatorChanges: Subscription;

    totalPages: number;

    isMobile = (window.navigator.userAgent.indexOf('Mobile') >= 0);

    constructor(private actionsService: ActionsService) {

        this.pages = [];

    }

    ngOnInit() {

        

        this.paginatorChanges = this.actionsService.registerObserver(
            'paginator.change',
            action => {
                if (this.isMobile) {
                    this.initializeSmallScreen();
                } else {
                    this.initialize();
                }
            },
            this
        );

        if (this.isMobile) {
            this.initializeSmallScreen();
        } else {
            this.initialize();
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (this.isMobile) {
            this.initializeSmallScreen();
        } else {
            this.initialize();
        }
        if (changes['model']) {

        }

    }

    initialize() {

        this.pages = [];

        this.totalPages = Math.ceil(this.model.totalRecords / this.model.pageSize);

        if (this.totalPages < 10) {
            for (var i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
            }
        } else {
            for (var i = 0, j = this.model.page - 4; this.pages.length < 10; i++ , j++) {
                if (this.model.page < 5) {
                    j = this.model.page - 9 + i
                } if (this.model.page > this.totalPages - 5) {
                    j = this.totalPages - 9 + i;
                }
                if (j > 0 && j <= this.totalPages) {
                    this.pages.push(j);
                }
            }
            if (this.model.page > (this.totalPages)) {
                this.model.page = 1;
            }
        }

    }

    initializeSmallScreen() {
        this.pages = [];

        this.totalPages = Math.ceil(this.model.totalRecords / this.model.pageSize);

        if (this.totalPages < 3) {
            for (var i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
            }
        } else {
            for (var i = 0, j = this.model.page - 1; this.pages.length < 3; i++ , j++) {
                if (this.model.page < 1) {
                    j = this.model.page - 2 + i
                } if (this.model.page > this.totalPages - 1) {
                    j = this.totalPages - 2 + i;
                }
                if (j > 0 && j <= this.totalPages) {
                    this.pages.push(j);
                }
            }
            if (this.model.page > (this.totalPages)) {
                this.model.page = 1;
            }
        }

    }

    first() {

        if (this.model.page > 1) {
            this.model.page = 1;
            this.broadcast();
        }

    }

    last() {

        if (this.model.page < this.totalPages) {
            this.model.page = this.totalPages;
            this.broadcast();
        }

    }

    next() {
        if (this.model.page < this.totalPages) {
            this.model.page++;
            this.broadcast();
        };

    }

    previous() {

        if (this.model.page > 1) {
            this.model.page--;
            this.broadcast();
        };

    }

    goto(page: number) {
        if (page > 0 && page <= this.totalPages) {
            this.model.page = page;
            this.broadcast();
        };

    }

    show() {

        this.model.visible = true;

    }

    hide() {

        this.model.visible = false;

    }

    toggle() {

        this.model.visible = (!this.model.visible);

    }

    broadcast() {
        if (this.parentName == 'showRoom') {
            this.actionsService.broadcast({
                action: 'pageNumber',
                behaviour: 'change',
                value: this.model.page
            });
        }
        if (this.parentName == 'customerList') {


            var action = {
                action: GROUP + this.pagerId,
                behaviour: 'page',
                value: this.model.page
            };

            this.actionsService.broadcast(action);
        }
    }

}
