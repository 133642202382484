import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApplicationStatusService } from '../application-status/application-status.service';

@Injectable()
export class VehicleSearchesLeftService {

  constructor(private applicationStatusService : ApplicationStatusService) {
    
  }

  initializeVehicleSearchesLeft(): number {

    if(!environment.production){
      this.vehicleSearchesLeft = 9999;
    } 
    else {

      /*
        Ideally we would get metadata on the application object to tell us how many vehicle searches are left so we can proactively report this to the user.
        BUT without this facility we need to use local storage to store remaining searches. This will work fine unless the user changes browser during the quote 
        stage of the application and tries to make a lot of searches.
      */

      /*
        Check to see if the local storage setting exists and if it doesn't set it to the config value
        This should handle first time usage to initialise the setting.
      */
      if (this.vehicleSearchesLeft == -1) {
        this.vehicleSearchesLeft = this.applicationStatusService.configuration.vehicle.maximumSearchesPermitted;
      }
      /*
        Check to see if the local storage setting is greater than the config value and set it to the config value if it is.
        This should handle cases where the config value changes.
      */
      else if (this.vehicleSearchesLeft > this.applicationStatusService.configuration.vehicle.maximumSearchesPermitted) {
        this.vehicleSearchesLeft = this.applicationStatusService.configuration.vehicle.maximumSearchesPermitted;
      }
    }

    /*
      Return the current locaCheck to see if the local storage setting exists and if it doesn't set it to the config value
    */
    return this.vehicleSearchesLeft;

  }

  get vehicleSearchesLeft(): number {

    var searchesReached = localStorage.getItem('vehicleSearchesLeft');

    if (searchesReached == null) {
      return -1;
    }
    return parseInt(searchesReached);

  }

  set vehicleSearchesLeft(value: number) {

    localStorage.setItem('vehicleSearchesLeft', value.toString());

  }
}
