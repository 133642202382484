import { Injectable } from '@angular/core';

import { Subject, Subscription } from 'rxjs';

@Injectable()
class ActionsService {

    public context: string;

    // Global actions subject available for subscription and will return actions from all components that implement the broadcast method
    private globalActionSubject = new Subject<any>();

    private actions: any = {};
    // Will contain an object of specific action subscriptions i.e. save.click, cancel.click, add.click, edit.click in the format below
    // {
    //    save:{
    //      root:Subscription,
    //      behaviours:{
    //          click:Subscription,
    //          actrive:Subscription
    //      }
    //    },
    //    ...
    // }

    private acceptsSetValue: any = {};
    private acceptsGetValue: any = {};

    constructor() { }

    // Broadcasts actions globally through actionsource and for specific subscriptions in the actions object
    // The components that are subscribed can then act upon the action accordingly
    broadcast(action: any) {

        action.context = this.context;
        this.globalActionSubject.next(action);

        var observers = this.actions[action.action];

        if (typeof observers != 'undefined') {
            observers.root.next(action);

            if (typeof action.behaviour != 'undefined' && typeof observers.behaviours[action.behaviour] != 'undefined') {
                observers.behaviours[action.behaviour].next(action);
            }
        }

    }

    // Register an observer for global actions and return an associated subscription
    registerGlobalObserver(callback, context): Subscription {

        return this.globalActionSubject.subscribe(callback, context);

    }


    // Register an observer for a specific action i.e save.click, edit.click and return an associated subscription
    // This can be used by a component to check for a specific action on another component that it can then act upon
    registerObserver(action, callback, context): Subscription {

        var splitAction = action.split('.');
        var rootAction = splitAction[0];

        if (typeof this.actions[rootAction] == 'undefined') {
            this.actions[rootAction] = {
                root: new Subject<any>(),
                behaviours: {}
            };
        }

        if (splitAction.length == 2) {
            if (typeof this.actions[rootAction].behaviours[splitAction[1]] == 'undefined') {
                this.actions[rootAction].behaviours[splitAction[1]] = new Subject<any>();
            }
            var behaviour = this.actions[rootAction].behaviours[splitAction[1]];
            return behaviour.subscribe(callback, context);
        }
        return this.actions[rootAction].root.subscribe(callback, context);

    }

    // Register a list of observers for specific actions i.e save.click, edit.click and return associated subscriptions
    // This can be used by a component to check for specific actions on other components that it can then act upon
    registerObservers(actions, callback, context): Subscription[] {

        var subscriptions: Subscription[] = [];

        actions.map(
            action => {
                var observer = this.registerObserver(action, callback, context);
                subscriptions.push(observer);
            },
            this
        );

        return subscriptions;
    }

    // Cancel all subscriptions made for a particular registerObservers call
    cancelSubscriptions(subscription: Subscription[]) {

        if (subscription) {
            subscription.map(subscription => { subscription.unsubscribe(); });
        }
    }

    // Cancel a subscription made through registerObserver
    cancelSubscription(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    // Register an observer component that will be accept requests to change attributes/values/properties
    registerAcceptsSetValue(group, callback, context): Subscription {

        if (typeof this.acceptsSetValue[group] == 'undefined') {
            this.acceptsSetValue[group] = new Subject<any>();
        }

        return this.acceptsSetValue[group].subscribe(callback, context);

    }

    // Set a value on a component that accepts change requests
    setValue(group: string, key: string, itemkey: string, value) {

        var subject = this.acceptsSetValue[group];

        var values = {
            key: key,
            itemkey: itemkey,
            value: value
        };

        if (typeof subject != 'undefined') {
            subject.next(values);
        }

    }

    // Register an observer component that will accept requests to return attributes/values/properties information
    registerAcceptsGetValue(group, callback, context): Subscription {

        if (typeof this.acceptsGetValue[group] == 'undefined') {
            this.acceptsGetValue[group] = new Subject<any>();
        }

        return this.acceptsGetValue[group].subscribe(callback, context);

    }

    // Get a value from a component that accepts requests for attributes/values/properties information
    getValue(group: string, key: string, itemkey: string) {

        var subject = this.acceptsGetValue[group];

        var values = {
            key: key,
            itemkey: itemkey
        };

        if (typeof subject != 'undefined') {
            subject.source.next(values);
        }

    }

}

export {
    ActionsService
}
