import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from './../../../core/services/login/login.service'
import { TokenService } from "../../../core/services/token/token.service";

import { Subscription } from 'rxjs';
import { ROLES } from '../../../core/services/configuration/configuration-roles';
import { URLS } from '../../../core/services/configuration/configuration.urls';

@Component({
  selector: 'login-status',
  templateUrl: 'login-status.component.html',
  styleUrls: ['login-status.component.scss']
})

export class LoginStatusComponent {

  loggedIn: boolean;
  accountAccessible: boolean = false;
  
  username: string;

  subscription: Subscription;

  constructor(private loginService: LoginService, private parentRouter: Router, private tokenService:TokenService) {

    this.subscription = loginService.loginStatusAnnounced$.subscribe(loggedIn => { this.loginStatusAnnounced(loggedIn); })
    this.loginStatusAnnounced(this.tokenService.isAuthenticated());

   }

  ngOnChanges() {

    this.username = this.tokenService.userName;

  }

  ngOnDestroy() {

    this.subscription.unsubscribe();

  }

  logout() {

    this.parentRouter.navigateByUrl(URLS.Login.route);

  }

  login() {

    this.parentRouter.navigateByUrl(URLS.Login.route);

  }

  loginStatusAnnounced(loggedIn: boolean) {

    this.loggedIn = loggedIn;
    this.username = this.tokenService.userName;

    if (this.loggedIn) {
      this.checkAccountIsAccessibleBasedOnRoles();
    } 

  }

  checkAccountIsAccessibleBasedOnRoles() {

    this.accountAccessible = this.tokenService.roles.some(
      (role) => role == ROLES.CUSTOMER.name
    );

  }

}
