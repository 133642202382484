import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterTestingModule } from "@angular/router/testing";
import { HttpClientModule } from '@angular/common/http';
import { By } from '@angular/platform-browser';
import { LoginService } from './../../../core/services/login/login.service';
import { UserService } from './../../../core/services/user/user.service';
import { GlobalModalService } from './../../../core/services/modal/global-modal.service';
import { ActionsService } from "../../../core/services/actions/actions.service";
import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { CreditRatingService } from './../../../core/services/credit-rating/credit-rating.service';
import { DateService } from './../../../core/services/date/date.service';
import { SorterService } from './../../../core/services/addressesSorter/address-sorter.service';
import { ConditionsService } from './../../../core/services/conditions/conditions.service';
import { AddressLookupService } from './../../../core/services/address-lookup/address-lookup.service';
import { SectionChildContainerComponent } from './../../../shared/components/section-child-container/section-child-container.component';
import { HelpPanelComponent } from './../../../shared/components/help-panel/help-panel.component';
import { ValidInvalidIconComponent } from './../../../shared/components/valid-invalid-icon/valid-invalid-icon.component';
import { ControlErrorMessagesComponent } from './../../../shared/components/control-error-messages/control-error-messages.component';
import { IconComponent } from './../../../shared/components/icon-component/icon-component';
import { CustomRadioButtonComponent } from './../custom-radio-button/custom-radio-button.component';
import { YesOrNoComponent } from './../custom-radio-button/yes-or-no.component';
import { BackwardButtonComponent } from './../backward-button/backward-button.component';
import { FocusIfDirective } from './../../directives/focus/focus-if.directive';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BaseFormTester } from './base-form.component.spec';

// import { BankAccount, Answer, Employer, ResidentialAddress, Contact, Identity, Dealer, DemandsAndNeeds, Gap, ProductOption, Warranty, Quote, LoanOffer } from "../../../core/services/types";
import { BankAccount, Employer, ResidentialAddress, Contact, Identity, Dealer, Quote, LoanOffer } from "../../../core/services/types";

class mockVehicleService {

}

class mockLoginService {
}

class mockUserService {
}

class mockAddressLookupService {
}

const BANK_DETAILS_VALID: BankAccount = {
  bankName: 'Abbey National',
  accountName: 'Tyler',
  accountNumber: '12345678',
  sortCode: '123456',
  dateOpened: '2017-03-03'
};

const BANK_DETAILS_INVALID: BankAccount = {
  bankName: '',
  accountName: '',
  accountNumber: '',
  sortCode: '',
  dateOpened: ''
};

const DEALER_VALID: Dealer = {
  name: 'Trotter Independent Trading',
  vehicleLink: 'https://portal.autotrader.co.uk',
  website: 'https://portal.autotrader.co.uk',
  address: {
    id: '0',
    company: null,
    department: null,
    buildingNumber: '65',
    buildingName: '',
    address1: 'Southdown Road',
    address2: '',
    town: 'Weymouth',
    county: 'Dorset',
    postcode: 'DT4 9DF',
    country: 'UK',
  },
  contact: {
    name: 'John',
    telephone: '10305 734 567'
  }
};

const DEALER_INVALID: Dealer = {
  name: '',
  address: {
    id: '',
    company: null,
    department: null,
    buildingNumber: '',
    buildingName: '',
    address1: '',
    address2: '',
    town: '',
    county: '',
    postcode: '',
    country: '',
  },
  contact: {
    name: 'John',
    telephone: '10305 734 567'
  },
  vehicleLink: '',
  website: ''
};

const IDENTITY_VALID: Identity = {
  title: 'Mr',
  forename: 'Joe',
  surname: 'Bloggs',
  dateOfBirth: '2017-12-27',
  gender: 'male',
  maritalStatus: 'SG',
  drivingLicense: 'FULL',
  nationality:'British'
};

const IDENTITY_INVALID: Identity = {
  title: '',
  forename: '',
  surname: '',
  dateOfBirth: '',
  gender: '',
  maritalStatus: '',
  drivingLicense: '',
  nationality:'British'
};

const ADDRESSES_VALID: ResidentialAddress[] = [
  {
    id: '0',
    buildingNumber: '21',
    buildingName: '',
    address1: 'Southdown Road',
    address2: '',
    town: 'Weymouth',
    county: 'Dorset',
    postcode: 'DT4 9LH',
    startDate: new Date('1968-11-16T00:00:00'),
    endDate: '2017-12-27',
    country: 'UK',
    residentialStatus: 'OW'
  }
];

const ADDRESSES_INVALID: ResidentialAddress[] = [
  {
    id: '',
    buildingNumber: '',
    buildingName: '',
    address1: '',
    address2: '',
    town: '',
    county: '',
    postcode: '',
    startDate: new Date('1968-11-16T00:00:00'),
    endDate: '',
    country: '',
    residentialStatus: ''

  }
];

const EMPLOYMENT_VALID: Employer[] = [
  {
    status: 'full-time',
    name: 'Test Solutions Ltd.',
    jobTitle: 'Testing',
    jobIndustry: 'testing',
    employmentStatus: 'full time',
    JobType : '',
    address: {
      id: "0",
      company: null,
      department: null,
      buildingNumber: '21',
      buildingName: '',
      address1: 'Southdown Road',
      address2: '',
      town: 'Weymouth',
      county: 'Dorset',
      postcode: 'DT4 9DF',
      country: 'UK'
    },
    startDate: new Date('1968-11-16T00:00:00'),
    endDate: new Date(),
    telephone: '12345'
  }
];

const EMPLOYMENT_INVALID: Employer[] = [
  {
    status: '',
    name: '',
    jobTitle: '',
    jobIndustry: '',
    JobType : '',
    employmentStatus: '',
    address: {
      id: '0',
      company: null,
      department: null,
      buildingNumber: '',
      buildingName: '',
      address1: '',
      address2: '',
      town: '',
      county: '',
      postcode: '',
      country: ''
    },
    startDate:new Date('1968-11-16T00:00:00'),
    endDate: new Date(),
    telephone: ''
  }
];

const INCOME_VALID = {
  salary: 0,
  monthlyIncome: 0
};

const INCOME_INVALID = {
  salary: 25000,
  monthlyIncome: null
};

const EXPENDITURE_VALID = {
  mortgage: 0,
  councilTax: 0,
  utilities: 0,
  loans: 0,
  other: 0,
  broadband: 0,
  children: 0,
  insurance: 0,
  dependents: 0
};

const EXPENDITURE_INVALID = {
  mortgage: null,
  councilTax: null,
  utilities: null,
  loans: null,
  other: null,
  broadband: null,
  children: null,
  insurance: null,
  dependents: null
};

const VALID_QUOTE: Quote[] = [
  {
    id: 29,
    applicationId: 29,
    isSelected: true,
    dateReceived: '2017-10-10',
    vehicle: {
      id: 0,
      model: '3 Series 320d',
      make: 'BMW',
      derivative: '2.0 Zetec',
      registration: 'HG04YJV',
      dateOfRegistration: '2017-01-01',
      newcar: true,
      engineSize: 3000,
      fuelType: 'Petrol',
      transmission: 'Manual',
      doors: 5,
      colour: 'Gray',
      valuation: {
        average: 20000,
        below: 20000,
        clean: 20000,
        retail: 20000,
        future: 20000
      },
      mileage: 15000,
      price: 11500,
      ImageUrl_Main: './assets/bmw.jpg',
      capDetails: {
        id: 32785,
        code: null
      }
    },
    financeRequest: {
      loanAmount: 10000,
      deposit: {
        cash: 1000,
        partExchange: {},
        total: 1000
      },
      term: 36,
      annualMileage: 15000,
      financeType: 'LP'
    },
    financeResponse: {
    }
  }
];

const INITIAL_VALID_QUOTE: Quote[] = [
  {
    id: 29,
    applicationId: 29,
    isSelected: true,
    dateReceived: '2017-10-10',
    vehicle: {
      id: 0,
      model: '3 Series 320d',
      make: 'BMW',
      derivative: '2.0 Zetec',
      registration: 'HG04YJV',
      dateOfRegistration: '2017-01-01',
      newcar: true,
      engineSize: 3000,
      fuelType: 'Petrol',
      transmission: 'Manual',
      doors: 5,
      colour: 'Gray',
      valuation: {
        average: 20000,
        below: 20000,
        clean: 20000,
        retail: 20000,
        future: 20000
      },
      mileage: 15000,
      price: 11500,
      ImageUrl_Main: './assets/bmw.jpg',
      capDetails: {
        id: 32785,
        code: null
      }
    },
    financeRequest: {
      loanAmount: 10000,
      deposit: {
        cash: 1000,
        partExchange: {},
        total: 1000
      },
      term: 36,
      annualMileage: 15000,
      financeType: 'LP'
    },
    financeResponse: {
    }
  }
];


const INVALID_QUOTE_VEHICLE: Quote[] = [
  {
    id: 29,
    applicationId: 29,
    isSelected: true,
    dateReceived: '2017-10-10',
    vehicle: {
      registration: '',
      mileage: 0,
      price: 0
    },
    financeRequest: {
      loanAmount: 10000,
      deposit: {
        cash: 1000,
        partExchange: {},
        total: 1000
      },
      term: 36,
      annualMileage: 15000,
      financeType: 'LP'
    },
    financeResponse: {
    }
  }
];

const INVALID_QUOTE_FINANCE_REQUEST: Quote[] = [
  {
    id: 29,
    applicationId: 29,
    isSelected: true,
    dateReceived: '2017-10-10',
    vehicle: {
      id: 0,
      model: '3 Series 320d',
      make: 'BMW',
      derivative: '2.0 Zetec',
      registration: 'HG04YJV',
      dateOfRegistration: '2017-01-01',
      newcar: true,
      engineSize: 3000,
      fuelType: 'Petrol',
      transmission: 'Manual',
      doors: 5,
      colour: 'Gray',
      valuation: {
        average: 20000,
        below: 20000,
        clean: 20000,
        retail: 20000,
        future: 20000
      },
      mileage: 15000,
      price: 11500,
      ImageUrl_Main: './assets/bmw.jpg',
      capDetails: {
        id: 32785,
        code: null
      }
    },
    financeRequest: {
      loanAmount: 10000,
      deposit: {
        cash: 1000,
        partExchange: {},
        total: 1000
      },
      term: 36,
      annualMileage: 15000,
      financeType: null
    },
    financeResponse: {
    }
  }
];

const LOAN_OFFER_VALID: LoanOffer[] = [{
  id: 3,
  lenderid: 100,
  lendername: 'CP',
  funderReference: '',
  // product: 'HP',
  image: './assets/CreditPlus.PNG',
  status: '',
  isSelected: true,
  dateReceived: '2017-08-07',
  loanOffer: {
    // id: 1,
    // lender: 'CP',
    product: 'HP',
    loanAmount: 8500,
    requiredProofs: [],
    payment: 456.78,
    rate: 14,
    apr: 0.225,
    fees: 360,
    balloon: 0,
    deposit: 100,
    term: 48,
    totalPayable: 2342.00
  },
  errors: null,
  commission: {
    dealerCut: 500,
    totalValue: 1000,
    lenderTier: {
      lenderId:-2,
      tier: 1
    }
  }
}];


const LOAN_OFFER_INVALID: LoanOffer[] = [{
  id: 3,
  lenderid: 100,
  lendername: 'CP',
  funderReference: '',
  image: './assets/CreditPlus.PNG',
  status: '',
  isSelected: true,
  dateReceived: '2017-08-07',
  loanOffer: {
    // id: 1,
    // lender: 'CP',
    product: 'HP',
    requiredProofs: [
      {
        id: 'DRIVING_LICENSE',
        label: 'Driving license',
        description: '',
        docsRequired: 3
      },
      {
        id: 'PASSPORT',
        label: 'UK Passport',
        description: '',
        docsRequired: 1
      }
    ],
    loanAmount: 8500,
    payment: 456.78,
    rate: 14,
    apr: 0.225,
    fees: 360,
    balloon: 0,
    deposit: 1000,
    term: 48,
    totalPayable: 2342.00
  },
  errors: null,
  commission: {
    dealerCut: 500,
    totalValue: 1000,
    lenderTier: {
      lenderId:-2,
      tier: 1
    }
  }
}
];


const VALID_QUOTE_FOR_WARRANTY: Quote[] = [{
  id: 29,
  applicationId: 29,
  isSelected: true,
  dateReceived: '2017-10-10',
  vehicle: {
    id: 0,
    model: '3 Series 320d',
    make: 'BMW',
    derivative: '2.0 Zetec',
    registration: 'HG04YJV',
    dateOfRegistration: '2017-01-01',
    newcar: true,
    engineSize: 3000,
    fuelType: 'Petrol',
    transmission: 'Manual',
    doors: 5,
    colour: 'Gray',
    valuation: {
      average: 20000,
      below: 20000,
      clean: 20000,
      retail: 20000,
      future: 20000
    },
    mileage: 15000,
    price: 9500,
    ImageUrl_Main: './assets/bmw.jpg',
    capDetails: {
      id: 32785,
      code: null
    }
  },
  financeRequest: {
    loanAmount: 6650,
    deposit: {
      cash: 2850,
      partExchange: {},
      total: 2850
    },
    term: 12,
    annualMileage: 22000,
    financeType: 'LP'
  },
  financeResponse: {
  }
}];

const CONSUMER_INFORMATION_VALID = {
  secciConfirmed: true
};

const CONSUMER_INFORMATION_INVALID = {
  secciConfirmed: null
};


export {
  FormsModule,
  ReactiveFormsModule,
  RouterTestingModule,
  HttpClientModule,
  CollapseModule,
  By,
  LoginService,
  UserService,
  GlobalModalService,
  ActionsService,
  ApplicationStatusService,
  CreditRatingService,
  DateService,
  SorterService,
  ConditionsService,
  AddressLookupService,
  SectionChildContainerComponent,
  HelpPanelComponent,
  ValidInvalidIconComponent,
  ControlErrorMessagesComponent,
  IconComponent,
  CustomRadioButtonComponent,
  YesOrNoComponent,
  BackwardButtonComponent,
  FocusIfDirective,
  mockVehicleService,
  mockLoginService,
  mockUserService,
  mockAddressLookupService,
  BaseFormTester,
  BANK_DETAILS_VALID,
  BANK_DETAILS_INVALID,
  IDENTITY_VALID,
  IDENTITY_INVALID,
  EMPLOYMENT_VALID,
  EMPLOYMENT_INVALID,
  ADDRESSES_VALID,
  ADDRESSES_INVALID,
  INCOME_VALID,
  INCOME_INVALID,
  EXPENDITURE_VALID,
  EXPENDITURE_INVALID,
  VALID_QUOTE,
  INITIAL_VALID_QUOTE,
  INVALID_QUOTE_VEHICLE,
  INVALID_QUOTE_FINANCE_REQUEST,
  DEALER_VALID,
  DEALER_INVALID,
  LOAN_OFFER_VALID,
  LOAN_OFFER_INVALID,
  VALID_QUOTE_FOR_WARRANTY,
  CONSUMER_INFORMATION_VALID,
  CONSUMER_INFORMATION_INVALID,
  BankAccount, Employer, ResidentialAddress, Contact, Identity
}
