<div class="navbar-offset"></div>

<div class="navbar-container">
  <a routerLink="/dashboard/{{currentUser}}"><div class="navbar-link" [ngStyle]="currentSection=='dashboard' ? {'color' : '#c50084'} : {'color' : '#a5a5a5'}">
    <div class="current-section" [ngStyle]="currentSection=='dashboard' ? {'background-color' : '#c50084'} : null"></div>
    <div class="link-icon">
      <span *ngIf="currentSection=='dashboard'">7</span>
      <span *ngIf="currentSection!='dashboard'">0</span>
      </div>
    <div class="link-text">Home</div>
  </div></a>
  <a routerLink="/vehicle/{{currentUser}}"><div class="navbar-link" [ngStyle]="currentSection=='vehicle' ? {'color' : '#c50084'} : {'color' : '#a5a5a5'}">
    <div class="current-section" [ngStyle]="currentSection=='vehicle' ? {'background-color' : '#c50084'} : null"></div>
    <div class="link-icon">
      <span *ngIf="currentSection=='vehicle'">V</span>
      <span *ngIf="currentSection!='vehicle'">,</span>
    </div>
    <div class="link-text">Cars</div>
  </div></a>
  <!--<a routerLink="/applications/{{currentUser}}/docs"><div class="navbar-link" [ngStyle]="currentSection=='documents' ? {'color' : '#c50084'} : {'color' : '#a5a5a5'}">
    <div class="current-section" [ngStyle]="currentSection=='documents' ? {'background-color' : '#c50084'} : null"></div>
    <div class="link-icon">
      <span *ngIf="currentSection=='documents'">?</span>
      <span *ngIf="currentSection!='documents'">.</span>
    </div>
    <div class="link-text">Docs</div>
  </div></a>-->
  <a routerLink="/applications/{{currentUser}}}/settings"><div class="navbar-link" [ngStyle]="currentSection=='account' ? {'color' : '#c50084'} : {'color' : '#a5a5a5'}">
    <div class="current-section" [ngStyle]="currentSection=='account' ? {'background-color' : '#c50084'} : null"></div>
    <div class="link-icon">
      <span *ngIf="currentSection=='account'">A</span>
      <span *ngIf="currentSection!='account'">-</span>
    </div>
    <div class="link-text">Account</div>
  </div></a>
</div>
