import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Application, Proof, RequiredProof } from '../../../core/services/types';
import { Router } from '@angular/router';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { LoadingModalService } from '../../../core/services/modal/loading-modal.service';
import { TokenService } from './../../../core/services/token/token.service';
import { environment } from './../../../../environments/environment';

//onfido objects
import { Applicant } from './../../../shared/models/onfido/Applicant';
import { Address } from './../../../shared/models/onfido/Address';
import { SdkToken } from './../../../shared/models/onfido/SdkToken';
import { OnfidoRef } from './../../../shared/models/onfido/OnfidoRef';
import { Check } from './../../../shared/models/onfido/Check';

import * as moment from 'moment/moment'
import { HttpClient, HttpHeaders,
  //RequestOptionsArgs, RequestOptions, ResponseContentType 
} from '@angular/common/http';
import { ProofsService } from '../../../core/services/proofs/proofs.service';
import {LoginService} from '../../../core/services/login/login.service';
import { Title } from '@angular/platform-browser';
import { OtherProofsCheck } from '../../../shared/models/onfido/OtherProofsCheck';
class DropZone {
  uploader: FileUploader;
  label: string;
  id: string;
  requiredProof: RequiredProof;
  addingProof: boolean;
}

class EmailRequest{
  customer : Customer
  Metadata : MetaData
}
class Customer{
contactid: number
fname: string
lname: string
email: string

}

class MetaData {
  EmailTemplateId: number
  Optional_text: Array<string>
}


@Component({
  selector: 'proofs',
  templateUrl: './proofs.component.html',
  styleUrls: ['./proofs.component.scss']
})

export class ProofsComponent implements OnInit {
  @Input()
  application: Application;
  dropZone: DropZone;
  availableProofs: Proof[]
  uploadProofUrl: string;
  checkProofUrl: string;
  //requestArgs: RequestOptionsArgs;
  fileFound;
  allowedExtensions: string[];
  incomeProofCount;
  sdkToken: SdkToken;
  showOnfidoSdk: boolean =false;
  onfidoRef: OnfidoRef;
  otherProofsCheck: OtherProofsCheck;

  @ViewChild('licenceFrontUpload') drivingLicenceUpload: ElementRef;
  @ViewChild('licenceBackUpload') drivingLicenceBackUpload: ElementRef;
  @ViewChild('addressProofUpload') addressProofUpload: ElementRef;
  @ViewChild('incomeProofUpload') incomeProofUpload: ElementRef;
  @ViewChild('licenceModal') licenceModal: ElementRef;
  @ViewChild('incomeModal') incomeModal: ElementRef;
  @ViewChild('addressModal') addressModal: ElementRef;
  @ViewChild('fileFormatModal') fileFormatModal: ElementRef;
  @ViewChild('incomeProofMaxModal') incomeProofMaxModal: ElementRef;

  constructor(private http: HttpClient,
    private parentRouter: Router,
    private loadingModalService: LoadingModalService,
    private tokenService: TokenService,
    private proofService: ProofsService,
    private loginService:LoginService,
    private titleService: Title,
  ) {
  }

  ngOnInit() {

    //onfido page currently disabled;
    this.parentRouter.navigateByUrl("/login");

    return;

    //delete this block to re-enable onfido

    this.onfidoRef = new OnfidoRef();
    this.onfidoRef.checks = [];
    this.titleService.setTitle("Proofs");
    this.incomeProofCount = 0;
    this.allowedExtensions = [".png", ".jpg", ".jpeg", ".gif", ".tif", ".tiff"]
    this.fileFound =
    {
      addressProof: false,
      incomeProof: false,
    }

    var requiredProof = {
      label: "Driving licence Front",
      id: "drivingLicenseFront",
      description: "Driving Licence (front)",
      docsRequired: 1
    };

    this.dropZone = {
      uploader: new FileUploader({ removeAfterUpload: true }),
      label: "Driving licence Front",
      id: "drivingLicenseFront",
      requiredProof: requiredProof,
      addingProof: true
    };

    this.uploadProofUrl = environment.base + `/docs/?applicationId=${this.application.id}`;
    this.availableProofs = [];
    this.checkProofUrl = environment.base + `/docs/download/?applicationId=${this.application.id}`;
    this.computeMissingDocuments(this.dropZone);
    this.bindEvents(this.dropZone);
    this.getFiles();
  }

  ngAfterViewInit(){
    this.retrieveOnfidoReferences();
    this.checkUploadedFiles();
  }



  importFile(file: File, addingProofs: boolean) {
    this.loadingModalService.show();
    this.dropZone.addingProof = addingProofs;
    this.dropZone.uploader.addToQueue([file]);
    this.loadingModalService.hide();
  }

  getProof(item: FileItem, id: string) {
    return <Proof>{
      dateUploaded: moment(new Date()).format("DD-MM-YYYY"),
      filename: item._file.name,
      id: 0,
      title: item._file.name,
      type: id
    };
  }

  retrieveOnfidoReferences(){
    var applicant = new Applicant();
    applicant.id = this.application.id.toString();
    this.http.post(environment.base + "/onfido/retrieve-references", applicant).subscribe(
      (result) => {this.handleRetrievedReferences(result)}
  )
}

handleRetrievedReferences(result){
  if (result!=null){
  this.onfidoRef = JSON.parse(result);
  // To protect against duplicate checks for the same 
  if (this.onfidoRef!=null && this.onfidoRef.checks!=null && this.onfidoRef.checks.length>1){
      this.onfidoRef.checks.sort((b, a) => new Date(a.statusChangeDate).getTime() - new Date(b.statusChangeDate).getTime());
      this.onfidoRef.checks[0].status="complete";
      this.onfidoRef.checks[0].result="consider"; //for testing only
    }
  }
}


checkUploadedFiles(){
  var applicant = new Applicant();
    applicant.id = this.application.id.toString();
    this.http.post(environment.base + "/onfido/check-other-proofs", applicant).subscribe(
      (result) => {this.handleUploadedFileCheck(result)}
  )
}

handleUploadedFileCheck(result){
  this.otherProofsCheck = JSON.parse(result);
  if (this.otherProofsCheck.proofAddressFound==true){
    this.fileFound.addressProof = true;
  }
  if (this.otherProofsCheck.proofIncomeFound==true){
    this.fileFound.incomeProof = true;
  }
  this.updateFiles();
}


  checkOnfidoReference(){
    if (this.onfidoRef.id==null){
      this.createOnfidoApplicant();
    }
    else{
      this.startOnfidoSDK();
    }
  }

  createOnfidoApplicant(){
    
    var applicant = new Applicant();
    
    //add the 454 number to the applicant ID for sending to the backend. This will be stripped out and replaced with
    //the onfido reference id once created
    applicant.id = this.application.id + "";

    applicant.first_name = this.application.mainApplicant.identity.forename;
    applicant.last_name = this.application.mainApplicant.identity.surname;
    applicant.email = this.application.mainApplicant.contact.email;
    applicant.dob = this.application.mainApplicant.identity.dateOfBirth;

    var address = new Address();

    address.street = this.application.mainApplicant.addresses[0].address1;
    address.town = this.application.mainApplicant.addresses[0].town;
    address.postcode = this.application.mainApplicant.addresses[0].postcode;
    address.country = "GBR";

    applicant.address = address;

    this.http.post(environment.base + "/onfido/create-applicant", applicant).subscribe(
      (result) => {console.log(result); this.onfidoRef.id = result.toString(); 
      this.startOnfidoSDK();}
    );
  }

  startOnfidoSDK(){
    var applicant = new Applicant();
    applicant.id = this.onfidoRef.id;
    this.http.post(environment.base + "/onfido/get-sdk-token", applicant).subscribe(
      (result) => {console.log(result); this.sdkToken=JSON.parse(result.toString()); this.showOnfidoSdk=true;}
    );
  }

  onfidoFinished(Input: any){
    //console.log("Trying to cose onfido with input = ");
    //console.log(Input);
    //console.log("document front id = " + Input.document_front.id);
    //console.log("document back id = " + Input.document_back.id);
    //console.log("face id = ");
    this.showOnfidoSdk=false;
   
    var applicant = new Applicant();    
    applicant.id = this.onfidoRef.id;
    applicant.first_name = this.application.mainApplicant.identity.forename;
    applicant.last_name = this.application.mainApplicant.identity.surname;
   
    this.http.post(environment.base + "/onfido/create-check", applicant).subscribe(
      (result) => {this.retrieveOnfidoReferences();}
    );
  }

  //uses object taken from actual onfido web hook request
  onfidoWebHookTest(){
    this.http.post(environment.base + "/onfido/hook", {"payload": {
      "resource_type": "check",
      "action": "check.completed",
      "object": {
        "id": "c7472418-6b67-44c8-bc81-ff8b6dcf8d5a",
        "status": "complete",
        "completed_at_iso8601": "2021-06-17T14:42:06Z",
        "href": "https://api.onfido.com/v3/checks/410dbc74-c82c-4951-9890-38a89ccc2b4a"
      }
    }}).subscribe(
      (result) => {console.log(result);}
    );
  }

  bindEvents(dropZone: DropZone) {
    dropZone.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.removeAnyDuplicateAvailableProof(item);
      if (dropZone.addingProof) {
        this.availableProofs.push(this.getProof(item, dropZone.requiredProof.id));
      } else {
        this.dropZone.addingProof = true;
      }
    };
    dropZone.uploader.onCompleteAll = () => {
      this.computeMissingDocuments(this.dropZone);
    };
  };

  computeMissingDocuments(dropZone: DropZone) {
    dropZone.uploader.setOptions({
      url: this.uploadProofUrl,
      queueLimit: 4,
      authToken: 'Bearer ' + this.tokenService.bearerToken,
      additionalParameter: {
        label: dropZone.id
      }
    });
  }

  removeAnyDuplicateAvailableProof(item: FileItem) {
    var foundProof = this.availableProofs.find(availableProof => availableProof.filename == item.file.name);
    if (foundProof != null) {
      this.removeProofFromAvailableProofs(foundProof);
    }
  }

  removeProofFromAvailableProofs(proof: Proof) {
    let existingProof = this.availableProofs.find(p => p.filename === proof.filename);
    if (existingProof == null) {
      throw new Error("The deleted proof does not exist");
    }
    let proofIndex = this.availableProofs.indexOf(existingProof);
    if (proofIndex === -1) {
      throw new Error("No index could be found for the existing index");
    }
    this.availableProofs.splice(proofIndex, 1);
  }

  fileEvent(fileInput: Event, filename) {
    var incomeCountMaxed = false;
    var file = (fileInput.target as HTMLInputElement).files[0];
    var fileExtension = "." + file.name.split(".")[1];
    if (this.allowedExtensions.indexOf(fileExtension.toLowerCase()) != -1) {
      switch (filename.toLowerCase()) {

        case ("addressproof"):
          this.addressProofUpload.nativeElement.classList.add("file-to-upload")
          this.addressProofUpload.nativeElement.classList.remove("file-found-image")
          this.addressProofUpload.nativeElement.classList.remove("no-file-image")
          this.fileFound.addressProof = true;
          break;

        case ("incomeproof"):
          if(this.incomeProofCount >= 15){
            incomeCountMaxed = true;
          } else {
          this.fileFound.incomeProof = true;
          filename = filename + (this.incomeProofCount + 1).toString();
          this.incomeProofCount++;
          }
          this.incomeProofUpload.nativeElement.classList.add("file-to-upload")
          this.incomeProofUpload.nativeElement.classList.remove("file-found-image")
          this.incomeProofUpload.nativeElement.classList.remove("no-file-image")
          break;


        default: break;
      }
      if(!incomeCountMaxed){
      let filev2 = new File([file], filename + fileExtension.toLowerCase(), { type: file.type });
      this.importFile(filev2, true);
      //this.updateFiles();
      } else {
        this.showModal("incomeProofMaxModal")
      }
    } else {
      this.showModal("fileFormatError")
    }
  }

  updateFiles() {

    if (this.fileFound.addressProof == true) {
      this.addressProofUpload.nativeElement.classList.remove("no-file-image")
      this.addressProofUpload.nativeElement.classList.remove("file-to-upload")
      this.addressProofUpload.nativeElement.classList.add("file-found-image")
    }
    else {
      this.addressProofUpload.nativeElement.classList.add("no-file-image")
      this.addressProofUpload.nativeElement.classList.remove("file-found-image")
    }
    if (this.fileFound.incomeProof == true) {
      this.incomeProofUpload.nativeElement.classList.remove("no-file-image")
      this.incomeProofUpload.nativeElement.classList.remove("file-to-upload")
      this.incomeProofUpload.nativeElement.classList.add("file-found-image")
    }
    else {
      this.incomeProofUpload.nativeElement.classList.add("no-file-image")
      this.incomeProofUpload.nativeElement.classList.remove("file-found-image")
    }    
    
  }

  getFiles() {
    this.proofService.getAllFiles(this.application.id.toString()).subscribe(response => {
      //Body is returned as a long string. Converting to array.
      //Remove "[ ]"
      var fileList = response._body;
      if (fileList!=null){
        fileList = fileList.substr(1, fileList.length - 2)
        fileList = fileList.split(",");      
      var incomeProofCountTemp = 0;
      var incomeProofNumber = 0;
      for (var i = 0; i < fileList.length; i++) {
        //Removing all whitespace, and quotation marks, then separating the extension.
        fileList[i] = fileList[i].replace(/\s/g, "").replace('"', "").replace('"', "").toLowerCase();
        fileList[i] = fileList[i].split(".")[0];
        if(fileList[i].includes("incomeproof")){
          incomeProofNumber = parseInt(fileList[i].replace("uploaded-incomeproof","") ) 
          if(incomeProofCountTemp < incomeProofNumber ){
            incomeProofCountTemp = incomeProofNumber; 
          }
        }
      }    
      this.incomeProofCount = incomeProofCountTemp;

      if (fileList.indexOf("uploaded-addressproof") != -1) {
        this.fileFound.addressProof = true;
      }

      if (fileList.indexOf("uploaded-incomeproof1") != -1) {
        this.fileFound.incomeProof = true;
      }
 
      this.updateFiles();
    }
    })
  }

  closeModal(modalName) {
    switch (modalName) {
      case ("fileFormatError"):
        this.fileFormatModal.nativeElement.style.display = "none";
        break;

      case ("licenceModal"):
        this.licenceModal.nativeElement.style.display = "none";
        break;

      case ("incomeModal"):
        this.incomeModal.nativeElement.style.display = "none";
        break;


      case ("addressModal"):
        this.addressModal.nativeElement.style.display = "none";
        break;

      case ("incomeProofMaxModal"):
        this.incomeProofMaxModal.nativeElement.style.display = "none";
        break;
      
      default:
        break;
    }
  }

  showModal(modalName) {
    switch (modalName) {
      case ("fileFormatError"):
        this.fileFormatModal.nativeElement.style.display = "block";
        break;

      case ("licenceModal"):
        this.licenceModal.nativeElement.style.display = "block";
        break;
      case ("incomeModal"):
        this.incomeModal.nativeElement.style.display = "block";
        break;


      case ("addressModal"):
        this.addressModal.nativeElement.style.display = "block";
        break;
    
      case ("incomeProofMaxModal"):
        this.incomeProofMaxModal.nativeElement.style.display = "block";
        break;

      default:
        break;
    }
  }

  
  doneButton() {    
    this.updateFiles();
    this.fileFound.addressProof=false;
    this.fileFound.incomeProof=false;
    this.retrieveOnfidoReferences();
   //console.log(this.fileFound);
  //  this.emailSalesperson();
  }
}

