
declare type EventStates = { [type: string]: PageEventPages };
declare type PageEventPages = { [type: string]: PageState };

class PageState {
    public enabled: boolean;
    public active: boolean;
    public editing: boolean;
    public sectionDefaults?: PageSectionState;
    public sections?: PageSectionStates;
}

declare type PageSectionStates = { [type: string]: PageSectionState };

class PageSectionState {
    public visible: boolean;
    public editing: boolean;
    public enabled: boolean;
}

const PAGE_ENABLED_AND_EDITABLE: PageState = { enabled: true, active: true, editing: true, sectionDefaults: { visible: true, editing: true, enabled: true } };
const PAGE_ACTIVE_AND_EDITABLE: PageState = { enabled: true, active: true, editing: true, sectionDefaults: { visible: true, editing: true, enabled: true } };
const PAGE_ACTIVE_AND_READONLY: PageState = { enabled: true, active: true, editing: true, sectionDefaults: { visible: true, editing: true, enabled: true } };
const SECTION_VISIBLE_AND_EDITABLE: PageSectionState = { visible: true, editing: true, enabled: true };
const SECTION_VISIBLE_AND_READONLY: PageSectionState = { visible: true, editing: true, enabled: true };
const SECTION_HIDDEN: PageSectionState = { visible: true, editing: true, enabled: true };
const SECTION_DISABLED: PageSectionState = { visible: true, editing: true, enabled: true };
const PAGE_DISABLED: PageState = { enabled: true, active: true, editing: true, sectionDefaults: { visible: true, editing: true, enabled: true } };
const PAGE_ENABLED_AND_READONLY: PageState = { enabled: true, active: true, editing: true, sectionDefaults: { visible: true, editing: true, enabled: true } };

export {
    EventStates,
    PageEventPages,
    PageState,
    PageSectionStates,
    PageSectionState,
    PAGE_ENABLED_AND_EDITABLE,
    PAGE_ACTIVE_AND_EDITABLE,
    PAGE_ACTIVE_AND_READONLY,
    SECTION_VISIBLE_AND_EDITABLE,
    SECTION_VISIBLE_AND_READONLY,
    SECTION_HIDDEN,
    SECTION_DISABLED,
    PAGE_DISABLED,
    PAGE_ENABLED_AND_READONLY
}
