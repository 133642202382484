import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {
  @Input()
  loading:boolean=false;

  @Input()
  text:string='...loading...';

  @Input()
  imagesource:string='./assets/hourglass.gif';

  @Input()
  className:string;

  classes:any={};

  constructor() {

  }

  ngOnInit() {

    if (this.className) {
      this.classes[this.className]=true;
    }

  }

}
