import { Component, OnInit } from '@angular/core';
import { ActionsService } from '../base-form/base-form-testing.barrel.spec';

@Component({
  selector: 'ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent implements OnInit {

  hideToggle: boolean = false;
  displayToggle: boolean = false;
  constructor(private actionsService: ActionsService) { }

  ngOnInit() {
    this.actionsService.registerObserver(
      'hide.privacy',
      action => {
        this.displayToggle = false;
        this.hideToggle = true;
      },
      this
    );
  }
  displayPolicy() {
    this.displayToggle = true;
  }
  hideRibbon(){
    this.hideToggle = true;
  }
}
