
const SECTIONS = {
    ACCEPTANCE_STATUS: 'acceptance-status',
    ADD_A_CAR: 'add-a-car',
    ADDRESS: 'address',
    BANK_DETAILS: 'bank-details',
    CONSUMER_INFORMATION: 'consumer-information',
    CONTACT: 'contact', 
    CREDIT_RATING: 'credit-rating',
    DASHBOARD: 'dashboard',
    DEALER: 'dealer',
    DEALERPORTAL: 'dealer-portal',
    DEMANDS_AND_NEEDS: 'demands-and-needs',
    DOCUMENTS: 'agreement-and-documentation',
    EXTRADETAILS: 'extra-details',
    EMPLOYMENT: 'employment',
    FINANCE_DOCUMENTS: 'documents',
    FINAL_PAGE: 'final-page',
    FINANCE_REQUEST: 'finance-request',
    GAP: 'gap',
    GAP_PRODUCT: 'gap-product',
    GET_QUOTES: 'quotes',
    HELP: 'help',
    INCOME: 'income',    
    JOINT_APPLICANT: 'joint-applicant',
    MARKETING: 'marketing',
    OUTGOINGS: 'outgoings',
    PRODUCTS: 'products',
    PRODUCT_ITEMS: 'product_items',
    PROOFS: 'proofs',
    PROOF_VERIFY: 'proofVerify',
    QUOTE_EXAMPLE: 'quote-example',
    SETTINGS: 'settings',
    TERMS: 'terms-and-acceptance',
    TIMELINE: 'timeline',
    WARRANTY: 'warranty',
    WARRANTY_PRODUCT: 'warranty-product',
    DOCS: 'docs',
    VEHICLES: 'vehicles'
};

export {
    SECTIONS
}