import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[selectAllOnFocus]'
})
export class SelectAllOnFocusDirective {
  constructor(private el: ElementRef) { }

  @HostListener('focus') onFocus(){
    // from http://stackoverflow.com/questions/4067469/selecting-all-text-in-html-text-input-when-clicked
    this.el.nativeElement.setSelectionRange(0, this.el.nativeElement.value.length);
  } 
}
