<div class="container white section-container" title="">
  <div class="card card-full-width" [ngClass]="(enabled)?'':'disabled'">
    <div class="card-header">
      <h1 *ngIf="(valid | json)!={}" (click)="toggleCollapse()" (keyup.enter)="toggleCollapse()">
        <icon-component [icons]="icons" [creationTimeInSeconds]="creationTimeInSeconds"></icon-component>
        <span class="section-title">{{title}}</span> <i class="icon-help" aria-hidden="true" (click)="clickHelp($event)"></i>
        <span class="pull-right" aria-hidden="true" [ngClass]="(closed)?'icon-down':'icon-up'"  tabindex="0"></span>
      </h1>
    </div>
    <div class="card-block" [collapse]="closed">
      
      <loading *ngIf="isLoading" [loading]="isLoading" [text]="loadingText"></loading>
      <ng-content *ngIf="isLoading === false"></ng-content>
    </div>
  </div>
</div>

        