import { NgModule } from '@angular/core';

import { SharedModule } from './../shared/shared.module';

import { LoginRoutingModule } from './login-routing.module';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgottenPasswordComponent } from './components/forgotten-password/forgotten-password.component';
import { OldLoginStatusComponent } from './components/login-status/login-status.component';
import { LoginPanelComponent } from './components/login-panel/login-panel.component';
// import { VehicleDisplayComponent } from './components/vehicle-display/vehicle-display.component';
// import { ShowVehicleComponent } from './components/show-vehicle/show-vehicle.component';
// import { SuggestFinanceComponent } from './components/suggest-finance/suggest-finance.component';
import { RegisterPanelComponent } from './components/register-panel/register-panel.component';
import { RegistrationCompleteComponent } from './components/registration-complete/registration-complete.component';

@NgModule({
  imports: [SharedModule, LoginRoutingModule],
  declarations: [
    LoginComponent, OldLoginStatusComponent, RegisterComponent, ForgottenPasswordComponent, LoginPanelComponent,
    RegisterPanelComponent,
    RegistrationCompleteComponent],
  exports: [ ]
})

export class LoginModule { }
