<div class="mobile-only">
  <div class="fixed-header">
    <div style="width:100%; max-width:900px; margin:0 auto;">
      <a routerLink="/applications/{{application.id}}/docs">
        <div class="raised-back-button">
          <div class="raised-back-button-icon">L</div>
       </div>
      </a>
    </div>
  </div>
  <div class=fixed-header-fade></div>
  <div class="fixed-header-offset"></div>
  <div class="main-body-narrow-padding">
<div class="medium-title">Finance Agreements</div>
<div class="medium-title-subtext">
  To finalise your purchase, you need to sign the agreement(s).
</div>
<div class="raised-card">
  <div class="agreement-title">Creditplus Agreement</div>
<div class="agreement-action-row">
  <div class="action-image">
    <a *ngIf="customerPackEsign == true" href="{{ customerPackEsignUrl }}" target="_blank">
      <div class="docs-action" [ngStyle]="docsRecv ? {'background-image' : 'url(../../../../assets/accepted_tick.png)'} : null"></div></a>
    <div class="docs-locked" *ngIf="customerPackEsign == false" ></div>
  </div>
  <div class="action-label">
    <a href="{{ customerPackEsignUrl }}" target="_blank" *ngIf="customerPackEsign == true" style="color:#203757">
      E-Sign
    </a>
    <a target="_blank" *ngIf="customerPackEsign == false" style="color:#203757">
      E-Sign
    </a>
  </div>
  <a *ngIf="customerPackEsign == true" href="{{ customerPackEsignUrl }}" target="_blank" style="margin-left:auto; color: #203757">
    <div class="documents-chevron">M</div></a>
</div>
</div>

<div class="raised-card" #LenderAgreement *ngIf="this.docsIssued && this.lenderPack"> 
  <div class="agreement-title">Lender Agreement</div>
  <div class="agreement-subtext">Download and sign your lender agreement. Then, Upload the signed document below.</div>
   <div class="download-link"> <a [href]="sanitize(lenderPackDocsUrl)" download="lenderPack.pdf">Download Agreement <span class="download-icon">4</span></a></div>
<div class="agreement-action-row" >
  
  <div class="action-image">
    <dropzone [dropZone]="dropZone" (removeProofFromAvailableProofs)="removeProofFromAvailableProofs($event)">     
      <div class="docs-action" #lenderPackUpload (click)="lenderPackUploadButton.click()"
      [ngStyle]="docsRecv ? {'background-image' : 'url(../../../../assets/accepted_tick.png)'} : 
            signedLenderPack || fileBeingUploaded ? {'background-image' : 'url(../../../../assets/fileToUpload.png)'} :
             {'background-image' : 'url(../../../../assets/Upload.png)'}"></div>
      <input style="display:none;" type="file" #lenderPackUploadButton (change)='fileEvent($event, "lenderPackUpload")' />
    </dropzone>
  </div>
  <div class="action-label">
    <span (click)="lenderPackUploadButton.click()">
      Upload Signed Agreement
    </span>
  </div>
  <div class="documents-chevron" (click)="lenderPackUploadButton.click()">M</div>

</div>
</div>

    <div style="height:100px"></div>
  
  </div>
<navbar [currentSection]="'documents'"></navbar>

</div>