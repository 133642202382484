<article class="loan-offer-item-container raised-card" *ngIf="offer.status == 'APPLICATION_ACCEPTED'">
  
    <!--<div class="flex-between" *ngIf="offer.loanOffer.product == 'PCP' || offer.loanOffer.product == 'PersonalContractPurchase'">

      <div class="flex-ctrls">
        
          <p>
            {{offer.loanOffer.term}} monthly payments of </p>
          <p>
            <strong>{{offer.loanOffer.payment | currency:'GBP'}}</strong>
          </p>
        

      </div>
      <div class="line"></div>
      <div>
        <p>Purchase Option</p>
        <h2>{{offer.loanOffer.balloon | currency:'GBP'}}</h2>
      </div>
    </div>-->


    <div class="purchase-headline-row">
        
      <div class="pre-approved"> <span class="tick-circle">J</span> Pre-Approved </div>
        <div class="purchase-type-term">{{offer.loanOffer.term/12}} year
          <span *ngIf="offer.loanOffer.product == 'PCP' || offer.loanOffer.product.toLowerCase() == 'personalcontractpurchase'">PCP</span>
          <span *ngIf="offer.loanOffer.product == 'HP' || offer.loanOffer.product.toLowerCase() == 'hirepurchase'">HP</span>
        </div>
    </div>
    
    <div class="payment-headline-row">
      <div class="term-months">{{offer.loanOffer.term}} monthly payments of</div>
      <div class="monthly-price">{{offer.loanOffer.payment | currency:'GBP'}}</div>
    </div>

      <div class="extra-details-container" [ngStyle]="showExtraDetails==false ?
               {'max-height': '0px', 'margin-top' : '0px', 'opacity':'0'} 
               : {'max-height': '220px', 'margin-top' : '30px', 'opacity' : '1'}">
        <div class="extra-detail-row">
          <div class="extra-detail-label">Deposit:</div>
          <div class="extra-detail-item">£{{offer.loanOffer.deposit | number : '1.2-2'}}</div>
        </div>
        <div class="extra-detail-row">
          <div class="extra-detail-label">Vehicle price:</div>
          <div class="extra-detail-item">£{{offer.loanOffer.loanAmount + offer.loanOffer.deposit | number : '1.2-2'}}</div>
        </div>
        <div class="extra-detail-row">
          <div class="extra-detail-label">APR:</div>
          <div class="extra-detail-item">{{offer.loanOffer.apr | number : '1.2-2'}}%</div>
        </div>
        <div class="extra-detail-row">
          <div class="extra-detail-label">Loan amount:</div>
          <div class="extra-detail-item">£{{offer.loanOffer.loanAmount | number : '1.2-2'}}</div>
        </div>
        <div class="extra-detail-row">
          <div class="extra-detail-label">Total cost of credit:</div>
          <div class="extra-detail-item">£{{offer.loanOffer.totalPayable - offer.loanOffer.loanAmount | number : '1.2-2'}}</div>
        </div>
        <div class="extra-detail-row">
          <div class="extra-detail-label">Total amount payable:</div>
          <div class="extra-detail-item">£{{offer.loanOffer.totalPayable | number: '1.2-2'}}</div>
        </div>
        <div class="extra-detail-row" *ngIf="offer.loanOffer.product == 'PCP' || offer.loanOffer.product.toLowerCase() == 'personalcontractpurchase'">
          <div class="extra-detail-label">Optional balloon payment:</div>
          <div class="extra-detail-item">£{{offer.loanOffer.balloon | number: '1.2-2'}}</div>
        </div>
        <div class="extra-detail-row" *ngIf="offer.loanOffer.product == 'PCP' || offer.loanOffer.product.toLowerCase() == 'personalcontractpurchase'">
          <div class="extra-detail-label">Annual mileage limit:</div>
          <div class="extra-detail-item">{{"NEEDS MILEAGE"}}</div>
        </div>
      </div>


      <div class="show-details-link" [hidden]="showExtraDetails==true" (click)="showMoreDetails(true)">
        Show more details
      </div>

      <div class="show-details-link" [hidden]="showExtraDetails==false" (click)="showMoreDetails(false)">
        Show less details
      </div>
      
    <div class="proceed-button-container">
      <!--needs to be generated properly not set as static link-->
      <a (click)="acceptOffer()"><div  id="proceed-button "class="standard-button" >Reserve</div></a>
    </div>
</article>
