import {
    AuthenticationConfiguration, LookupsConfiguration, LookupItem, SelectListItem, AcceptanceConfiguration, EmploymentConfiguration, AddressesConfiguration,
    OutgoingsConfiguration, IdentityConfiguration, ContactConfiguration, QuotesConfiguration, PageSCrollConfiguration, 
    SaveConfiguration, ProductsConfiguration, PostcodeLookupConfiguration, FinanceRequestConfiguration, VehicleConfiguration, 
    FinanceOptions, Journeys, JourneyConfiguration, ErrorsConfiguration, CreditRatingConfiguration, ProofsConfiguration, AppearanceConfiguration
} from "./configuration.types"

import { JOURNEYS, PAGES, SECTIONS, PAGE_JOURNEYS } from "./configuration-journeys";

import { JourneyPage } from "../types";
import { CONFIGURATION } from "./configuration-groups-base";

export class Configuration {
    public appearance?: AppearanceConfiguration; 
    public errors?: ErrorsConfiguration;
    public currentJourney?: string;                          // 'FORWARDS_ONLY' | 'BACKWARDS_AND_FORWARDS';
    public authentication?: AuthenticationConfiguration;
    public contact?: ContactConfiguration;
    public identity?: IdentityConfiguration;
    public creditRating?: CreditRatingConfiguration;
    public pageScroll?: PageSCrollConfiguration;
    public outgoings?: OutgoingsConfiguration;
    public vehicle?: VehicleConfiguration;
    public financeRequest?: FinanceRequestConfiguration;
    public quotes?: QuotesConfiguration;
    public proofs?: ProofsConfiguration;
    public proofVerify?: ProofsConfiguration;
    public products?: ProductsConfiguration;
    public save?: SaveConfiguration;
    public financeOptions?: FinanceOptions;
    public addressLookup?: PostcodeLookupConfiguration;
    public employment?: EmploymentConfiguration;
    public addresses?: AddressesConfiguration;
    public acceptanceConfiguration?: AcceptanceConfiguration;
    public lookupConfiguration?: LookupsConfiguration;


    /*
        NOTE: The current journey is currently being picked up from the base configuration CONFIGURATION.currentJourney.
        It should be loaded based upon the group configuration associated with the logged in role.
    */

    static loadCurrentJourneyType(): JourneyConfiguration {
        let current: string = CONFIGURATION.currentJourney;

        if (current) {
            let journeys = Configuration.loadAvailableJourneys();

            if (journeys[current] == null) {
                throw Error("The journey selected was not found")
            }

            return journeys[current];
        }

        throw Error("Journey" + CONFIGURATION.currentJourney + "does not exist");
    }

    static loadCurrentJourneyPages(roles): JourneyPage[] {

        return this.loadCurrentJourney(roles).pages;

    }

    static loadCurrentJourney(roles): any {

        var foundJourney;

        for (var i = 0, ii = roles.length; i < ii; i++) {

            foundJourney = PAGE_JOURNEYS.find(
                pageJourney => pageJourney.roles.indexOf(roles[i]) != -1
            );

            if (foundJourney) {
                break;
            }

        }

        if (!foundJourney) {
            throw Error(`Journey for the roles ${roles.join(',')} does not exist`);
        }

        return foundJourney;

    }

    static loadCurrentJourneyKey(): string {
        let current: string = CONFIGURATION.currentJourney;

        if (current) {
            let journeys = Configuration.loadAvailableJourneys();

            if (journeys[current] == null) {
                throw Error("The journey selected was not found")
            }

            return current;
        }

        throw Error(`Journey ${CONFIGURATION.currentJourney} does not exist`);
    }

    static loadAvailableJourneys(): Journeys {
        if (JOURNEYS == null) {
            throw Error("No journey was defined");
        }

        return JOURNEYS;
    }

    static loadJourneyKeys(): Array<string> {
        let journeyKeys: Array<string> = Object.keys(this.loadAvailableJourneys());

        if (journeyKeys.length === 0) {
            throw Error("No journey was configured");
        }

        return journeyKeys;
    }

    static selectJourney(journeyKey: string): void {
        let journeys: Array<string> = Configuration.loadJourneyKeys();

        if (journeys.find(j => journeyKey === j) == null) {
            throw Error("The selected journey was not found");
        }

        CONFIGURATION.currentJourney = journeyKey;
    }

}

export {
    CONFIGURATION, PAGES, SECTIONS, JOURNEYS, LookupItem, SelectListItem, JourneyConfiguration
};
