
import { ErrorConfiguration } from "../configuration/configuration.types";

export class ApplicationError extends Error {

    errorConfiguration: ErrorConfiguration;

    constructor(message: string, errorConfiguration: ErrorConfiguration) {
        super(message);

        this.errorConfiguration = errorConfiguration;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApplicationError.prototype);
    }

}