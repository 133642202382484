<onfido-sdk [sdkToken]="sdkToken.token" *ngIf="showOnfidoSdk==true" (onfidoFinished)='onfidoFinished($event)'></onfido-sdk>

<div class="mobile-only" >
  <div [hidden]="showOnfidoSdk==true">
  <div class="fixed-header">
    <div class="fixed-page-title-vehicle-serarch fixed-page-title-vehicle-serarch-backbtn">
      <a routerLink="/applications/{{application.id}}/docs">
        <div class="raised-back-button">
          <div class="raised-back-button-icon">L</div>
       </div>
      </a>
    </div>
  </div>
  <div class=fixed-header-fade></div>
  <div class="fixed-header-offset"></div>
<div class="main-body-narrow-padding">
  <div class="medium-title">Upload your Proofs</div>

<div class="medium-title-subtext">
  To prevent fraud and complete the underwriting process, please upload proofs of your identity.
</div>
  <div class="raised-card">
    <div class="proof-row" *ngIf="onfidoRef.checks == null || onfidoRef.checks.length <=0">
      <div class="proof-image">
        <div class="proofs-input no-file-image" #licenceFrontUpload (click)="checkOnfidoReference()"></div>
      </div>    
      <div class="proof-name" (click)="checkOnfidoReference()">Driving Licence</div>     
      <div class="info-circle" (click)="showModal('licenceModal')">i</div> 
      <div class="proof-arrow" (click)="checkOnfidoReference()"> 
        M
      </div>
    </div>

    <div *ngIf="onfidoRef.checks != null && onfidoRef.checks.length > 0">
      <div class="proof-row" *ngIf="onfidoRef.checks[0].status!='complete'">
        <div class="proof-image">
          <div class="proofs-input file-to-upload" #licenceFrontUpload ></div>
        </div>    
        <div class="proof-name" >Driving Licence</div>     
        <div class="info-circle" (click)="showModal('licenceModal')">i</div> 
        <div class="proof-arrow" > 
          M
        </div>
      </div>
    </div>

    <div *ngIf="onfidoRef.checks != null && onfidoRef.checks.length > 0">
      <div class="proof-row" *ngIf="onfidoRef.checks[0].status=='complete' && onfidoRef.checks[0].result=='clear'">
        <div class="proof-image">
          <div class="proofs-input file-found-image" #licenceFrontUpload ></div>
        </div>    
        <div class="proof-name">Driving Licence</div>     
        <div class="info-circle" (click)="showModal('licenceModal')">i</div> 
        <div class="proof-arrow" > 
          M
        </div>
      </div>
    </div>

    <div *ngIf="onfidoRef.checks != null && onfidoRef.checks.length > 0">
      <div class="proof-row" *ngIf="onfidoRef.checks[0].status=='complete' && onfidoRef.checks[0].result!='clear'">
        <div class="proof-image">
          <div class="proofs-input rejected-image" #licenceFrontUpload (click)="licenceUploadInput.click()"></div>
        </div>    
        <div class="proof-name">Driving Licence</div>     
        <div class="info-circle" (click)="showModal('licenceModal')">i</div> 
        <div class="proof-arrow"> 
          M
        </div>        
      </div>  
      <div *ngIf="onfidoRef.checks[0].status=='complete' && onfidoRef.checks[0].result!='clear'" class="onfido-consider-result">
        We’ve been unable to process your document, you will need to speak with our team to complete the underwriting process.
        Please call 0333 6000 290.</div>    
    </div>
    
    

    <div class="proof-row">
      <div class="proof-image">
        <dropzone [dropZone]="dropZone" [availableProof]="availableProofs[dropZone.requiredProof.id]" (removeProofFromAvailableProofs)="removeProofFromAvailableProofs($event)">     
          <div class="proofs-input no-file-image" #addressProofUpload (click)="addressProofUploadInput.click()"></div>
          <input style="display:none;" type="file" #addressProofUploadInput (change)='fileEvent($event, "addressProof")' /> 
        </dropzone>
      </div>    
      <div class="proof-name" (click)="addressProofUpload.click()">Proof of Address</div>
      <div class="info-circle" (click)="showModal('addressModal')">i</div>
          
      <div class="proof-arrow" (click)="addressProofUpload.click()">
        M
      </div>
    </div>
  
    <div class="proof-row">
      <div class="proof-image">
        <dropzone [dropZone]="dropZone" [availableProof]="availableProofs[dropZone.requiredProof.id]" (removeProofFromAvailableProofs)="removeProofFromAvailableProofs($event)">     
          <div class="proofs-input no-file-image" #incomeProofUpload (click)="incomeProofUploadInput.click()"></div>
          <input style="display:none;" type="file" #incomeProofUploadInput (change)='fileEvent($event, "incomeProof")' /> 
        </dropzone>
      </div>   
      <div class="proof-name" (click)="incomeProofUpload.click()">Proof of Income</div>
        <div class="info-circle" (click)="showModal('incomeModal')">i</div>   
      <div class="proof-arrow" (click)="incomeProofUpload.click()">
        M
      </div>
      </div>
    </div>  
  </div>


  <div class="upload-button-container">
    <div class="standard-button" (click)="doneButton()" 
    [ngStyle]="fileFound.drivingLicenceBack==false&&fileFound.addressProof==false&&fileFound.drivingLicenceFront==false
    &&fileFound.employmentProof==false ? {'opacity':'0.4', 'pointer-events':'none'} : {}">Upload documents</div>
    </div>
</div>
<navbar [currentSection]="'documents'"></navbar>
</div>


<!-- ERROR AND NOTIFICATION MODALS -->
<div #licenceModal (click)="closeModal('licenceModal')" class="info-modal" id="licence-modal" style="display: none;">
  <div class="modal-wrapper">
    
    <div class="modal-title"> Requirements </div>
    <div class="modal-items-list">
     <div class="modal-item">- Valid driver's licence with 6 months remaining</div>
     <div class="modal-item">- Clear photo with no obstruction or glares</div>
     <div class="modal-item">- All four corners of the licence shown</div>
    </div>
    <div class="close-pos" (click)="closeModal('licence-modal')">Close</div>    
  </div>
</div>

<div #addressModal (click)="closeModal('addressModal')" class="info-modal" id="address-modal" style="display: none;">
  <div class="modal-wrapper">
    <div class="modal-title"> Requirements </div>
    <div class="modal-items-list">
    <div class="modal-item">Any of the following, dated within the last 60 days:</div>
    <div class="modal-item">- A bank or credit card statement</div>
    <div class="modal-item">- A council text letter</div>
    <div class="modal-item">- A utility bill</div>
    </div>
    <div class="close-pos" (click)="closeModal('licence-modal')">Close</div>
  </div>
</div>

<div #incomeModal (click)="closeModal('incomeModal')" class="info-modal" id="income-modal" style="display: none;">
  <div class="modal-wrapper">   
    <div class="modal-title"> Requirements </div>
    <div class="modal-items-list">
    <div class="modal-item">If you are employed:</div>
    <div class="modal-item">- Take a photo of your payslips from the last 2 months</div>
    <br/>
    <div class="modal-item">If you are self-employed:</div>
    <div class="modal-item">- Upload your last 3 months bank statements</div>
    <div class="modal-item">- Upload proof of self-employment from either of the following:</div>
    <div class="modal-item nested-list">- Your most recent tax return</div>
    <div class="modal-item nested-list">- HMRC document which shows the UTR number</div>
    </div>
    <div class="close-pos" (click)="closeModal('licence-modal')">Close</div>
  </div>
</div>


<div #fileFormatModal (click)="closeModal('fileFormatError')" class="info-modal" id="file-format-modal" style="display: none;">
  <div class="modal-wrapper">
    <span (click)="closeModal('fileFormatError')">X</span>
    <h2> File format! </h2>
    <p>- Please upload any file(s) in an image format!</p>
    <p>- Acceptable formats: png, jpg, jpeg, gif, tiff. </p>
    <br/>
    <p class="desc"> If you would like to give upload a file with another format please contact us.</p>
  </div>
</div>

<div #incomeProofMaxModal (click)="closeModal('incomeProofMaxModal')" class="info-modal" id="file-format-modal" style="display: none;">
  <div class="modal-wrapper">
    <span (click)="closeModal('incomeProofMaxModal')">X</span>
    <h2> Upload limit </h2>
    <p class="desc"> You can only upload up to 15 files to prove income, if you have accidentally uploaded an incorrect file, or too many files, please contact us.</p>
    <br/>
  </div>
</div>
