import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

var gmtId = 'GTM-PDST233';

if (environment.production) {
  enableProdMode();
  gmtId = 'GTM-NQMQ6C'
}

if(environment.name == "MOCK") {
  var cacheControl = document.createElement("meta");
  cacheControl.setAttribute('http-equiv', 'Cache-Control');
  cacheControl.setAttribute('content', 'no-cache, no-store, must-revalidate')
  document.head.insertBefore(cacheControl, document.head.children[0]);


  var pragma = document.createElement("meta");
  pragma.setAttribute('http-equiv', 'Pragma');
  pragma.setAttribute('content', 'no-cache') 
  document.head.insertBefore(pragma, document.head.children[0]);

  var expires = document.createElement("meta");
  expires.setAttribute('http-equiv', 'Expires');
  expires.setAttribute('content', '0') 
  document.head.insertBefore(expires, document.head.children[0]);
}

var script = document.createElement("script");
script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','`+gmtId+`');`
document.head.insertBefore(script, document.head.children[0]);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err =>(err));
