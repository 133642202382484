<form *ngIf="model.visible" class="navbar-form record-navigator">
  <div class="form-group">
    <div class="input-group">
      <span class="input-group-addon" (click)="first()" [class.toolbar-button-disabled]="model.position==0">
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </span>
      <span class="input-group-addon" (click)="previous()" [class.toolbar-button-disabled]="model.position==0">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
      </span>
      <input id="position" type="text" class="form-control text-center" [(ngModel)]="model.position" #position name="position" />
      <span class="input-group-addon">of {{model.items.length}}</span>
      <div class="input-group-addon" (click)="next()" [class.toolbar-button-disabled]="model.position==(model.items.length-1)">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </div>
      <span class="input-group-addon" (click)="last()" [class.toolbar-button-disabled]="model.position==(model.items.length-1)">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </span>
    </div>
  </div>
</form>
