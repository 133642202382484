import { Directive, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, ValidationErrors } from "@angular/forms";
import { isANumber } from "../utilities";

export function min(min: number): ValidatorFn {
  if (!isANumber(min)) {
    throw Error("The minimum is expected to be numerical");
  }

  return (control: AbstractControl): ValidationErrors => {
    if (control.value != null) {
      let value = Number(control.value);

      if (isANumber(value) && value < min) {
        return {
          'min': {
            actualValue: value,
            minimumValue: min
          }
        }
      }
    }

    return null;
  };
}

@Directive({
  selector: '[minimum]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDirective, multi: true }]
})
export class MinDirective implements Validator, OnChanges {
  @Input()
  minimum: number;

  validator: ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['minimum'];

    if (change) {
      this.validator = min(change.currentValue);
    }
    else {
      this.validator = null;
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.validator) {
      return this.validator(control)
    }
  }
}
