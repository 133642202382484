import { Injectable } from '@angular/core';

import { DYNAMIC_COMPONENTS } from '../configuration/configuration-dynamic-components';
import { PAGE_JOURNEYS } from '../configuration/configuration-journeys';

import { TokenService } from '../token/token.service';

@Injectable()
export class DynamicComponentsService {

  constructor(protected tokenService: TokenService) { }

  /*
    The components object stores details on each component available for use by the application
  */
  components = DYNAMIC_COMPONENTS;

  getRolePageComponents(page: string): any[] {

    /*
      Get a list of components based upon a particular role and journey page
    */
    var roles = this.tokenService.roles,
      foundJourney = null;

    // roles=['dealerplus'];

    for (var i = 0, ii = roles.length; i < ii; i++) {

      foundJourney = PAGE_JOURNEYS.find(journey => journey.roles.indexOf(roles[i]) != -1);

      if (foundJourney) {
        break;
      }

    }

    if (foundJourney) {

      var foundPage = foundJourney.pages.find(foundJourneyPage => foundJourneyPage.name == page);

      if (foundPage) {
        var components = this.getComponents(foundPage.sections);
        return components;
      }

      throw `The page ${page} does not exist in the journey ${foundJourney.title}.`

    }

    throw `No journey was found for the role(s) ${roles.join(',')}.`

  }

  getComponents(sections: string[]) {

    return sections.map(
      section => {
        var a = 1;
        return this.components.find(component => component.id == section);
      }
    );

  }
}

