import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { Application, Document, Proof, RequiredProof } from '../../../core/services/types';
import { Router } from '@angular/router';
import { TimelineService } from '../../../core/services/timeline/timeline.service'; 
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from './../../../../environments/environment';
import * as moment from 'moment/moment'
import { TokenService } from './../../../core/services/token/token.service';

class DropZone {
  uploader: FileUploader;
  label: string;
  id: string;
  requiredProof: RequiredProof;
  addingProof: boolean;
}

@Component({
  selector: 'finance-documents',
  templateUrl: './finance-documents.component.html',
  styleUrls: ['./finance-documents.component.scss']
})
export class FinanceDocumentsComponent implements OnInit {
  @Input()
  dropZone: DropZone;
  availableProofs: Proof[]
  uploadProofUrl: string;
  checkProofUrl: string;
  application: Application;
  customerPack: Document;
  customerPackDocsUrl: string;
  customerPackEsignUrl: string;
  customerPackEsign: boolean;
  docsIssued: boolean;
  docsRecv: boolean;
  downloadableFiles: number = 0;
  lenderPack: Document;
  signedLenderPack: Document;
  fileBeingUploaded:boolean = false;
  lenderPackDocsUrl: string;
  lenderPackEsignUrl: string;
  lenderPackEsign: boolean = false;
  @ViewChild('lenderPackUpload') lenderPackUpload: ElementRef;
  @ViewChild('LenderAgreement') lenderAgreement: ElementRef;
  constructor(private parentRouter: Router, private timelineService: TimelineService, private domSanitizer: DomSanitizer,
              private tokenService: TokenService) { }

  ngOnInit() {
  this.customerPackEsign = false;
  this.lenderPackEsign = false;
  var requiredProof = {
    label: "Driving licence Front",
    id: "drivingLicenseFront",
    description: "Driving Licence (front)",
    docsRequired: 1
  };
  this.dropZone = {
    uploader: new FileUploader({ removeAfterUpload: true }),
    label: "Driving licence Front",
    id: "drivingLicenseFront",
    requiredProof: requiredProof,
    addingProof: true
  };
  this.uploadProofUrl = environment.base + `/docs/?applicationId=${this.application.id}`;
  this.availableProofs = [];
  this.setUpDocuments();
    var dealStates = this.timelineService.getDealStates(this.application.dealActionHistory);
    if(dealStates[0].indexOf("9") != -1 ){
      this.docsIssued = true;
    } else {
      this.docsIssued = false;
    }
    if(dealStates[0].indexOf("10") != -1 ){
      this.docsRecv = true;
    } else {
      this.docsRecv = false;
    }

    //for testing
    this.docsIssued=true;
    this.lenderPack={clicked : false, id: "something", title: "sometitle", status: "somestatus", fileName: "somefile", links: null};


    ////console.log(this.application);
    this.computeMissingDocuments(this.dropZone);
    this.bindEvents(this.dropZone);

  }

  doneButton(){
    this.parentRouter.navigateByUrl("applications/" + this.application.id + "/docs")
  }


  setUpDocuments() {
    if (this.application.documents && Object.keys(this.application.documents).length > 0) {
      this.customerPack = this.application.documents.filter(doc => doc.id == "CUSTOMER_PACK")[0];
      this.lenderPack = this.application.documents.filter(doc => doc.id == "LENDER_PACK")[0];
      this.signedLenderPack = this.application.documents.filter(doc => doc.id == "SIGNED_LENDER_PACK")[0];
      if (this.customerPack) {
        for (var i = 0; i < this.customerPack.links.length; i++) {
          if (this.customerPack.links[i].isEsignLink) {
            this.customerPackEsign = true;
            this.customerPackEsignUrl = this.customerPack.links[i].url;
          }
        } 
        if (!this.customerPackEsign) {
          this.customerPackDocsUrl = this.createPdfBlob(this.application.documents[0].links[1].url);
        }
      }
      if (this.lenderPack  != undefined || this.lenderPack != null ) {
        for (var i = 0; i < this.lenderPack.links.length; i++) {
          if (this.lenderPack.links[i].isEsignLink) {
            this.lenderPackEsign = true;
            this.lenderPackEsignUrl = this.lenderPack.links[i].url;
          }
        }
        if (!this.lenderPackEsign) {
          this.lenderPackDocsUrl = this.createPdfBlob(this.application.documents[1].links[0].url);
        }
      }
    }
  }

  createPdfBlob(base64String: string): string {
    var byteCharacters = atob(base64String);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: 'application/pdf' })
    return URL.createObjectURL(blob);
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }



  bindEvents(dropZone: DropZone) {
    dropZone.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.removeAnyDuplicateAvailableProof(item);
      if (dropZone.addingProof) {
        this.availableProofs.push(this.getProof(item, dropZone.requiredProof.id));
      } else {
        this.dropZone.addingProof = true;
      }
    };
    dropZone.uploader.onCompleteAll = () => {
      this.computeMissingDocuments(this.dropZone);
    };
  };

  computeMissingDocuments(dropZone: DropZone) {
    dropZone.uploader.setOptions({
      url: this.uploadProofUrl,
      queueLimit: 4,
      authToken: 'Bearer ' + this.tokenService.bearerToken,
      additionalParameter: {
        label: dropZone.id
      }
    });
  }

  removeAnyDuplicateAvailableProof(item: FileItem) {
    var foundProof = this.availableProofs.find(availableProof => availableProof.filename == item.file.name);
    if (foundProof != null) {
      this.removeProofFromAvailableProofs(foundProof);
    }
  }

  removeProofFromAvailableProofs(proof: Proof) {
    let existingProof = this.availableProofs.find(p => p.filename === proof.filename);
    if (existingProof == null) {
      throw new Error("The deleted proof does not exist");
    }
    let proofIndex = this.availableProofs.indexOf(existingProof);
    if (proofIndex === -1) {
      throw new Error("No index could be found for the existing index");
    }
    this.availableProofs.splice(proofIndex, 1);
  }
  
  importFile(file: File, addingProofs: boolean) {
    this.dropZone.addingProof = addingProofs;
    this.dropZone.uploader.addToQueue([file]);
  }

  getProof(item: FileItem, id: string) {
    return <Proof>{
      dateUploaded: moment(new Date()).format("DD-MM-YYYY"),
      filename: item._file.name,
      id: 0,
      title: item._file.name,
      type: id
    };
  }

  fileEvent(fileInput: Event, filename) {
    this.fileBeingUploaded = false;
    var incomeCountMaxed = false;
    var file = (fileInput.target as HTMLInputElement).files[0];
    var fileExtension = "." + file.name.split(".")[1];
      switch (filename.toLowerCase()) {
        case ("lenderpackupload"):
          this.fileBeingUploaded=true;
          //let filev2 = new File([file], filename + fileExtension, { type: file.type });
          this.importFile(file, true);
          break;

        default: break;
      }
  }

  
}


