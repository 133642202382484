<div bsModal [config]="model.config" #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reject()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="model.title"></h4>
      </div>
      <div class="modal-body">
        <p [innerHTML]="model.content"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="reject()">{{model.no}}</button>
        <button type="button" class="btn btn-primary" (click)="confirm()">{{model.yes}}</button>
      </div>
    </div>
  </div>
</div>
