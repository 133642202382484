<div class="main-body">
  <div class="display-only-mobile">
    <div class="logo-top-left">
      <img src="../../../../assets/cp-logo.png" />
    </div>
    <div [hidden]="forgotPassword" class="new-container">
      <h1>Login to your portal</h1>

      <div *ngIf="contactId==null || username ==null" class="need-to-register">
        <a href="https://www.creditplus.co.uk/apply?src=WEB_CP_PORTAL_SU">Don't have an account? Sign up </a>
      </div>
      <div *ngIf="contactId!=null && username !=null" class="need-to-register">
        <a href="{{registerLink}}">
          Don't have an
          account? Sign up
        </a>
      </div>

      <div class="raised-card">
        <form class="login-form" #loginForm="ngForm">
          <div class="form-group">
            <label for="username">Email</label>
            <div class="input-group">
              <input type="text" class="form-control" id="username" maxlength="128" [(ngModel)]="model.username"
                     name="username" #username="ngModel" spellcheck="false" required placeholder="Please enter email"
                     [ngStyle]="model.username=='' || model.username == null ? {'background-color' : 'white'} : {}" />
            </div>
          </div>
          <div class="form-group">
            <div class="position">
              <label class="password-label" for="password">Password</label>
            </div>
            <div class="input-group">
              <input (keydown.enter)="login();" [type]="(showPassword === true) ? 'text' : 'password'" class="form-control" id="password"
                     maxlength="20" [(ngModel)]="model.password" name="password" #password="ngModel" required
                     [passwordPolicyValidator]="passwordPolicy" placeholder="Please enter password"
                     [ngStyle]="model.password=='' || model.password == null ? {'background-color' : 'white'} : {}" (input)="checkPassword();" />
              <div class="password-eye">
                <div class="eye" (click)="showHidePassword()">E</div>
              </div>
            </div>
            <div class="forgotText" (click)="forgottenPassword(true)">Forgot password?</div>

          </div>
          <div class="access-denied" *ngIf="accessDenied">
            The email or password that you entered is incorrect
          </div>
        </form>
      </div>
    </div>

    <div [hidden]="!forgotPassword" class="new-container">
      <h1>Password Reset</h1>
      <div class="reset-subtext">Please enter your email address to receive password reset instructions</div>
      <div class="back-to-login" (click)="forgottenPassword(false)">
        <span class="arrow-left">L</span> Back to Sign in
      </div>
      <div class="raised-card">
        <form class="login-form" #resetForm="ngForm">
          <div class="form-group">
            <label for="username">Email address</label>
            <div class="input-group">
              <input type="text" class="form-control" id="username" maxlength="128" [(ngModel)]="model.username"
                     name="username" #username="ngModel" spellcheck="false" required placeholder="Please enter email"
                     [ngStyle]="model.username=='' || model.username == null ? {'background-color' : 'white'} : {}"
                     (focus)="changingForgottenPasswordEmail()" />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="raised-card">
      <div class="contact-row"></div><div class="fixed-details">From your portal account:</div>
      <div class="contact-row"></div><div class="fixed-details">● See your finance options with personalised offers</div>
      <div class="contact-row"></div><div class="fixed-details">● Choose from our RAC approved cars, or tell us about a car you've already found</div>
    </div>

    <div class="fixed-bar-offset"></div>


    <div class="fixed-bar" style="text-align:center;" [hidden]="passwordEmailFail==true || passwordEmailSuccess==true || verifyingPasswordEmail==true">
      <button *ngIf="!forgotPassword" class="standard-button login-button" (click)="login();" [disabled]="loggingIn" [ngStyle]="passwordError==false ?
                 {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
        Login
      </button>
      <button *ngIf="forgotPassword" class="standard-button login-button" (click)="sendPasswordEmail()">
        Send Password
        Reset Email
      </button>
    </div>
    <div class="fixed-bar" style="text-align:center;" [hidden]="passwordEmailSuccess==false">
      <div class="fixed-bar-notification email-sent">
        <span class="email-sent-icon">f</span>Email sent!
      </div>
    </div>
    <div class="fixed-bar" style="text-align:center;" [hidden]="passwordEmailFail==false">
      <div class="fixed-bar-notification">
        No account found! Confirm email address
      </div>
    </div>

    <div class="fixed-bar" style="text-align:center;" [hidden]="verifyingPasswordEmail==false">
      <div class="fixed-bar-notification email-verifying"><img src="../../../../assets/spinner.gif"></div>
    </div>

  </div>
</div>

<!--<desktop-warning [contactId]="contactId" [contactEmail]="contactEmail"></desktop-warning>-->
