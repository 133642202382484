import { Condition, AnswerCondition, AnswersCondition } from './types-condition';
import { ResidentialAddress, Address, ResidentialInformation } from './types-address';
import { Quote, BaseVehicle, ProposedVehicle, VehicleValuation, VehicleCap, QuoteServiceResponse, FinanceRequest, FinanceResponse } from './types-quote';
import { CreditRating } from './types-credit-rating';
import { Proofs, RequiredProof, Proof } from './types-proof';
import { Offer, LoanOffer } from './types-loan-offer';
import { DealAction } from './types-deal-action';
import { Document, DocumentLink } from './types-document';
import { Employer } from './types-employer';
import { Identity } from './types-identity';
import { Contact } from './types-contact';
import { BankAccount } from './types-bank-account';
import { Dealer, DealerContact } from './types-dealer';
import { Income } from './types-income';
import { NotificationMessage } from './types-notification';
import { Range } from './types-range';
import { ApplicantVehicle, Vehicle } from '../../shared/models/vehicle.model';


class Preference {
  marketing: boolean;
}

// code taken from https://www.typescriptlang.org/docs/handbook/advanced-types.html
function extend<T, U>(first: T, second: U): T & U {
  let result = <T & U>{};
  for (let id in first) {
    (<any>result)[id] = (<any>first)[id];
  }
  for (let id in second) {
    if (!result.hasOwnProperty(id)) {
      (<any>result)[id] = (<any>second)[id];
    }
  }
  return result;
}

class Application {
  public id: number;
  public status;
  public dateOpened: string;
  public mainApplicant: Applicant;
  public creditRating: CreditRating;
  public dealActionHistory: Array<DealAction>
  public quotes: Quote[];
  public bankAccount: BankAccount;
  public loanProposalResponses: Array<LoanOffer>;
  public dealer: Dealer;
  public metadata: MetaData;
  public proofs?: Proof[];
  public documents: Array<Document>
  public roles: String[];
  public applicationNotes: string;
  public salesPerson: string;
  public favouriteVehicles: ApplicantVehicle[];
  public vehicle: ApplicantVehicle;
  public searchResults: Vehicle[];
  public bestSellers: Vehicle[];
  public numberOfPages: number;
  public totalFeedCount: number;
  public feedCategories: Vehicle[];
  public dataProtection: DataProtection;
  public sugestedVehicles: ApplicantVehicle[];

}

class Applicant {
  public identity?: Identity;
  public contact?: Contact;
  public addresses?: ResidentialAddress[];
  public employment?: Employer[];
  public income: Income;
  public Dependents?: number;
}

class MetaData {
  public numberOfVehicleSearches: number;
}

/*
  User
*/
class User {
  public id: number;
  public applications: Application[];
  public preference: Preference;
}

class ApplicationHistoryCustomer {
  public pageResults: any[];
  public pageSize: number;
  public pageNumber: number;
  public totalRecords: number;
}

class JourneyPage {
  public label: string;
  public name: string;
  public route: string;
  public active: boolean;
  public enabled: boolean;
  public completed: boolean;
  public order?: string;
  public content?: JourneyPageContent;
  public activePageNextStep?: NextStep;
  public sections?: string[];
  public childSections?: ChildSection[];
  public sectionValidityObservers?: SectionValidityObserver[];
  public workflows?: string[];
  public states?: pageState[];
}

class NextStep {
  public label?: string;
  public displayProceedButton?: boolean;
  public setStatus?: string;
}

class SectionValidityObserver {
  public id: string;
  public sections: string[];
}

class ChildSection {
  public parentSectionName: string;
  public sections: string[];
}

class pageState {
  public workflows: string[];
  public pageDefaults?: string;
  public sectionDefaults?: string;
  public sectionStates: sectionState[];
}

class sectionState {
  public section: string;
  public state: string;
}

class JourneyPageContent {
  editing?: string;
  readOnly?: string;
}

class IndustryTypes{
  public id: number;
  public name: string;
}
class employedStatus{
  public id: number;
  public label: string;
}

class DataProtection {
  public tCsPrivacyPolicy: boolean;
  public email: boolean;
  public  phone: boolean;
  public sms: boolean;
  public post: boolean;
  public partnerLegitimateInterest: boolean;
  public fraudster: boolean;
}

export {
  User, ApplicationHistoryCustomer, Identity, Contact, Address, Application, Employer, BaseVehicle, ProposedVehicle, VehicleValuation, 
  VehicleCap, Quote, QuoteServiceResponse, Income,  Condition,
  FinanceRequest, FinanceResponse, Applicant, BankAccount, Preference, Dealer, DealerContact, ResidentialAddress, Proof, Proofs, RequiredProof, Range, 
  ResidentialInformation, extend, JourneyPage, MetaData, AnswerCondition, AnswersCondition, Document, DocumentLink, NotificationMessage, CreditRating, Offer, 
  LoanOffer, IndustryTypes, employedStatus, DataProtection
};
