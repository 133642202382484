<div class="details-content">
    <div class="top-section">
        <a (click)="returnShowroom()">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.05469 11.918C7.16406 11.8086 7.21875 11.6445 7.21875 11.4531C7.21875 11.2891 7.13672 11.125 7.02734 10.9883L3.74609 7.84375H11.5938C11.7578 7.84375 11.9219 7.78906 12.0586 7.65234C12.168 7.54297 12.25 7.37891 12.25 7.1875V6.3125C12.25 6.14844 12.168 5.98438 12.0586 5.84766C11.9219 5.73828 11.7578 5.65625 11.5938 5.65625H3.74609L7.02734 2.51172C7.13672 2.40234 7.21875 2.23828 7.21875 2.04688C7.21875 1.88281 7.16406 1.71875 7.05469 1.58203L6.42578 0.980469C6.28906 0.871094 6.125 0.789062 5.96094 0.789062C5.76953 0.789062 5.60547 0.871094 5.49609 0.980469L0.191406 6.28516C0.0546875 6.42188 0 6.58594 0 6.75C0 6.94141 0.0546875 7.10547 0.191406 7.21484L5.49609 12.5195C5.60547 12.6562 5.76953 12.7109 5.96094 12.7109C6.125 12.7109 6.28906 12.6562 6.42578 12.5195L7.05469 11.918Z"
                    fill="#203757" />
            </svg>
            Back to Quotes
        </a>
    </div>
    <div class="group">
        <div class="vehicle-make-mobile">
            <h1>{{selectedVehicle.make}} {{selectedVehicle.model}}</h1>
        </div>
        <div class="finance-price">
            <div class="cash-price">
                <label>Cash price</label>
                <h2>{{selectedVehicle.price | currency:'GBP':true:'1.2-2'}}</h2>
            </div>
            <div class="margin">
                <label>Monthly price</label>
                <h2>{{monthlyPayment | currency:'GBP':true:'1.2-2'}}</h2>
            </div>
            <div *ngIf="financeType == 'PCP' " class="margin">
                <label>Balloon </label>
                <h2>{{balloon | currency:'GBP':true:'1.2-2'}}</h2>
            </div>
        </div>
    </div>
    <div class="body-content">
        <div class="gallery">
            <div class="main-section" [style.background-image]="'url(' + mainImage + ')'">
                <div class="right-arrow" >
                    <i class="fas fa-arrow-right" (click)="scrollImage('right', mainImage)"></i>
                </div>
                <div class="left-arrow" >
                    <i class="fas fa-arrow-left" (click)="scrollImage('left', mainImage)"></i>
                </div>
            </div>
            <div class="seconday-images-wrapper">
                <div *ngFor="let image of vehicleImages" class="secondary-images-container">
                    <img src="{{image}}" class="secondary-images" (click)="setMainImage(image)">
                </div>
            </div>
        </div>
        <div class="finance-details">
            <div class="content">
                <div class="vehicle-info">
                    <label>Registration</label>
                    <p>{{selectedVehicle.registration}}</p>
                </div>
                <div class="vehicle-info">
                    <label>Engine</label>
                    <p>{{selectedVehicle.engineSize}} CC</p>
                </div>
                <div class="vehicle-info">
                    <label>Fuel type</label>
                    <p>{{selectedVehicle.fuelType}}</p>
                </div>
                <div class="vehicle-info">
                    <label>Transmission</label>
                    <p>{{selectedVehicle.transmission}}</p>
                </div>
                <div class="vehicle-info">
                    <label>Mileage</label>
                    <p>{{selectedVehicle.mileage}} mi</p>
                </div>
                <div class="vehicle-info">
                    <label>Year</label>
                    <p>{{selectedVehicle.registrationYear}}</p>
                </div>
                <div class="vehicle-info">
                    <label>Doors</label>
                    <p>{{selectedVehicle.doors}}</p>
                </div>
                <div class="vehicle-info">
                    <label>Colour</label>
                    <p>{{selectedVehicle.colour}}</p>
                </div>
                <div class="vehicle-info">
                    <label>Cash price</label>
                    <p>{{selectedVehicle.price | currency:'GBP':true:'1.2-2'}}</p>
                </div>
                <button class="primary-button wide" (click)="onSelect()">Add To Quotes</button>
                <div class="breakdown">
                    <a (click)="financeBreakdown()">Finance breakdown</a>
                </div>
            </div>
        </div>
    </div>
    <div class="vehicle-promote">
        <div class="vehicle-description">
            <p>{{selectedVehicle.description}}</p>
        </div>
        <div class="rac-container">
            <h4>Benefits of buying with Creditplus</h4>
            <div class="rac-content">
                <div class="rac-image">
                    <img src="../../../../assets/RAC-LOGO.png">
                </div>
                <div class="rac-text">
                    <label>All our cars are RAC approved</label>
                </div>
            </div>
        </div>
    </div>
</div>