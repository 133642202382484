import { VehicleSearchesLeftService } from './services/vehicle-searches/vehicle-searches-left.service';
import { ProofsService } from './services/proofs/proofs.service';

import { CommonModule } from '@angular/common';
import { Injector, NgModule, Optional, Provider, SkipSelf, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { BrowserXhr, ResponseOptions, XHRBackend, XSRFStrategy } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';

//import { SaveGuard } from '../applications/guards/save-guard';
import { ActionsService } from './services/actions/actions.service';
import { SorterService } from './services/addressesSorter/address-sorter.service';
import { ApplicationStatusService } from './services/application-status/application-status.service';
import { AuthenticationGuard } from './services/authentication-guard/authentication-guard.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard/can-deactivate-guard.service';
import { ConditionsService } from './services/conditions/conditions.service';
import { CreditRatingService } from './services/credit-rating/credit-rating.service';
import { DateService } from './services/date/date.service';
import { GlobalModalService } from './services/modal/global-modal.service';
import { LoadingModalService } from './services/modal/loading-modal.service';
import { TabsService } from './services/tabs-service/tabs-service';
import { environment } from '../../environments/environment';
import { TimelineService } from './services/timeline/timeline.service';
import { LoginService } from './services/login/login.service';
import { TokenService } from './services/token/token.service';
import { UserService } from './services/user/user.service';
import { AddressLookupService } from './services/address-lookup/address-lookup.service';
import { QuotesService } from './services/quotes/quotes.service';
import { ApplyService } from './services/apply/apply.service';
import { VehicleService } from './services/vehicle/vehicle.service';
import { CustomersService } from './services/customers/customers.service';
import { NotificationsService } from './services/notifications/notifications.service';

// import { GlobalErrorHandlerService } from './services/global-error-handler/global-error-handler.service';

import { ApplicationResolver } from './resolvers/applications';
import { UserResolver } from './resolvers/user';

import { TimedActionService } from './services/timed-action/timed-action.service';

import { GlobalModalComponent } from './components/global-modal/global-modal.component';
import { DocumentsService } from './documents.service';
import { DynamicComponentsService } from './services/dynamic-components/dynamic-components.service';
import { FavIconService } from './services/fav-icon/fav-icon.service';

import { JourneyPageGuard } from './services/journey-page-guard/journey-page-guard.service';

@NgModule({
  imports: [CommonModule, FormsModule, BrowserAnimationsModule, ModalModule.forRoot(), CollapseModule.forRoot() ],
  declarations: [GlobalModalComponent],
  exports: [GlobalModalComponent],
  providers: [
    LoginService, TokenService, GlobalModalService, LoadingModalService, //SaveGuard,
     ActionsService,
    AuthenticationGuard, CanDeactivateGuard, UserService,
    DateService, SorterService, TabsService, CreditRatingService,
    ConditionsService, ApplicationStatusService, AddressLookupService, QuotesService,
    ApplyService, VehicleService, TimedActionService, environment.provider, ApplicationResolver, UserResolver,
    ProofsService, CustomersService, NotificationsService, DocumentsService, VehicleSearchesLeftService, DynamicComponentsService, JourneyPageGuard,
    FavIconService, TimelineService,
    /*{
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    }*/
  ]
})
export class CoreModule {

  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
