import { QuotesComponent } from '../../../applications/components/quotes/quotes.component';
import { AcceptanceStatusComponent } from '../../../applications/components/acceptance-status/acceptance-status.component';
import { ProofsComponent } from "../../../applications/components/proofs/proofs.component";
import { FinanceDocumentsComponent } from "../../../applications/components/finance-documents/finance-documents.component"
import { SettingsComponent } from "../../../applications/components/settings/settings.component"
import { HelpComponent } from "../../../applications/components/help/help.component"

import { noop } from 'rxjs';
import { SECTIONS } from '../configuration/configuration';
import { AgreementsDocumentationComponent } from '../../../applications/components/agreements-documentation/agreements-documentation.component';
import { MarketingPreferencesComponent } from '../../../applications/components/marketing-preferences/marketing-preferences.component';
import { DocsComponent } from '../../../applications/components/docs/docs.component';
import { ProofsNoOnfidoComponent } from '../../../applications/components/proofs-no-onfido/proofs-no-onfido.component';

const DYNAMIC_COMPONENTS = [
    {
      component: DocsComponent,
      id: SECTIONS.DOCS,
      title: 'Docs'
    },
    {
      id: SECTIONS.CREDIT_RATING,
      title: 'Credit Rating',
      valid: (context, instance) => instance.form.valid,
      dirty: (context, instance) => instance.form.dirty,
      markAsPristine: (context, instance) => {
        instance.form.markAsPristine();
      },
      getData: (application, data, context, instance) => {
        application.creditRating.questions = data;
      }
    },
    {
      component: QuotesComponent,
      id: SECTIONS.GET_QUOTES,
      title: 'Quotes'
    },
    {
      component: ProofsNoOnfidoComponent,
      id: SECTIONS.PROOFS,
      title: 'Proofs'
    },
    {
      component: ProofsComponent,
      id: SECTIONS.PROOF_VERIFY,
      title: 'ProofVerify'
    },
    {
      component: FinanceDocumentsComponent,
      id: SECTIONS.FINANCE_DOCUMENTS,
      title: 'FinanceDocuments'
    },
    {
      component: SettingsComponent,
      id: SECTIONS.SETTINGS,
      title: 'Settings'
    },
    {
      component: HelpComponent,
      id: SECTIONS.HELP,
      title: 'Help'
    },
    {
      component: MarketingPreferencesComponent,
      id: SECTIONS.MARKETING,
      title: 'Marketing'
    },  
    {
      component: AcceptanceStatusComponent,
      id: SECTIONS.ACCEPTANCE_STATUS,
      title: 'Get my loan offer',
      valid: (context, instance) => true,
      dirty: (context, instance) => instance.isDirty,
      getData: (application, data, context, instance) => {

        if (data.length > 0) {
          application.loanProposalResponses = data;
        }

      },
      markAsPristine: noop
    },
    {
      component: AgreementsDocumentationComponent,
      id: SECTIONS.DOCUMENTS,
      title: 'Agreements & Documentation',
      valid: (context, instance) => false,
      dirty: (context, instance) => false,
      getData: (application, data, context, instance) => {
        // Document proof uploads and deletions are handled through seperate web service calls
      }
    }
  ];

  export {
    DYNAMIC_COMPONENTS
  }
