<ng-container *ngIf="model.visible">
  <div class="form-group">
    <div class="input-group">
      <span class="input-group-addon" (click)="minus()" [class.toolbar-button-disabled]="model.min==model.value">
        <i class="fa fa-minus" aria-hidden="true"></i>
      </span>
      <input id="value" type="text" [min]="model.min" [max]="model.max" class="form-control text-center" [(ngModel)]="model.value" #value name="value" />
      <span class="input-group-addon" (click)="plus()" [class.toolbar-button-disabled]="model.max==model.value">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </span>
    </div>
  </div>
</ng-container>
