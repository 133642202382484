
import {timer as observableTimer,  Subscription, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { CONFIGURATION } from "../configuration/configuration";

import * as moment from 'moment/moment';

import { ActionsService } from "../actions/actions.service";
import { TokenService } from "../token/token.service";
import { environment } from '../../../../environments/environment';

import { URLS } from '../configuration/configuration.urls';

@Injectable()
export class TimedActionService {

  subscription: Subscription;
  subscriptionExpired: Subscription;

  subscriptionForceExpire: Subscription;

  constructor(private actionsService: ActionsService, private parentRouter: Router, private tokenService: TokenService) {

    this.registerForceExpirationObserver();

  }

  startTimer() {

    /*
      Make sure to only call this method directly if you only want it to start if not already initialised
      otherwise call resetTimer()
  
      A setting for lifetime of 0 indicates that the user will be permanently logged in
    */

    if (this.tokenService.lifetime>0 && (!this.subscription || (this.subscription && this.subscription.closed))) {

      this.log('timer started:' + moment().format('h:mm:ss a') + ' lifetime=' + this.tokenService.lifetime);

      // Update the token expires setting (extends token expiry by the lifetime from now)
      this.tokenService.updateExpires();

      // Start a timer to broadcast before the token expiration takes place
      this.subscription = observableTimer(this.tokenService.lifetime - CONFIGURATION.authentication.expirationCountdown).subscribe(
        () => this.broadcastExpirationImpending()
      );

      // Start a timer to run on actual token expiration
      this.subscriptionExpired = observableTimer(this.tokenService.lifetime).subscribe(
        () => {
          this.expire();
          this.broadcastExpiration();
        }
      );

    }

  }

  resetTimer() {

    this.log('timer reset');
    this.clearTimer();
    this.startTimer();

  }

  checkResetTimerOnError(error) {

    /*
      Resets the timer where the response status is anything other than 401 and the internet connection is present
    */
    if (error.status !== 401 && navigator.onLine) {
      this.resetTimer();
    }

  }

  clearTimer() {

    if (this.subscription) {
      this.log('cleared timer subscription');
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    if (this.subscriptionExpired) {
      this.log('cleared timer subscriptionExpired');
      this.subscriptionExpired.unsubscribe();
      this.subscriptionExpired = null;
    }

  }

  broadcastExpirationImpending() {

    /*
      Components and/or services can subscribe to this action
    */
    this.actionsService.broadcast(
      {
        action: 'authentication',
        behaviour: 'expiration-impending',
        value: true
      }
    );

    this.log('authentication.expiration-impending:' + moment().format('h:mm:ss a'));

  }

  broadcastExpiration() {

    /*
      Components and/or services can subscribe to this action
    */
    this.actionsService.broadcast(
      {
        action: 'authentication',
        behaviour: 'expiration',
        value: true
      }
    );

    this.log('authentication.expiration:' + moment().format('h:mm:ss a'));

  }


  registerForceExpirationObserver() {
    
    // Components and/or services can broadcast this action to force an expiration and redirect
    
    this.subscriptionForceExpire = this.actionsService.registerObserver(
      'authentication.forceExpiration',
      action => this.expire(),
      this
    );

  }

  expire() {

    this.clearTimer();
    this.tokenService.clearUserInformation();
    this.parentRouter.navigateByUrl(URLS.Login.route);

  }

  log(message: string) {

    if (!environment.production) {
    }

  }

}
