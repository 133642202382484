import { Component, OnInit, Input } from '@angular/core';
import { Vehicle } from '../../models/vehicle.model';

@Component({
  selector: 'vehicle-summary',
  templateUrl: './vehicle-summary.component.html',
  styleUrls: ['./vehicle-summary.component.scss']
})
export class VehicleSummaryComponent implements OnInit {

  @Input() vehicle:Vehicle;  
  @Input() loanOptionsReady:boolean;
  @Input() agreementDocsReady:boolean;
  @Input() stage:string;
  @Input() customerId:string;
  mileage: number;
  constructor() { }

  ngOnInit() {
    this.mileage = this.vehicle.Mileage;

    //console.log(this.vehicle);
  }

}
