import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { Application } from '../../../core/services/types';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'applications-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})

export class QuotesComponent {
  headerObservers: Subscription;
  displayShowRoom: boolean = false;
  id: number = -1;
  vehicleDetailsPage: boolean = false;
  financeBreakdownRequested: boolean = false

  @Input()
  application: Application;

  selectedVehicle: any;

  constructor(private actionsService: ActionsService, private locationStrategy: LocationStrategy) {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      if (this.vehicleDetailsPage == true) {
        this.displayShowroom(true);
      }
      else {
        this.displayShowroom(false);
      }
      history.pushState(null, null, location.href);
    })
  }

  ngOnInit() {
    if (this.application.quotes[0].vehicle.derivative == "Best Deal Car") {
      this.displayShowRoom = true;
      this.id = 0;
    }
    this.headerObservers = this.actionsService.registerObserver(
      'showroom.display',
      action => {
        this.displayShowRoom = action.value.displayShowroom;
        this.id = action.value.id;
      },
      this
    );
    this.headerObservers = this.actionsService.registerObserver(
      'back.quotes',
      action => {
        this.displayShowRoom = false;
      },
      this
    );
    this.headerObservers = this.actionsService.registerObserver(
      'back.showroom',
      action => {
        this.displayShowRoom = true;
        this.vehicleDetailsPage = false;
      },
      this
    );

    this.headerObservers = this.actionsService.registerObserver(
      'display.financeBreakdown',
      action => {
        this.financeBreakdownRequested = action.value.display;
      },
      this
    );
  }

  ngOnDestroy() {
    this.headerObservers.unsubscribe();
  }

  displayVehicleDetails(value) {
    this.displayShowRoom = false;
    this.vehicleDetailsPage = true;
    this.selectedVehicle = value;
    window.scroll(0, 0);
  }
  displayBreakDown(value) {
    this.selectedVehicle = value;
    this.financeBreakdownRequested = true;
  }
  displayShowroom(value) {
    this.displayShowRoom = value;
    this.vehicleDetailsPage = false;
  }
  financeBreakdownClicked(value) {
    this.financeBreakdownRequested = value;
  }
}