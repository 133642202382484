 <div bsModal [config]="model.config" #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="model.maybe" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="maybe()"><span aria-hidden="true">&times;</span></button>
        <button *ngIf="!model.maybe" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reject()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="model.title"></h4>
      </div>
      <div class="modal-body">
        <p [innerHTML]="model.content"></p>
        <ng-container *ngIf="model.exceptionInfo && model.exceptionInfo.show">
          <div [hidden]="collapsedContent">
            <div class="card" style="overflow:auto;max-height:400px;padding:1rem">
              <p>{{model.exceptionInfo.message}}</p>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="collapsedContentToggle()" *ngIf="model.exceptionInfo && model.exceptionInfo.show">{{(collapsedContent)?'View':'Hide'}} error details</button>
        <button *ngIf="model.no" type="button" class="btn btn-primary" data-dismiss="modal" (click)="reject()">{{model.no}}</button>
        <button *ngIf="model.yes" type="button" class="btn btn-primary" (click)="confirm()">{{model.yes}}</button>
        <button *ngIf="model.maybe" type="button" class="btn btn-primary" (click)="maybe()">{{model.maybe}}</button>
      </div>
    </div>
  </div>
</div>