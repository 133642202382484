import { Component, Input, OnInit } from '@angular/core';

import { ActionsService } from './../../../../core/services/actions/actions.service';

import { Search, Visibility, Broadcast } from './../../models.class';

@Component({
    moduleId:module.id,
    selector: 'search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss']
})

export class SearchComponent implements OnInit, Visibility, Broadcast {

    @Input()
    model :Search={
        value:'',
        placeholder:'Search',
        visible:true
    };

    constructor(private actionsService :ActionsService) { }

    ngOnInit() { }

    search() {

        this.broadcast();

    }

    show() {

        this.model.visible=true;

    }

    hide() {

        this.model.visible=false;

    }

    toggle() {

        this.model.visible=(! this.model.visible);

    }

    broadcast() {

        var action={
            group:'search',
            value:this.model.value
        };

        this.actionsService.broadcast(action);
    }

}
