import { Input } from '@angular/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FileUploader, FileItem } from "ng2-file-upload";
import { ApplicationStatusService } from '../base-form/base-form-testing.barrel.spec';


@Component({
  selector: 'document-lister',
  templateUrl: './document-lister.component.html',
  styleUrls: ['./document-lister.component.scss']
})

export class DocumentListerComponent {

  @Input()
  uploader: FileUploader;

  @Output()
  itemUploaded: EventEmitter<FileItem> = new EventEmitter<FileItem>();

  @Output()
  itemDeleted: EventEmitter<FileItem> = new EventEmitter<FileItem>();

  maximumFileSize: number;
  acceptedFileFormats: string[];
  acceptedFileFormatsList: string ;
    
  constructor(private applicationStatusService : ApplicationStatusService) {
    this.maximumFileSize = this.applicationStatusService.configuration.proofs.maximumFileSize;
    this.acceptedFileFormats =  this.applicationStatusService.configuration.proofs.acceptedFileFormats.map(acceptedFileFormat => acceptedFileFormat.mimeType);
    this.acceptedFileFormatsList = this.applicationStatusService.configuration.proofs.acceptedFileFormats.map(acceptedFileFormat => acceptedFileFormat.extention).sort().join(', ');
      
  }

  onRemove(item:FileItem){
    item.remove();
    this.itemDeleted.emit(item);
  }

  onUpload(item:FileItem){
    item.upload();
    this.itemUploaded.emit(item);
  }

}
