import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { LoginService } from './../login/login.service';
import { TimedActionService } from "../timed-action/timed-action.service";
import { TokenService } from './../token/token.service';
import { URLS } from '../configuration/configuration.urls';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router, private timedActionService: TimedActionService, private tokenService: TokenService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url) && this.checkRoles(route);
  
  }

  checkLogin(url: string): boolean {
  
    if (this.tokenService.isAuthenticated()) {
      this.timedActionService.startTimer();
      return true;
    }
    this.loginService.redirectUrl = url;
    this.router.navigateByUrl(URLS.Login.route);

    return false;
  
  }

  checkRoles(route: ActivatedRouteSnapshot) {
   

    if (route.data && route.data.roles) {      
      var foundRole: boolean = this.tokenService.roles.some(
        (role) => route.data.roles.indexOf(role) != -1
      );

      if (!foundRole) {
        this.router.navigateByUrl(URLS.NotFound.route);
        return false;
      }

    }
    else if (route.data && route.data.redirectRoles) {

      var foundRedirectRole = route.data.redirectRoles.find( (redirectRole) => this.tokenService.roles.indexOf(redirectRole.id) != -1 );

      if (foundRedirectRole) {
        this.router.navigateByUrl(foundRedirectRole.redirectTo);
        return false;
      }

    }
    
    return true;
  
  }

}
