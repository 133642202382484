import { Configuration } from "./configuration";

var CONFIGURATION_DEALER: Configuration = {
    appearance: {
        logo: './assets/LogoDealerPlus.png',
        phoneIcon: './assets/call-button.png',
        title: 'DealerPlus Self Serve',
        favIcon: 'assets/favicon-dealer.ico'
    },
    authentication: {
        passwordPolicy: {
            minimumLength: 6,
            numbers: false,
            letters: true,
            specialCharacters: ''
        },
        expirationCountdown: 30000
    },
    contact: {
        telephone: '0333 222 4070',
        telephoneComplaints: '01202 684 898',
        email: 'sales@dealerplus.co.uk',
        emailComplaints: 'complaints@dealerplus.co.uk',
        address: 'Waterloo House, Fleets Corner, Nuffield Rd, Poole, Dorset, BH17 0HL',
        defaultPreferredContactMethod: 'Mobile',
        defaultPreferredContactTime: 'No Preference'
    },
    financeRequest: {
        maximumLTVPercentage: 1.2,
        minimumLoan: 3000,
        minimumDeposit: 500,
        term: {
            minimum: 24,
            maximum: 120,
            step: 6
        },
        annualMileage: {
            minimum: 0,
            maximum: 40000,
            step: 1000
        }
    },
    vehicle: {
        maximumSearchesPermitted: -1,
        maximumAgeAtStartOfLoan: 5,
        maximumAgeAtEndOfLoan: 9,
        maximumMileageAtStartOfLoan: 120000,
        minRegistrationLength: 2,
        maxRegistrationLength: 8
    },
};

export {
    CONFIGURATION_DEALER
}