import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './core/services/authentication-guard/authentication-guard.service';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
// import { ROLES } from './core/services/configuration/configuration-roles';

const appRoutes: Routes = [
  /*{
    path: '', pathMatch: 'full',
    component: NotFoundComponent,
    canActivate: [AuthenticationGuard],
    data: {
      redirectRoles: [
        {
          id: ROLES.CUSTOMER.name,
          redirectTo: '/login',
        },
        {
          id: ROLES.DEALER.name,
          redirectTo: '/dealer-portal',
        },
        {
          id: ROLES.ADMIN.name,
          redirectTo: '/dealer-portal',
        }
      ]
    }
  },*/
  {
    path: 'login',
    loadChildren: () => import ('../app/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import ('../app/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register-panel',
    loadChildren: () => import('../app/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'applications',
    loadChildren: () => import('../app/applications/applications.module').then(m => m.ApplicationsModule)
  },
  {
    path: 'NotFound',
    component: NotFoundComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'vehicle', 
    loadChildren: () => import('../app/vehicle/vehicle.module').then(m => m.VehicleModule)
  },
  {
    path: 'dashboard', 
    loadChildren: () => import('../app/dashboard/dashboard.module').then(m =>m.DashboardModule)
  },
  {
    path: '', 
    loadChildren: () => import('../app/home/home.module').then(m => m.HomeModule)
  },
  { path: '**', redirectTo: 'NotFound' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
