import { ApplicationStatusService } from './../../../core/services/application-status/application-status.service';
import { noop } from 'rxjs';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PATTERNS } from "../../../core/services/validationservice/validation.config";
import { Address } from '../../../core/services/types';
import { AddressLookupResult } from '../../../core/services/address-lookup/address-lookup';
import { AddressLookupService } from "../../../core/services/address-lookup/address-lookup.service";

@Component({
  selector: 'addressLookup',
  template: `           
   <div class="input-group">
      <input type="text" class="form-control form-control2" placeholder="Start typing your postcode/address" required [ngModel]="postcode" (ngModelChange)="setValue($event);addressLookup()" [ngClass]="{'ng-invalid': isInvalid } ">
    </div>
    <div class="test">
    <select *ngIf="status === lookupStatus.Selecting" class="form-control coloured-select" size="6" [ngModel]="selectedAddress" (ngModelChange)="getAddressDetails($event)">
      <option *ngFor="let address of addressLookups" id="address.id" [ngValue]="address">{{address.text}}</option>
    </select>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressLookupComponent),
      multi: true,
    }]
})
export class AddressLookupComponent implements ControlValueAccessor {
  @Output() address: EventEmitter<Address> = new EventEmitter<Address>();

  @Input() isInvalid: boolean;

  @Input() id: number;

  addresses: Address[];

  addressLookups: AddressLookupResult[];

  postcode: string;

  status: LookupStatus = LookupStatus.Init;

  lookupStatus = LookupStatus;

  selectedAddress: AddressLookupResult = null;

  private onChangeCallback: (_: any) => void = noop;

  setValue(postcode: any) {
    this.writeValue(postcode);
    this.onChangeCallback(this.postcode);
  }

  writeValue(postcode: any): void {
    this.postcode = postcode;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState(isDisabled: boolean): void { }

  toSearchingStatus() {
    this.status = LookupStatus.Searching;
  }

  async addressLookup() {
    this.status = LookupStatus.Searching;
    let addresses = [];

    try {
      //addresses = await this.addressLookupService.search(this.postcode).toPromise();
    }
    catch (error) {

      if (error.status == 404) {
        /*
          Not found so emit a null address which can then be handled elsewhere to display an error
        */
        this.address.emit(null);
      }
      else if (error.status == 422) {
        /*
          Display any validation errors returned by the service as we aren't expecting any
        */
        throw {
          customError: {
            errorConfiguration: this.applicationStatusService.configuration.errors.postcode_validation,
            error: error
          }
        };
      }
      else {
        throw {
          customError: {
            errorConfiguration: this.applicationStatusService.configuration.errors.postcode_generic,
            error: error
          }
        };
      }

    }

    if (addresses && addresses.length > 0) {
      this.status = LookupStatus.Selecting;
      this.addressLookups = addresses;
    }
    else {
      this.status = LookupStatus.Init;
    }
  }
  constructor(private addressLookupService: AddressLookupService, private applicationStatusService : ApplicationStatusService) { }
}

enum LookupStatus {
  Init = 0,
  Searching = 1,
  Selecting = 2
}
