<div id="quote-zone-data">
  <div class="empty-card" *ngIf="!vehicle.valid && !vehicle.open; else vehicleOpen">
    <h3>Want to compare this with another quote?</h3>
    <a class="vrm-lookup" (click)="this.updateVehicleCard.emit(id)">Get a new quote</a>
  </div>
  <ng-template #vehicleOpen>
    <div class="adjust-width">
      <span class="cross-img" (click)="this.updateVehicleCard.emit(id)" click>&times;</span>
    </div>
    <div *ngIf="!vehicle.valid" class="search content">
      <h3>Add the vehicle you want to buy</h3>
      <div [hidden]="regLookupEnabled == false">
        <form [formGroup]="form">
          <label id="regNumber-lbl" for="regNumberInput">Enter the registration</label>
          <div class="inputs">
            <div class="vrm">
              <span></span>
              <input tabindex="1" id="regNumberInput" type="text" placeholder="Registration" required [formControl]="form.controls['regNumber']"
              />
            </div>
            <span *ngIf="form.controls['regNumber'].touched && !form.controls['regNumber'].valid" class="error">Please enter a valid registration number</span>
          </div>
          <div class="inputs">
            <label class="secondary-lbl" for="priceInput">Asking price</label>
            <div class="price">
              <span>&pound;</span>
              <input tabindex="2" id="priceInput" type="text" placeholder="0" required [formControl]="form.controls['price']" />
            </div>
            <span *ngIf="form.controls['price'].touched && !form.controls['price'].valid" class="error">Please enter a valid price</span>
          </div>
          <div class="inputs">
            <label class="secondary-lbl" for="mileageInput" required>Mileage</label>
            <div class="mileage">
              <span>m</span>
              <input tabindex="3" id="mileageInput" type="text" placeholder="0" [formControl]="form.controls['mileage']" />
            </div>
            <span *ngIf="form.controls['mileage'].touched && !form.controls['mileage'].valid" class="error">Please enter a valid mileage</span>
          </div>
          <button class="primary-button wide" tabindex="4" (click)="search()" [disabled]="!form.valid">Lookup vehicle</button>
          <div>
            <span class="error" *ngIf="vehicle.errorList != null && vehicle.errorList.length > 0 ">{{vehicle.errorList[0].message}}</span>
          </div>
        </form>
      </div>
      <div *ngIf="source != 'dealer'">
        <div [hidden]="regLookupEnabled == true">
          <h4 class="margin title">Haven't found a car yet? </h4>
          <span class="showroom-text">Search our database of over
            <strong>200,000</strong> vehicles.</span>
          <button class="primary-button wide" tabindex="5" (click)="displayshowroom()">Search Vehicles</button>
          <span class="divided">or</span>
          <div>
            <h4 class="margin">I have already found the vehicle
            </h4>

            <button class="secondary-button wide" tabindex="4" (click)="changeLayout(true)">Lookup Vehicle</button>
          </div>
          <div class="card-open-error">
            <span class="error" *ngIf="vehicle.errorList != null && vehicle.errorList.length > 0 ">{{vehicle.errorList[0].message}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="vehicle.valid" class="vehicle-content">
      <div class="vehicle-card-img">
        <img src={{vehicle.vehicle.imageUrl}} />
      </div>
      <h5 *ngIf="vehicle.cardSuggested == true" class="car-suggestion">Our suggestion</h5>
      <div class="vehicle-card-details">
        <div class="vehicle-card-title">
          <p *ngIf="vehicle.cardSuggested == true && source == 'customer'">From Creditplus stock for you...</p>
          <p *ngIf="vehicle.cardSuggested == true && source == 'ccf'">From Compare Car Finance stock for you...</p>
          <p *ngIf="vehicle.cardSuggested == false">You are looking to buy this...</p>
          <h3>{{vehicle.vehicle.make}} {{vehicle.vehicle.model}}</h3>
          <p *ngIf="application.quotes[0].financeRequest.financeType == 'HP'">Finance type:
            <strong>Hire purchase</strong>
          </p>
          <p *ngIf="application.quotes[0].financeRequest.financeType == 'PCP'"> Finance type:
            <strong> Personal contract purchase</strong>
          </p>
          <p>Registration: {{vehicle.vehicle.registration}} ({{vehicle.year}})</p>
        </div>
        <span class="divider"></span>
        <div class="flex">
          <div class="vehicle-card-payment-hp">
            <p *ngIf="vehicle.quote.financeType == 'HP'">{{vehicle.term}} monthly payments from</p>
            <h1 *ngIf="vehicle.quote.financeType == 'HP'">£{{vehicle.monthlyPayment}}</h1>
          </div>
          <div class="vehicle-card-payment">
            <span *ngIf="vehicle.quote.financeType == 'PCP'">
              from
            </span>

            <h2 *ngIf="vehicle.quote.financeType == 'PCP'">£{{vehicle.monthlyPayment}}</h2>
            <span *ngIf="vehicle.quote.financeType =='PCP'">
              per month for {{vehicle.term}} months
            </span>
          </div>
          <div *ngIf="vehicle.quote.financeType =='PCP'" class="line"></div>
          <div *ngIf="vehicle.quote.financeType == 'PCP'" class="vehicle-card-payment">
            <span>With a </span>
            <h2>£{{vehicle.quote.balloon}}</h2>
            <span>purchase option</span>
          </div>
        </div>
        <div class="card-open-error">
          <span class="error" *ngIf="vehicle.errorList != null && vehicle.errorList.length > 0 ">{{vehicle.errorList[0].message}}</span>
        </div>
        <div [hidden]="vehicle.errorList != null && vehicle.errorList.length > 0">
          <a (click)="quoteSelected()">
            <button class="primary-button wide">Get offers</button>
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</div>