/*
  Identity
*/
class Identity {
    public title: string;
    public forename: string;
    public surname: string;
    public dateOfBirth: string;
    public gender: string             // 'Male' | 'Female';
    public maidenName?: string;
    public maritalStatus: string
    public drivingLicense: string;
    public nationality: string;
}


export {
    Identity
}