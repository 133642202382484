import { Component, OnInit } from '@angular/core';
import { ActionsService } from '../base-form/base-form-testing.barrel.spec';

@Component({
  selector: 'dealerplus-privacy-policy',
  templateUrl: './dealerplus-privacy-policy.component.html',
  styleUrls: ['./dealerplus-privacy-policy.component.scss']
})
export class DealerplusPrivacyPolicyComponent implements OnInit {
  constructor(private actionsService: ActionsService) { }

  ngOnInit() {
  }

  hidePolicy() {
    this.actionsService.broadcast({
      action: 'hide',
      behaviour: 'privacy',
      value: true
    });

  }
}