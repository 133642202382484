import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'saving-alert',
  templateUrl: './saving-alert.component.html',
  styleUrls: ['./saving-alert.component.scss']
})
export class SavingAlertComponent implements OnInit {

  @Input()
  saving: boolean = false;

  @Input()
  dirty: boolean = false;

  @Input()
  message: string = 'Saving';

  @Output()
  save: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  exit: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
