<ng-container *ngIf="showHelp">

  <div class="alert alert-info" role="alert" [class.active]="showHelp">
    <ng-container *ngIf="infoText">
      <!--<span class="badge badge-default"> <i class="fa fa-info fa-2" aria-hidden="true"></i> </span>-->
      <!-- <p>{{infoText}}</p> -->
      <p [innerHtml]="infoText"></p>
    </ng-container>
    <ng-container *ngIf="whyText">
      <!--<span class="badge badge-default">why?</span>-->
      <p>{{whyText}}</p>
    </ng-container>
  </div>
</ng-container>
