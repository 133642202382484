import { Component, Input } from '@angular/core';

import { ButtonGroupComponent }  from './../button-group/button-group.component';
import { ButtonGroup } from './../../models.class';

import { ActionsService } from './../../../../core/services/actions/actions.service';

import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'save-cancel',
    templateUrl: 'save-cancel.component.html',
    styleUrls: ['save-cancel.component.scss']
})
export class SaveCancelComponent extends ButtonGroupComponent {

    @Input()
    model :ButtonGroup = new ButtonGroup(
        {
            group:'SAVECANCEL',
            type:'icons',
            btnclass:'btn-default',
            navbar:true,
            selection:'none',
            visible:true,
            items:[
                {icon:'fa-check', action:'SAVE', disabled:false, active:false, visible:true},
                {icon:'fa-undo', action:'CANCEL', disabled:false, active:false, visible:true}
            ]
        }
    );

    observers :Subscription[];
    observer :Subscription;

    constructor(public actionsService :ActionsService) {

        super(actionsService);

        this.observer=this.actionsService.registerObserver(
            'EDIT.click',
            action => {
                this.behaviour(action);
            },
            this
        );

        this.observers= this.actionsService.registerObservers(
            ['ADD.click','EDIT.visible', 'DELETE.enabled'],
            action => {
                this.behaviour(action);
            },
            this
        );

    }

    behaviour(action :any) {
        this.toggle('save');
        this.show('cancel');

    }

    ngOnDestroy(){
        this.actionsService.cancelSubscription(this.observer);
        this.actionsService.cancelSubscriptions(this.observers);
    }

}
