import { JourneyPage } from "../types";

import { WORKFLOWS } from "./configuration-workflows";
import { SECTIONS } from "./configuration-sections";
import { PAGES } from "./configuration-pages";


const DEALER_JOURNEY: JourneyPage[] = [
    {
        label: 'Quotes',
        name: PAGES.QUOTES.label,
        route: PAGES.QUOTES.route,
        active: true,
        enabled: true,
        completed: false,
        order: '2',
        content:{
            editing: '<p>Now it’s time for the exciting part of your journey! Tell us the car you want and then what loan you are looking for…</p>'
        },
        activePageNextStep: {
            label: 'Proceed using this quote',
            displayProceedButton: false,
            // setStatus: 'DETAILS_STARTED'

        },
        sections: [SECTIONS.GET_QUOTES],
        sectionValidityObservers:[],
        workflows: [WORKFLOWS.ENQUIRY_RECEIVED, WORKFLOWS.PROVISIONALLY_ACCEPTED, WORKFLOWS.QUOTES_STARTED],
        states: []
    }, 
    {
        label: 'Customer details',
        name: PAGES.EXTRADETAILS.label,
        route: PAGES.EXTRADETAILS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '100',
        activePageNextStep: {
            label: 'Proceed and fill in your details',
            displayProceedButton: false,
            setStatus: 'DETAILS_STARTED'
        },
        sections: [SECTIONS.EXTRADETAILS],
        sectionValidityObservers: [],
        workflows: [],
        states: []
    },
    {
        label: 'Extra details',
        name: PAGES.EXTRADETAILS.label,
        route: PAGES.EXTRADETAILS.route,
        active: true,
        enabled: true,
        completed: false,
        order: '3',
        sections: [SECTIONS.EXTRADETAILS],
        sectionValidityObservers: [],
        workflows: [
            WORKFLOWS.Further_Info_Required,
        ],
        states: []
    },
    {
        label: 'Dealer Portal',
        name: PAGES.DEALERPORTAL.label,
        route: PAGES.DEALERPORTAL.route,
        active: true,
        enabled: true,
        completed: false,
        order: '1',
        sections: [SECTIONS.DEALERPORTAL],
        sectionValidityObservers: [],
        workflows: [
            WORKFLOWS.ENQUIRY_RECEIVED,
        ],
        states: []
    },
    {
        label: 'Final page',
        name: PAGES.FINAL.label,
        route: PAGES.FINAL.route,
        active: true,
        enabled: true,
        completed: false,
        order: '6',
        content: {
            editing: '<p>To get you started on your way to getting a new car we need to get some personal details from yourself so that we know who you are! The more information you provide, the better the loan offer!</p>',
            readOnly: '<p>If you have made a slight error entering your details then please contact us on {{telephone}} to make any changes, this is to prevent fraudulent activity.</p>'
        },
        activePageNextStep: {
            label: 'Get me a loan offer',
            displayProceedButton: true,
            setStatus: 'APPLICATION_PENDING'
        },
        sections: [SECTIONS.FINAL_PAGE],
        sectionValidityObservers: [],
        workflows: [
            WORKFLOWS.ENQUIRY_RECEIVED,
            WORKFLOWS.PROVISIONALLY_ACCEPTED,
            WORKFLOWS.QUOTES_STARTED,
            WORKFLOWS.DETAILS_STARTED,
            WORKFLOWS.DOCS_TO_BE_ISSUED,
            WORKFLOWS.DOCS_ISSUED,
            WORKFLOWS.DOCS_RECEIVED,
            WORKFLOWS.DOCS_WITH_LENDER,
            WORKFLOWS.AWAITING_PROOFS,
            WORKFLOWS.PROOFS_RECEIVED,
            WORKFLOWS.PAID_OUT,
            WORKFLOWS.CLIENT_CANCELLED,
            WORKFLOWS.ABANDONED,
            WORKFLOWS.CANNOT_LEND,
            WORKFLOWS.APPLICATION_PENDING,
            WORKFLOWS.APPLICATION_SENT,
            WORKFLOWS.APPLICATION_DECLINED,
            WORKFLOWS.APPLICATION_REFERRED,
            WORKFLOWS.APPLICATION_ACCEPTED,
            WORKFLOWS.APPLICATION_ERROR,
            WORKFLOWS.APPLICATION_MANUAL
        ],
        states: []
    },
    {
        label: 'Loan offer',
        name: PAGES.CONFIRMATION.label,
        route: PAGES.CONFIRMATION.route,
        active: true,
        enabled: true,
        completed: false,
        order: '4',
        content:{
            editing: '<p>That’s a great choice of car! You’re nearly there! The final part of your application is below, once this is completed simply select SUBMIT MY APPLICATION to secure your finance deal.</p>'
        },
        activePageNextStep: {
            label: 'Accept this offer',
            displayProceedButton: false,
            setStatus: 'DOCS_TO_BE_ISSUED'
        },
        sections: [SECTIONS.ACCEPTANCE_STATUS],
        sectionValidityObservers:[],
        workflows: [WORKFLOWS.APPLICATION_PENDING, WORKFLOWS.APPLICATION_SENT, WORKFLOWS.APPLICATION_DECLINED, WORKFLOWS.APPLICATION_REFERRED, WORKFLOWS.APPLICATION_ACCEPTED, WORKFLOWS.APPLICATION_ERROR, WORKFLOWS.APPLICATION_MANUAL],
        states: [
            {
                workflows: [WORKFLOWS.APPLICATION_DECLINED, WORKFLOWS.APPLICATION_REFERRED, WORKFLOWS.APPLICATION_ACCEPTED, WORKFLOWS.APPLICATION_ERROR],
                pageDefaults: 'PAGE_ACTIVE_AND_READONLY',
                sectionStates:[]
            }
        ]
    },
    {
        label: 'Agreement & documents',
        name: PAGES.AGREEMENT.label,
        route: PAGES.AGREEMENT.route,
        active: true,
        enabled: true,
        completed: false,
        order: '5',
        content:{
            editing: '<p>Congratulations! You’ve been accepted! The final part of your journey awaits! In order to secure your finance deal we need to get your proofs approved and tell you about our useful products designed to help you when you’re on the road!</p>'
        },
        sections: [SECTIONS.DOCUMENTS],
        activePageNextStep: {
            displayProceedButton: false
        },
        workflows: [WORKFLOWS.DOCS_TO_BE_ISSUED, WORKFLOWS.DOCS_ISSUED, WORKFLOWS.DOCS_RECEIVED, WORKFLOWS.DOCS_WITH_LENDER, WORKFLOWS.AWAITING_PROOFS, WORKFLOWS.PROOFS_RECEIVED, WORKFLOWS.PAID_OUT],
        states: [
            {
                workflows: [WORKFLOWS.DOCS_TO_BE_ISSUED],
                sectionStates:[
                    {
                        section: SECTIONS.DOCUMENTS,
                        state: 'SECTION_HIDDEN'
                    }
                ]
            },
            {
                workflows: [WORKFLOWS.DOCS_ISSUED, WORKFLOWS.DOCS_RECEIVED, WORKFLOWS.DOCS_WITH_LENDER, WORKFLOWS.AWAITING_PROOFS],
                sectionDefaults: 'SECTION_VISIBLE_AND_READONLY',
                sectionStates:[
                    {
                        section: SECTIONS.DOCUMENTS,
                        state: 'SECTION_VISIBLE_AND_EDITABLE'
                    }
                ]
            },
            {
                workflows: [WORKFLOWS.PROOFS_RECEIVED, WORKFLOWS.PAID_OUT],
                pageDefaults: 'PAGE_ACTIVE_AND_READONLY',
                sectionStates:[]
            }
        ]
    }
];

export {
    DEALER_JOURNEY
}
