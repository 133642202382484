import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from './../../../core/services/login/login.service'
import { Router } from '@angular/router';
import { Application } from '../../../core/services/types';
import { UserService } from '../../../core/services/user/user.service';
import { ApplicationStatusService } from '../../../core/services/application-status/application-status.service';
import { Title } from '@angular/platform-browser';
import * as pako from 'pako';
@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @Input()
  application: Application;
  homeUrl = "../../../../assets/navbar/home.png";
  vehicleUrl = "../../../../assets/navbar/vehicles.png";
  docsUrl = "../../../../assets/navbar/documents.png";
  accountUrl = "../../../../assets/navbar/account-active.png";
  constructor(private loginService:LoginService, private ParentRouter:Router,
              private userService: UserService, private applicationStatusService: ApplicationStatusService,
              private titleService: Title) { }
              
  ngOnInit() {
    this.titleService.setTitle("Account Settings");
    const compressed = pako.deflate(JSON.stringify(this.application));
    localStorage.setItem('_sdma', JSON.stringify(compressed));
  }

  async logout(){
    this.loginService.logout();
    this.ParentRouter.navigateByUrl("/login");
  }
  goHome(){
    this.ParentRouter.navigateByUrl("applications/" + this.application.id + "/dashboard")
  }
  goDocuments(){
    this.ParentRouter.navigateByUrl("applications/" + this.application.id + "/docs")
  }

  goVehicles(){
    this.ParentRouter.navigateByUrl("applications/" + this.application.id + "/vehicles")
    this.application.mainApplicant.identity.forename
  }

  changePassword(){
    this.ParentRouter.navigateByUrl("applications/" + this.application.id + "/settings/change-password")
  }
  // updateBankDetails(){
  //   this.ParentRouter.navigateByUrl("applications/" + this.application.id + "/extra-details")
  // }
  marketingPreferences(){
    this.ParentRouter.navigateByUrl("applications/" + this.application.id + "/marketing")
  }
}
