import { CommercialAddress } from "./types-address";

/*
  Dealer
*/
class Dealer {
    public name?: string;
    public address?: CommercialAddress;
    public contact?: DealerContact;
    public vehicleLink?: string;
    public website?: string;
}

class DealerContact {
    public name: string;
    public telephone: string;
}

export {
    Dealer, DealerContact
}
