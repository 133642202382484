<vehicle-display class="mobile-only"></vehicle-display>
<div class="documents-pannel">
  <h1>Agreement & Documentation</h1>
  <h6>Please sign and upload the following documentation and proofs</h6>
  <div class="agreement-section">
    <div *ngIf="source != 'dealer'">
      <h3>Your Customer Pack</h3>

      <h6 *ngIf="!customerPackEsign" class="pack-description">Please download the document and then print it so you can sign the relevant parts. Then scan the new document and upload
        it in the box below.</h6>
      <div class="btn-group customer-pack">
        <div class="margin">
          <a *ngIf="customerPackEsign" [href]="customerPackEsignUrl" target="_blank">
            <button class="btn secondary-button long">e-Sign</button>
          </a>
          <div *ngIf="!customerPackEsign">
            <a [href]="sanitize(customerPackDocsUrl)" download="CustomerPack.pdf">
              <button class="btn tertiary-button long">Download</button>
            </a>
          </div>
        </div>
        <div class="eSign-choice">
          <div *ngIf="customerPackEsign && customerPack.links[0].isEsignLink">
            <a (click)="toogleEsign(true,false)">Don't want to e-Sign?</a>
          </div>
          <div *ngIf="!customerPackEsign && customerPack.links[0].isEsignLink">
            <a (click)="toogleEsign(true,true)">Prefer to e-Sign?</a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="lenderPack != null">
      <h3>Your Lender Documentation</h3>
      <h6 *ngIf="!lenderPackEsign" class="pack-description">Please download the document and then print it so you can sign the relevant parts. Then scan the new document and upload
        it in the box below.</h6>
      <div class="btn-group lender-pack">
        <div class="margin">
          <div *ngIf="!isStaging">
            <a *ngIf="lenderPackEsign" [href]="lenderPackEsignUrl" target="_blank">
              <button class="btn secondary-button long">e-Sign</button>
            </a>
          </div>
          <div *ngIf="isStaging">
            <a *ngIf="lenderPackEsign" [href]="customerPack.links[0].url" target="_blank">
              <button class="btn secondary-button long">e-Sign</button>
            </a>
          </div>
          <div class="order" *ngIf="!lenderPackEsign">
            <a [href]="sanitize(lenderPackDocsUrl)" download="LenderPack.pdf">
              <button class="btn tertiary-button long">Download</button>
            </a>
          </div>
        </div>
        <div *ngIf="!isStaging">
          <div class="eSign-choice">
            <div *ngIf="lenderPackEsign && lenderPack.links[0].isEsignLink">
              <a (click)="toogleEsign(false,false)">Don't want to e-Sign?</a>
            </div>
            <div *ngIf="!lenderPackEsign && lenderPack.links[0].isEsignLink">
              <a (click)="toogleEsign(false,true)">Prefer to e-Sign?</a>
            </div>
          </div>
        </div>
        
        <div *ngIf="isStaging">
            <div class="eSign-choice">
              <div *ngIf="lenderPackEsign">
                <a (click)="toogleEsign(false,false)">Don't want to e-Sign?</a>
              </div>
              <div *ngIf="!lenderPackEsign">
                <a (click)="toogleEsign(false,true)">Prefer to e-Sign?</a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="desktop-only">
    <h2 class="next-steps-title">Proofs for you to upload</h2>
    <h6>Please upload a copy of the following: </h6>
    <div class="proofs-header">
      <div class="proofs-title">
        <div class="step">
          <h6>Driving Licence (Front, displaying current address)
            <a (click)="displayHelpBox('drivingLicenseFront')">
              <i class="far fa-question-circle"></i>
            </a>
          </h6>
        </div>
      </div>
    </div>
    <div class="proofs-header">
      <div class="proofs-title">
        <div class="step">
          <h6>Driving Licence (back)
            <a (click)="displayHelpBox('drivingLicenseback')">
              <i class="far fa-question-circle"></i>
            </a>
          </h6>
        </div>
      </div>
    </div>
    <div *ngIf="CreditRating != 'Prime'">
      <div class="proofs-header">
        <div class="proofs-title">
          <div class="step">
            <h6>Proof of employment
              <a (click)="displayHelpBox('employment')">
                <i class="far fa-question-circle"></i>
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="proofs-header" *ngIf="!customerPackEsign">
      <div class="proofs-title">
        <div class="step">
          <h6>Customer pack
          </h6>
        </div>
      </div>
    </div>
    <div class="proofs-header" *ngIf="!lenderPackEsign && lenderPack != null">
      <div class="proofs-title">
        <div class="step">
          <h6>Lender pack
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-only">
    <div class="line"></div>
    <h2 class="next-steps-title">Proofs for you to upload</h2>
    <h6>Please upload a copy of the following: </h6>
    <div class="proofs-header">
      <div class="proofs-title">
        <div class="proofs-header">
          <div class="step">
            <h6>Driving licence (Front, displaying current address)
              <a (click)="displayHelpBox('drivingLicenseFront')">
                <i class="far fa-question-circle"></i>
              </a>
            </h6>
          </div>
        </div>
        <div class="proofs-header">
          <div class="step">
            <h6>Driving licence (back)
              <a (click)="displayHelpBox('drivingLicenseback')">
                <i class="far fa-question-circle"></i>
              </a>
            </h6>
          </div>
        </div>
        <div *ngIf="CreditRating != 'Prime'">
          <div class="proofs-header">
            <div class="step">
              <h6>Proof of employment
                <a (click)="displayHelpBox('employment')">
                  <i class="far fa-question-circle"></i>
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div class="proofs-header" *ngIf="!customerPackEsign">
          <div class="step">
            <h6>Customer pack
            </h6>
          </div>
        </div>
        <div class="proofs-header" *ngIf="!lenderPackEsign  && lenderPack != null">
          <div class="step">
            <h6>Lender pack
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="desktop-only">
  <dropzone [dropZone]="dropZone" [availableProof]="availableProofs[dropZone.requiredProof.id]" (removeProofFromAvailableProofs)="removeProofFromAvailableProofs($event)"
    [isDragAndDrop]="true">
    <div class="upload-proff-section">
      <div class="upload-proff-content">
        <div class="svg">
          <img src="./assets/file-upload.png" />
        </div>
        <p>Drag and drop your file(s) here.</p>
        <div class="upload-btn-wrapper">
          <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event, true)" />
          <button (click)="fileUpload.click()" class="secondary-button">Upload file(s)</button>
        </div>
      </div>
    </div>
  </dropzone>
</div>
<div class="mobile-only">
  <dropzone [dropZone]="dropZone" [availableProof]="availableProofs[dropZone.requiredProof.id]" (removeProofFromAvailableProofs)="removeProofFromAvailableProofs($event)"
    [isDragAndDrop]="true">
    <div class="upload-proff-section">
      <div class="mobile-drop-zone">
        <div class="upload-btn-wrapper">
          <div class="tap-to-upload">
            <div class="svg">
              <img src="./assets/file-upload.png" />
            </div>
            <div class="span">
              <span>Tap here to upload files</span>
            </div>
          </div>
          <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event, true)" />
        </div>
      </div>
    </div>
  </dropzone>
</div>
<div>
  <div class="proofs-uploaded">
    <div class="proof-container" *ngFor="let proofs of availableProofs">
      <h6>{{proofs.filename}}</h6>
      <i (click)="removeProofFromAvailableProofs(proofs)" class="fa fa-times-circle fa-lg"></i>
      &nbsp;
      <i style="color:green;" class="fas fa-check"></i>
    </div>
  </div>
</div>
<div class="documents-pannel-footer">
  <button class="primary-button width" (click)="goForward()">Finish</button>
</div>
<div *ngIf="verificationModel">
  <div class="verifcationModel">
    <div class="content">
      <h1>Confirm uploads</h1>
      <p>Please double check, that you have uploaded all of the following proofs before continuing</p>
      <div class="checkbox">
        <label>&#9679; Driving licence (Front, displaying current address)</label>
      </div>
      <div class="checkbox">
        <label>&#9679; Driving licence (Back)</label>
      </div>
      <div *ngIf="CreditRating != 'Prime'">
        <div class="checkbox">
          <label>&#9679; Proof of employment</label>
        </div>
      </div>
      <div class="checkbox" *ngIf="!customerPackEsign">
        <label>&#9679; Customer pack</label>
      </div>
      <div class="checkbox" *ngIf="!lenderPackEsign">
        <label>&#9679; Lender pack</label>
      </div>
      <div class="documents-pannel-footer-finish">
        <button class="secondary-button width" (click)="displayVerificationModel(false)">Back</button>
        <button class="primary-button width" (click)="goForward()">Continue</button>
      </div>
    </div>
  </div>
</div>
<div class="verifcationModel" *ngIf="showExamples">
  <upload-examples [proofId]="proofId"></upload-examples>
</div>