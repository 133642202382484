import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { LoanOffer, Application } from '../../../core/services/types';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../core/services/user/user.service';
import { ApplicationStatusService } from '../../../core/services/application-status/application-status.service';
import { Subscription } from '../../../../../node_modules/rxjs';
import { ActionsService } from '../../../core/services/actions/actions.service';
import { dealerCommission } from '../../../core/services/types-loan-offer';

@Component({
  selector: 'offers-list-item',
  templateUrl: './offers-list-item.component.html',
  styleUrls: ['./offers-list-item.component.scss']
})
export class OffersListItemComponent implements OnChanges {

  @Input()
  offer: LoanOffer;
  @Input()
  application: Application;
  @Input()
  isLast: boolean;
  
  commission:dealerCommission;
  
  @Output()
  selectedOffer: EventEmitter<LoanOffer> = new EventEmitter<LoanOffer>();
  @Output() 
  updateProposal = new EventEmitter();

  nextPageUrl: string;
  currentQuote?;
  deposit: number;
  offersListObservers: Subscription[];
  source: string; 

  //for showing/hiding extra details
  showExtraDetails:boolean = false;

  constructor(private parentRouter: Router, private userService: UserService,private actionsService: ActionsService,
    private applicationStatusService: ApplicationStatusService, protected activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.application = this.activatedRoute.snapshot.data["application"];
    if (this.application.roles.indexOf("dealerplus dealer") != -1) {
      this.source = 'dealer';
    }
   //console.log("not broken");
    this.nextPageUrl = 'applications/' + this.application.id + '/confirmation'
   //console.log("application id = " + this.application.id);
  }
  
  acceptOffer() {
    this.application.loanProposalResponses.map(offer => {
      if(offer.isSelected){
        offer.isSelected=false;
      }
    });
    this.application.loanProposalResponses.find(offer => offer.status == "APPLICATION_ACCEPTED").isSelected = true;
    this.saveQuotes();
  }

  async saveQuotes() {
    //console.log( "I'm before application step 6");
    this.application = await this.userService.updateApplication(this.application, 6).toPromise();
    //console.log("I'm before application step 5");
    this.application = await this.userService.updateApplication(this.application, 5).toPromise(); //broken
    //console.log("I'm after both update steps");
    this.parentRouter.navigateByUrl(this.nextPageUrl);
    //console.log("i'm after redirect step");
  }

  ngOnChanges() {
    if (this.application == null || this.application.quotes == null) {
      throw Error('the application and the quotes should be populated at this stage');
    }
    
    const currentQuote = this.application.quotes.find(q => q.isSelected);
    if (currentQuote == null) {
      throw Error('At least one quote should have been selected at this stage');
    }

    this.deposit = currentQuote.financeResponse.deposit
  }

  showMoreDetails(show:boolean){
    if (show==true){
      this.showExtraDetails=true;
    }
    else{
      this.showExtraDetails=false;
    }
  }

}
