import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'properties-panel',
  templateUrl: './properties-panel.component.html',
  styleUrls: ['./properties-panel.component.scss']
})
export class PropertiesPanelComponent implements OnInit {

  @Input()
  configuration: any;

  @Input()
  designActiveControl: any;

  activePropertyName: string;

  constructor() { }

  ngOnInit() {
  }

  setActivePropertyName(activePropertyName) {

    this.activePropertyName = activePropertyName;

  }

  removeOptionValue(option) {

    var index = this.designActiveControl.selectionOptions.indexOf(option);

    if (index != -1) {
      this.designActiveControl.selectionOptions.splice(index, 1);
    }

  }

  addOptionValue() {

    this.designActiveControl.selectionOptions.push(
      {
        label: 'New option',
        value: 'NewOption',
      }
    );

  }

  moveOptionValueUp(option) {

    var index = this.designActiveControl.selectionOptions.indexOf(option);

    if (index) {
      this.arrayMove(this.designActiveControl.selectionOptions, index, index - 1);
    }

  }

  moveOptionValueDown(option) {

    var index = this.designActiveControl.selectionOptions.indexOf(option);

    if (index <= this.designActiveControl.selectionOptions.length ) {
      this.arrayMove(this.designActiveControl.selectionOptions, index, index + 1);
    }

  }

  changeControlOrder(newIndex) {

    if (newIndex>0 && newIndex <= this.configuration.controls.length) {
      var currentIndex=this.configuration.controls.indexOf(this.designActiveControl);
      this.arrayMove(this.configuration.controls, currentIndex, --newIndex,);
    }

  }

  save() {

  }

  // https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
  arrayMove(arr, old_index, new_index) {

    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing

  };

}
