import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'navbar',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() currentSection:string;
  currentUser;
  constructor() { }

  ngOnInit() {
    this.currentUser = localStorage.getItem("currentUser");
  }

}
