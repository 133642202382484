import { ErrorsConfiguration } from "./configuration.types";

const ERRORS: ErrorsConfiguration = {
    generic: {
        popupText: "<p>Your session has expired. Please login again and resume your journey.</p>",
        title: "An error occurred",
        includeExceptioninfo: true,
        reDirect: true,
        type: "generic"
    },
    notfound: {
        title: "Not found",
        popupText: "<p>The searched item could not be found, please try again or call {{TELEPHONE}} to continue your application</p>",
        includeExceptioninfo: false,
        type: "Not found"
    },
    unauthorised: {
        title: "Unauthorised",
        popupText: "<p>The user is not authorised, please try to log in again or contact us on {{TELEPHONE}} to continue your application</p>",
        includeExceptioninfo: false,
        type: "Unauthorised"
    },
    validationError: {
        title: "Validation error(s)",
        popupText: "<p>You've already created an account. Please login to continue.</p>",
        includeExceptioninfo: false,
        type: "Validation error(s)"
    },
    offline: {
        title: "No internet connection",
        popupText: "Your internet connection appears to be down. Please check and re-connect before proceeding.",
        includeExceptioninfo: false,
        reDirect: false,
        type: "No internet connection"
    },
    quote_generic: {
        popupText: "A technical error occurred with a code of {{ERROR_CODE}} while attempting to get you a quote, please contact us on {{TELEPHONE}} to continue your application.",
        title: "Get quote error occured",
        includeExceptioninfo: true,
        type: "Get quote error occured"
    },
    quote_validation: {
        title: "Quote request validation error",
        popupText: "<p>A request made by the application to the quote service was unsuccessful as the information supplied is invalid.</p><p>The following error messages were returned: <ul>{{ERROR_MESSAGES}}</ul></p><p>Please contact us on {{TELEPHONE}} to continue your application</p>",
        includeExceptioninfo: false,
        type: "Quote request validation error"
    },
    vehicle_generic: {
        title: "Get vehicle error occured",
        popupText: "A technical error occurred with a code of {{ERROR_CODE}} while attempting to return your vehicle details, please contact us on {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: true,
        type: "Get vehicle error occured"
    },
    vehicle_validation: {
        title: "Vehicle lookup validation error",
        popupText: "<p>A request made by the application vehicle lookup service was unsuccessful as the information supplied is invalid.</p><p>The following error messages were returned: <ul>{{ERROR_MESSAGES}}</ul></p><p>Please contact us on {{TELEPHONE}} to continue your application</p>",
        includeExceptioninfo: false,
        type: "Vehicle lookup validation error"
    },
    postcode_generic: {
        title: "Postcode lookup error occured",
        popupText: "A technical error occurred with a code of {{ERROR_CODE}} while attempting to return address details for the postcode you entered, please contact us on {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: true,
        type: "Postcode lookup error occured"
    },
    postcode_validation: {
        title: "Postcode lookup error",
        popupText: "<p>A request made by the application postcode lookup service was unsuccessful as the information supplied is invalid.</p><p>The following error messages were returned: <ul>{{ERROR_MESSAGES}}</ul></p><p>Please enter the address information manually to continue your application</p>",
        includeExceptioninfo: false,
        type: "Postcode lookup error"
    },
    offer_generic: {
        title: "Loan offer request error occured",
        popupText: "A technical error occurred with a code of {{ERROR_CODE}} while attempting to get you a loan offer, please contact us on {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: true,
        type: "Loan offer request error occured"
    },
    offer_validation: {
        title: "Loan offer request validation error",
        popupText: "<p>A request made by the application loan offer service was unsuccessful as the information supplied is invalid.</p><p>The following error messages were returned: <ul>{{ERROR_MESSAGES}}</ul></p><p>Please contact us on {{TELEPHONE}} to continue your application</p>",
        includeExceptioninfo: false,
        type: "Loan offer request validation error"
    },
    proofs_upload: {
        title: "Document upload error occured",
        popupText: "A technical error occurred while uploading your documents with a code of {{ERROR_CODE}}, please contact us on  {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: false,
        type: "Document upload error occured"
    },
    proofs_delete: {
        title: "Document delete error occured",
        popupText: "A technical error occurred while deleting your documents with a code of {{ERROR_CODE}}, please contact us on  {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: false,
        type: "Document delete error occured"
    },
    proofs_download: {
        title: "Document download error occured",
        popupText: "A technical error occurred while downloading your documents with a code of {{ERROR_CODE}}, please contact us on  {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: false,
        type: "Document download error occured"
    },
    notifications_generic: {
        title: "Notifications error occured",
        popupText: "A technical error occurred with a code of {{ERROR_CODE}} while attempting to return notifications, please contact us on {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: true,
        type: "Notifications error occured"
    },
    save_generic: {
        title: "Application save error occured",
        popupText: "A technical error occurred while attempting to save your application, please contact us on {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: false,
        reDirect: false,
        type: "Application save error occured"
    },
    creditRating_generic: {
        title: "Credit Rating error occured",
        popupText: "A technical error occurred while retrieving your Credit Rating information, please contact us on {{TELEPHONE}} to continue your application.",
        includeExceptioninfo: false,
        type: "Credit Rating error occured"
    }
};


export {
    ERRORS
}