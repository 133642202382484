<div [ngClass]="hideToggle? 'security-block-hide':'security-block'">
  <div class="cont">
    <div class="align">
      <i style="font-size: 24px;" class="fas fa-shield-alt"></i>
    </div>
    <div class="sb-middle">
      <h4>We care about your needs.</h4>
      <p>By proceeding, a hard search may be conducted by the lender(s), which may affect your credit profile. For more information, please see our 
        <a (click)="displayPolicy(); hideRibbon()">
          <span id="priv_policy">Terms & Conditions</span>
        </a>.</p>
    </div>
    <div id="security-close-btn">
      <a (click)="hideRibbon()">
        <i class="fa fa-times-circle"></i>
      </a>
    </div>
  </div>
</div>
<privacy-policy *ngIf="displayToggle"></privacy-policy>